import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-icon',
  template: '<svg class="sl-icon" attr.width="{{width}}px" [ngClass]="setFillOrStroke()" [class.current] = "current === true" [class.disabled] = "disabled === true" attr.height="{{height}}px" attr.stroke="{{stroke}}" attr.fill="{{fill}}"><use attr.xlink:href="../../../../../assets/icons/{{icon}}.svg#{{icon}}"/></svg>',
  styleUrls: ['./svg-icon.component.scss']
})
export class SVGIconComponent implements OnInit {

  @Input() icon!: string;
  @Input() width?: number;
  @Input() height?: number;
  @Input() size?: number = 24;
  @Input() stroke?: string;
  @Input() fill?: string;
  @Input() current?: boolean = false;
  @Input() disabled?:boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.setSize();
    this.setFillOrStroke();
  }

  public setSize() {
    if (!this.width || !this.height) {
      this.width = this.size;
      this.height = this.size;
    }
  }

  public setFillOrStroke() {
    if(this.fill){
      this.current ? this.fill = "#007e7a" : this.fill;
      return "svg_fill"
    } else {
      !this.stroke && !this.fill? this.stroke = '#969696' : this.stroke;
      this.current ? this.stroke = "#007e7a" : this.stroke;
      return "svg_stroke";
    }
  }

}
