import { Component, Input, inject } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NlpModalSectionTermReferenceService } from '../../nlp-reference-term/nlp-modal-new-section-term-reference/service/nlp-modal-section-term-reference.service';

@Component({
  selector: 'app-delete-section',
  templateUrl: './delete-section-modal.component.html',
  styleUrls: ['./delete-section-modal.component.scss'],
})
export class DeleteSectionModalComponent {
  readonly #modal = inject(NzModalRef);
  @Input() section;

  constructor(
    private serviceSelectTermReference:NlpModalSectionTermReferenceService,
    private message: NzMessageService
  ) {}

  public handleDelete() {
    this.serviceSelectTermReference.deleteDocumentSection(this.section).subscribe(
      () => {
        this.message.success('Documento excluído!', {
          nzDuration: 2500,
        });
      },
      (error) => {
        this.message.error('Erro ao excluir arquivo!', {
          nzDuration: 1500,
        });
      },
    );
    this.close();
  }

  public close() {
    this.#modal.close(true);
  }

  public handleCancel(): void {
    this.close();
  }
}
