import {
  Component,
  OnInit,
  inject,
  Input,
} from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { MessageService } from 'src/app/shared/common/services/message.service';
import { AttachmentsService } from 'src/app/shared/services/attachments.service';
import { UtilService } from 'src/app/shared/util.service';
import { LicensingService } from 'src/app/modules/projects/projects-licensing/service/licensing.service';
import { ProjectsV2Service } from 'src/app/shared/services/projects-v2.service';

@Component({
  selector: 'app-send-analisys-modal',
  templateUrl: './send-analisys-modal.component.html',
  styleUrls: ['./send-analisys-modal.component.scss'],
})
export class SendAnalisysModalComponent implements OnInit {
  readonly #modal = inject(NzModalRef);
  @Input() dataToAnalisys: any;
  @Input() subProjectId: any;
  fileList: NzUploadFile[] = [];
  progressBarValue = 0

  constructor(
    private readonly modal: NzModalService,
    private readonly message: MessageService,
    private readonly utilService: UtilService,
    private readonly attachmentsService: AttachmentsService,
    private readonly service: LicensingService,
    private projectsV2Service: ProjectsV2Service
  ) {
  }

  ngOnInit(): void {
    this.preencheProgressBar()

  }

  preencheProgressBar() {
    while (this.progressBarValue < 100) this.progressBarValue+=0.0001;
    setTimeout(() => {
      this.#modal.destroy()
    }, 2000);
  }

  close(){
    this.#modal.destroy();
  }

}
