import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-badge",
  templateUrl: "./badge.component.html",
  styleUrls: ["./badge.component.scss"]
})
export class BadgeComponent implements OnChanges {
  @Input() badgeText: string = '';
  @Input() badgeColor: 'green' | 'gray' | 'red' | 'blue' | 'yellow' | null;

  constructor() { }

  // Default style class based on the badgeText
  defaultStyleClass: string = "";

  ngOnChanges(changes: SimpleChanges): void {
    this.setBadgeText();
    if (!this.badgeColor) { // Only set default style class if badgeColor is not set
      this.setStyleClass();
    }
  }

  setBadgeText() {
    if (this.badgeText === "") {
      this.badgeText = "statusPhase.starting";
      return;
    }

    switch (this.badgeText) {
      case "INI":
        this.badgeText = "statusPhase.starting"
        break;
      case "ENV":
      case "EAN":
        this.badgeText = "statusPhase.in-analisys";
        break;
      case "ELA":
        this.badgeText = "statusPhase.in-elaboration";
        break;
      case "PEM":
        this.badgeText = "statusPhase.emitted";
        break;
      case "INF":
        this.badgeText = "statusPhase.more-info-requested";
        break;
      case "ARQ":
        this.badgeText = "statusPhase.filed";
        break;
      case "EST":
        this.badgeText = "statusPhase.organ-analising";
        break;
      case "REV":
        this.badgeText = "statusPhase.revision";
        break;
      case "CAN":
        this.badgeText = "statusPhase.canceled";
        break;
      case "CON":
        this.badgeText = "statusPhase.done";
        break;
      case "COT":
        this.badgeText = "statusPhase.in-contract";
        break;
      case "MOB":
        this.badgeText = "statusPhase.in-mobilization";
        break;
      case "ANA":
        this.badgeText = "statusPhase.in-analisys";
        break;
      default:
        this.badgeText;
    }
  }

  private setStyleClass() {
    switch (this.badgeText) {
      case "statusPhase.starting":
      case "statusPhase.filed":
      case "statusPhase.canceled":
        this.defaultStyleClass = "sl-badge-gray";
        break;
      case "statusPhase.in-progress":
      case "statusPhase.in-analisys":
      case "statusPhase.organ-analising":
      case "statusPhase.in-contract":
      case "statusPhase.in-mobilization":
        this.defaultStyleClass = "sl-badge-blue";
        break;
      case "statusPhase.revision":
      case "statusPhase.more-info-requested":
        this.defaultStyleClass = "sl-badge-yellow";
        break;
      case "statusPhase.done":
      case "statusPhase.emitted":
        this.defaultStyleClass = "sl-badge-green";
        break;
      // Add more cases for different texts
      case "statusPhase.in-elaboration":
        this.defaultStyleClass = "sl-badge-blue";
        break;
      default:
        this.defaultStyleClass = "sl-badge-gray";
        this.badgeText = "statusPhase.starting"
        break;
    }
  }
}
