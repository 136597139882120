import {Component, ViewContainerRef} from '@angular/core';
import {NzModalService} from "ng-zorro-antd/modal";
import {VideoModalComponent} from "./video-modal/video-modal.component";

@Component({
  selector: 'app-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss']
})
export class HelpCenterComponent {

  constructor(private modal: NzModalService,
              private viewContainerRef: ViewContainerRef) {
  }

  showVideo(title: string, url: string): void {
    const modal = this.modal.create<VideoModalComponent>({
      nzTitle: title,
      nzWidth: 780,
      nzContent: VideoModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
      nzOnCancel: () => {
        modal.destroy();
      },
    });
    const instance = modal.getContentComponent();
    instance.url = url;
  }

}
