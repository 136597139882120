import {Component, OnDestroy, ViewChild, OnInit, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {AnsweredQuestion, DisciplineAssessmentResponse} from "../../../shared/dto/assessment-by-id-response.dto";
import {DisciplineAssessmentService} from "../../../shared/services/discipline-assessment.service";
import {FormControl, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ProjectsV2Service} from "../../../shared/services/projects-v2.service";
import {ProjectByIdResponseDto} from "../../../shared/dto/project-by-id-response.dto";
import {AssessmentUpdateDto, QuestionDto} from "../../../shared/dto/assessment-update.dto";
import {UtilService} from "../../../shared/util.service";
import {DatePipe} from "@angular/common";
import {NzModalService} from "ng-zorro-antd/modal";
import {SuccessConfirmationComponent} from "./success-confirmation/success-confirmation.component";
import {ErrorModalComponent} from "./error-modal/error-modal.component";
import {ProgressModalComponent} from "./progress-modal/progress-modal.component";
import {SubProjectByIdResponse} from "../../../shared/dto/subproject-by-id-response.dto";
import {NzUploadFile} from "ng-zorro-antd/upload";
import {MessageService} from "../../../shared/common/services/message.service";
import {lastValueFrom, Subject, take} from "rxjs";
import {AttachmentsService} from "../../../shared/services/attachments.service";
import {AttachmentSaveDto} from "../../../shared/dto/attachment-save.dto";
import {PROJECT_ASSESSMENT_ENTITY_NAME} from "../../projects/constants/projectsNewConstants";
import {AttachmentsModel} from "../../../shared/models/attachments.model";
import {CostStudyModel} from "../../../shared/models/discipline-assessment.model";
import {NzDatePickerComponent} from 'ng-zorro-antd/date-picker';
import {BreadcrumbState} from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';
import {Store} from '@ngrx/store';
import {ProjectState} from 'src/app/shared/ngrx/breadcrumb/model';
import {BREADCRUMB_ACTIONS} from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';
import {FileValidationService} from "../../../shared/services/file-validation.service";

@Component({
  selector: 'app-project-v2-assessment',
  templateUrl: './project-v2-assessment.component.html',
  styleUrls: ['./project-v2-assessment.component.scss']
})
export class ProjectV2AssessmentComponent implements OnInit, OnDestroy {

  @ViewChild('analysisDataPicker') analysisDataPicker!: NzDatePickerComponent;

  private readonly destroy$: Subject<any> = new Subject();

  stateStore: any[] = []

  projectId: number;
  subProjectId: number;
  assessmentId: number;
  subproject: any;
  disciplineAssessment: DisciplineAssessmentResponse;
  loadingAssessment: boolean = false;
  loadingProject: boolean = false;
  project: ProjectByIdResponseDto;
  progress: number = 0;
  form!: UntypedFormGroup;
  collaborativeArchive: string = ''
  loadingSubProjects: boolean = false;
  fileList: NzUploadFile[] = [];
  fileListAlreadyUploaded: NzUploadFile[] = [];
  filesToDelete: string[] = [];
  fileListDuplicated: NzUploadFile[] = [];
  fileLisToSave: NzUploadFile[] = [];
  costStudyList: CostStudyModel[] = [];
  deleteCostStudyList: number[] = []
  disciplineAnalisys: boolean = false;
  hintOtherCost: string = 'Aqui você pode inserir custos relacionados ao processo de licenciamento de modo a dar maior visibilidade a área demandante';
  isInvalidFile: boolean = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private disciplineAssessmentService: DisciplineAssessmentService,
              private fb: UntypedFormBuilder,
              private projectsV2Service: ProjectsV2Service,
              private utilService: UtilService,
              private fileValidationService: FileValidationService,
              private datePipe: DatePipe,
              private modal: NzModalService,
              private viewContainerRef: ViewContainerRef,
              private message: MessageService,
              private attachmentsService: AttachmentsService,
              private store: Store<BreadcrumbState>,
  ) {
  }

  ngOnInit(): void {
    this.disciplineAnalisys = this.utilService.getAuthorization('edit', 'DISCIPLINEANALISYS');
    this.route.params.subscribe((params: Params) => {
      this.projectId = +params['projectId'];
      this.subProjectId = +params['subProjectId'];
      this.assessmentId = +params['assessmentId'];
      this.getDisciplineAssessment();
      this.getProject(this.projectId);
      this.getSubProjects(this.subProjectId);
      this.getAttachment();
      this.dispatchProjectOnStore();


    });
  }

  formAssessmentOnScroll() {
    document.body.click();
    this.analysisDataPicker.checkAndClose();
  }

  initForm(): void {

    let requiresFieldVisit = this.disciplineAssessment.data.requiresFieldVisit ? this.disciplineAssessment.data.requiresFieldVisit + '' : 'false';
    let analysisSummary = this.disciplineAssessment.data.analysisSummary ? this.disciplineAssessment.data.analysisSummary : '';
    let analysisDeadlineAfter = this.disciplineAssessment.data.analysisDeadlineAfter ?
      this.datePipe.transform(this.disciplineAssessment.data.analysisDeadlineAfter, 'yyyy/MM/dd') : '';

    this.form = this.fb.group({
      requiresFieldVisit: [this.disciplineAssessment.data.answeredQuestions[0].answer == null ? null : requiresFieldVisit, [Validators.required]],
      analysisDeadlineAfter: [analysisDeadlineAfter],
      analysisSummary: [analysisSummary],
    });
    this.updateValidators();
    this.buildQuestionFormControls(this.disciplineAssessment.data.answeredQuestions);
  }


  // INICIO BREADCRUMB
  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private dispatchProjectOnStore(): void {
    let retry;
    if(!this.project || !this.disciplineAssessment){
      setTimeout(() => {this.dispatchProjectOnStore(), 500});
      retry++;
    } else if(retry > 10) {
      this.getDisciplineAssessment();
      this.getProject(this.projectId);
      this.getSubProjects(this.subProjectId);
    }
     else {

      let project: ProjectState = {
        name: 'Projetos',
        url: 'projects-v2/',
        last: false,
        sublevels: true,
        project: {
          name: this.project ? this.project.name : this.subproject.projectName,
          url: 'project-v2-view/' + this.project.id,
          last: false,
        },
        subProject: {
          name: 'RIPLA',
          url: 'project-v2-analysis/' + '/' + this.projectId + '/' + this.subProjectId,
          last: true,
          discipline: {
            name: 'Análise de ' + this.disciplineAssessment.data.discipline.description,
            url: this.route.snapshot.url[0].path + '/' + this.route.snapshot.url[1].path + '/' + this.route.snapshot.url[2].path + '/' + this.route.snapshot.url[3].path,
            last: true
          }
        },
        parecerFinal: null,
        pedidoRevisao: null,
        comunicacoes: null,
        processos: null,
        atividades: null,
        subatividades: null,
        mudancaEscopo: null
      }

      this.store.dispatch(BREADCRUMB_ACTIONS.getRiplaProject({project}));
    }
  }

  // FINAL BREADCRUMB


  updateValidators() {
    if (this.form.value.requiresFieldVisit === 'true') {
      this.form.controls["analysisDeadlineAfter"].addValidators([Validators.required]);
    } else {
      this.form.controls["analysisDeadlineAfter"].clearValidators();
      this.form.controls["analysisDeadlineAfter"].updateValueAndValidity();
    }
  }

  submitForm(status: string): void {
    if (this.form.valid) {
      let payload: AssessmentUpdateDto = this.buildPayload(status);

      this.removeDuplicatedFiles();

      this.deleteAttachment(this.filesToDelete);

      let modalProgressComponent = this.modalProgress(payload);
      this.uploadFilesAndUpdateAssessment(payload, modalProgressComponent);

    } else {
      this.showInvalidFormMensages();
    }
  }

  private showInvalidFormMensages() {
    this.message.showErrorMessage("Preencha todos os campos obrigatórios antes de salvar!");
    Object.values(this.form.controls).forEach(control => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({onlySelf: true});
      }
    });
  }

  getAttachment() {

    this.attachmentsService
      .getAttachmentsByEntityAndEntityId(
        this.assessmentId,
        PROJECT_ASSESSMENT_ENTITY_NAME
      )
      .subscribe((data) => {
        const nzUploadFiles = this.buildNzUploadFileArrayFromResponse(data);
        this.fileList = nzUploadFiles;
        this.fileListAlreadyUploaded = nzUploadFiles;
      });
  }

  private buildNzUploadFileArrayFromResponse(data: AttachmentsModel[]) {
    const files: NzUploadFile[] = [];
    for (const image of data) {
      const file: NzUploadFile = {
        description: image.description,
        name: image.name,
        filename: image.name,
        uid: image.id?.toString() || '',
        thumbUrl: this.utilService.getIconFromFileName(image.name),
        status: 'done',
      };
      files.push(file);
    }
    return files;
  }

  private uploadFilesAndUpdateAssessment(payload: AssessmentUpdateDto, modalProgressComponent: ProgressModalComponent) {

    let progressPerFile = 100 / this.fileLisToSave.length;

    if (this.fileLisToSave.length) {

      this.loopThroughFiles(modalProgressComponent, progressPerFile, payload);

    } else {

      modalProgressComponent.progress = 100;

      this.updateAssessment(payload, modalProgressComponent);

    }
  }

  private loopThroughFiles(modalProgressComponent: ProgressModalComponent,
                           progressPerFile: number,
                           payload: AssessmentUpdateDto) {
    for (let file of this.fileLisToSave) {

      if (!this.fileListAlreadyUploaded.some((item) => item.uid === file.uid)) {

        const attachment = this.buildFilePayload(file);

        this.uploadAttachmentFile(attachment, modalProgressComponent, progressPerFile, payload);

      } else {

        modalProgressComponent.progress += progressPerFile;

        this.updateAssessment(payload, modalProgressComponent);
      }
    }
  }

  private uploadAttachmentFile(attachment: AttachmentSaveDto,
                               modalProgressComponent: ProgressModalComponent,
                               progressPerFile: number,
                               payload: AssessmentUpdateDto) {
    this.attachmentsService
      .uploadAttachment(attachment)
      .pipe(take(1))
      .subscribe({
        next: () => {
          modalProgressComponent.progress += progressPerFile;

          this.updateAssessment(payload, modalProgressComponent);
        }
      });
  }

  private buildFilePayload(file: NzUploadFile) {
    const attachment: AttachmentSaveDto = {
      name: file.name,
      description: file.name,
      entityId: this.assessmentId,
      entityName: PROJECT_ASSESSMENT_ENTITY_NAME,
      contextInfo: {
        phase: 'Cadastro'
      },
      file,
    };
    return attachment;
  }

  deleteAttachment(fileListToDelete: string[]): void {
    if (this.filesToDelete.length > 0) {
      for (const file of fileListToDelete) {
        this.attachmentsService
          .deleteAttachment(file)
          .pipe(take(1))
          .subscribe(() => {
          });
      }
    }
  }

  removeDuplicatedFiles() {

    this.fileLisToSave = this.fileListDuplicated
      .filter(
        (item1) =>
          !this.fileList.some(
            (item2) => item1.uid === item2.uid && item1.name === item2.name
          )
      )
      .concat(
        this.fileList.filter(
          (item2) =>
            !this.fileListDuplicated.some(
              (item1) => item2.uid === item1.uid && item2.name === item1.name
            )
        )
      );
  }

  checkIfValid(question: string) {
    if (this.form.dirty) {
      return this.form.controls[question].invalid;
    }

    return false;
  }


  modalSuccess(payload: AssessmentUpdateDto) {
    const modal = this.modal.create<SuccessConfirmationComponent>({
      nzTitle: '',
      nzClosable: true,
      nzWidth: 555,
      nzCentered: true,
      nzContent: SuccessConfirmationComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });

    const instance = modal.getContentComponent();

    if (payload.status == 'CON') {
      instance.title = 'Sua análise foi finalizada com sucesso!'
      instance.subTitle = 'A análise foi enviada para o time de licenciamento que poderá entrar em contato para eventuais revisões e validações necessárias.'
    }
    if (payload.status == 'EAN') {
      instance.title = 'Rascunho salvo com sucesso!';
      instance.subTitle = 'Você poderá retornar a esta avaliação e concluí-la depois.';
    }

    modal.afterClose.subscribe(() => {
      this.router.navigate(['/project-v2-analysis/' + this.projectId + '/' + this.subProjectId]);
    });

  }

  modalError(errorMessage: string): void {
    const modal = this.modal.create<ErrorModalComponent>({
      nzTitle: '',
      nzClosable: true,
      nzWidth: 555,
      nzCentered: true,
      nzContent: ErrorModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });

    const instance = modal.getContentComponent();

    instance.errorMessage = 'Não foi possível finalizar a avaliação';
    instance.subTitle = errorMessage;
  }

  modalProgress(payload: AssessmentUpdateDto): ProgressModalComponent {

    const modal = this.modal.create<ProgressModalComponent>({
      nzTitle: '',
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzWidth: 555,
      nzCentered: true,
      nzContent: ProgressModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });

    const instance = modal.getContentComponent();

    instance.subtitulo = 'Isso pode levar alguns minutos'
    if (payload.status == 'CON') {
      instance.titulo = 'Finalizando análise'
    }
    if (payload.status == 'EAN') {
      instance.titulo = 'Salvando rascunho';
    }
    this.progress = 0;
    instance.progress = this.progress;

    return instance;
  }

 async updateAssessment(payload: AssessmentUpdateDto, modalProgressComponent: ProgressModalComponent) {
    if (modalProgressComponent.progress > 99) {

      if (this.deleteCostStudyList && this.deleteCostStudyList.length > 0) {
        await this.deleteCostStudy();
      }

      if (payload.costStudyList && payload.costStudyList.length > 0) {
        await this.saveCostStudyList(payload.costStudyList, payload.status);
      }

      await lastValueFrom(this.disciplineAssessmentService.updateAssessment(payload)).then(
        () => {
          modalProgressComponent.progress = 100;
          this.modal.closeAll();
          this.modalSuccess(payload);
          this.getAttachment();
        }).catch(reason => {
        this.modal.closeAll();
        this.modalError(reason.error.message);
      })
    }
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    if (this.fileValidationService.isExtensionForbidden(file.name) === true) {
      this.isInvalidFile = true
      this.message.showErrorMessage(`Extensão do arquivo ${file.name} não é suportada!`);
      return false
    }

    this.verifyIfIsValidFile(file).then(result => {
      if (this.isInvalidFile === false) {
        if (this.fileValidationService.isExtensionForbidden(file.name)) {
          this.message.showErrorMessage(`Extenção do arquivo ${file.name} não é suportada!`);
        } else {
          if (this.checkFileNameLength(file) && this.checkFileSize(file)) {
            file.thumbUrl = this.utilService.getIconFromFileName(file.name);
            this.fileList = this.fileList.concat(file);
          }
        }
      } else {
        this.isInvalidFile = false
      }
    })
    return false;
  };

  async verifyIfIsValidFile(file) {

    await this.fileValidationService.isExeFile(file).then(result => {
      if (result === true) {
        this.isInvalidFile = true
        this.message.showErrorMessage(`Tipo de arquivo ${file.name} não é suportado!`);
      }
    })
    await this.fileValidationService.isZipFile(file).then(result => {
      if (result === true) {
        this.isInvalidFile = true
        this.message.showErrorMessage(`Conteúdo ou extensão do arquivo ${file.name} não é suportada!`);
      }
    })
  }

  removeFiles = (file: NzUploadFile): any => {
    this.modal.confirm({
      ...(this.message.showMessageModalConfirmDelete() as any),
      nzOnOk: () => {
        this.filesToDelete.push(file.uid);
        this.fileList = this.fileList.filter((item) => item !== file);
        this.fileListDuplicated = this.fileListDuplicated.filter(
          (item) => item !== file
        );
        this.modal.closeAll();
        return false;
      }
    });
  };

  downloadFile = (file: NzUploadFile): void => {
    if (file.uid === undefined) return;

    this.attachmentsService.getDownloadFile(+file.uid).subscribe({
      next: (resp) => this.attachmentsService.downloadFile(resp, file.name),
    });
  };

  private checkFileSize(file: NzUploadFile): boolean {
    if (file && file.size && file.size > 350 * 1024 * 1024) {
      this.message.showErrorMessage('O arquivo é muito grande. Por favor, selecione um arquivo com menos de 350 MB.');
      return false;
    }
    return true;
  }

  private checkFileNameLength(file: NzUploadFile): boolean {
    if (file.name.length > 200) {
      this.message.showErrorMessage('Nome do arquivo muito grande. Máximo é 200 caracteres.');
      return false;
    }
    return true;
  }

  private buildPayload(status: string): AssessmentUpdateDto {
    let analysisDeadlineAfter = this.utilService.DateFormatter(this.form?.value?.analysisDeadlineAfter);

    if (this.form?.value?.requiresFieldVisit == 'false') {
      analysisDeadlineAfter = '';
    }

    return {
      idSubProject: this.subProjectId,
      idDiscipline: this.disciplineAssessment.data.discipline.id,
      analysisSummary: this.form?.value?.analysisSummary,
      idUserAssessment: this.disciplineAssessment.data.userAssessment.id,
      requiresFieldVisit: this.form?.value?.requiresFieldVisit,
      analysisDeadlineAfter: analysisDeadlineAfter,
      disciplineAssessmentQuestion: this.buildQuestionPayload(),
      status: status,
      costStudyList: this.costStudyList
    }
  }

  private buildQuestionPayload(): QuestionDto[] {
    let i = 0;
    let questions: QuestionDto[] = [];
    for (let question of this.disciplineAssessment.data.answeredQuestions) {
      questions.push({
        id: question.id,
        answer: this.form.controls['question-' + i].value
      });
      i++;
    }
    return questions;
  }

  private buildQuestionFormControls(questionList: AnsweredQuestion[]) {
    let i = 0;
    for (let question of questionList) {
      let isQuestionAnswered = question.answer !== null ? question.answer + '' : '';

      let control = new FormControl(isQuestionAnswered, [Validators.required]);
      this.form.addControl('question-' + i, control);
      i++;
    }
  }

  private getDisciplineAssessment() {
    this.loadingAssessment = true;
    this.disciplineAssessmentService.getDisciplineAssessmentById(this.assessmentId).subscribe({
      next: (assessment: DisciplineAssessmentResponse) => {
        this.disciplineAssessment = assessment;
        this.setCostStudyListData();
        this.loadingAssessment = false;
        this.initForm();
      }
    });
  }

  private setCostStudyListData() {
    if (this.disciplineAssessment.data.costStudies.length > 0) {
      this.disciplineAssessment.data.costStudies.forEach(cs => {
        const costStudy: CostStudyModel = {
          id: cs.id,
          cost: cs.cost,
          idCategory: cs.category.id,
          idDisciplineAssessment: this.disciplineAssessment.data.id,
          idStudyType: cs.studyType ? cs.studyType.id : null,
          isOtherCost: cs.category.id !== 1,
          title: cs.title,
          newStudy: false,
          isAltered: false
        }
        this.costStudyList.push(costStudy);
      });
    }
  }

  private getProject(projectId: number): void {
    this.loadingProject = true;
    this.projectsV2Service.getProjectByProjectId(projectId).subscribe({
      next: (project: ProjectByIdResponseDto) => {
        this.project = project;
        this.loadingProject = false;
      }
    });
  }

  private getSubProjects(subProjectId: number) {
    this.loadingSubProjects = true;
    this.projectsV2Service.getSubProjectBySubProjectId(subProjectId).subscribe({
      next: (response: SubProjectByIdResponse) => {
        this.subproject = response
        this.loadingSubProjects = false;

        this.collaborativeArchive = response.analysisDocumentUrl

      }
    });
  }

  setCostStudyList(costStudy) {
    if (this.costStudyList && this.costStudyList.length > 0 && !costStudy.newStudy) {
      this.costStudyList.forEach(cs => {
        if (cs.id === costStudy.id) {
          cs.cost = costStudy.cost;
          cs.idCategory = costStudy.idCategory;
          cs.idStudyType = costStudy.idStudyType;
          cs.idDisciplineAssessment = costStudy.idDisciplineAssessment;
          cs.title = costStudy.title;
          cs.isAltered = true;
        }
      })
    } else {
      this.costStudyList.push(costStudy);
    }
  }

  removeCostStudy(costId: number) {
    const costStudy = this.costStudyList.filter(cost => cost.id === costId)[0];
    if (costStudy) {
      this.costStudyList = this.costStudyList.filter(cost => cost.id !== costId);
      this.deleteCostStudyList.push(costId);
    }
  }

  private async saveCostStudyList(costStudyList: CostStudyModel[], status: string) {
    for (let cs of costStudyList) {
      if (cs.newStudy) {
        await lastValueFrom(this.disciplineAssessmentService.saveCostStudy(cs)).catch(reason => {
          this.message.showErrorMessage("Erro ao salvar estudo")
        })
      } else if (cs.isAltered) {
       await lastValueFrom(this.disciplineAssessmentService.updateCostStudy(cs.id, cs)).catch(reason => {
         this.message.showErrorMessage("Erro ao alterar estudo")
       });
      }
    }
  }

  private async deleteCostStudy() {
    for (let costId of this.deleteCostStudyList) {
      await lastValueFrom(this.disciplineAssessmentService.removeCostStudy(costId)).catch(reason => {
        this.message.showErrorMessage("Erro ao deletar estudo");
      })
    }
  }
}
