
import { createAction, props } from '@ngrx/store';

import { ACTION } from './constants';
import { ProjectState } from './model';

const getRiplaProject = createAction(
    ACTION.LOAD_PROJECT_RIPLA,
    props<{project: ProjectState}>()
);

export const BREADCRUMB_ACTIONS = {
    getRiplaProject
}