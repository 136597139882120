import {Component, Input, OnInit} from '@angular/core';
import {ProjectsV2Service} from "../../../../shared/services/projects-v2.service";
import {ProjectByIdResponseDto} from "../../../../shared/dto/project-by-id-response.dto";
import {AttachmentsService} from "../../../../shared/services/attachments.service";
import {AttachmentsModel} from "../../../../shared/models/attachments.model";

@Component({
  selector: 'app-project-info',
  templateUrl: './project-info.component.html',
  styleUrls: ['./project-info.component.scss']
})
export class ProjectInfoComponent implements OnInit {
  @Input() projectId!: number;
  loading: boolean = false;
  @Input() project: ProjectByIdResponseDto;
  attachments: AttachmentsModel[];
  @Input() subProjectId!: number;

  constructor(private projectsV2Service: ProjectsV2Service,
              private attachmentsService: AttachmentsService) { }

  ngOnInit(): void {
    if(this.project === undefined) {
      this.getProject(this.projectId);
    }

    this.getAttachments(this.subProjectId);
  }

  private getProject(projectId: number):void {
    this.loading = true;
    this.projectsV2Service.getProjectByProjectId(projectId).subscribe({
      next: (project: ProjectByIdResponseDto) => {
        this.project = project;
        this.loading = false;
      }
    });
  }

  private getAttachments(projectId: number): void{
    this.attachmentsService.getAttachmentsByEntityAndEntityId(projectId, 'SUB_PROJECT').subscribe({
      next: (attachments) => {
        this.attachments = attachments;
      }
    });
  }

  downloadFile(id: number | undefined, name: string):void {
    if(id===undefined) return;

    this.attachmentsService.getDownloadFile(id).subscribe({
      next: (resp) => this.attachmentsService.downloadFile(resp, name),
    });
  }

  getHintText(fileName: string): string {
    if(fileName.length > 60){
      return fileName
    }
    return "";
  }
}
