<div class="center-container">


  <div nz-row nzJustify="center" nzAlign="middle">

    <div nz-col nzSpan="8">
      <h1>{{ 'welcomeMessage' | translate }}</h1>
    </div>
    <div nz-col nzSpan="8" nzOffset="2" class="content">
      <p>Caso você ainda não tenha um estudo cadastrado na ferramenta de análise, clique no botão abaixo para cadastrá-lo:<br/>
      <button nz-button class="btn-sl-green" (click)="showAddProjInformationsModal()">
        Cadastrar Estudo
      </button></p>

      <p class="mt-20">Caso você já tenha um estudo cadastrado na ferramenta de análise, selecione-o na sua lista clicando no botão abaixo:<br/>
      <button nz-button class="btn-sl-green" routerLink="/nlp/list" >
        Selecionar Estudo
      </button></p>
    </div>

  </div>
</div>
