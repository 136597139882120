import { ActivatedRoute, Params } from '@angular/router';
import { AfterViewInit, Component, Input, OnChanges, OnInit } from '@angular/core';
@Component({
  selector: 'app-communications-container',
  templateUrl: './communications-container.component.html',
  styleUrls: ['./communications-container.component.scss']
})
export class CommunicationsContainerComponent implements OnChanges, AfterViewInit {
  @Input() communicationsList;
  @Input() params;
  @Input() disabledBlock?;
  @Input() loadingCommunications!: boolean;

  customStyle = {
    background: 'white',
    'font-size': '12px',
  }; // TODO - COMP PADRAO

  constructor(
    private _route: ActivatedRoute,
  ) { }

  ngOnChanges(): void {
    this.isParams();
  }

  ngAfterViewInit(): void {
    this.isParams()
  }

  isParams(): void {
    this._route.parent?.params
      .subscribe({
        next: (params: Params) => {
          this.params = {
            projectId: +params['projectId'],
            processId: +this._route?.snapshot.params['processId'],
            activityId: +this._route?.snapshot.params['activityId'],
          }
        }
      });
  }
}
