import {Component, inject, Input, OnInit} from '@angular/core'
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms'

import {NzModalRef, NzModalService} from "ng-zorro-antd/modal";

import { DisciplineAssessmentDto } from 'src/app/shared/dto/discipline-assessment.dto'

import { MessageService } from 'src/app/shared/common/services/message.service'
import { NlpModalSectionTermReferenceService } from './service/nlp-modal-section-term-reference.service'
import { mergeMap } from 'rxjs'
import { myParam, newSection, Section } from '../../interface/nlp-interface'
import { UserService } from 'src/app/shared/services/user.service'


@Component({
  selector: 'app-nlp-modal-new-section-term-reference',
  templateUrl: './nlp-modal-new-section-term-reference.component.html',
  styleUrls: ['./nlp-modal-new-section-term-reference.component.scss']
})
export class NlpModalNewSectionTermReferenceComponent implements OnInit {

  readonly #modal = inject(NzModalRef);
  idDocReference:number | undefined |null;
  idProjectNLP:number | undefined;
  flagNewSection:boolean = true;
  section:Section | undefined;
  @Input() typeSave;
  @Input() selectedAssessment?: DisciplineAssessmentDto
  form!: UntypedFormGroup;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly modal: NzModalService,
    private readonly message: MessageService,
    private service:NlpModalSectionTermReferenceService,
    private userService:UserService
    ) { }

  ngOnInit(): void {
    this.flagNewSection =  this.userService.isFlagNlpNewSection;
    this.userService.productNlpObservable$.subscribe(data =>  {
      this.idProjectNLP = data.id;
      this.idDocReference = data.idDocReference;});
    this.section = this.userService.selectSection;
    this.initForm()
  }

  initForm(): void {
    this.form = this.fb.group({
      nome: [ , [Validators.required]],
      title: [this.typeSave === 'position'? '' : this.section?.title, [Validators.required]],
      content: [this.typeSave === 'position'? '' : this.section?.description]
    });

    if(!this.flagNewSection){
      this.form.controls['nome'].setValidators(null);

    }

  }


  submitForm(): void {
    if(this.flagNewSection){
      this.newProject();
    }
    else{
        this.newSection(this.typeSave);
    }
  }

   newProject(): void{
    if (this.form.valid) {
      this.service.postDocumentReference( this.idProjectNLP , this.form.controls['nome'].value)?.pipe(
        mergeMap(result =>  this.service.postDocumentSection( result,this.form.value)))
      .subscribe(result1 => {
        this.userService.idSectionlist = result1.idDocReference;
        this.userService.setDocReferenceId(result1.idDocReference);
        this.close();
      }, (error =>  this.onError(error) ));
    } else {
      Object.values(this.form.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }


  private onError(error: any) {
    this.message.showErrorMessage(`Erro ao avaliar seção: ${error.message}`);
  }


  newSection(typeSave:myParam): void{
    if (this.form.valid) {


      if(typeSave === 'position'){
          let result :newSection = {id:this.idDocReference , position : this.section?.dsOrder !== undefined ? this.section?.dsOrder + 1 : null };

          this.service.postDocumentPositionSection( result,this.form.value)
          .subscribe(() => {
            this.close();
          });
      }
      if(typeSave === 'edit'){
        let result = {id:this.section?.id }
        this.service.editDocumentSection( result,this.form.value)
        .subscribe(() => {
          this.close();
        });
    }

      if(typeSave === 'new'){
        let result = {id:this.idDocReference}
         if (
           (this.form.value.description == '' ||
           this.form.value.description == undefined) && (this.form.value.content == '' || this.form.value.content == undefined)
         ) {
           this.form.value.content = ' ';
         }
        this.service.postDocumentSection( result,this.form.value)
        .subscribe(() => {
          this.close();
        });
    }

    } else {
      Object.values(this.form.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }


  showCancelConfirm(): void {
    if(this.form.dirty){
      this.modal.confirm({
        ...(this.message.showMessageModalConfirmCancel() as any),
        nzOnOk: () => this.close(),
      });
    } else {
      this.close();
    }
  }

  private close() {
   this.#modal.close(true);
  }

  handleCancel(): void {
    this.#modal.triggerCancel();
  }


  updateFlagSubject(flag:boolean) {
    // Actualización del mensaje
    this.service.documentFlagSubject.next(flag);
  }

}
