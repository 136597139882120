<div class="log" style="max-height: 100%;">
  <div class="log__header">
    <h2 class="log__header__title ">{{ 'projects.view.history.header-title' | translate }}</h2>
  </div>
  <div class="log__list">
    <nz-timeline class="log__list__timeline" id="timeline-sm">
      <nz-timeline-item class="timeline-sm" [nzDot]="dotTemplate" *ngFor="let log of logs">
        <div class="log__list__timeline__item">
          <span class="log__list__timeline__item__title timeline-title_span">{{log.userName}}</span>
          <div class="log__list__timeline__item__description" [innerHTML]="log.logDescription"></div>
          <span class="log__list__timeline__item__date">{{log.registrationDate+"Z" | date: 'dd/MM/yyyy HH:mm' : 'America/Sao_Paulo'}}</span>
        </div>
      </nz-timeline-item>
      <div style="height: 60px; width: 100%;"></div>
    </nz-timeline>
    <ng-template #dotTemplate>
      <img src="assets/icons/ellipse.svg" alt="">
    </ng-template>
  </div>
  <div *ngIf="logs.length > 2" class="log__list__gradient"></div>
</div>
