import { Store } from '@ngrx/store';
import { Subject, forkJoin, takeUntil } from 'rxjs';
import { DatePipe, Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { NzModalService } from 'ng-zorro-antd/modal';

import { LicensingActivityEnum } from 'src/app/shared/enum/licensing-activity.enum';

import { ProjectState } from 'src/app/shared/ngrx/breadcrumb/model';

import { BREADCRUMB_ACTIONS } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';

import { BreadcrumbState } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';

import {
  LicenseActivitiesBody,
  LicenseActivitityUpdateBody,
} from 'src/app/modules/projects/projects-licensing/licensing.interface';

import { UtilService } from 'src/app/shared/util.service';
import { UserService } from 'src/app/shared/services/user.service';
import { MessageService } from 'src/app/shared/common/services/message.service';
import { ProjectsV2Service } from 'src/app/shared/services/projects-v2.service';
import { TextShortnerHelper } from 'src/app/shared/components/text-shortner/text-shortner.helper';
import { CommunicationsListResponseDto } from 'src/app/shared/dto/communications-list-response.dto';
import { LicensingService } from 'src/app/modules/projects/projects-licensing/service/licensing.service';

@Component({
  selector: 'app-new-activity',
  templateUrl: './new-activity.component.html',
  styleUrls: ['./new-activity.component.scss'],
})
export class NewActivityComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<any> = new Subject();
  activity;
  user: any;
  formFields;
  params: any;
  project: any; //ProjectByIdResponseDto;
  isEIA = false;
  activitiesTypes;
  editMode = false;
  listOfUsers: any;
  userRoleList?: any;
  userComplexList?: any;
  disabledActions = true;
  projectIdParam: number;
  activityIdParam: number;
  activityIdParamForBreadcrumb: number;
  projectComplexList?: any;
  badgeColor: any = 'gray';
  activitySubIdParam: number; //Id da atvidade, usado para criar uma subatividade
  titleActivityEdit: string = '';
  projectLicensingIdParam: number;
  isUserPermission: boolean = true;
  enumStatusActivity = LicensingActivityEnum;
  listOfActivities: Array<{ id: string; description: string }> = [];
  customStyle = {
    background: 'none',
    'font-size': '12px',
  };
  communicationsList: CommunicationsListResponseDto[] = [];

  nzFilterOption = (): boolean => true;

  compareFn = (o1: string, o2: string) => (o1 && o2 ? o1 == o2 : false);
  license: any;
  licensePreview: any;
  activityPrevious: any;
  retry = 0;
  loadingCommunications = true;
  cadActivityInsert: boolean = false;
  cadActivityEdit: boolean = false;
  cadActivityExclude: boolean = false;
  cadCommunications: boolean = false;
  projectId: number;
  savingActivity: boolean = false;

  constructor(
    public readonly utilService: UtilService,
    private readonly _location: Location,
    private readonly _message: MessageService,
    private readonly _router: Router,
    private readonly _modalService: NzModalService,
    private _licensingService: LicensingService,
    private _projectsV2Service: ProjectsV2Service,
    private _userService: UserService,
    private _route: ActivatedRoute,
    private _datePipe: DatePipe,
    private _store: Store<BreadcrumbState>,
    public textShortnerHelper: TextShortnerHelper
  ) {}

  ngOnInit() {
    this.cadActivityInsert = this.utilService.getAuthorization(
      'insert',
      'CADACTIVITY'
    );
    this.cadActivityEdit = this.utilService.getAuthorization(
      'edit',
      'CADACTIVITY'
    );
    this.cadActivityExclude = this.utilService.getAuthorization(
      'exclude',
      'CADACTIVITY'
    );
    this.cadCommunications = this.utilService.getAuthorization(
      'insert',
      'CADCOMMUNICATIONS'
    );
    this._route.params.subscribe(() => {
      this.getRouteIds();
      this.setMode();
      this.initForm();
      this.loadUserSession();
      this.getComplex();
      this.getTitles();
      this.getProject();
    });
  }

  setMode() {
    if (window.location.href.indexOf('edit') > -1) {
      this.editMode = true;
      this.disabledActions = false;
      this.getActivityById();
    } else {
      this.editMode = false;
      this.getProject();
    }
  }

  // INICIO BREADCRUMB
  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  getLicensesByProjectId(projectId) {
    this._licensingService.getLicencing(projectId).subscribe({
      next: (licenses) => {
        this.license = licenses;
        this.licensePreview = licenses;
      },
      complete: () => {
        this.getActivityById();
      },
      error: () => {
        console.error('Erro no Processo');
      },
    });
  }

  getProject() {
    this._projectsV2Service.getProjectByProjectId(this.projectIdParam).subscribe({
      next: (project) => {
        this.project = project;
      },
      complete: () => {
        this.getLicensesByProjectId(this.projectLicensingIdParam);
        this.dispatchProjectOnStore();
      },
    });
  }

  private dispatchProjectOnStore(): void {
    if (this.activity) {
      let project: ProjectState = {
        name: 'Projetos',
        url: '/projects-v2',
        last: false,
        sublevels: true,
        project: {
          name: this.project ? this.project.projectName : null,
          url: 'project-v2-view/' + this.project ? this.project?.id : '',
          last: false,
        },
        subProject: null,
        parecerFinal: null,
        pedidoRevisao: null,
        comunicacoes: null,
        processos: {
          name: this.license ? this.license.title : '',
          url:
            'project/' +
            this.project?.id +
            '/edit-processv2/' +
            this._route.snapshot.url[1].path,
          last: false,
        },
        atividades: {
          name: this.activity.activityParentId
            ? this.activityPrevious?.title?.description
            : this.activity.title.description,
          url:
            'projects/' +
            this.project?.id +
            '/process/' +
            this._route.snapshot.url[1].path +
            '/edit-activity/' +
            this._route.snapshot.url[1].path,
          last: false,
        },
        subatividades: this.activity.activityParentId ? {
          name: this._route.snapshot.url[2].path === 'new-sub-activity' ? 'Nova subatividade' : this.activity.title.description,
          url: 'projects/' + this.project.id + '/process/' + this._route.snapshot.url[1].path + '/edit-activity/' + this._route.snapshot.url[3].path,
          last: true,
        } : null,
        mudancaEscopo: null
      }
      if(this._route.snapshot.url[2].path === 'new-sub-activity') {
        project.subatividades = {
          name: 'Nova subatividade',
          url: '',
          last: false
        }
      }
      this._store.dispatch(BREADCRUMB_ACTIONS.getRiplaProject({ project }));
    } else {
      let project: ProjectState = {
        name: 'Projetos',
        url: '/projects-v2',
        last: false,
        sublevels: true,
        project: {
          name: this.project ? this.project.projectName : null,
          url: 'project-v2-view/' + this.project ? this.project?.id : '',
          last: false,
        },
        subProject: null,
        parecerFinal: null,
        pedidoRevisao: null,
        comunicacoes: null,
        processos: {
          name: this.license
            ? this.license.title
            : this.licensePreview
            ? this.licensePreview.title
            : '',
          url:
            'project/' +
            this.project?.id +
            '/edit-processv2/' +
            this._route.snapshot.url[2].path,
          last: false,
        },
        atividades: {
          name: this.activity
            ? this.activity.title
            : this._route.snapshot.url[2].path === 'new-sub-activity'
            ? 'Nova subatividade'
            : 'Nova atividade',
          url: this.activity
            ? 'projects/' +
              this.project?.id +
              '/process/' +
              this._route.snapshot.url[1].path +
              '/edit-activity/' +
              this._route.snapshot.url[1].path
            : 'projects/' +
              this.project?.id +
              '/process/' +
              this._route.snapshot.url[1].path +
              '/new-activity',
          last: false,
        },
        subatividades:  {
          name: this.activity ? this.activity.title : (this.activity && this._route.snapshot.url[2].path === 'new-sub-activity' ? 'Nova subatividade' : null),
          url: this.activity ? ('projects/' + this.project?.id + '/process/' + this._route.snapshot.url[1].path + '/edit-activity/' + this._route.snapshot.url[1].path) : 'projects/' + this.project?.id + '/process/' + this._route.snapshot.url[1].path + '/new-activity',
          last: false
        },
        mudancaEscopo: null
      }
      this._store.dispatch(BREADCRUMB_ACTIONS.getRiplaProject({ project }));
    }
  }
  // FINAL BREADCRUMB

  initForm() {
    this.formFields = {
      title: 0,
      description: '',
      responsible: '',
      deadline: '',
      conclusionDate: '',
      status: '',
    };
  }

  isUpdate(): void {
    if (this.activityIdParam) {
      this.getActivityById();
    }
    this.isEiaProduct();
    this.getTitles();
    if (
      this._route.snapshot.url[2].path === 'new-sub-activity' ||
      this._route.snapshot.url[2].path === 'new-activity'
    ) {
      this.getLicensesByProjectId(
        Number(this._route?.snapshot.params['processId'])
      );
    } else {
      this.getActivityById();
    }
    this.dispatchProjectOnStore();
  }

  isDisableInput(): boolean {
    return !(!this.activityIdParam && this.isUserPermission);
  }

  setColorBadge(status: string): void {
    switch (status) {
      case this.enumStatusActivity.START:
      case this.enumStatusActivity.CANCELED:
        this.badgeColor = 'gray';
        break;
      case this.enumStatusActivity.HIRING:
      case 'MOB':
      case this.enumStatusActivity.ELABORATION:
      case this.enumStatusActivity.REVIEW:
        this.badgeColor = 'yellow';
        break;
      case this.enumStatusActivity.COMPLETED:
        this.badgeColor = 'green';
        break;
      default:
        this.badgeColor = 'default';
    }
  }

  loadUserSession(): void {
    const userRecuperado = sessionStorage.getItem('user');
    this.user = userRecuperado ? JSON.parse(userRecuperado) : null;
  }

  buildPayload(): LicenseActivitiesBody {
    return {
      projectLicensingId: this.projectLicensingIdParam,
      titleId: this.formFields.title,
      description: this.formFields.description,
      userResponsibleId: this.formFields.responsible,
      deadline:
        this._datePipe
          .transform(this.formFields.deadline, 'yyyy-MM-dd')
          ?.toString() + 'T00:00:00',
    };
  }

  buildCustomMessageDelete(): any {
    return {
      nzTitle: 'Deseja mesmo excluir esta atividade?',
      nzContent:
        'As informações contidas na atividade e subatividades relacionadas serão perdidas e não poderão ser recuperadas.',
      nzOkText: 'Excluir atividade',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzCancelText: 'Voltar',
      nzCentered: true,
      nzWidth: 580,
      nzClosable: false,
    };
  }

  navigateBack() {
    this._location.back();
  }

  navigateProcess() {
    if (this.activitySubIdParam) {
      this._router.navigate([
        `/projects/${this.projectIdParam}/process/${this.projectLicensingIdParam}/edit-activity/${this.activitySubIdParam}`,
      ]);
    } else {
      this._router.navigate([
        `/project/${this.projectIdParam}/edit-processv2/${this.projectLicensingIdParam}`,
      ]);
    }
  }

  search(event: string): void {
    this.listOfActivities = this.activitiesTypes.filter((activity) =>
      activity.description.toLowerCase().includes(event.toLowerCase())
    );
  }

  onCancel(): void {
    this._modalService.confirm({
      ...(this._message.showMessageModalConfirmCancel() as any),
      nzOnOk: () => {
        this.navigateProcess();
      },
    });
  }

  onChangeStatusActivity(statusEnum: string, color: string): void {
    this.formFields.status = statusEnum;
    this.badgeColor = color;
    this.updateActivity();
  }

  validateUserPermission(): void {
    this.isUserPermission = true;
    return;
  }

  validateUserPermissionManager(): boolean {
    if (this.userRoleList && this.userRoleList.length > 0) {
      const role = this.userRoleList.filter(
        (userRole) => userRole.role === 'ROLE_GESTOR'
      );
      return role.length > 0;
    } else {
      return false;
    }
  }

  // REQUESTS
  getComplex(): void {
    /* GLOBAL USE - MOVE TO USER SERVICE */
    const userLogged = this._userService.getLoogedUser();
    const currentProject = this._projectsV2Service.getProjectByProjectId(
      this.projectIdParam
    );
    forkJoin([userLogged, currentProject])
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ([user, project]) => {
          console.log(project)
          this.project = project;
          this.userComplexList = user.complexes;
          this.userRoleList = user.roles;
          this.projectComplexList = project.complex;
          this.getResponsibleList();
          this.validateUserPermission();
          this.validateUserPermissionManager();
        },
        error: (err) => {
          this._message.showErrorMessage(err.error.errors.details);
        },
      }); // TODO CAROL - chamada duplicada?
  }

  public getRouteIds() {
    this._route.parent?.params.pipe(takeUntil(this.destroy$)).subscribe({
      next: (params: Params) => {
        this.projectIdParam = +params['projectId'];
        this.projectLicensingIdParam = Number(
          this._route?.snapshot.params['processId']
        );
        if (this._route?.snapshot.params['activityId']){
          this.activityIdParam = Number(
            this._route?.snapshot.params['activityId']
          );
        } else {
          this._route.queryParams.subscribe(params => {
            this.activityIdParam = params['activityId']
          })
        }
        this.activitySubIdParam = Number(
          this._route?.snapshot.params['activitySubId']
        );
        this.isUpdate();
      },
      error: (err) => {
        this._message.showErrorMessage(err.error.errors.details);
        this.navigateProcess();
      },
    });
  }

  getTitles() {
    if (this.isEIA) {
      this._licensingService.getTitles('EIA').subscribe({
        next: (data) => {
          this.activitiesTypes = data;
          this.listOfActivities = data;
        },
        error: (err) => {
          this._message.showErrorMessage(err.error.errors.details);
          this.navigateProcess();
        },
      });
    } else {
      this._licensingService.getTitles().subscribe({
        next: (data) => {
          this.activitiesTypes = data;
          this.listOfActivities = data;
        },
        error: (err) => {
          this._message.showErrorMessage(err.error.errors.details);
          this.navigateProcess();
        },
      });
    }
  }

  getResponsibleList() {
    this._userService
      .getUsersByComplex(this.projectComplexList.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.listOfUsers = response;
        },
        error: (err) => {
          this._message.showErrorMessage(err.error.errors.details);
          this.navigateProcess();
        },
      });
  }

  getActivityByIdForBreadcrumb(id: number): void {
    this._licensingService.getActivityById(id).subscribe({
      next: (response) => {
        this.activity = response;
        if (this.activity?.activityParentId) {
          this._licensingService
            .getActivityById(this.activity?.activityParentId)
            .subscribe({
              next: (result) => {
                this.activityPrevious = result;
              },
            });
        }
      },
      error: (err) => {
        console.log("Erro ao carregar api", err)
      },
      complete: () => {
        this.dispatchProjectOnStore();
      },
    });
  }

  getActivityById(): void {
    if (
      this._route.snapshot.url[2].path === 'new-sub-activity' &&
      !this.activity
    ) {
      this._licensingService
        .getActivityById(this.activityIdParam)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response) => {
            this.activity = response;
            this.formFields = {
              title: response.title.id,
              description: response.description,
              responsible: response.responsibleUser.id,
              deadline: response.deadline + 'T00:00:00',
              conclusionDate: response.conclusionDate,
              status: response.status,
            };
            if(this._route.snapshot.url[2].path === 'new-sub-activity'){
              this.formFields = {
                title: '',
                description: '',
                responsible: '',
                deadline: '',
                conclusionDate: '',
                status: '',
              };
            }
            this.activityIdParam = response.activityParentId;
            this.communicationsList = response.communications;
            this.loadingCommunications = false;

            this.setColorBadge(response.status);
            this.titleActivityEdit = response.title.description;
            if (this.activity?.activityParentId) {
              this._licensingService
                .getActivityById(this.activity?.activityParentId)
                .subscribe({
                  next: (result) => {
                    this.activityPrevious = result;
                  },
                });
            }
            this.isEiaProduct();
          },
          complete: () => {
            this.dispatchProjectOnStore();
          },
          error: (err) => {
            this._message.showErrorMessage(err.error.errors.details);
            this.navigateProcess();
          },
        });
    }
    if (
      this._route.snapshot.url[2].path === 'new-sub-activity' ||
      this._route.snapshot.url[2].path === 'new-activity'
    ) {
      this.isDisableInput()
      return;
    }
    this._licensingService
      .getActivityById(this.activityIdParam)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.activity = response;
          this.formFields = {
            title: response.title.id,
            description: response.description,
            responsible: response.responsibleUser.id,
            deadline: response.deadline + 'T00:00:00',
            conclusionDate: response.conclusionDate,
            status: response.status,
          };
          this.communicationsList = response.communications;
          this.loadingCommunications = false;

          this.setColorBadge(response.status);
          this.titleActivityEdit = response.title.description;
          if (this.activity?.activityParentId) {
            this._licensingService
              .getActivityById(this.activity?.activityParentId)
              .subscribe({
                next: (result) => {
                  this.activityPrevious = result;
                },
              });
          }
          this.isEiaProduct();
        },
        complete: () => {
          this.dispatchProjectOnStore();
        },
        error: (err) => {
          this._message.showErrorMessage(err.error.errors.details);
          this.navigateProcess();
        },
      });
  }

  saveActivity() {
    this.savingActivity = true;
    if (
      !this.formFields.title ||
      !this.formFields.description ||
      !this.formFields.responsible ||
      !this.formFields.deadline
    ) {
      this._message.showErrorMessage(
        'Todos os campos obrigatórios devem ser preenchidos!'
      );
      this.savingActivity = false;
      return;
    }

    const payload = this.buildPayload();
    let feedbackSucess = 'Nova atividade cadastrada';

    if (this.activitySubIdParam) {
      payload.activityParentId = this.activitySubIdParam;
      feedbackSucess = 'Nova subatividade cadastrada';
    }

    this._licensingService.createNewActivity(payload).subscribe({
      next: () => {
        this._message.showSucessMessage(feedbackSucess);
        this.savingActivity = false;
        this.navigateProcess();
      },
      error: (err) => {
        if (err.error && err.error.message) {
          this._message.showErrorMessage(err.error.message);
        } else if (err.error && err.error.errors.details) {
          this._message.showErrorMessage(err.error.errors.details);
        } else if (err.message) {
          this._message.showErrorMessage(err.message);
        } else {
          this._message.showErrorMessage('Erro ao criar atividade');
        }
        this.savingActivity = false;
      },
    });
  }

  deleteActivity() {
    if (this.activityIdParam || this.activitySubIdParam) {
      let id = this.activityIdParam
        ? this.activityIdParam
        : this.activitySubIdParam;
      this._modalService.confirm({
        ...this.buildCustomMessageDelete(),
        nzOnOk: () => {
          this._licensingService.deleteActivity(id).subscribe({
            next: () => {
              this._message.showSucessMessage('Atividade Removida');
              this.navigateProcess();
            },
            error: () => {},
          });
        },
      });
    }
  }

  isEiaProduct() {
    if (
      this.activity &&
      this.activity.title.description === 'Estudo de Impacto Ambiental'
    ) {
      return (this.isEIA = true);
    } else if (this._route?.snapshot.params['product'] === 'EIA') {
      return (this.isEIA = true);
    } else {
      return (this.isEIA = false);
    }
  }

  toNewSubActivity() {
    if (this.activityIdParam) {
      this._router.navigate([
        `/projects/${this.projectIdParam}/process/${this.projectLicensingIdParam}/new-sub-activity/${this.activityIdParam}`,
        'REF',
      ]);
    }
  }

  buildPayloadUpdate(): LicenseActivitityUpdateBody {
    return {
      userResponsibleId: this.formFields.responsible,
      deadline: this._datePipe
        .transform(this.formFields.deadline, 'yyyy-MM-dd')
        ?.toString() as string,
      description: this.formFields.description,
      status: this.formFields.status,
    };
  }

  updateActivity(): void {
    if (!this.editMode) return;

    this._licensingService
      .updateActivityById(this.activityIdParam, this.buildPayloadUpdate())
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.formFields.conclusionDate = data.conclusionDate;
          this._message.showSucessMessage('Atividade Atualizada');
        },
        error: (err) => {
          this._message.showErrorMessage(err.error.errors.details);
        },
      });
  }

  newCommunication(type: string) {
    let navigationParams = {
      queryParams: {
        projectId: this.projectIdParam,
        processId: this.license.id,
        type: type,
        activityId: this.activity.id,
      },
    };

    this._router.navigate([`/communications/new`], navigationParams);
  }
}
