import { Subject } from "rxjs";
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { AttachmentsModel } from "src/app/shared/models/attachments.model";

import { AttachmentsService } from "src/app/shared/services/attachments.service";
import { CommunicationByIdResponse } from "src/app/shared/dto/communication-by-id-response.dto";
import { LicensingService } from "src/app/modules/projects/projects-licensing/service/licensing.service";

import { COMMUNICATION_ENTITY_NAME } from "src/app/modules/projects/constants/projectsNewConstants";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-communication-summary',
  templateUrl: './communication-summary.component.html',
  styleUrls: ['./communication-summary.component.scss']
})

export class CommunicationSummaryComponent implements OnInit {
  @Input() communication!: Subject<CommunicationByIdResponse>;
  @Input() attachments: AttachmentsModel[];

  communicationSummary: CommunicationByIdResponse;
  type: string | null;

  constructor(
    private licensingService: LicensingService,
    private route: ActivatedRoute,
    private attachmentsService: AttachmentsService) {
  }

  ngOnInit(): void {
    this.type = this.route.snapshot.queryParamMap.get('type');

    this.communication.subscribe(c => {
      this.communicationSummary = c;
      this.getAttachments();
    });
  }

  private getAttachments(): void {
    if (this.communicationSummary.id === undefined) return;
    this.attachmentsService.getAttachmentsByEntityAndEntityId(this.communicationSummary.id, COMMUNICATION_ENTITY_NAME).subscribe({
      next: (attachments) => {
        this.attachments = attachments;
      }
    });
  }

  downloadFile(id: number | undefined, name: string): void {
    if (id === undefined) return;

    this.attachmentsService.getDownloadFile(id).subscribe({
      next: (resp) => this.attachmentsService.downloadFile(resp, name),
    });
  }

  getLocation(): string {
    let location = '-';

    location = this.communicationSummary?.project?.region?.description + ' / '
      + this.communicationSummary?.project?.complex?.description
      + (this.communicationSummary?.project?.operationalUnit?.description ? ' / ' + this.communicationSummary?.project?.operationalUnit?.description : '');



    return this.communicationSummary?.project?.region?.description !== undefined ? location : '-';
  }

  getOrganResponse(): string {
    if (this.communicationSummary?.organResponse === undefined) return '-';
    return this.communicationSummary?.organResponse ? 'Sim' : 'Não';
  }

}
