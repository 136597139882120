<div class="documents-list">
  <div nz-row class="filters mt-20">
    <div nz-col [nzSpan]="10">
      <nz-input-group [nzPrefix]="prefixIconSearch" nzSize="large" class="search-input">
        <input id="txtPesquisar" nz-input placeholder="Pesquisar..." [(ngModel)]="value" (input)="searchFilter()" />
      </nz-input-group>
      <ng-template #prefixIconSearch>
        <span nz-icon nzType="search"></span>
      </ng-template>
    </div>
    <div nz-col [nzSpan]="14">
      <div nz-row class="selects">
        <div nz-col [nzSpan]="6" class="optionSelects">
          <div class="select-title">Complexo</div>
          <nz-select nzAllowClear [nzDisabled]="complexDisabled" [nzBorderless]="true" name="status" id="status"
            class="status" [(ngModel)]="filterByComplex" (ngModelChange)="filterByComplexElements()">
            <nz-option nzValue="{{ complex }}" nzLabel="{{ complex }}"
              *ngFor="let complex of filterComplex"></nz-option>
          </nz-select>
        </div>

        <div nz-col [nzSpan]="6" class="optionSelects">
          <div class="select-title">Responsável</div>
          <nz-select nzAllowClear [nzBorderless]="true" [(ngModel)]="filterByResponsible" name="status" id="status"
            class="status" (ngModelChange)="filterByResponsibleElements()">
            <nz-option nzValue="{{ responsible }}" nzLabel="{{ responsible }}"
              *ngFor="let responsible of filterResponsible"></nz-option>

          </nz-select>
        </div>
        <div nz-col [nzSpan]="6" class="optionSelects">
          <div class="select-title">Agrupar por</div>
          <nz-select [nzDisabled]="levelGroup" nzAllowClear [nzBorderless]="true" [(ngModel)]="groupedBy" name="grouped"
            id="grouped" class="status" (ngModelChange)="filterByGroupElements()">
            <nz-option nzValue="complexName" nzLabel="Complexo"></nz-option>
            <nz-option nzValue="user" nzLabel="Responsável"></nz-option>
          </nz-select>
        </div>
      </div>
    </div>
  </div>
  <div class="table-outer-border">
    <div nz-row>
      <div nz-col [nzSpan]="24" style="
          display: flex;
          justify-content: space-between;
          margin: 5px 0px 15px 0px;
        ">
        <div style="display: flex; align-items: center">
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none"
            style="margin-right: 5px">
            <path
              d="M7.12516 7.125H11.8752M15.0418 3.95833V15.8333C15.0418 16.0433 14.9584 16.2447 14.81 16.3931C14.6615 16.5416 14.4601 16.625 14.2502 16.625H4.75016C4.5402 16.625 4.33884 16.5416 4.19037 16.3931C4.0419 16.2447 3.9585 16.0433 3.9585 15.8333V3.16667C3.9585 2.9567 4.0419 2.75534 4.19037 2.60687C4.33884 2.45841 4.5402 2.375 4.75016 2.375H13.4585L15.0418 3.95833ZM13.4585 2.375V3.95833H15.0418L13.4585 2.375ZM11.8752 13.4583H7.12516H11.8752ZM7.12516 10.2917H11.8752H7.12516Z"
              stroke="#252525" stroke-width="1.58333" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <span class="title-todos" *ngFor="let bread of breadcrumb; let i = index" (click)="getBreadcrumb(bread, i)">
            {{i >= 1 ? '>' : ''}}&nbsp;
            <span class="breadcrumb-label" [ngClass]="{'ellipsis': bread.label.length > 20}">
              {{ bread.label }}
            </span>
            &nbsp;
          </span>
        </div>
        <div style="margin-right: 10px; cursor: pointer" (click)="showBookmarks()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            *ngIf="!showBookmarksElements">
            <path
              d="M12.2402 2.52637L14.4212 9.23877H21.479L15.7691 13.3873L17.9501 20.0997L12.2402 15.9512L6.53025 20.0997L8.71124 13.3873L3.00133 9.23877H10.0592L12.2402 2.52637Z"
              stroke="#252525" stroke-width="1.89474" stroke-linejoin="round" />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            *ngIf="showBookmarksElements">
            <path
              d="M12.2402 2.52637L14.4212 9.23877H21.479L15.7691 13.3873L17.9501 20.0997L12.2402 15.9512L6.53025 20.0997L8.71124 13.3873L3.00133 9.23877H10.0592L12.2402 2.52637Z"
              fill="#FFC943" stroke="#FFC943" stroke-width="1.89474" stroke-linejoin="round" />
          </svg>
        </div>
      </div>

      <!-- TABELA NAO AGRUPADA -->
      <div *ngIf="!groupedTable">
        <nz-table class="documents-table" #sortTable [nzData]="dataList" nzTableLayout="fixed" [nzFrontPagination]="false"
          (nzCurrentPageDataChange)="onCurrentPageDataChange($event)">
          <thead>
            <tr>
              <th class="rounded-checkbox" [(nzChecked)]="checked" [nzIndeterminate]="indeterminate"
                (nzCheckedChange)="onAllChecked($event)"></th>

              <!-- ALTERA TH PARA ARQUIVOS -->
              <ng-container *ngIf="!isExistFile">
                <th [nzSortFn]="column.compare" *ngFor="let column of listOfColumn" class="table-title-text-normal"
                  [ngClass]="
                    column.title === 'Projeto'
                      ? 'projeto'
                      : '' || column.title === 'Complexo'
                      ? 'complex'
                      : ''
                  ">
                  {{ column.title }}
                </th>
              </ng-container>

              <!-- ALTERA TH PARA PASTAS -->
              <ng-container *ngIf="isExistFile">
                <th *ngFor="let column of listOfColumnFiles" class="table-title-text-normal" [nzSortFn]="column.compare"
                  [ngClass]="
                    column.title === 'Arquivos'
                      ? 'arquivos'
                      : ''
                  ">
                  {{ column.title }}
                </th>
              </ng-container>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <ng-template ngFor let-document let-attachments [ngForOf]="sortTable.data">
              <tr>
                <ng-template [ngIf]="!document.description">
                  <td class="rounded-checkbox" [nzChecked]="setOfCheckedFolderId.has(document.id) || setOfCheckedSubFolderId.has(document.id)"
                      (nzCheckedChange)="onItemChecked(document.id, $event, document)"
                    ></td>
                  <td>
                    <div class="align-vertical">
                      <div
                        *ngIf="!document.name.includes('pdf') || document.name.includes('doc') || document.name.includes('docx') || document.name.includes('json')">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none"
                          style="
                            margin-right: 16px;
                            width: 24px !important;
                            height: 20px !important;
                          ">
                          <path
                            d="M0.75 2C0.75 1.30964 1.30964 0.75 2 0.75H9.17157C9.50309 0.75 9.82104 0.881696 10.0555 1.11612L11.8839 2.94454C12.3996 3.46027 13.0991 3.75 13.8284 3.75H22C22.6904 3.75 23.25 4.30964 23.25 5V18C23.25 18.6904 22.6904 19.25 22 19.25H2C1.30964 19.25 0.75 18.6904 0.75 18V2Z"
                            stroke="black" stroke-width="1.5" />
                        </svg>
                      </div>
                      <div class="align-vertical"
                        *ngIf="document.name.includes('pdf') || document.name.includes('doc') || document.name.includes('docx') || document.name.includes('json')">
                        <img class="demand-file-icon" src=" {{ getIconFromFileName(document.name) }}" alt="some file" />
                        <span class="col-project-title" id="txtProjectName {{ document.id }}"
                          (click)="copyLink(document.id)" href="">
                          {{ document.name.length > 50 ? (document.name | slice:0:45) + '...' +
                          document.name.split('.').pop() : document.name }}
                        </span>
                        <span *ngIf="document.isBookmarked" (click)="unmakeBookmark(document.user.id, attachments.id)" class="makeLink" title="Remover favorito">
                          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19"
                            style="margin-left: 10px">
                            <path
                              d="M9.69048 2L11.4171 7.31399H17.0046L12.4842 10.5982L14.2108 15.9122L9.69048 12.628L5.17013 15.9122L6.89675 10.5982L2.3764 7.31399H7.96386L9.69048 2Z"
                              fill="#FFC943" />
                          </svg>
                        </span>
                      </div>
                      <span *ngIf="!document.name.includes('pdf')" class="col-project-title"
                        id="txtProjectName {{ document.id }}" (click)="getEnterFolder(document, document.complexName)">
                        {{ document.name.length > 50 ? (document.name | slice:0:45) + '...' +
                        (document.name | slice:document.name.length - 5:document.name.length) 
                        : document.name }}
                      </span>
                      <span *ngIf="document.isBookmarked" (click)="unmakeBookmarkFolder(document.user.id, attachments.id)" class="makeLink" title="Remover favorito">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19"
                          style="margin-left: 10px">
                          <path
                            d="M9.69048 2L11.4171 7.31399H17.0046L12.4842 10.5982L14.2108 15.9122L9.69048 12.628L5.17013 15.9122L6.89675 10.5982L2.3764 7.31399H7.96386L9.69048 2Z"
                            fill="#FFC943" />
                        </svg>
                      </span>
                    </div>
                  </td>
                  <td style="padding: 0">
                    <span class="cells-text-normal" id="txtComplex {{ document.id }}">{{ document.complexName }}</span>
                  </td>
                  <td>
                    <span class="cells-text-normal" id="txtUpdateDate {{ document.id }}">
                      {{ document.updateDate ? (document.updateDate | date : "dd/MM/yyyy") : null }} às {{
                      document.updateDate ? (document.updateDate+"Z" | date: 'HH:mm' : 'America/Sao_Paulo') : null }}
                    </span>
                  </td>
                  <td style="width: 10% !important">
                    <span class="cells-text-normal" id="txtDeploymentDate {{ document.id }}">{{ document.creationDate |
                      date : "dd/MM/yyyy" }} às {{ document.creationDate+"Z" | date: 'HH:mm' : 'America/Sao_Paulo'}}</span>
                  </td>
                  <td>
                    <span class="cells-text-normal" id="txtResponsible{{ document.user.id }}">{{ document.user.name
                      }}</span>
                  </td>
                  <td style="padding: 0 !important">
                    <div>
                      <button class="buttonMenuFlow" nz-button nz-dropdown [nzDropdownMenu]="menu">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <ellipse cx="10.5" cy="10.5" rx="1.5" ry="1.5" transform="rotate(-90 10.5 10.5)"
                            fill="#007E7A" />
                          <ellipse cx="10.5" cy="4.5" rx="1.5" ry="1.5" transform="rotate(-90 10.5 4.5)"
                            fill="#007E7A" />
                          <ellipse cx="10.5" cy="16.5" rx="1.5" ry="1.5" transform="rotate(-90 10.5 16.5)"
                            fill="#007E7A" />
                        </svg>
                      </button>
                    </div>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                      <ul nz-menu>
                        <li
                          [nzDisabled]="document.isBookmarked"
                          nz-menu-item class="itemMenuFlow" (click)="makeBookmarkFolder(document.user.id, attachments.id)">
                          <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                            viewBox="0 0 16 16" fill="none" style="margin-right: 5px">
                            <path *ngIf="!document.isBookmarked"
                              d="M8.76094 1.48904C8.67639 1.22881 8.43389 1.05263 8.16027 1.05263C7.88666 1.05263 7.64416 1.22881 7.5596 1.48904L6.24741 5.52756H2.00105C1.72743 5.52756 1.48493 5.70375 1.40038 5.96397C1.31583 6.2242 1.40846 6.50927 1.62982 6.6701L5.06519 9.16605L3.753 13.2046C3.66844 13.4648 3.76107 13.7499 3.98243 13.9107C4.20379 14.0715 4.50354 14.0715 4.7249 13.9107L8.16027 11.4148L11.5956 13.9107C11.817 14.0715 12.1168 14.0715 12.3381 13.9107C12.5595 13.7499 12.6521 13.4648 12.5675 13.2046L11.2554 9.16605L14.6907 6.6701C14.9121 6.50927 15.0047 6.2242 14.9202 5.96397C14.8356 5.70375 14.5931 5.52756 14.3195 5.52756H10.0731L8.76094 1.48904Z"
                              stroke="#007E7A" stroke-width="1.26316" stroke-linejoin="round" />
                            <path *ngIf="document.isBookmarked"
                              d="M8.76094 1.48904C8.67639 1.22881 8.43389 1.05263 8.16027 1.05263C7.88666 1.05263 7.64416 1.22881 7.5596 1.48904L6.24741 5.52756H2.00105C1.72743 5.52756 1.48493 5.70375 1.40038 5.96397C1.31583 6.2242 1.40846 6.50927 1.62982 6.6701L5.06519 9.16605L3.753 13.2046C3.66844 13.4648 3.76107 13.7499 3.98243 13.9107C4.20379 14.0715 4.50354 14.0715 4.7249 13.9107L8.16027 11.4148L11.5956 13.9107C11.817 14.0715 12.1168 14.0715 12.3381 13.9107C12.5595 13.7499 12.6521 13.4648 12.5675 13.2046L11.2554 9.16605L14.6907 6.6701C14.9121 6.50927 15.0047 6.2242 14.9202 5.96397C14.8356 5.70375 14.5931 5.52756 14.3195 5.52756H10.0731L8.76094 1.48904Z"
                              stroke="#CCCCCC" stroke-width="1.26316" stroke-linejoin="round" />
                          </svg>
                          Favoritar
                        </li>
                      </ul>
                    </nz-dropdown-menu>
                  </td>
                </ng-template>

                <ng-template [ngIf]="document.description">
                  <td class="rounded-checkbox" style="padding: 0px !important" [(nzExpand)]="document.expand"
                    (nzExpandChange)="getHistoric(document)"
                    [nzChecked]="setOfCheckedId.has(document.id) || checkedAttachmentInsideFolder.has(document.id)"
                    (nzCheckedChange)="
                      onItemChecked(document.id, $event, document)
                    "></td>
                  <td>
                    <div class="align-vertical">
                      <img class="demand-file-icon" src=" {{ getIconFromFileName(document.description) }}"
                        alt="some file" />

                      <span class="col-project-title" id="txtProjectName {{ document.id }}"
                        (click)="copyLink(attachments.id)" href="">
                        {{ document.name.length > 50 ? (document.name | slice:0:45) + '...' +
                        document.name.split('.').pop() : document.name }}
                      </span>
                      <span *ngIf="document.isBookmarked" (click)="unmakeBookmark(document.user.id, attachments.id)" class="makeLink" title="Remover favorito">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19"
                          style="margin-left: 10px">
                          <path
                            d="M9.69048 2L11.4171 7.31399H17.0046L12.4842 10.5982L14.2108 15.9122L9.69048 12.628L5.17013 15.9122L6.89675 10.5982L2.3764 7.31399H7.96386L9.69048 2Z"
                            fill="#FFC943" />
                        </svg>
                      </span>
                    </div>
                  </td>
                  <td>
                    <span class="cells-text-normal" id="txtVersion {{ document.id }}">{{ document.version }} </span>
                  </td>

                  <td>
                    <span class="cells-text-normal" id="txtCommets {{ document.id }}">{{ document.comments || 'Sem
                      comentários'}}</span>
                  </td>
                  <td>
                    <span class="cells-text-normal" id="txtUpdateDate {{ document.date }}">{{ document.date | date :
                      "dd/MM/yyyy" }} às {{ document.date+"Z" | date: 'HH:mm' : 'America/Sao_Paulo'}}</span>
                  </td>
                  <td>
                    <span class="cells-text-normal" id="txtResponsible{{ document.user.id }}">{{ document.user.name
                      }}</span>
                  </td>
                  <td style="padding: 0 !important">
                    <div>
                      <button class="buttonMenuFlow" nz-button nz-dropdown [nzDropdownMenu]="menu">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <ellipse cx="10.5" cy="10.5" rx="1.5" ry="1.5" transform="rotate(-90 10.5 10.5)"
                            fill="#007E7A" />
                          <ellipse cx="10.5" cy="4.5" rx="1.5" ry="1.5" transform="rotate(-90 10.5 4.5)"
                            fill="#007E7A" />
                          <ellipse cx="10.5" cy="16.5" rx="1.5" ry="1.5" transform="rotate(-90 10.5 16.5)"
                            fill="#007E7A" />
                        </svg>
                      </button>
                    </div>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                      <ul nz-menu>
                        <li nz-menu-item class="itemMenuFlow" (click)="copyLink(attachments.id)">
                          <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                            viewBox="0 0 16 16" fill="none" style="margin-right: 5px">
                            <path
                              d="M9.66683 6.33337C10.1081 6.77579 10.356 7.37516 10.356 8.00004C10.356 8.62492 10.1081 9.22429 9.66683 9.66671L6.33349 13C5.89108 13.4413 5.29171 13.6892 4.66683 13.6892C4.04195 13.6892 3.44257 13.4413 3.00016 13C2.55886 12.5576 2.31104 11.9583 2.31104 11.3334C2.31104 10.7085 2.55886 10.1091 3.00016 9.66671"
                              stroke="#007E7A" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                            <path
                              d="M12.9998 6.33337C13.4411 5.89096 13.689 5.29159 13.689 4.66671C13.689 4.04183 13.4411 3.44245 12.9998 3.00004C12.5574 2.55874 11.958 2.31091 11.3332 2.31091C10.7083 2.31091 10.1089 2.55874 9.6665 3.00004L6.33317 6.33337C5.89187 6.77579 5.64404 7.37516 5.64404 8.00004C5.64404 8.62492 5.89187 9.22429 6.33317 9.66671V9.66671"
                              stroke="#007E7A" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                          Copiar Link
                        </li>
                        <li
                          *ngIf="document.isBookmarked === false && (!document.name.includes('pdf') || !document.name.includes('doc') || !document.name.includes('docx'))"
                          nz-menu-item class="itemMenuFlow" (click)="makeBookmark(document.user.id, attachments.id)">
                          <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                            viewBox="0 0 16 16" fill="none" style="margin-right: 5px">
                            <path
                              d="M8.76094 1.48904C8.67639 1.22881 8.43389 1.05263 8.16027 1.05263C7.88666 1.05263 7.64416 1.22881 7.5596 1.48904L6.24741 5.52756H2.00105C1.72743 5.52756 1.48493 5.70375 1.40038 5.96397C1.31583 6.2242 1.40846 6.50927 1.62982 6.6701L5.06519 9.16605L3.753 13.2046C3.66844 13.4648 3.76107 13.7499 3.98243 13.9107C4.20379 14.0715 4.50354 14.0715 4.7249 13.9107L8.16027 11.4148L11.5956 13.9107C11.817 14.0715 12.1168 14.0715 12.3381 13.9107C12.5595 13.7499 12.6521 13.4648 12.5675 13.2046L11.2554 9.16605L14.6907 6.6701C14.9121 6.50927 15.0047 6.2242 14.9202 5.96397C14.8356 5.70375 14.5931 5.52756 14.3195 5.52756H10.0731L8.76094 1.48904Z"
                              stroke="#007E7A" stroke-width="1.26316" stroke-linejoin="round" />
                          </svg>
                          Favoritar
                        </li>
                        <li nz-menu-item class="itemMenuFlow" (click)="accessFlow(attachments.id)">
                          <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                            viewBox="0 0 16 16" fill="none"  style="margin-right: 5px">
                            <path
                              d="M10 10.6667L13.74 7.85333C13.8179 7.79087 13.8807 7.71171 13.9239 7.62171C13.9671 7.53171 13.9895 7.43316 13.9895 7.33333C13.9895 7.23351 13.9671 7.13496 13.9239 7.04496C13.8807 6.95496 13.8179 6.8758 13.74 6.81333L10 4M6 10.6667V8.66667C3.15333 8.66667 2 12 2 12C2 9.19333 2.71333 6 6 6V4L10.5067 7.33333L6 10.6667Z"
                              stroke="#007E7A" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                          Acessar Fluxo
                        </li>
                      </ul>
                    </nz-dropdown-menu>
                  </td>
                </ng-template>
              </tr>
              <tr [nzExpand]="document.expand ?? false">
                <app-historic-element style="display: inline-table; width: 102% !important;"
                  (refreshDisplayAfterDelete)="refreshDisplay($event)" (refreshDisplayAfterRestore)="refreshDisplayAfterRestore($event)" [document]="document" (requestData)="checked" [enteredFolder]="enteredFolder"
                  (requestItemChecked)="onItemCheckedInput($event)"></app-historic-element>
              </tr>

            </ng-template>
          </tbody>
        </nz-table>
      </div>

      <!-- TABELA AGRUPADA -->
      <div *ngIf="groupedTable">
        <app-documents-table-grouped [data]="dataTableGroup"
          (checkClick)="handleCheckClick($event)"></app-documents-table-grouped>
      </div>

    </div>


  </div>

  <div *ngIf="setOfCheckedId.size > 0 || setOfCheckedFolderId.size > 0" nz-row nzJustify="end">
    <div nz-col class="add-project mt-10">
      <button (click)="downloadList()" class="btn-sl btn-sl-green" id="btbNovoProjeto">
        <span>Download</span>
      </button>
    </div>
  </div>

</div>
