import { Component, OnInit } from '@angular/core';
import { NlpTempProjectService } from '../nlp-add-proj-informations/nlp-add-proj-informations.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss'],
})
export class ProductsListComponent implements OnInit {
  public productsNlpList: any;
  public filteredList;

  constructor(
    private readonly nlpTempProjectService: NlpTempProjectService,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.listProductsNlp();
  }

  public listProductsNlp(): any {
    this.nlpTempProjectService.getNlpTempProjectList().subscribe((item) => {
      this.productsNlpList = item;
      this.filteredList = this.productsNlpList;
    });
  }

  public navigateProductNlp(id) {
    this.nlpTempProjectService.getNlpProductById(id).subscribe((product) => {
      localStorage.removeItem('productNlpId');
      this.userService.updateProductNlp(product);
      this.userService.updateValueTopMenu(true);
      this.router.navigate([`nlp/termo-referencia`]);
    });
  }
}
