<div class="container request-review-modal-container">
  <div nz-col nzSpan="24" class="ali-center-flex">
    <h1 class="titulo">{{ 'projects.ripla.modals.request-review-analisys.title' | translate }}</h1>
  </div>

  <p>{{ 'projects.ripla.modals.request-review-analisys.subtitle' | translate }}</p>

  <form [formGroup]="formRequestReview">
    <div class="checkbox-options">
      <nz-checkbox-wrapper style="width: 100%;" (nzOnChange)="onChangeCheckbox($event)">
        <div nz-row>
          <div *ngFor="let option of checkOptions" nz-col nzSpan="12" class="space-options">
            <label nz-checkbox [nzChecked]="reasonsIdsChecked.includes(option.id)" [nzValue]="option.id">{{
              option.description
              }}</label>
          </div>
        </div>
      </nz-checkbox-wrapper>
    </div>

    <div class="textarea-detail">
      <span>{{ 'projects.ripla.modals.request-review-analisys.form.input-textarea-detail' | translate }}</span><span>
        *</span>
      <textarea formControlName="comments" rows="6" nz-input
        placeholder="{{ 'projects.ripla.modals.request-review-analisys.form.input-textarea-placeholder' | translate }}">
            </textarea>
    </div>
  </form>

  <div class="buttons">
    <button class="btn-cancel" (click)="onCancel()">
      {{ 'commons.cancel' | translate }}
    </button>

    <button class="btn-request" (click)="onSubmit()">
      {{ 'commons.request' | translate }}
    </button>
  </div>


</div>
