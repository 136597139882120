import {Injectable} from '@angular/core'

import {HttpClient} from '@angular/common/http'
import {ProjectModel} from '../../shared/models/project.model'
import {Observable} from 'rxjs'
import {
  PROJECT_API,
  baseUrl,
} from "./constants/apiEndpointsConstants";
import { ProjectsListModel } from 'src/app/shared/models/projects-list.model'

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  selectedProjectId: number = 0
  responsible: string = '';
  constructor(private readonly http: HttpClient) {
  }

  getProjectsList(page, size?): Observable<ProjectsListModel> {
    let listSize;
    if (size) {
      listSize = size
    } else {
      listSize = 20
    }
    return this.http.get<ProjectsListModel>(`${baseUrl}/projects?page=${page}&size=${listSize}`);
  }

  getProject(id: number): Observable<ProjectModel> {
    return this.http.get<ProjectModel>(`${PROJECT_API}/${id}`)
  }

  evaluateProjectName(projectName: string): Observable<any> {
    return this.http.get<any>(`${PROJECT_API}/evaluate?name=${projectName}`)
  }
}
