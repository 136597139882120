import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NzModalService } from 'ng-zorro-antd/modal';

import { Activity } from 'src/app/shared/dto/activities.dto';
import { UtilService } from '../../../../../../shared/util.service';
import { LicensingService } from '../../../service/licensing.service';
import { MessageService } from 'src/app/shared/common/services/message.service';

export interface TreeNodeInterface {
  key: string;
  id: string;
  level?: number;
  expand?: boolean;
  title: string;
  responsibleUser?: string;
  dueDate?: string;
  status: string;
  product?: string;
  children?: TreeNodeInterface[];
  parent?: TreeNodeInterface;
}
@Component({
  selector: 'app-activities-list',
  templateUrl: './activities-list.component.html',
  styleUrls: ['./activities-list.component.scss'],
})
export class ActivitiesListComponent implements OnInit {
  @Output() reload: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() activities!: Activity[];
  @Input() hasActivityParent?: boolean = false;
  @Input() projectId?: number;
  @Input() processId?: number;
  @Input() activitySubId?: number;
  @Input() titleResponsibleFor: 'edit-processv2' | 'project-view';

  mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {};

  listOfMapData: TreeNodeInterface[] = [];

  title: string = '';
  route: string = '';
  cadActivityDelete: boolean = false;

  constructor(
    private readonly _router: Router,
    private readonly _modal: NzModalService,
    private readonly _message: MessageService,
    private readonly _utilService: UtilService,
    private _activatedRoute: ActivatedRoute,
    private _licensingService: LicensingService
  ) {}

  ngOnInit() {
    this.cadActivityDelete = this._utilService.getAuthorization(
      'exclude',
      'CADACTIVITY'
    );
    this.title = this.hasActivityParent
      ? 'activities.activity-title'
      : 'activities.sub-activities-title';
  }

  isExistActivity(): boolean {
    return this.activities.length > 0;
  }

  toggle(activity: Activity): void {
    activity.expanded = !activity.expanded;
  }

  deleteActivity(id) {
    this._modal.confirm({
      ...(this._message.showMessageModalConfirmCancel() as any),
      nzOnOk: () => {
        this._licensingService.deleteActivity(id).subscribe({
          next: () => {
            this.reload.emit(true);
          },
          error: () => {},
        });
      },
    });
  }

  goToActivity(id) {
    this._router.navigate([
      `/projects/${this.projectId}/process/${this.processId}/edit-activity/${id}`,
    ]);
  }

  getActivityChildCount(activity: Activity): number {
    if (this._activatedRoute.snapshot.url[2].path !== 'edit-processv2') {
      return activity.children ? activity.children.length - 1 : 0;
    } else {
      return 0;
    }
  }
}
