import { Component, Input, OnInit } from "@angular/core";
import { SummaryAboutProject } from "src/app/modules/scope-change/interface/summary-scope-change";

@Component({
  selector: 'app-project-resume-infos',
  templateUrl: './project-resume-infos.component.html',
  styleUrls: ['./project-resume-infos.component.scss']
})
export class ProjectResumeInfosComponent implements OnInit {
  @Input() projectSummary: SummaryAboutProject;
  ngOnInit(): void {
      
  }
}