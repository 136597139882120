<h2>Últimos 7 dias</h2>
<div
  class="content-line"
  *ngFor="let item of top7Days"
  id="notification-assigned7-{{ item.id }}"
>
  <div class="title-notification" [innerHtml]="item.message">
  </div>
  <div class="project-notification">{{ item.projectName }}</div>
  <div class="date-notification">{{ item.dateTimeNotification | date : "dd/MM/yyyy" }}</div>
</div>

<h2>Mais antigas</h2>

<div
  class="content-line"
  *ngFor="let item of offsetDays"
  id="notification-assigned-{{ item.id }}"
>
<div class="title-notification" [innerHtml]="item.message">
</div>
<div class="project-notification">{{ item.projectName }}</div>
<div class="date-notification">{{ item.dateTimeNotification | date : "dd/MM/yyyy" }}</div>
</div>