import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private readonly message: NzMessageService) { }

  showSucessMessage(message: string): void {
    this.message.success(message, { nzDuration: 5000 });
  }

  showErrorMessage(message: string): void {
    this.message.error(message, { nzDuration: 5000 });
  }


  showMessageUploadAttachment() {
    this.message.info("Anexos sendo carregados, por gentileza aguarde", { nzDuration: 5000 })
  }

  showMessageModalConfirmCancel() {
    return {
      nzTitle: 'Deseja mesmo cancelar?',
      nzContent:
        'Ao cancelar, as informações não serão salvas.',
      nzOkText: 'Sim, cancelar',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzCancelText: 'Não',
      nzCentered: true,
      nzWidth: 580
    };
  }

  showMessageModalConfirmCancelRequisition() {
    return {
      nzTitle: 'Deseja mesmo cancelar?',
      nzContent:
        'Essa ação não poderá ser desfeita.',
      nzOkText: 'Cancelar requisição',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzCancelText: 'Voltar',
      nzCentered: true,
      nzWidth: 580,
      nzClosable: false,
    };
  }

  showMessageModalConfirmCancelScopeChange() {
    return {
      nzTitle: 'Deseja mesmo cancelar?',
      nzContent:
        'Essa ação não poderá ser desfeita.',
      nzOkText: 'Cancelar mudança de escopo',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzCancelText: 'Voltar',
      nzCentered: true,
      nzWidth: 580,
      nzClosable: false,
    };
  }
  
  showMessageModalConfirmCancelFinalConsolidation() {
    return {
      nzTitle: 'Deseja mesmo sair?',
      nzContent:
      'As informações preenchidas serão perdidas.',
      nzOkText: 'Abandonar parecer',
      nzOkType: 'primary',
      nzOkDanger: true,
      nzCancelText: 'Salvar rascunho',
      nzCentered: true,
      nzWidth: 580,
      nzClosable: false,
    };
  }

  showMessageModalConfirmCancelGeneric(nzOkText: string = 'Cancelar requisição') {
    return {
      nzTitle: 'Deseja mesmo cancelar?',
      nzContent:
        'Essa ação não poderá ser desfeita.',
      nzOkText: nzOkText,
      nzOkType: 'primary',
      nzOkDanger: true,
      nzCancelText: 'Voltar',
      nzCentered: true,
      nzWidth: 580,
      nzClosable: false,
    };
  }
  showMessageModalConfirmCancelRequestGeneric(nzOkText: string) {
    return {
      nzTitle: 'Deseja mesmo cancelar?',
      nzContent:
        'Essa ação não poderá ser desfeita.',
      nzOkText: nzOkText,
      nzOkType: 'primary',
      nzOkDanger: true,
      nzCancelText: 'Voltar',
      nzCentered: true,
      nzWidth: 580,
      nzClosable: false,
    };
  }


  showMessageModalConfirmCancelRequisitionRevision(nzOkText: string = 'Cancelar requisição') {
    return {
      nzTitle: 'Deseja mesmo cancelar?',
      nzContent:
        'Essa ação não poderá ser desfeita.',
      nzOkText: nzOkText,
      nzOkType: 'primary',
      nzOkDanger: true,
      nzCancelText: 'Voltar',
      nzCentered: true,
      nzWidth: 580,
      nzClosable: false,
    };
  }

  showMessageModalConfirmDelete(nzOkText: string = 'Excluir') {
    return {
      nzTitle: 'Deseja mesmo excluir?',
      nzContent:
        'Essa ação não poderá ser desfeita.',
      nzOkText: nzOkText,
      nzOkType: 'primary',
      nzOkDanger: true,
      nzCancelText: 'Voltar',
      nzCentered: true,
      nzWidth: 580,
      nzClosable: false,
    };
  }

}
