import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  AfterContentInit,
  ViewContainerRef,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import {Router, TitleStrategy} from "@angular/router";
import {LicensingProcess} from 'src/app/shared/models/licensing-strategy';
import {
  FINAL_ANALYSIS_ENTITY_NAME,
  SUB_PROJECT_ENTITY_NAME,
  SUB_PROJECT_PARECER_FINAL
} from 'src/app/modules/projects/constants/projectsNewConstants';
import {SendAnalisysModalComponent} from '../../modal/send-analisys-modal/send-analisys-modal.component';
import {
  ConfirmationSendDraftModalComponent
} from '../../modal/confirmation-send-draft-modal/confirmation-send-draft-modal.component';
import {
  SendFinalAnalisysModalComponent
} from '../../modal/send-final-analisys-modal/send-final-analisys-modal.component';
import {
  FinalAnalysisConfirmationSendModalComponent
} from '../../modal/final-analysis-confirmation-send-modal/final-analysis-confirmation-send-modal.component';

import {Subject, take, takeUntil, tap} from 'rxjs';
import {ActivatedRoute, Params} from "@angular/router";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

import {NzUploadFile} from 'ng-zorro-antd/upload';
import {NzModalService} from 'ng-zorro-antd/modal';

import {DateCalcHelper} from "src/app/shared/helpers/date-calc.helper";

import {AttachmentSaveDto} from 'src/app/shared/dto/attachment-save.dto';

import {UserModel} from "src/app/shared/models/user.model";
import {AttachmentsModel} from 'src/app/shared/models/attachments.model';
import {CompetentOrgan} from 'src/app/shared/models/competent-organ.model';

import {UtilService} from 'src/app/shared/util.service';
import {UserService} from "src/app/shared/services/user.service";
import {ProjectsService} from 'src/app/modules/projects/projects.service';
import {ProjectsV2Service} from "src/app/shared/services/projects-v2.service";
import {LicensesV2Service} from 'src/app/shared/services/licenses-v2.service';
import {MessageService} from 'src/app/shared/common/services/message.service';
import {AttachmentsService} from 'src/app/shared/services/attachments.service';
import {RestrictionsService} from 'src/app/shared/services/restrictions.service';
import {CompetentOrganService} from 'src/app/shared/services/competent-organ.service';
import {EnvironmentalStudiesService} from 'src/app/shared/services/environmental-studies.service';
import {SubProjectByIdResponse} from "src/app/shared/dto/subproject-by-id-response.dto";
import {LoadingComponent} from 'src/app/shared/components/loading-modal/loading-modal.component';
import {BreadcrumbState} from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';
import {Store, select} from '@ngrx/store';
import {selectRiplaProject} from 'src/app/shared/ngrx/breadcrumb/breadcrumb.selector';
import {ProjectState} from 'src/app/shared/ngrx/breadcrumb/model';
import {ProjectByIdResponseDto} from 'src/app/shared/dto/project-by-id-response.dto';
import {BREADCRUMB_ACTIONS} from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';
import {FileValidationService} from "../../../../shared/services/file-validation.service";


@Component({
  selector: 'app-project-v2-analysis-consolidation-final',
  templateUrl: './project-v2-analysis-consolidation-final.component.html',
  styleUrls: ['./project-v2-analysis-consolidation-final.component.scss']
})
export class ProjectV2AnalysisConsolidationFinalComponent implements OnInit, AfterContentInit {

  private readonly destroy$: Subject<any> = new Subject();

  @ViewChild('uploadInput') divClick!: ElementRef;
  @Output() isVisibleModal = new EventEmitter<boolean>();
  isAddProjectVisible: boolean = true;
  isDraft: string = ''

  editMode: boolean = true
  editStrategy: boolean = false
  temporaryStrategyData: any[] = []
  editStudy: boolean = false

  projectId: number;
  subProjectId: number;
  enviromentalStudyId: number;

  loadingSubProjects: boolean;
  listOfSubProjects: SubProjectByIdResponse[] = [];
  subProject: SubProjectByIdResponse;
  subProjectDiscipline: any[] = [];
  editId: string | null = null;
  selectedUserResponsibleId: string = "";
  users: UserModel[] = [];
  customStyle = {
    'font-size': '12px',
    'width': '300px',
    'max-width': '300px',
    'max-heigth': 'auto'
  };
  popOverEditRipla: boolean = false;

  popOverEditDiscipline: boolean = false;

  colaborativeArchive: string = ''
  hasColaborativeArchive: boolean = false;

  editLink: boolean = false;
  link: string = ''

  showResume: boolean = false
  resumeData: any = []

  form!: UntypedFormGroup;

  consolidationFinal;

  restrictionOption: string = ''
  securityQuestionOption: string = ''
  demandByLicensingOption: string = ''

  restrictionNo: string = ''
  showRestrictions: boolean = false
  isRestrictionsListLoading: boolean = false
  restrictions: any[] = []
  restrictionsList: any[] = []

  addNewLicensingStrategy: boolean = false
  newOrganStrategy;
  newLicensingProcess;


  selectedStrategy: LicensingProcess[] = [];
  licensingStrategy: any[] = []

  estimatedCost: any[] = []

  estimatedCostForStudies: any[] = []

  organList: CompetentOrgan[] = []

  licensingProcessList: any[] = []
  licensingProcessListAll: any[] = []

  addNewNecessaryStudy: boolean = false
  addNewStudy: boolean = false
  newEstimatedCost: string = ''
  newOrganNecessaryStudy: string = ''
  newLicensingProcessNecessaryStudy: string = ''
  newDescriptionNecessaryStudy: string = ''
  editingDiscipline: any = null;
  editingStrategy: any = null;
  updateLicenseForStudyId: any = null;

  fileList: NzUploadFile[] = [];
  fileListAlreadyUploaded: NzUploadFile[] = [];
  filesToDelete: string[] = [];
  fileListDuplicated: NzUploadFile[] = [];
  fileLisToSave: NzUploadFile[] = [];
  arrayImageDelete: string[] = [];
  documents: any[] = [];
  finalPublication: boolean = false;

  descriptionStudyList: any[] = [];
  licensingStudyList: any[] = [];
  createStudy: any[] = [];
  environmentalStudiesList: any[] = []
  startEditEnvironmentStudy: boolean = false
  updateLicenseForStudySelected: any;
  newLicensingProcessId: any;

  listOfCosts: any[] = []
  totalStudy: number = 0
  totalCosts: number = 0
  editCache: { [key: string]: { edit: boolean; popupedit: boolean, data: any } } = {};
  formatterReal = (value: number): string => `R$ ${value}`;
  parserReal = (value: string): string => value.replace('R$ ', '');
  statusRipla: string = ''

  studyListFromDiscipline: any[] = []

  editingRow: number | null = null;
  editingStudyRow: number | null = null;

  project: ProjectByIdResponseDto;
  windowResolution: number;

  totalLengthRestriction: number = 0

  compareFn = (o1: string, o2: string) => (o1 && o2 ? o1 == o2 : false);
  isInvalidFile: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectsV2Service: ProjectsV2Service,
    private readonly fb: UntypedFormBuilder,
    private readonly modal: NzModalService,
    private readonly attachmentsService: AttachmentsService,
    private readonly message: MessageService,
    private competentOrganService: CompetentOrganService,
    private utilService: UtilService,
    private fileValidationService: FileValidationService,
    private licensesV2Service: LicensesV2Service,
    private restrictionsService: RestrictionsService,
    private environmentalStudiesService: EnvironmentalStudiesService,
    private viewContainerRef: ViewContainerRef,
    private store: Store<BreadcrumbState>,
  ) {
  }

  ngOnInit(): void {
    this.finalPublication = this.utilService.getAuthorization("FINALPUBLICATION", "insert");
    this.route.params.subscribe((params: Params) => {
      this.projectId = +params['projectId'];
      this.subProjectId = +params['subProjectId'];
      this.enviromentalStudyId = +params['enviromentalStudyId'];
    });
    this.initForm()
    this.getProjectAndUsers(this.projectId)
    this.getConsolidationFinal()
    this.getRestricionsList()
    this.getAttachments()
    this.getAllLicensingProcess()
    this.replaceResume()
    this.getCompetentOrgans()
    this.windowResolution = window.innerWidth;
  }

  // INICIO BREADCRUMB
  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  sliceAttachment(value: string): string {
    if (this.windowResolution >= 1920) {
      return value.slice(0, 80) + '...';
    } else {
      return value.slice(0, 50) + '...';
    }
  }

  private dispatchProjectOnStore(): void {
    let project: ProjectState = {
      name: 'Projetos',
      url: 'projects-v2/',
      last: false,
      sublevels: true,
      project: {
        name: this.project.name,
        url: 'project-v2-view/' + this.project.id,
        last: false,
      },
      subProject: {
        name: 'RIPLA',
        url: 'project-v2-analysis/' + this.projectId + '/' + this.subProjectId,
        last: true,
        discipline: null
      },
      parecerFinal: {
        name: 'Parecer Final',
        url: 'project-v2-analysis-consolidation-final/' + this.projectId + '/' + this.subProjectId,
        last: true,
      },
      pedidoRevisao: null,
      comunicacoes: null,
      processos: null,
      atividades: null,
      subatividades: null,
      mudancaEscopo: null
    }
    this.store.dispatch(BREADCRUMB_ACTIONS.getRiplaProject({project}));
  }

  // FINAL BREADCRUMB

  private getProjectAndUsers(projectId: number): void {
    this.projectsV2Service.getProjectByProjectId(projectId).subscribe({
      next: (project: ProjectByIdResponseDto) => {
        this.project = project;
        this.dispatchProjectOnStore()
      },
    });
  }

  ngAfterContentInit() {
    return;
    // this.initForm()
    // if(!this.consolidationFinal) this.getConsolidationFinal()
    // if(!this.resumeData) this.replaceResume()
    // if(!this.licensingProcessListAll) this.getAllLicensingProcess()
    // this.reloadListOfRestrictions()
  }

  initForm(): void {
    this.form = this.fb.group({
      environmentalRestrictions: [null, [Validators.required]],
      selectedRestrictionsList: [null, [Validators.required]],
      securityQuestions: [null, [Validators.required]],
      demandByLicensing: [null, [Validators.required]],
    });
  }

  onScrollForm() {
    document.body.click();
  }

  onScroll() {
    document.body.click();
  }

  updateFormWithData(data) {
    if (data['asmtRestrictions'] === null) {
      this.form.controls['environmentalRestrictions'].setValue(null)
    } else {
      this.form.controls['environmentalRestrictions'].setValue(data['asmtRestrictions'] ? 'sim' : 'nao')
    }
    const selectedRestrictionsList: any[] = [];
    data.restrictions.forEach((restriction) => {
      selectedRestrictionsList.push(String(restriction.id))
    })
    this.form.controls['selectedRestrictionsList'].setValue(selectedRestrictionsList);
    this.showRestrictions = data['asmtRestrictions']
    if (data['asmtPriority'] === null) {
      this.form.controls['securityQuestions'].setValue(null)
    } else {
      this.form.controls['securityQuestions'].setValue(data['asmtPriority'] ? 'sim' : 'nao')
    }
    if (data['asmtLicenseRequired'] === null) {
      this.form.controls['demandByLicensing'].setValue(null)
    } else {
      this.form.controls['demandByLicensing'].setValue(data['asmtLicenseRequired'] ? 'sim' : 'nao')
    }
    this.getLicensingStrategy()
    this.getEnviromentalNecessaryStudies()
  }

  private createEnvironmentalStudyWithoutLicense(envStudy) {
    return {
      id: envStudy.id,
      description: envStudy.description,
      cost: envStudy.cost,
      subProjectId: this.subProjectId,
      categoryId: envStudy.category.id,
      categoryName: envStudy.category.description,
      license: null,
      licenseId: null
    }
  }

  getConsolidationFinal() {
    this.licensesV2Service.getFinalAnalysisBySubProjectId(this.subProjectId).subscribe({
      next: (response: any) => {
        (response as any)?.environmentalStudyResponse?.forEach(envStudy => {
          if (!envStudy.license) {
            this.environmentalStudiesList.push(this.createEnvironmentalStudyWithoutLicense(envStudy))
          }
        });

        if (response['asmtRestrictions'] != null) {
          // Rascunho:
          this.consolidationFinal = response;
          this.editMode = true;
          this.statusRipla = response['status'];
          this.updateFormWithData(response);
          this.resumeData.compensationsCostsList = response.costs.compensations;
          this.resumeData.otherCostsList = response.costs.otherCosts;
          this.replaceResume()
        } else {
          // Create:
          this.showResume = false;
          // Caso exista custos: Exibe o resumo
          let otherCostsItem = response.costs.otherCosts.itens?.length;
          let compensationsItem = response.costs.compensations.itens?.length;
          let studiesItem = response.costs.studies.itens?.length;
          let enviromentalStudy = response?.environmentalStudyResponse?.length;

          if (otherCostsItem > 0 || compensationsItem > 0 || studiesItem > 0 || enviromentalStudy) {
            this.resumeData.compensationsCostsList = response.costs.compensations;
            this.resumeData.otherCostsList = response.costs.otherCosts;
            this.getLicensingStrategy();
            this.getEnviromentalNecessaryStudies();
            this.replaceResume();
          }
        }
      }
    })
  }


  showRestrictionsClick() {
    if (this.form.controls['environmentalRestrictions'].value === 'sim') {
      this.showRestrictions = true
      this.getRestricionsList()
    } else {
      this.form.controls['selectedRestrictionsList'].reset()
      this.showRestrictions = false
    }
    this.replaceResume()
  }

  reloadListOfRestrictions() {
    let listOfRestrictions: any[] = [];
    if (this.form.controls['selectedRestrictionsList'].value) {
      this.form.controls['selectedRestrictionsList'].value.forEach(element => {
        const name = this.restrictionsList.filter(restriction => restriction.id == Number(element) || null);
        name.length > 0 && name[0].description !== undefined ? listOfRestrictions.push(name[0].description) : null
      });
    }
  }

  somarCaracteres(arrayDeStrings) {
    let totalCaracteres = 0;
    for (let i = 0; i < arrayDeStrings.length; i++) {
        totalCaracteres += arrayDeStrings[i].length;
    }
    return this.totalLengthRestriction = totalCaracteres;
  }

  replaceResume() {
    this.showResume = true
    this.checkOptionsOnCreateOrDraft();


    let listOfRestrictions: any[] = [];
    if (this.form.controls['selectedRestrictionsList'].value) {
      this.form.controls['selectedRestrictionsList'].value.forEach(element => {
        const name = this.restrictionsList.filter(restriction => restriction.id == Number(element) || null);
        name.length > 0 && name[0].description !== undefined ? listOfRestrictions.push(name[0].description) : null
      });
    }
    this.resumeData.listOfRestrictions = listOfRestrictions ? listOfRestrictions : '-';
    this.resumeData.estimatedCostForStudies = this.environmentalStudiesList;
    this.somarCaracteres(this.resumeData.listOfRestrictions)

    //RESUMO ESTUDOS
    let totalCost = 0
    this.totalCosts = 0
    this.resumeData.estimatedCostForStudies.forEach(cost => {
      this.totalCosts += cost.cost
    });

    let compensationCost = this.resumeData['compensationsCostsList']?.cost ? this.resumeData['compensationsCostsList']?.cost : 0;
    let otherCost = this.resumeData['otherCostsList']?.cost ? this.resumeData['otherCostsList']?.cost : 0;
    const groupedStudyListCost = this.createStudyByGroup(this.resumeData.estimatedCostForStudies);
    const groupedOtherCosts = this.createStudyByGroup(this.resumeData.estimatedCostForStudies);
    this.resumeData.totalCosts = totalCost;
    this.totalCosts += compensationCost + otherCost;
    this.resumeData.estimatedCostForStudies = groupedStudyListCost
  }

  // Rascunho: value != null
  checkOptionsOnCreateOrDraft(): void {
    this.resumeData.environmentalRestrictions = '-'
    if (this.form.controls['environmentalRestrictions'].value != null) {
      this.resumeData.environmentalRestrictions = this.form.controls['environmentalRestrictions'].value === 'sim' ? 'Sim' : 'Não'
    }

    this.resumeData.securityQuestions = '-';
    if (this.form.controls['securityQuestions'].value != null) {
      this.resumeData.securityQuestions = this.form.controls['securityQuestions'].value === 'sim' ? 'Sim' : 'Não'
    }

    this.resumeData.demandByLicensing = '-';
    if (this.form.controls['demandByLicensing'].value != null) {
      this.resumeData.demandByLicensing = this.form.controls['demandByLicensing'].value === 'sim' ? 'Sim' : 'Não'
    }
  }


  createStudyByGroup(list) {
    const estimatedCostForStudies = list;
    const groupedByCategory = {};
    let costItem = 0
    estimatedCostForStudies.forEach(cost => {
      const category = cost.categoryName;
      const costValue = parseFloat(cost.cost);
      if (!groupedByCategory[category]) {
        groupedByCategory[category] = [];
      }
      groupedByCategory[category].push(cost);
      costItem += Number(costValue);
      groupedByCategory[category].total = costItem;
    });
    const groupedArray = Object.entries(groupedByCategory).map(([category, costs]) => ({
      category,
      costs
    }));
    return groupedArray
  }

  getRestricionsList() {
    this.restrictionsService.getRestrictions().subscribe({
      next: response => this.restrictionsList = response
    })
  }

  getCosts() {
    this.projectsV2Service.getDisciplineAssessmentList(this.projectId, this.subProjectId).subscribe({
      next: response => {
        response.forEach(study => {
          let totalCostStudy = 0
          study.costStudies.forEach(cost => {
            if (cost.category.id === 1) {
              totalCostStudy = cost.cost != undefined ? totalCostStudy + cost.cost : 0
            }
          })
          this.studyListFromDiscipline.push({
            id: study.discipline.id,
            description: study.discipline.description,
            cost: totalCostStudy
          })
        })
        return this.listOfCosts = response[0].costStudies
      }
    })
  }

  buildPayloadStrategy(strategyList: any[]) {
    let payloadStrategy: any[] = [];
    strategyList.forEach(strategy => {
      payloadStrategy.push({
        subProject: this.subProjectId,
        competentOrgan: Number(strategy['competentOrgan']),
        license: Number(strategy['license'])
      })
    })
    return payloadStrategy
  }

  buildPayloadEnvoromentalStudy(studyList: any[], isDraft: boolean) {
    let envoromentalStudyList: any[] | null = [];
    for (let study of studyList) {
      if (!isDraft && !study['licenseId']) {
        envoromentalStudyList = null;
        break;
      }

      envoromentalStudyList?.push({
        description: study['description'],
        cost: study['cost'],
        subProject: this.subProjectId,
        license: (!study['licenseId'] && isDraft) ? null : Number(study['licenseId']),
        category: 1,
      })
    }

    return envoromentalStudyList
  }

  buildPayloadEnviromentalStudy(studyList: any[]) {
    let envoromentalStudyList: any[] = [];
    studyList.forEach(study => {
      envoromentalStudyList.push({
        description: study['description'],
        cost: study['cost'],
        subProjectId: this.subProjectId,
        categoryId: 1,
        categoryName: "Estudo Ambiental",
        licenseName: study['license'],
        licenseId: study['licenseId']
      })
    })
    return envoromentalStudyList
  }

  private buildPayload(): any {
    return {
      asmtRestrictions: this.form?.value?.environmentalRestrictions === 'sim' ? true : false,
      restrictions: this.showRestrictions ? this.form?.value?.selectedRestrictionsList : null,
      asmtPriority: this.form?.value?.securityQuestions === 'sim' ? true : false,
      asmtLicenseRequired: this.form?.value?.demandByLicensing === 'sim' ? true : false,
    };
  }

  saveDraft() {
    // VERIFICA SE O URUARIO TEM AUTORIZACAO
    let payload = this.buildPayload()

    payload.isDraft = true
    this.licensesV2Service.saveDraftFinalAnalysis(this.subProjectId, payload).subscribe({
      next: result => {
        const strategyList = this.buildPayloadStrategy(this.licensingStrategy)

        const modal =
          this.modal.create<LoadingComponent>({
            nzTitle: 'Salvando rascunho',
            nzWidth: 555,
            nzClosable: false,
            nzCentered: true,
            nzMaskClosable: false,
            nzContent: LoadingComponent,
            nzViewContainerRef: this.viewContainerRef,
            nzFooter: null,
          });
        let apiCallsAndStates: any[] = [];


        if (this.form.controls['environmentalRestrictions'].value === 'sim') {
          if (this.resumeData.listOfRestrictions.length <= 0) {
            this.message.showErrorMessage("Lista de Restrições deve ser preenchida");
            return
          }
        }

        payload = this.buildPayload()
        payload.isDraft = true

        // ATUALIZA LISTA DE ESTRATEGIA INICIAL DE LICENCIAMENTO
        const requestStrategyList = this.buildPayloadStrategy(this.licensingStrategy);

        // ATUALIZA LISTA DE ESTUDOS AMBIENTAIS NECESSARIOS
        const enviromentalList = this.buildPayloadEnvoromentalStudy(this.environmentalStudiesList, true);

        payload = this.buildPayload()
        payload.isDraft = true

        apiCallsAndStates.push(
          {
            state: 'not_started',
            call: () => this.sendFinalAnalisysToDraft.apply(this, [payload]),
            label: "Enviando a requisição..."
          },
          {
            state: 'not_started',
            call: () => this.createInitialStrategyList.apply(this, [requestStrategyList]),
            label: "Salvando estratégia inicial..."
          },
          {
            state: 'not_started',
            call: () => this.createEnvironmentalStudies.apply(this, [enviromentalList]),
            label: "Salvando estudos ambientais..."
          },
        )

        this.addDeleteAttachmentToLoad(apiCallsAndStates);
        this.createUploadFilesCalls(this.subProjectId, apiCallsAndStates);

        const instance = modal.getContentComponent();
        instance.loadingCalls = apiCallsAndStates;
        instance.savedEntityIdPath = "subProjectId";
        modal.afterClose.subscribe(({status}) => {
          if (status === false) {
            this.message.showErrorMessage("Erro ao salvar rascunho!");
          } else {
            this.modalDraftRealizada();
          }
        })

      },
      error: error => {
        if (error.status === 401) {
          this.message.showErrorMessage("Usuário não possui Autorização")
          return
        }
      }
    })
  }

  private compareFileSize(a: NzUploadFile, b: NzUploadFile) {
    return (a.size || 0) - (b.size || 0);
  }

  callAttachmentUpload(attachment: AttachmentSaveDto, id) {
    if (!attachment.entityId) {
      attachment.entityId = id ? id : this.subProjectId;
    }

    return this.attachmentsService
      .uploadAttachment(attachment);
  }

  private createUploadFilesCalls(entityId, calls: any[]) {
    this.fileList = this.fileList.sort(this.compareFileSize);

    if (this.fileList.length) {
      for (const [index, file] of this.fileList.entries()) {
        const attachment: AttachmentSaveDto = {
          name: file.name,
          description: file.name,
          entityId: entityId,
          entityName: FINAL_ANALYSIS_ENTITY_NAME,
          contextInfo: {
            phase: 'Cadastro'
          },
          file: file.originFileObj ? file.originFileObj : file,
        };

        if (file.size) {
          calls.push({
            state: 'not_started',
            call: (entityId) => this.callAttachmentUpload.apply(this, [attachment, entityId]),
            label: `Salvando ${index + 1}º anexo...`
          })
        }
      }
    }
  }

  sendFinalAnalisysToDraft(payload: any) {
    return this.licensesV2Service.saveDraftFinalAnalysis(this.subProjectId, payload);
  }

  modalDraftRealizada() {
    const modal = this.modal.create<ConfirmationSendDraftModalComponent>({
      nzTitle: '',
      nzClosable: true,
      nzWidth: 555,
      nzCentered: true,
      nzContent: ConfirmationSendDraftModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });

    const instance = modal.getContentComponent();

    modal.afterClose.subscribe(() => {
      this.router.navigate(['/project-v2-analysis/', this.projectId, this.subProjectId])
    })
  }

  publishFinalAnalysis() {
    if (this.resumeData.environmentalRestrictions === '' ||
      this.fileList.length === 0 ||
      this.resumeData.securityQuestions === '' ||
      this.resumeData.demandByLicensing === '' ||
      this.environmentalStudiesList.length === 0 ||
      this.licensingStrategy.length === 0) {
      this.message.showErrorMessage("Dados obrigatórios não preenchidos!")
      return
    } else {

      const payloadDraft = this.buildPayload()
      payloadDraft.isDraft = true

      // ATUALIZA LISTA DE ESTRATEGIA INICIAL DE LICENCIAMENTO
      const requestStrategyList = this.buildPayloadStrategy(this.licensingStrategy);

      // ATUALIZA LISTA DE ESTUDOS AMBIENTAIS NECESSARIOS
      const enviromentalList = this.buildPayloadEnvoromentalStudy(this.environmentalStudiesList, false);

      if (enviromentalList == null) {
        this.message.showErrorMessage("Dados incompletos para cadastro de novo Estudo Ambiental")
        return;
      }

      const modal =
        this.modal.create<LoadingComponent>({
          nzTitle: 'Publicando parecer',
          nzWidth: 555,
          nzClosable: false,
          nzCentered: true,
          nzMaskClosable: false,
          nzContent: LoadingComponent,
          nzViewContainerRef: this.viewContainerRef,
          nzFooter: null,
        });

      let apiCallsAndStates: any[] = [];

      apiCallsAndStates.push(
        {
          state: 'not_started',
          call: () => this.sendFinalAnalisysToDraft.apply(this, [payloadDraft]),
          label: "Enviando a requisição..."
        },
        {
          state: 'not_started',
          call: () => this.createInitialStrategyList.apply(this, [requestStrategyList]),
          label: "Salvando estratégia inicial..."
        },
        {
          state: 'not_started',
          call: () => this.createEnvironmentalStudies.apply(this, [enviromentalList]),
          label: "Salvando estudos ambientais..."
        }
      )

      this.addDeleteAttachmentToLoad(apiCallsAndStates);

      let initialCalls = apiCallsAndStates.length;
      this.createUploadFilesCalls(this.subProjectId, apiCallsAndStates);
      const payload = this.buildPayload()
      payload.isDraft = false

      apiCallsAndStates.push({
        state: 'not_started',
        call: () => this.publishFinalAnalisys.apply(this, [payload]),
        label: "Finalizando publicação de parecer...",
        lastCall: true
      })

      const instance = modal.getContentComponent();
      instance.loadingCalls = apiCallsAndStates;
      instance.initialCallsAmount = initialCalls;
      instance.savedEntityIdPath = "subProjectId";
      instance.requiredValuePath = "subProjectId";
      modal.afterClose.subscribe(({status, requiredValue}) => {
        if (status === false) {
          this.message.showErrorMessage("Erro ao publicar parecer!");
        } else {
          this.modalConsolidationFinalRealizada(requiredValue);
        }
      })
    }
  }


  createInitialStrategyList(payload: any) {
    return this.licensesV2Service.postInitialStrategyList(this.subProjectId, payload);
  }

  createEnvironmentalStudies(payload: any) {
    return this.licensesV2Service.postEnviromentalStudyList(this.subProjectId, payload);
  }

  publishFinalAnalisys(payload: any) {
    return this.licensesV2Service.publishFinalAnalysis(this.subProjectId, payload);
  }

  modalConsolidationFinalRealizada(subProjectId: any = null) {
    const modal = this.modal.create<FinalAnalysisConfirmationSendModalComponent>({
      nzTitle: '',
      nzClosable: true,
      nzWidth: 555,
      nzCentered: true,
      nzContent: FinalAnalysisConfirmationSendModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });

    const instance = modal.getContentComponent();
    subProjectId = subProjectId ? subProjectId : this.subProjectId
    modal.afterClose.subscribe(() => {
      this.router.navigate(['/project-v2-analysis/', this.projectId, subProjectId])
    })
  }


  // INITAL LICENSE STRATEGY
  getLicensingStrategy() {
    this.licensesV2Service.getInitialStrategy(this.subProjectId).subscribe({
      next: response => {
        if (response) {
          response.forEach(license => {
            this.licensingStrategy.push({
              subProject: this.subProjectId,
              competentOrgan: license.competentOrgan.id,
              competentOrganName: license.competentOrgan.code,
              license: license.license.id,
              licenseName: license.license.description
            })
          });
        } else {
          this.licensingStrategy = []
        }
      }
    })
  }

  getCompetentOrgans() {
    this.competentOrganService.getCompetentOrgans().subscribe({
      next: response => {
        this.organList = response
      },
      error: erro => {
      }
    })
  }

  set LicensingProcessValue(val: any) {
    this.newLicensingProcess = val;
  }

  get LicensingProcessValue() {
    return this.newLicensingProcess || null;
  }

  getLicensingProcessByOrganId(setLicenseId: boolean = true) {
    this.licensesV2Service.getLicensesByCompetentOrganId(this.newOrganStrategy).subscribe({
      next: response => {
        this.licensingProcessList = response
        if (setLicenseId) {
          this.newLicensingProcess = this.newLicensingProcess == null ? -1 : this.newLicensingProcess;
        } else {
          this.newLicensingProcess = null
        }
      },
      error: erro => {
      }
    })
  }

  cancelNewLicensingProcessClick() {
    this.addNewLicensingStrategy = false;
    this.editStrategy = false;
    this.editingRow = null;
    this.editingStrategy = null;
    //this.editStudy = false;
    this.newOrganStrategy = null;
    this.newLicensingProcess = null;
    this.licensingProcessList = []
  }

  addNewProcessClick() {
    this.editStrategy = false
    this.addNewLicensingStrategy = true
    this.newOrganStrategy = null
    this.newLicensingProcess = null

    // Desabilita form de estudos
    this.editStudy = false;
    this.editingDiscipline = null;
    this.startEditEnvironmentStudy = false;
  }

  addNewLicensingProcess() {
    // this.showResume = true;
    if (this.newOrganStrategy && this.newLicensingProcess && this.newLicensingProcess != -1) {
      const requestBody = {
        subProject: this.subProjectId,
        competentOrgan: Number(this.newOrganStrategy),
        license: Number(this.newLicensingProcess)
      }
      this.message.showSucessMessage("Estratégia de licenciamento cadastrada com sucesso!")
      this.editStrategy = true

      this.selectedStrategy.push({
        organId: Number(this.newOrganStrategy),
        licensingProcessId: Number(this.newLicensingProcess)
      })
      const organName = this.organList.filter(organ => organ.id === Number(this.newOrganStrategy))[0].code
      const organId = this.organList.filter(organ => organ.id === Number(this.newOrganStrategy))[0].id
      const processName = this.licensingProcessList.filter(process => process.id === Number(this.newLicensingProcess))[0].description

      this.licensingStrategy.push({
        organId: organId,
        subProject: this.subProjectId,
        competentOrgan: Number(this.newOrganStrategy),
        competentOrganName: organName,
        license: Number(this.newLicensingProcess),
        licenseName: processName
      })
      // this.organList=[]
      // this.licensingProcessList=[]
      this.newOrganStrategy = null
      this.newLicensingProcess = null
      this.addNewLicensingStrategy = false
      this.editStrategy = false
    } else {
      this.message.showErrorMessage("Preencha todas as informações")
    }
  }

  deleteLicesingProcess(id) {
    const index = this.licensingStrategy.filter(license => license.id === id)[0]
    this.licensingStrategy.splice(this.licensingStrategy.indexOf(index), 1)
    this.message.showSucessMessage("Estratégia de licenciamento excluída com sucesso!")
    this.addNewLicensingStrategy = false
  }


  // NECESSARY STUDIES
  getEnviromentalNecessaryStudies() {
    this.environmentalStudiesService.getEnvironmentalStudiesList(this.subProjectId)
      .pipe(
        take(1),
        tap((studies) => {
          studies.forEach(study => {
            this.environmentalStudiesList.push({
              id: study.id,
              description: study.description,
              cost: study.cost,
              subProjectId: this.subProjectId,
              categoryId: study.category.id,
              categoryName: study.category.description,
              license: study.license.description,
              licenseId: study.license.id
            })
          });
          this.replaceResume()
        })
      ).subscribe();

    this.environmentalStudiesService.getFinalAnalysis(this.subProjectId)
      .pipe(
        take(1),
        tap((studies) => {
          this.listOfCosts = []
          this.listOfCosts.push(studies.costs)
          this.totalStudy = studies.costs.total.cost
        })
      ).subscribe();
  }

  getNecessaryStudyList(): void {
    this.environmentalStudiesService.getEnviromentnalStudyTypes().subscribe({
      next: (response: any) => {
        this.descriptionStudyList = [];
        this.descriptionStudyList = this.descriptionStudyList.concat(response.filter(element => element.description !== 'Nenhum estudo necessário'));
      }
    })
  }

  startEditDiscipline(id, study) {
    this.editingDiscipline = study;
    this.newEstimatedCost = study.cost;
    this.editStudy = true
    //this.editingRow = id
    this.editingStudyRow = id
    this.startEditEnvironmentStudy = true;
    this.newLicensingProcess = study.licenseId;
    this.replaceResume()

    // desabilitando form estrategia
    this.addNewLicensingStrategy = false;
    this.editStrategy = false;
  }

  startEditStrategy(id, strategy) {
    this.newOrganStrategy = strategy.competentOrgan ? strategy.competentOrgan : null;
    this.newLicensingProcess = strategy.license ? strategy.license : null;

    if (this.organList.length == 0) {
      this.getCompetentOrgans();
    }
    this.getLicensingProcessByOrganId()
    this.editingRow = id;
    this.editStrategy = true;
    this.editingStrategy = strategy;

    // Desabilita form de estudos
    this.editingStudyRow = null;
    this.editStudy = false;
    this.editingDiscipline = null;
    this.startEditEnvironmentStudy = false;
  }


  updateLicenseForStudy(study: any, editingStudyRow: any) {
    this.updateLicenseForStudySelected = study;
    this.updateLicenseForStudySelected.editingStudyRow = editingStudyRow;
    this.newLicensingProcessId = this.newLicensingProcess;
    this.editingRow = null;
  }

  getAllLicensingProcess() {
    this.licensesV2Service.getLicenses().subscribe({
      next: response => {
        this.licensingProcessListAll = response;
      },
      error: erro => {
      }
    })
  }

  getStudyLicenses() {
    this.licensesV2Service.getLicensingList()
      .pipe(
        take(1),
        tap((licenses) => {
          this.licensingStudyList = licenses;
        })
      ).subscribe();
  }

  createsEnvironmentalStudy(editingStudyRow) {
    const description = this.descriptionStudyList.filter(desc => desc.id === Number(this.newDescriptionNecessaryStudy))[0].description
    const id = this.descriptionStudyList.filter(desc => desc.id === Number(this.newDescriptionNecessaryStudy))[0].id;

    const requestBody: any = {
      id: id,
      description: description,
      cost: this.newEstimatedCost,
      subProjectId: this.subProjectId,
      categoryId: 1,
      categoryName: "Estudo Ambiental",
      license: this.licensingStudyList.filter(study => study.id === Number(this.newLicensingProcessNecessaryStudy))[0].description,
      licenseId: this.newLicensingProcessNecessaryStudy,
    };
    requestBody.editingStudyRow = editingStudyRow;
    this.environmentalStudiesList.push(requestBody)
    this.cancelNewNecessaryStudyClick()
    this.replaceResume()
    this.editStudy = true
  }

  deleteEnvironmentalStudy(studyId) {
    this.environmentalStudiesList.forEach((element, i) => {
      if (element.id === studyId) {
        this.environmentalStudiesList.splice(i, 1)
      }
    })
    this.replaceResume()
  }

  cancelNewNecessaryStudyClick() {
    this.editStudy = false;
    this.newDescriptionNecessaryStudy = ''
    this.licensingStudyList = []
    this.newLicensingProcessNecessaryStudy = ''
    this.newEstimatedCost = ''
    this.addNewNecessaryStudy = false;
    this.editingRow = null;
    this.editingStudyRow = null;
    this.startEditEnvironmentStudy = false;
    this.editingDiscipline = null;
  }

  addNewNecessaryStudyClick() {
    this.addNewNecessaryStudy = true;
    this.editStrategy = false;
    this.getNecessaryStudyList()
    this.getStudyLicenses();
  }

  editStrategyClick() {
    if (!this.newLicensingProcess || !this.newOrganStrategy) {
      this.message.showErrorMessage("Preencha todos os dados");
      return
    }
    this.editingRow = null;
    this.editingStudyRow = null;
    this.editStudy = false;
    this.editStrategy = false;
    this.licensingStrategy = this.licensingStrategy.map(licStrategy => {
      if (licStrategy.competentOrgan === this.editingStrategy.competentOrgan &&
        licStrategy.license === this.editingStrategy.license
      ) {
        licStrategy.competentOrgan = this.newOrganStrategy;
        licStrategy.competentOrganName = this.organList.filter(org => org.id == this.newOrganStrategy)[0]?.code;
        licStrategy.license = this.newLicensingProcess;
        licStrategy.licenseName = this.licensingProcessList.filter(lic => lic.id == this.newLicensingProcess)[0]?.description;
      }

      return licStrategy;
    })

    this.newOrganStrategy = null;
    this.newLicensingProcess = null;
  }

  editNecessaryStudyClick(editingStudyRow) {
    if (this.updateLicenseForStudySelected) {
      this.environmentalStudiesList.forEach((element, i) => {
        if (element.id === this.updateLicenseForStudySelected.id && element.editingStudyRow == this.updateLicenseForStudySelected.editingStudyRow) {
          this.environmentalStudiesList[i].licenseId = Number(this.newLicensingProcessId);
          this.environmentalStudiesList[i].license = this.licensingProcessListAll.filter(element => element.id === Number(this.newLicensingProcessId))[0].description;
        }
      });
    }

    this.editingRow = null;
    this.startEditEnvironmentStudy = false;
    this.editingStudyRow = null;
    this.editStudy = false;
    // this.addNewNecessaryStudy = false;
    if(this.editingDiscipline){
      this.editingDiscipline.cost = this.newEstimatedCost;
    }
    this.newEstimatedCost = '';
    this.environmentalStudiesList = this.environmentalStudiesList.map(envStd => {
      if (envStd.id == this.editingDiscipline.id && envStd.editingStudyRow == editingStudyRow) {
        envStd.cost = this.editingDiscipline?.cost
      }
      return envStd
    })

    this.environmentalStudiesList[editingStudyRow].cost = this.editingDiscipline?.cost

    this.replaceResume();
    this.editingDiscipline = null
  }

  addNewNecessaryStudyDB(editingStudyRow) {
    this.showResume = true;
    if (!this.newDescriptionNecessaryStudy || !this.newLicensingProcessNecessaryStudy || !this.newEstimatedCost) {
      this.message.showErrorMessage("Dados incompletos para cadastro de novo Estudo Ambiental")
      return
    }
    this.addNewStudy = true;
    this.createsEnvironmentalStudy(editingStudyRow);
    this.cancelNewNecessaryStudyClick();
  }


  // UPLOAD DOCUMENTS
  beforeUpload = (file: NzUploadFile): boolean => {
    this.verifyIfIsValidFile(file).then(result => {
      if( this.isInvalidFile === false){
        if(this.fileValidationService.isExtensionForbidden(file.name)){
          this.message.showErrorMessage(`Extenção do arquivo ${file.name} não é suportada!`);
        } else {
          if (this.checkFileNameLength(file) && this.checkFileSize(file)) {
            file.thumbUrl = this.utilService.getIconFromFileName(file.name);
            this.fileList = this.fileList.concat(file);
            this.showResume = true;
          }
        }
      } else {
        this.isInvalidFile = false
      }
    })
    return false;
  };

  private checkFileSize(file: NzUploadFile): boolean {
    if (file && file.size && file.size > 350 * 1024 * 1024) {
      this.message.showErrorMessage('O arquivo é muito grande. Por favor, selecione um arquivo com menos de 350 MB.');
      return false;
    }
    return true;
  }

  private checkFileNameLength(file: NzUploadFile): boolean {
    if (file.name.length > 200) {
      this.message.showErrorMessage('Nome do arquivo muito grande. Máximo é 200 caracteres.');
      return false;
    }
    return true;
  }

  async verifyIfIsValidFile(file) {

    await this.fileValidationService.isExeFile(file).then(result => {
      if (result === true) {
        this.isInvalidFile = true
        this.message.showErrorMessage(`Tipo de arquivo ${file.name} não é suportado!`);
      }
    })
    await this.fileValidationService.isZipFile(file).then(result => {
      if (result === true) {
        this.isInvalidFile = true
        this.message.showErrorMessage(`Conteúdo ou extensão do arquivo ${file.name} não é suportada!`);
      }
    })
  }

  removeFiles = (file: NzUploadFile): boolean => {
    this.filesToDelete.push(file.uid);
    if (this.statusRipla === "CON") {
      // this.deleteAttachment(this.filesToDelete)
      this.fileList = this.fileList.filter((item) => item !== file);
      // this.message.showSucessMessage("Arquivo excluído com sucesso")
      return false
    }
    this.fileList = this.fileList.filter((item) => item !== file);
    this.fileListDuplicated = this.fileListDuplicated.filter(
      (item) => item !== file
    );
    return false;
  };

  addDeleteAttachmentToLoad(loadingCallsList: any[]) {
    for (const file of this.filesToDelete) {
      loadingCallsList.push({
        state: 'not_started',
        call: () => this.deleteAttachment.apply(this, [file]),
        label: "Excluindo anexos removidos...",
      })
    }
  }

  deleteAttachment(file) {
    return this.attachmentsService
      .deleteAttachment(file)
  }

  private close(subProjectId) {
    this.isVisibleModal.emit(false);
    this.isAddProjectVisible = false;
  }

  private uploadFiles(entityId) {
    if (this.fileList.length) {
      for (const file of this.fileList) {
        const attachment: AttachmentSaveDto = {
          name: file.name,
          description: file.name,
          entityId,
          entityName: SUB_PROJECT_PARECER_FINAL,
          contextInfo: {
            phase: 'Parecer Final'
          },
          file,
        };

        this.attachmentsService
          .uploadAttachment(attachment)
          .pipe(take(1))
          .subscribe({
            next: () => {
              // this.message.showSucessMessage(`Arquivo enviado com sucesso: ${attachment.name}`);
              this.utilService.updateAttachment(true);
            },
            error: () => {
              this.utilService.updateAttachment(false);
            }
          });
      }
    }
    this.close(this.subProjectId);
  }

  downloadFile = (file: NzUploadFile): void => {
    if (file.uid === undefined) return;
    this.attachmentsService.getDownloadFile(+file.uid).subscribe({
      next: (resp) => this.attachmentsService.downloadFile(resp, file.name),
    });
  };

  handleError() {
    this.message.showSucessMessage(
      `Erro realizar download`
    );
  }

  getAttachments() {
    this.attachmentsService
      .getAttachmentsByEntityAndEntityId(
        this.subProjectId,
        FINAL_ANALYSIS_ENTITY_NAME
      )
      .subscribe((data) => {
        const nzUploadFiles = this.buildNzUploadFileArrayFromResponse(data);
        this.fileList = nzUploadFiles;
        this.fileListAlreadyUploaded = nzUploadFiles;
      });
  }

  private buildNzUploadFileArrayFromResponse(data: AttachmentsModel[]) {
    const files: NzUploadFile[] = [];
    for (const image of data) {
      const file: NzUploadFile = {
        description: image.description,
        name: image.name,
        filename: image.name,
        uid: image.id?.toString() || '',
        thumbUrl: this.utilService.getIconFromFileName(image.name),
        status: 'done',
      };
      files.push(file);
    }
    return files;
  }

  getHintText(fileName: string): string {
    if (fileName.length > 60) {
      return fileName
    }
    return "";
  }

  // BOTAO SAIR PARECER FINAL
  showCancelConfirm(): void {
    this.modal.confirm({
      ...(this.message.showMessageModalConfirmCancelFinalConsolidation() as any),
      nzOnOk: () => {
        this.licensingStrategy = []
        this.environmentalStudiesList = []
        this.router.navigate(['/project-v2-analysis/' + this.projectId + '/' + this.subProjectId])
      },
      nzOnCancel: () => this.saveDraft()
    });
  }

}
