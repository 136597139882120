import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {FINAL_ANALYSIS} from "../../modules/projects/constants/apiEndpointsConstants";
import {HttpClient} from "@angular/common/http";
import {FinalAnalysis} from "../dto/final-analysis-reponse.dto";

@Injectable({
  providedIn: 'root'
})
export class FinalAnalysisService {

  constructor(private readonly http: HttpClient) { }

  getFinalAnalysis(subprojectId: number): Observable<FinalAnalysis> {
    return this.http.get<FinalAnalysis>(`${FINAL_ANALYSIS}/${subprojectId}`)
  }
}
