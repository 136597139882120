<div nz-row nz-col nzSpan="24">

  <div nz-col nzSpan="24" class="ali-center-flex">

    <div class="icone">
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none" id="analisys_ok-icon">
        <path d="M28 49C39.598 49 49 39.598 49 28C49 16.402 39.598 7 28 7C16.402 7 7 16.402 7 28C7 39.598 16.402 49 28 49Z" stroke="#00A599" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.667 28L25.667 35L37.3337 23.3333" stroke="#00A599" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>

    </div>
    <h1 class="titulo">{{title}}</h1>
    <small class="textoFixo" >{{subTitle}}</small>
  </div>

  <div nz-col nzSpan="24" class="ali-left">
    <button
      id="btnCancel"
      type="button"
      class="btn-sl btn-sl-grey buttonCancel"
      (click)="handleOk()">
      Ok
    </button>
  </div>
</div>
