<div class="container" *ngIf="!loadingSubProjects">
  <div nz-row class="mb-20">
    <div nz-col nzSpan="14" class="header">
      <a [routerLink]="['/project-v2-view/' + projectId]" class="mr-10" id="goBackToProjectView">
        <img src="/assets/icons/icon_arrow-back.svg" alt="">
      </a>
      <h1 id="projectName" *ngIf="subProject">
        {{ "projects.analysis.ripla-analysis" | translate }}
        {{ subProject.type | titlecase }} {{ subProject.version }}
      </h1>
    </div>
    <div nz-col nzSpan="10" class="action-box">
      <button type="button" class="button-analysis" (click)="showOpenRequestReviewAnalisysModal(false)"
        *ngIf="(!projectHasOpenReview() && riplaRevisioRequisition || projectHasOpenReview() && riplaAnswerRequisition) && subProject.status !== 'CON'" [disabled]="!enableResponseReview">
        <i class="icon__edit">
          <svg class="svg-fill" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M3.66858 0.468629C3.96864 0.168571 4.3756 0 4.79995 0H13.6C13.8121 0 14.0156 0.0842855 14.1656 0.234315L15.7656 1.83431C15.9157 1.98434 16 2.18783 16 2.4V14.4C16 14.8243 15.8314 15.2313 15.5313 15.5314C15.2313 15.8314 14.8243 16 14.4 16H9.59995C9.15812 16 8.79995 15.6418 8.79995 15.2C8.79995 14.7582 9.15812 14.4 9.59995 14.4H14.4V3.2H13.6C13.1581 3.2 12.8 2.84183 12.8 2.4V1.6H4.79995L4.79995 6.4C4.79995 6.84183 4.44178 7.2 3.99995 7.2C3.55812 7.2 3.19995 6.84183 3.19995 6.4V1.6C3.19995 1.17565 3.36852 0.768687 3.66858 0.468629ZM6.39995 5.6C6.39995 5.15817 6.75812 4.8 7.19995 4.8H12C12.4418 4.8 12.8 5.15817 12.8 5.6C12.8 6.04183 12.4418 6.4 12 6.4H7.19995C6.75812 6.4 6.39995 6.04183 6.39995 5.6ZM6.39995 8.8C6.39995 8.35817 6.75812 8 7.19995 8H12C12.4418 8 12.8 8.35817 12.8 8.8C12.8 9.24183 12.4418 9.6 12 9.6H7.19995C6.75812 9.6 6.39995 9.24183 6.39995 8.8ZM8.79995 12C8.79995 11.5582 9.15812 11.2 9.59995 11.2H12C12.4418 11.2 12.8 11.5582 12.8 12C12.8 12.4418 12.4418 12.8 12 12.8H9.59995C9.15812 12.8 8.79995 12.4418 8.79995 12Z" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M1.58869 8.45737C1.88777 8.16421 2.28988 8 2.70869 8C3.12749 8 3.5296 8.16421 3.82869 8.45737L3.8344 8.46297L7.76237 12.391C7.9124 12.541 7.99669 12.7445 7.99669 12.9567V15.1967C7.99669 15.6385 7.63851 15.9967 7.19669 15.9967H4.95669C4.74451 15.9967 4.54103 15.9124 4.391 15.7624L0.457343 11.8287C0.16418 11.5296 0 11.1275 0 10.7087C0 10.2899 0.164208 9.88777 0.457372 9.58869L0.462972 9.58297L1.58869 8.45737ZM2.70869 9.60006L1.60006 10.7087L5.28806 14.3967H6.39669V13.2881L2.70869 9.60006Z" />
          </svg>
        </i>
        <span class="button__text">
          {{ projectHasOpenReview() ? ('projects.analysis.continue-request-review' | translate) :
          ('projects.analysis.request-review' | translate) }}
        </span>
      </button>

      <button type="button" class="button-primary" (click)="callAnalysisConsolidateFinal()"
        *ngIf="riplaFinalProtocol && subProject.status !== 'CON'">
        <i class="icon__check">
          <svg class="svg-fill" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_154_167" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="-2" y="-2" width="20"
              height="20">
              <rect x="-1.6001" y="-1.59998" width="19.2" height="19.2" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_154_167)">
              <path
                d="M6.40004 9.27984L11.12 4.55984C11.2667 4.41318 11.4534 4.33984 11.68 4.33984C11.9067 4.33984 12.0934 4.41318 12.24 4.55984C12.3867 4.70651 12.46 4.89318 12.46 5.11984C12.46 5.34651 12.3867 5.53318 12.24 5.67984L6.96004 10.9598C6.80004 11.1198 6.61337 11.1998 6.40004 11.1998C6.18671 11.1998 6.00004 11.1198 5.84004 10.9598L3.76004 8.87984C3.61337 8.73318 3.54004 8.54651 3.54004 8.31984C3.54004 8.09318 3.61337 7.90651 3.76004 7.75984C3.90671 7.61318 4.09337 7.53984 4.32004 7.53984C4.54671 7.53984 4.73337 7.61318 4.88004 7.75984L6.40004 9.27984Z" />
            </g>
          </svg>
        </i>
        <ng-container *ngIf="finalAnalysisResponse && !checkIfFinalAnalysisIsSketch()"> <span class="button__text">
            {{ 'projects.analysis.consolidate-final' | translate }}
          </span>
        </ng-container>
        <ng-container *ngIf="finalAnalysisResponse && checkIfFinalAnalysisIsSketch()">
          <span class="button__text">
            {{ 'projects.analysis.final-opinion.continue-final-opinion' | translate }}
          </span>
        </ng-container>
      </button>

      <button class="btn-final " id="btn-edit" (click)="callAnalysisConsolidateFinal()"
        *ngIf="riplaFinalProtocol && subProject.status === 'CON'">
        <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-5">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M14.1395 3.69638L16.3034 5.86034C16.5693 5.48321 16.7068 5.15032 16.7508 4.87173C16.8114 4.48799 16.713 4.10607 16.3034 3.69643C15.8937 3.28679 15.5118 3.18845 15.1281 3.24903C14.8495 3.29302 14.5166 3.43054 14.1395 3.69638ZM15.0712 7.19258L12.8072 4.92857L3.96181 13.774L3.9618 16.038L6.22581 16.038L15.0712 7.19258ZM14.8453 1.4579C15.8653 1.29685 16.8132 1.64179 17.5856 2.41422C18.358 3.18665 18.703 4.13456 18.5419 5.15454C18.3895 6.11976 17.8039 7.02436 16.9946 7.83368L7.24247 17.5858C7.07243 17.7558 6.84182 17.8513 6.60136 17.8513L3.05514 17.8513C2.81468 17.8513 2.58407 17.7558 2.41404 17.5858C2.244 17.4158 2.14848 17.1851 2.14848 16.9447L2.14848 13.3985C2.14848 13.158 2.244 12.9274 2.41403 12.7574L12.1661 3.00525C12.9755 2.19593 13.8801 1.6103 14.8453 1.4579Z"
            fill="#FFFFFF" />
        </svg>
        Editar parecer
      </button>
    </div>
  </div>

  <div nz-row>
    <div nz-col nzSpan="9">
      <app-project-info [projectId]="projectId" [project]="project" [subProjectId]="subProjectId"></app-project-info>
    </div>

    <div nz-col nzSpan="15" class="analysis-container analysisBox">

      <!-- SOBRE A REQUISICAO -->
      <div nz-col class="margin">
        <div class="rounded-row">
          <h2 class="mb-10">
            {{ "projects.analysis.about-request" | translate }}
          </h2>
          <nz-table nzTemplateMode nzSize="small">
            <thead>
              <tr>
                <th class="tableTh">{{ 'projects.analysis.request-number' | translate }}</th>
                <th class="tableTh">{{ 'projects.analysis.user-responsible' | translate }}</th>
                <th class="tableTh">{{ 'projects.analysis.submission-date' | translate }}</th>
                <th class="tableTh">{{ 'projects.analysis.deadline' | translate }}</th>
                <th class="tableTh">{{ 'projects.analysis.status' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="editable-row" id="riplaNumer" *ngIf="subProject">
                <td class="tableTd">
                  <span class="riplaNumber" id="riplaNumber">
                    <app-short-text-hint [originalText]="subProject.number" [maxChars]="10"> </app-short-text-hint>
                  </span>
                </td>
                <td class="tableTd">
                  <div class="editable-cell" id="" [hidden]="editId === subProject.id+''">
                    {{ subProject.userLegalRepresentative !== null ? subProject.userLegalRepresentative.name : '-' }}
                  </div>
                  <select [(ngModel)]="selectedUserResponsibleId" (ngModelChange)="selectNewUserAndSave()"
                    (blur)="stopEdit()" [hidden]="editId !== subProject.id+''" *ngIf="users"
                    id="usersResponsibleSelectOption" class="gridSelectOption">
                    <option value=" " label=" "  [hidden]="true"></option>
                    <option class="userItem selectItem" *ngFor="let user of users" value="{{user.id}}"
                      label="{{user.name}}"></option>
                  </select>
                </td>
                <td class="tableTd">{{ subProject.submissionDate | date: 'dd/MM/yyyy' }}</td>
                <td class="tableTd">{{ subProject.analysisDeadLine | date: 'dd/MM/yyyy' }}</td>
                <td class="tableTd statusTd">
                  <app-badge style="width: 140px;" [badgeText]="subProject.status"
                    [badgeColor]="subProject.overdue ? 'red' : 'blue'" *ngIf="subProject.status === 'EAN'"></app-badge>
                  <app-badge style="width: 140px;" [badgeText]="subProject.status"
                    *ngIf="subProject.status !== 'EAN'"></app-badge>
                  <a *ngIf="createDisciplineStudy" nz-popover nzPopoverPlacement="bottom" nzPopoverTrigger="click"
                    [nzPopoverContent]="popOverEditRiplaContent" [nzPopoverOverlayStyle]="customStyle"
                    [(nzPopoverVisible)]="popOverEditRipla">
                    <img src="assets/icons/icon_more_vertical.svg" alt="">
                  </a>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>

      <app-final-opinion [subProject]="subProject" *ngIf="subProject"
        [finalAnalysisResponse]="finalAnalysisResponse"></app-final-opinion>

      <!-- Pedido de revisão da RIPLA para área solicitante -->
      <section *ngIf="reviewSection">
        <app-project-v2-ripla-review-request *ngIf="reviewSection" [reviewId]="reviewId"
          [subProjectId]="this.subProject.id" [projectId]="this.subProject.projectId" [reviewData]="reviewData"
          [enableEditAndCancelReview]="enableEditAndCancelReview" [enableResponseReview]="enableResponseReview"
          (updateReview)="updateModalReview()" (cancelReview)="clearReviewItems()">
        </app-project-v2-ripla-review-request>
      </section>

      <!-- DISCIPLINAS -->
      <div nz-col class="margin">
        <div class="rounded-row">
          <h2 class="mb-10">Análise das disciplinas técnicas</h2>

          <!-- LINK PARA CARREGAR ARQUIVO COLABORATIVO -->
          <div class="disciplineLink">
            <div class="tituloLink">
              Arquivo colaborativo para consolidação das análises
            </div>
            <div *ngIf="!hasColaborativeArchive">
              <div class="subtituloColaborativeArchive">
                Link para o arquivo
              </div>
              <div class="addLinkAlign">
                <div class="linkColaborativeArchiveName">
                  <input nz-input [(ngModel)]="colaborativeArchive" placeholder="Insira o link"
                    style="border-radius: 8px;" class="inputLinkColaborativeArchiveName" />
                </div>
                <button *ngIf="createDisciplineStudy" nz-button class="btnLinkColaborativeArchiveName"
                  (click)="addLinkArchiveClick()">Inserir
                  Link
                </button>
              </div>
            </div>

            <div *ngIf="hasColaborativeArchive">
              <div class="subtituloLink">As análises de cada disciplina devem ser inseridas no arquivo disponível para a
                construção do Anexo IV.
              </div>
              <div class="linkGrid">
                <div class="icon">
                  <app-svg-icon icon="document-icon"></app-svg-icon>
                </div>
                <div class="linkName" *ngIf="!editLink"><a href="{{colaborativeArchive}}" target="_blank"> <app-short-text-hint [originalText]="colaborativeArchive" [maxChars]="140"></app-short-text-hint></a></div>
                <div class="linkName" *ngIf="editLink">
                  <input nz-input [(ngModel)]="colaborativeArchive" [title]="colaborativeArchive"
                    placeholder="Insira o link" style="border-radius: 8px;" class="inputLinkColaborativeArchiveName" />
                </div>
                <div class="edit-button">
                  <button *ngIf="createDisciplineStudy" nz-button class="buttonLink" (click)="editLinkClick()">Editar
                    Link</button>
                </div>
              </div>
            </div>
          </div>

          <!-- LISTA DE DISCPLINAS -->
          <div class="discipline-list">
            <nz-table nzTemplateMode nzSize="small">
              <thead>
                <tr>
                  <th class="tableTh">Disciplina</th>
                  <th class="tableTh">Responsável</th>
                  <th class="tableTh">Prazo</th>
                  <th class="tableTh">Status</th>
                  <th class="tableTh"></th>
                </tr>
              </thead>
              <tbody>
                <tr class="editable-row" id="riplaInfo" *ngFor="let discipline of disciplineListByProject; let i = index">
                  <td class="tableTdDiscipline" (click)="goToAssessment(discipline.id)">
                    <span class="disciplineName">
                      <app-short-text-hint [originalText]="discipline.discipline.description" [maxChars]="50"></app-short-text-hint>
                    </span>
                  </td>

                  <td>
                    <div *ngIf="editingRow === i"
                         style="border: 1px solid #EEEEEE; border-radius: 8px;padding-left: 3px;">
                      <nz-select [(ngModel)]="selectedUserResponsibleIdDiscipline"
                                 [compareWith]="compareFn"
                                 nzBorderless>
                        <nz-option *ngFor="let user of usersSpecialist"
                                   [nzValue]="user.id"
                                   [nzLabel]="user.name !== undefined ? user.name : ''"></nz-option>
                      </nz-select>
                    </div>
                    <span class="userItem selectItem" *ngIf="editingRow !== i">
                      <app-short-text-hint [originalText]="discipline.userAssessment.name" [maxChars]="32"></app-short-text-hint>
                    </span>
                  </td>

                  <td class="tableTd" (click)="goToAssessment(discipline.id)" *ngIf="editingRow !== i">
                      {{ discipline.analysisDeadline | date : "dd/MM/yyyy" }}
                  </td>

                  <td class="tableTd statusTd" (click)="goToAssessment(discipline.id)" *ngIf="editingRow !== i">
                    <span [ngClass]="discipline.status === 'EAN' ? 'statusDisciplineOn' : 'statusDisciplineOff'"
                      [class.statusDisciplineAfterDate]="isDeadLineDate(discipline) && discipline.status === 'EAN'">
                      {{discipline.status === 'EAN' ? 'Em análise' : ''}}
                      {{discipline.status === 'CON' ? 'Concluído' : ''}}
                    </span>
                  </td>

                  <td class="tableTd" (click)="goToAssessment(discipline.id)" *ngIf="editingRow === i" colspan="2">
                    <nz-date-picker id="editDateDeadlineDiscipline"
                                    nzFormat="dd/MM/yyyy"
                                    class="datepicker"
                                    [nzPlacement]="'topRight'"
                                    [nzDisabledDate]="disabledDate"
                                    [(ngModel)]="editDateDeadlineDiscipline">
                    </nz-date-picker>
                  </td>

                  <td style="padding: 0 !important">
                    <!-- TRES PONTOS MENU -->
                    <div>
                      <button *ngIf="createDisciplineStudy && !isEditing" class="buttonMenuFlow" nz-button nz-dropdown
                        [nzTrigger]="'click'" [nzDropdownMenu]="menu">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <ellipse cx="10.5" cy="10.5" rx="1.5" ry="1.5" transform="rotate(-90 10.5 10.5)"
                            fill="#007E7A" />
                          <ellipse cx="10.5" cy="4.5" rx="1.5" ry="1.5" transform="rotate(-90 10.5 4.5)" fill="#007E7A" />
                          <ellipse cx="10.5" cy="16.5" rx="1.5" ry="1.5" transform="rotate(-90 10.5 16.5)"
                            fill="#007E7A" />
                        </svg>
                      </button>
                    </div>

                    <nz-dropdown-menu #menu="nzDropdownMenu">
                      <ul nz-menu>
                        <li nz-menu-item class="itemMenuFlow" (click)="startEditDiscipline(i,discipline)">
                          <img src="assets/icons/icon_edit.svg" class="mr-10 icone" alt="" />
                          <span class="titleMenuItem">Editar</span>
                        </li>
                        <li nz-menu-item class="itemMenuFlow" (click)="deleteDiscipline(discipline.discipline.id)">
                          <img src="assets/icons/icon_delete.svg" class="mr-10 icone" alt="" />
                          <span class="titleMenuItem">Excluir</span>
                        </li>
                      </ul>
                    </nz-dropdown-menu>
                  </td>
                </tr>
                <tr *ngIf="editingRow !== null" class="add-discipline-action">
                  <td colspan="5">
                    <button (click)="stopEditDiscipline()" nz-button class="btn btn-green btnCancelDiscipline">Cancelar</button>
                    <button (click)="updateDiscipline()" nz-button nzType="primary" class="btnAddDiscipline">Editar</button>
                  </td>
                </tr>

                <!-- BLOCO PARA ADICIONAR NOVA DISCIPLINA -->
                <tr *ngIf="addNewDiscipline">
                  <!-- LISTA DE DISCIPLINAS -->
                  <td>
                    <select [(ngModel)]="newIdDiscipline"
                      [compareWith]="compareFn" *ngIf="users" id="disciplineSelectOption" class="gridSelectOption">
                      <option value=" " label=" "  [hidden]="true"></option>
                      <option class="selectItem" *ngFor="let discipline of disciplinesList" value="{{discipline.id}}"
                        label="{{discipline.description}}"></option>
                    </select>
                  </td>
                  <td>
                  <!-- RESPONSAVEL PELA DISCIPLINA -->
                    <select [(ngModel)]="newUserResponsibleIdDiscipline"
                      [compareWith]="compareFn"
                      id="usersSelectOption" class="gridSelectOption">
                      <option value=" " label=" "  [hidden]="true"></option>
                      <option class="userItem selectItem" *ngFor="let user of usersSpecialist" value="{{user.id}}"
                        label="{{user.name}}"></option>
                    </select>
                  </td>
                  <td colspan="3">
                  <!-- PRAZO DISCIPLINA -->
                    <nz-date-picker id="newDateDeadlineDiscipline" nzFormat="dd/MM/yyyy" class="datepicker"
                      [nzPlacement]="'topRight'" [nzDisabledDate]="disabledDate" [(ngModel)]="newDateDeadlineDiscipline">
                    </nz-date-picker>
                  <!-- STATUS DISCIPLINA -->

                    <select [hidden]="true" [(ngModel)]="newStatusDiscipline"
                      id="stateSelectOption" class="gridSelectOption">
                      <option value="EAN" [selected]="true">Em análise</option>
                    </select>
                  </td>
                </tr>

                <tr *ngIf="addNewDiscipline" class="add-discipline-action">
                  <td colspan="5">
                    <button (click)="cancelNewDisciplineClick()" nz-button class="btn btn-green btnCancelDiscipline">Cancelar</button>
                    <button (click)="addNewDisciplineDB()" nz-button nzType="primary" class="btnAddDiscipline">Adicionar</button>
                  </td>
                </tr>
                <!-- FIM BLOCO PARA ADICIONAR NOVA DISCIPLINA -->

                <tr>
                  <td colspan="5">
                    <div class="addNewDisciplineButton"
                      *ngIf="createDisciplineStudy && disciplineListByProject.length < 6">
                      <span class="project-doc-icon" (click)="addNewDisciplineClick()">
                        <img src="/assets/icons/icon_plus_circle.svg" alt="{{'projects.view.license.add-license'}}">
                      </span>
                      <span class="addNewDiscipline" (click)="addNewDisciplineClick()">Adicionar nova disciplina</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>
        </div>
      </div>

      <div nz-col class="margin">
        <app-histories-review [subProject]="subProject"></app-histories-review>
      </div>
    </div>
  </div>
</div>

<div class="spinner" *ngIf="loadingSubProjects">
  <nz-spin nzSimple id="spinner"></nz-spin>
</div>

<ng-template #popOverEditRiplaContent class="popOverMargin">
  <div class="btnEditRipla" id="btnEditRipla" (click)="startEdit(subProject.id + '')">
    <img src="assets/icons/icon_edit.svg" class="mr-10" alt="" />
    {{ "projects.analysis.edit" | translate }}
  </div>
</ng-template>

<ng-template #popOverEditDisciplineContent class="popOverMarginDiscipline">
  <div class="btnEditRipla mb-10" id="btnEditDiscipline" (click)="startEditDiscipline(subProject.id + '', null)">
    <img src="assets/icons/icon_edit.svg" class="mr-10" alt="" /> Editar
  </div>
  <div class="btnEditRipla" id="btnExcludeDiscipline" (click)="startEditDiscipline(subProject.id + '', null)">
    <img src="assets/icons/icon_delete.svg" class="mr-10" alt="" /> Excluir
  </div>
</ng-template>
