import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LicensingService } from '../../../service/licensing.service';
import { MessageService } from 'src/app/shared/common/services/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from 'src/app/shared/dto/activities.dto';
import { NzModalService } from 'ng-zorro-antd/modal';
import { UserService } from 'src/app/shared/services/user.service';
import {UtilService} from "../../../../../../shared/util.service";

@Component({
  selector: 'app-activity-study-items',
  templateUrl: './activity-study-items.component.html',
  styleUrls: ['./activity-study-items.component.scss']
})
export class ActivityStudyItemsComponent implements OnInit {

  @Output() reload: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() activities!: Activity[];
  @Input() hasActivityParent?: boolean = false;
  @Input() projectId?: number;
  @Input() processId?: number;
  @Input() activitySubId?: number;


  route: string = "";
  cadActivityInsert: boolean = false;
  cadActivtyExclude: boolean = false;

  constructor(
    private licensingService: LicensingService,
    private readonly message: MessageService,
    private activatedRoute: ActivatedRoute,
    private readonly modal: NzModalService,
    private readonly router: Router,
    public readonly userService: UserService,
    private readonly utilService: UtilService
  ) { }

  ngOnInit() {
    this.cadActivityInsert = this.utilService.getAuthorization('insert', 'CADACTIVITY')
    this.cadActivtyExclude = this.utilService.getAuthorization('exclude', 'CADACTIVITY')
  }


  isExistActivity(): boolean {
    return this.activities.length > 0 ? true : false;
  }

  toggle(activity: Activity): void {
    activity.expanded = !activity.expanded;
  }

  deleteActivity(id){
    this.modal.confirm({
      ...(this.message.showMessageModalConfirmCancel() as any),
      nzOnOk: () => {
        this.licensingService.deleteActivity(id)
        .subscribe({
          next: (response) => {
            this.reload.emit(true);
          },
          error: (err) => {}
        })
      },
    });
  }

  toNewEIAStudy() {
    let activitySubId = Number(this.activatedRoute?.snapshot.params['activityId']);
      this.router.navigate([`/projects/${this.projectId}/process/${this.processId}/new-sub-activity/${activitySubId}`,'EIA'])
  }
  goToActivity(id){
    this.router.navigate([`/projects/${this.projectId}/process/${this.processId}/edit-activity/${id}`]);
  }

}
