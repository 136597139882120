import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DISCIPLINE_ASSESSMENT_STUDY_TYPE, ENVIRONMENTAL_STUDIES, LICENSES_V2_FINAL_ANALYSIS } from 'src/app/modules/projects/constants/apiEndpointsConstants';
import { EnvironmentStudiesResponse } from '../models/environment-studies.model';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentalStudiesService {

  constructor(private readonly http: HttpClient) { }

  getEnvironmentalStudiesList(subProjectId: number): Observable<EnvironmentStudiesResponse[]> {
    const url = `${ENVIRONMENTAL_STUDIES}/${subProjectId}`;
    return this.http.get<EnvironmentStudiesResponse[]>(url);
  }

  getFinalAnalysis(subProjectId):Observable<any>{
    const url = `${LICENSES_V2_FINAL_ANALYSIS}/${subProjectId}`;
    return this.http.get<any>(url);
  }

  getEnviromentnalStudyTypes() {
    const url = `${DISCIPLINE_ASSESSMENT_STUDY_TYPE}`;
    return this.http.get<any[]>(url);
  }
}
