<div class="app-simple-layout sl__simpleLayout">
  
  <header>
    <div class="sl__simpleLayout__header__content">
      <button type="button" id="button-back">
        <img src="assets/icons/icon_arrow-back.svg" alt="Voltar">
      </button>

      <h1>Título da página</h1>
    </div>

    <!--  Header Botões  -->
    <div class="sl__simpleLayout__header__nav">
      <app-button-icon-secondary 
        [id]="'btn-icon-secondary'" 
        [icon]="'icon_review_info'" 
        [text]="'Texto'"
        altText="{{ 'Ícone Revisão' }}">
      </app-button-icon-secondary>
      
      <app-button-icon-primary 
        [id]="'btn-icon'" 
        [icon]="'icon_check_small'" 
        [text]="'Texto'"
        altText="{{ 'Ícone Check' }}">
      </app-button-icon-primary>
    </div>
  </header>


  <div class="outer__wrapper" (scroll)="onScroll()">
    <!-- BODY -->
    <div nz-row [nzGutter]="[16, 16]">
      <div nz-col [nzSpan]="14">
        <div class="round-border">
          Content 1
        </div>        
      </div>

      <div nz-col [nzSpan]="10">
        <div class="round-border">Content 2</div>
      </div>
    </div>
  </div>
 
</div>