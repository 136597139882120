import {Component, EventEmitter, OnDestroy, OnInit, Output, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {SubProjectByIdResponse} from "../../../shared/dto/subproject-by-id-response.dto";
import {differenceInCalendarDays} from "date-fns";
import {ProjectsV2Service} from 'src/app/shared/services/projects-v2.service';
import {ProjectModel} from 'src/app/shared/models/project.model';
import {Subject, take} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {RequestingArea} from 'src/app/shared/models/requestingArea.model';
import {UtilService} from 'src/app/shared/util.service';
import {UserService} from "../../../shared/services/user.service";
import {UserModel} from 'src/app/shared/models/user.model';
import {NzUploadFile} from 'ng-zorro-antd/upload';
import {MessageService} from 'src/app/shared/common/services/message.service';
import {AttachmentsService} from 'src/app/shared/services/attachments.service';
import {AttachmentSaveDto} from 'src/app/shared/dto/attachment-save.dto';
import {SUB_PROJECT_ENTITY_NAME} from '../../projects/constants/projectsNewConstants';
import {AttachmentsModel} from 'src/app/shared/models/attachments.model';
import {NzModalService} from 'ng-zorro-antd/modal';
import {LicensesV2Service} from 'src/app/shared/services/licenses-v2.service';
import {MandatoryDocumentsModel} from 'src/app/shared/models/mandatory-documents.model';
import {LoadingComponent} from 'src/app/shared/components/loading-modal/loading-modal.component';
import {BreadcrumbState} from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';
import {Store} from '@ngrx/store';
import {BREADCRUMB_ACTIONS} from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';
import {ProjectState} from 'src/app/shared/ngrx/breadcrumb/model';
import { ProgressModalComponent } from '../project-v2-assessment/progress-modal/progress-modal.component';
import { AssessmentUpdateDto } from 'src/app/shared/dto/assessment-update.dto';
import { FileValidationService } from 'src/app/shared/services/file-validation.service';

@Component({
  selector: 'app-project-v2-requisition-revision',
  templateUrl: './project-v2-requisition-revision.component.html',
  styleUrls: ['./project-v2-requisition-revision.component.scss']
})
export class ProjectV2RequisitionRevisionComponent implements OnInit, OnDestroy {

  private readonly destroy$: Subject<any> = new Subject();

  @Output() isVisibleModal = new EventEmitter<boolean>();

  projectId: number;
  subProjectId: number;
  reasonId: number;
  revisionId: number;
  loadingSubProjects: boolean;
  isAddProjectVisible: boolean = true;
  subProject: SubProjectByIdResponse;
  project: ProjectModel;
  subProjectDiscipline: any[] = [];

  customStyle = {
    background: 'white',
    'font-size': '12px',
  };
  popOverEditRipla: boolean = false;

  popOverEditDiscipline: boolean = false;

  colaborativeArchive: string = ''
  hasColaborativeArchive: boolean = false;

  fileList: NzUploadFile[] = [];
  fileListAlreadyUploaded: NzUploadFile[] = [];
  filesToDelete: string[] = [];
  docsToDelete: string[] = [];
  fileListDuplicated: NzUploadFile[] = [];
  fileLisToSave: NzUploadFile[] = [];
  arrayImageDelete: string[] = [];
  documents: any[] = [];

  today = new Date();
  statusRipla: string = ''

  users: UserModel[] = []

  regions: any[] = []
  complexes: any[] = []
  operacionalUnits: any[] = []
  riplaAnswerRevision: boolean = false;
  dataRevisionRiplaRequestingArea;

  resumeData: any = []
  form!: UntypedFormGroup;

  compareFn = (o1: string, o2: string) => (o1 && o2 ? o1 == o2 : false);
  isOpenSelect: boolean;
  isProjectTypesLoading: boolean = false
  isRegionsLoading: boolean = false
  isComplexesLoading: boolean = false
  isOperacionalUnitLoading: boolean = false
  quantDocumentosObrigatorios: number = 0;
  quantDocumentosUploaded: number = 0;

  resquestingAreas: RequestingArea[] = [];
  resquestingAreasFiltered: any[] = [];

  editMode: boolean = true
  documentToChange;

  projectName: string = ''
  projectCode: string = ''

  inputValue;
  inputValueAreaSolicitante;
  originalComplex;
  originalRegion;
  originalOperationalUnit;

  // ARQUIVOS OPCIONAIS
  fileListAdditional: NzUploadFile[] = [];
  fileListStorageRepiteAdditional: NzUploadFile[] = [];
  arrayImageDeleteAdditional: string[] = [];
  progress: number = 0;
  projectIdToUploadAdditional: number = 0
  bodyFormat: any;

  isInvalidFile: boolean = false

  constructor(private route: ActivatedRoute,
              private readonly fb: UntypedFormBuilder,
              private router: Router,
              private projectsV2Service: ProjectsV2Service,
              private readonly utilService: UtilService,
              private userService: UserService,
              private readonly message: MessageService,
              private readonly licenseV2Service: LicensesV2Service,
              private readonly attachmentsService: AttachmentsService,
              private readonly modal: NzModalService,
              private readonly messageService: MessageService,
              private viewContainerRef: ViewContainerRef,
              private fileValidationService: FileValidationService,
              private store: Store<BreadcrumbState>,
  ) {
  }

  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) < 0;


  ngOnInit(): void {
    this.riplaAnswerRevision = this.utilService.getAuthorization("insert", "REVISIONANSWER");
    this.route.params.subscribe((params: Params) => {
      this.projectId = +params['projectId'];
      this.subProjectId = +params['subProjectId'];
      this.revisionId = +params['revisionId'];
    });
    this.getUsers()
    this.getRequestingAreas()
    this.getRegions()
    this.getComplex()
    this.getSubProject(this.subProjectId);

  }

  // INICIO BREADCRUMB
  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  onScroll(): void {
    document.body.click()
  }

  private dispatchProjectOnStore(): void {
    let project: ProjectState = {
      name: 'Projetos',
      url: 'projects-v2/',
      last: false,
      sublevels: true,
      project: {
        name: this.subProject.projectName,
        url: 'project-v2-view/' + this.route.snapshot.url[1].path,
        last: false,
      },
      subProject: {
        name: 'RIPLA',
        url: 'project-v2-analysis/' + this.route.snapshot.url[1].path + '/' + this.route.snapshot.url[2].path,
        last: true,
        discipline: null
      },
      parecerFinal: null,
      pedidoRevisao: {
        name: 'Pedido de revisão',
        url: this.route.snapshot.url[0].path + '/' + this.route.snapshot.url[1].path + '/' + this.route.snapshot.url[2].path + '/' + this.route.snapshot.url[3].path,
        last: true
      },
      comunicacoes: null,
      processos: null,
      atividades: null,
      subatividades: null,
      mudancaEscopo: null
    }
    this.store.dispatch(BREADCRUMB_ACTIONS.getRiplaProject({project}));
  }

  // FINAL BREADCRUMB

  // CHAMADA ENDPOINT DE REVISAO DA RIPLA
  getSubprojectReview(revisionId) {
    this.projectsV2Service
    .getSubprojectReview(revisionId, this.subProjectId)
    .pipe(take(1))
    .subscribe((response: any) => {
      // this.reasonId = response

      this.dataRevisionRiplaRequestingArea = response || null;
      this.dataRevisionRiplaRequestingArea.userResponsibleName = response.user.name || null
      this.dataRevisionRiplaRequestingArea.reasons = response.subProjectReviewReasons || null
    })
  }

  // CHAMADA ENDPOINT DADOS DA RIPLA
  getSubProject(subProjectId: number) {
    this.loadingSubProjects = true;
    this.projectsV2Service.getSubProjectBySubProjectId(subProjectId).subscribe({
      next: (response: any) => {
        this.reasonId = response.subProjectReviews[0].id
        this.loadingSubProjects = false
        this.statusRipla = response['status']
        this.subProject = response;
        this.getMandatoryDocuments(response['type']);
        this.getSubprojectReview(this.revisionId)
        this.dispatchProjectOnStore()
        this.initForm()
      }
    });
  }

  async updateSubProjectReview() {
    const hasNewFiles = this.hasNewAttachments();
    this.deleteFilesWhenSendToAnalysis();
    if (hasNewFiles) {
      this.uploadNewFilesAndFinishReview();
    } else {
      this.finishSubProjectReview();
    }
  }

  private uploadNewFilesAndFinishReview() {
    const modal = this.createModal();

    const apiCallsAndStates = this.prepareApiCalls();

    const instance = modal.getContentComponent();
    instance.loadingCalls = apiCallsAndStates;
    instance.savedEntityIdPath = "id";
    instance.initialCallsAmount = 0;

    this.handleModalClose(modal);
  }

  private createModal() {
    return this.modal.create<LoadingComponent>({
      nzTitle: 'Enviando requisição',
      nzWidth: 555,
      nzClosable: false,
      nzCentered: true,
      nzMaskClosable: false,
      nzContent: LoadingComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });
  }

  private prepareApiCalls() {
    let apiCallsAndStates: any[] = []
    this.createUploadFilesCalls(this.subProjectId, apiCallsAndStates);
  
    const requestBody = this.prepareRequestBody();
    apiCallsAndStates.push(this.prepareFinalApiCall(requestBody));
  
    return apiCallsAndStates;
  }

  private prepareFinalApiCall(requestBody) {
    return {
      state: 'not_started',
      call: () => this.callEditSubprojectDraft.apply(this, [requestBody]),
      label: "Finalizando envio...",
      lastCall: true
    };
  }
  private prepareRequestBody() {
    return {
      areaId: Number(this.form.controls['areaSolicitante'].value),
      areaDescription: null,
      comments: this.form.controls['comments'].value,
      userLegalRepresentativeId: Number(this.form.controls['responsavelAreaSolicitante'].value)
    };
  }

  private handleModalClose(modal) {
    modal.afterClose.subscribe(({status, cancel}) => {
      if (cancel === true) {
        this.message.showSucessMessage("Requisição cancelada")
      } else if (status === false) {
        this.message.showErrorMessage("Erro ao reenviar para análise!");
      } else {
        this.message.showSucessMessage("RIPLA reenviada para análise!")
        this.router.navigate(['project-v2-analysis', this.projectId, this.subProjectId])
      }
    });
  }
  private callEditSubprojectDraft(requestBody) {
    return this.projectsV2Service.editSubProjectRascunho(requestBody, this.revisionId);
  }

  private finishSubProjectReview() {
    const requestBody = {
      areaId: Number(this.form.controls['areaSolicitante'].value),
      areaDescription: null,
      comments: this.form.controls['comments'].value,
      userLegalRepresentativeId: Number(this.form.controls['responsavelAreaSolicitante'].value)
    }
    this.callEditSubprojectDraft(requestBody).subscribe({
      next: response => {
        if (response) {
          this.message.showSucessMessage("Dados da RIPLA alterados com sucesso")
          this.router.navigate(['project-v2-analysis', this.projectId, this.subProjectId])
        }
      },
      error: error => {
        this.message.showErrorMessage("Erro ao reenviar para analise")
        console.log("Erro ao carregar api", error.message)
      }
    })
  }

  private hasNewAttachments() {
    return this.fileList.filter(file => file.originFileObj).length > 0;
  }

  // BOTAO CANCELAR
  goBackAnalysis() {
    this.router.navigate(['project-v2-analysis', this.projectId, this.subProjectId])
  }

  //RESUMO
  replaceResumeData() {
    this.resumeData.showResume = true;
    this.resumeData.projectName = this.subProject.projectName;
    this.resumeData.projectCode = this.subProject.projectCode;

    this.resumeData.comments = this.subProject.comments;
    this.resumeData.responsavelAreaSolicitante = this.form.controls['responsavelAreaSolicitante'].value;
    if (this.form.controls['areaSolicitante'].value) {
      if (Number(this.form.controls['areaSolicitante'].value) && this.resquestingAreas.length > 0) {
        this.resumeData.requestingArea = this.resquestingAreas.filter(area => area.id == this.form.controls['areaSolicitante'].value)[0].description || null;
      } else {
        this.resumeData.requestingArea = this.form.controls['areaSolicitante'].value;
      }
    }
    this.resumeData.projectClassification = this.form.controls['projectClassification']?.value;
    this.resumeData.comments = this.form.controls['comments'].value;
    if (this.form.controls['corredor'].value && this.regions.length > 0) {
      this.resumeData.corredor = this.regions.filter(region => region.id == this.form.controls['corredor'].value)[0].description || null
    } else {
      this.resumeData.corredor = ''
    }
    if (this.form.controls['responsavelAreaSolicitante'].value && this.users.length > 0) {
      this.resumeData.responsavelAreaSolicitante = this.users.filter(users => users.id == this.form.controls['responsavelAreaSolicitante'].value)[0].name || null
    }
    if (this.complexes.length > 0 && this.form.controls['complexo'].value) {
      this.resumeData.complexo = ' / ' + this.complexes.filter(complex => complex.id == this.form.controls['complexo'].value)[0].description || null
    } else {
      this.resumeData.complexo = ''
    }
    if (this.form.controls['unidadeOperacional'].value &&
      this.form.controls['unidadeOperacional'].value != -1 &&
      this.operacionalUnits.length > 0) {
      this.resumeData.unidadeOperacional = ' / ' + this.operacionalUnits.filter(unit => unit.id == this.form.controls['unidadeOperacional'].value)[0].description || null
    } else {
      this.resumeData.unidadeOperacional = ''
    }
    this.resumeData.documents = this.documents;
  }

  getRequestingAreas(): void {
    this.projectsV2Service
    .getAreas()
    .pipe(take(1))
    .subscribe((areas: any) => {
      this.resquestingAreas = areas;
      this.resquestingAreasFiltered = areas;
    })
  }

  getRegions(): void {
    this.projectsV2Service
    .getRegions()
    .pipe(take(1))
    .subscribe((regions: any) => {
      this.regions = regions;
    })
  }

  getComplex(): void {
    this.projectsV2Service
    .getComplex()
    .pipe(take(1))
    .subscribe((complex: any) => {
      this.complexes = complex;
    })
  }

  getOperationUnits(complexId): void {
    this.projectsV2Service
    .getOperationalUnits(complexId)
    .pipe(take(1))
    .subscribe((operacionalUnits: any) => {
      this.operacionalUnits = operacionalUnits;
    })
  }

  private getUsers() {
    this.userService
    .getUsersByRole('ROLE_DEMANDANTE')
    .pipe(take(1))
    .subscribe((users) => {
      this.users = users;
    });
  }

  initForm(): void {

    let areaSolicitante;
    let responsavelAreaSolicitante;
    let projectClassification = '';
    let comments = '';
    let corredor;
    let complexo;
    let unidadeOperacional;

    this.getOperationUnits(this.subProject.projectComplex.id)

    if (this.editMode) {
      this.projectName = this.subProject?.projectName ? this.subProject?.projectName : '';
      this.projectCode = this.subProject?.projectCode ? this.subProject?.projectCode : '';
      areaSolicitante = this.subProject?.area ? Number(this.subProject?.area.id) : '';
      responsavelAreaSolicitante = this.subProject?.projectUserLegal ? Number(this.subProject?.projectUserLegal.id) : '';
      projectClassification = this.subProject?.projectClassification ? this.utilService.formatString(this.subProject?.projectClassification) : '';
      comments = this.subProject?.comments ? this.subProject?.comments : '';
      corredor = this.subProject?.projectComplex?.region ? this.subProject?.projectComplex?.region.id : '';
      complexo = this.subProject?.projectComplex.id ? this.subProject?.projectComplex.id : '';
      unidadeOperacional = this.subProject?.projectOperationalUnit?.id ? this.subProject?.projectOperationalUnit.id : '';
    }

    this.form = this.fb.group({
      projectName: [{value: this.projectName, disabled: true}],
      projectCode: [{value: this.projectCode, disabled: true}],
      areaSolicitante: [areaSolicitante, [Validators.required]],
      responsavelAreaSolicitante: [responsavelAreaSolicitante, [Validators.required]],
      projectClassification: [projectClassification],
      comments: [comments, [Validators.required, Validators.maxLength(2000)]],
      corredor: [{value: corredor, disabled: true}],
      complexo: [{value: complexo, disabled: true}],
      unidadeOperacional: [{value: unidadeOperacional, disabled: true}],
    });

    if (this.subProject?.projectClassification === "GREENFIELD") {
      this.operacionalUnits.push({id: -1, description: 'Não se aplica'});
      this.form.controls['unidadeOperacional'].setValue(-1);
    }

    this.replaceResumeData()
  }

  // UPLOAD DOCUMENTS

  async verifyIfIsValidFile(file) {
    await this.fileValidationService.isExeFile(file).then(result => {
      if(result === true) {
        this.isInvalidFile = true
        this.message.showErrorMessage(`Tipo de arquivo ${file.name} não é suportado!`);
      } 
    })
    await this.fileValidationService.isZipFile(file).then(result => {
      if(result === true) {
        this.isInvalidFile = true
        this.message.showErrorMessage(`Conteúdo ou extensão do arquivo ${file.name} não é suportada!`);
      } 
    })
  }

  onChangeFile(fileEvent, document) {
    if (fileEvent.type == 'start') {
      if (this.documents.length > 0) {
        this.documents.map(value => {
          if (value.id === document.id) {
            if (value.statusUpload !== 'uploded') {
              const fileToVerify = fileEvent.file.originFileObj
              this.verifyIfIsValidFile(fileToVerify).then(result => {
                if( this.isInvalidFile === false){
                  if(this.fileValidationService.isExtensionForbidden(fileToVerify.name)){
                    this.message.showErrorMessage(`Extenção do arquivo ${fileToVerify.name} não é suportada!`);
                  } else {
                    if (this.checkFileNameLength(fileToVerify) && this.checkFileSize(fileToVerify)) {
                      value.statusUpload = 'ok';
                      value.fieluid = fileToVerify.uid
                      value.fileName = fileToVerify.name;
                      this.replaceResumeData();
                    }
                  }
                } else {
                  const fileUid = fileEvent.file.uid
                  const index = this.fileList.findIndex(file => file.uid === fileUid);
                  this.fileList.splice(index, 1);
                  this.isInvalidFile = false
                }
              })
              // value.statusUpload = 'ok';
              // value.fieluid = fileEvent.file.originFileObj.uid
              // value.fileName = fileEvent.file.name;
              // this.replaceResumeData();
            }
          } else {
            this.filesToDelete.push(this.documentToChange)
          }
        })
      }
    }
  }

  getAttachmentsId(documentToChange) {
    this.documentToChange = documentToChange.uid
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    if (this.checkFileNameLength(file) && this.checkFileSize(file)) {
      file.thumbUrl = this.utilService.getIconFromFileName(file.name);
      this.fileList = this.fileList.concat(file);
    }
    return false;
  };

  private checkFileSize(file: NzUploadFile): boolean {
    if (file && file.size && file.size > 350 * 1024 * 1024) {
      this.message.showErrorMessage('O arquivo é muito grande. Por favor, selecione um arquivo com menos de 350 MB.');
      return false;
    }
    return true;
  }

  private checkFileNameLength(file: NzUploadFile): boolean {
    if (file.name.length > 200) {
      this.message.showErrorMessage('Nome do arquivo muito grande. Máximo é 200 caracteres.');
      return false;
    }
    return true;
  }

  removeFiles = (file: NzUploadFile): boolean => {
    this.filesToDelete.push(file.uid);

    this.fileList = this.fileList.filter((item) => item !== file);
    this.fileListDuplicated = this.fileListDuplicated.filter(
      (item) => item !== file
    );
    return false;
  };

  deleteAttachment(fileListToDelete: string[]): void {
    for (const file of fileListToDelete) {
      this.attachmentsService
      .deleteAttachment(file)
      .pipe(take(1))
      .subscribe(() => {
        console.log('Arquivo excluido com sucesso', file);
      });
    }
  }

  private close(subProjectId) {
    this.isVisibleModal.emit(false);
    this.isAddProjectVisible = false;
  }

  private getMandadoryDocId(uid: string) {
    return this.documents.filter(doc => doc.fieluid == uid)[0].id;
  }

  private compareFileSize(a: NzUploadFile, b: NzUploadFile) {
    return (a.size || 0) - (b.size || 0);
  }

  private createUploadFilesCalls(entityId, calls: any[]) {
    this.fileList = this.fileList.sort(this.compareFileSize);
    this.fileListAdditional = this.fileListAdditional.sort(this.compareFileSize)

    const uploadedIds = this.fileListAlreadyUploaded.map(item => item.uid);
    const filesToAdd = this.fileListAdditional.filter(item => !uploadedIds.includes(item.uid))

    if (this.fileList.length) {
      for (const [index, file] of this.fileList.entries()) {
        if (file.originFileObj) {
          const attachment: AttachmentSaveDto = this.buildAttachPayload(file, entityId);
          calls.push({
            state: 'not_started',
            call: (entityId) => this.callAttachmentUpload.apply(this, [attachment, entityId]),
            label: `Salvando anexos...`,
            entity: 'ATTACHMENT',
            id: Math.random().toString(16).slice(2)
          })
        }
      }
    }

    if (filesToAdd.length) {
      for (const file of filesToAdd) {
        const attach = this.buildFilePayload(file);
        calls.push({
          state: 'not_started',
          call: (entityId) => this.callOptionalAttachmentUpload.apply(this, [attach, entityId]),
          label: `Salvando anexos opcionais...`,
          entity: 'ATTACHMENT',
          id: Math.random().toString(16).slice(2)
        })
      }
    }
  }

  private buildAttachPayload(file, entityId) {
    return {
      name: file.name,
      description: file.name,
      idMandatoryDoc: this.getMandadoryDocId(file.uid),
      entityId: entityId,
      entityName: SUB_PROJECT_ENTITY_NAME,
      contextInfo: {
        phase: 'Cadastro'
      },
      file: file.originFileObj ? file.originFileObj : file,
    };
  }

  callAttachmentUpload(attachment: AttachmentSaveDto, _) {
    return this.attachmentsService
      .uploadAttachmentMandadoryDock(attachment);
  }

  callOptionalAttachmentUpload(attachment: AttachmentSaveDto, id) {
    attachment.entityId = id ? id : this.subProjectId;

    return this.attachmentsService
      .uploadAttachmentsWithProgress(attachment);
  }

  downloadFile = (file: NzUploadFile): void => {
    if (file.uid === undefined) return;
    this.attachmentsService.getDownloadFile(+file.uid).subscribe({
      next: (resp) => this.attachmentsService.downloadFile(resp, file.name),
    });
  };

  downloadFileByIdAndName = (id, name) => {
    const idFile = this.fileList.filter(file => file.uid === id)[0].uid
    if (idFile === '' || !idFile) {
      return
    }
    this.attachmentsService.getDownloadFile(id === idFile ? id : 0).subscribe({
      next: (resp) => {
        this.attachmentsService.downloadFile(resp, name)
      },
      error: () => this.handleError(),
    })
  }

  deleteMandatoryDoc(document) {
    if (this.editMode && document.statusUpload === 'uploded') {
      this.docsToDelete.push(document.fieluid);
      this.fileList = this.fileList.filter(file => file.uid !== document.fieluid);
      this.documents.forEach(doc => {
        if (doc.id === document.id) {
          doc.statusUpload = 'upload'
          doc.fileName = ''
          doc.fieluid = ''
        }
      });

    } else {
      this.fileList = this.fileList.filter(file => file.uid !== document.fieluid);
      this.documents.forEach(doc => {
        if (doc.id === document.id) {
          doc.statusUpload = 'upload'
          doc.fileName = ''
          doc.fieluid = ''
        }
      })
    }
  }

  deleteFilesWhenSendToAnalysis(): void {
    this.docsToDelete.forEach(document => {
      this.attachmentsService.deleteAttachment(document).subscribe((data) => {
      });
    });
  }

  handleError() {
    this.message.showSucessMessage(
      `Erro realizar download`
    );
  }

  getMandatoryDocuments(subProjectType: string) {
    if (!subProjectType) return;

    const params = {
      PARAM_PROJ_TYPE: subProjectType?.toUpperCase(),
      PARAM_CLASSIFICATION: this.subProject.projectClassification.toUpperCase(),
    }
    if (this.editMode && this.documents.length > 0 && this.fileList.length > 0) {
      this.documents.forEach(document => {
        this.deleteMandatoryDoc(document);
      });
    } else if (this.documents.length > 0 && this.fileList.length > 0) {
      this.documents.forEach(document => {
        document.statusUpload = 'upload'
        document.fileName = ''
        document.fieluid = ''
      })
      this.fileList = [];
    }
    this.listMandadoryDocs(params);
  }

  

  private listMandadoryDocs(params: { PARAM_CLASSIFICATION: string; PARAM_PROJ_TYPE: string }) {
    this.projectsV2Service
    .getMandatoryDocuments(params)
    .subscribe((documents: [MandatoryDocumentsModel]) => {
      this.documents = documents;
      this.quantDocumentosObrigatorios = documents.length
      this.documents.forEach(doc => {
        const types = doc.types?.split(';');
        doc.types = ''
        types?.forEach(type => {
          if (doc.types === '') {
            doc.types = '.' + type.toLowerCase();
          } else {
            doc.types = doc.types + "," + "." + type.toLowerCase();
          }
        })
      })
      if (this.editMode) {
        this.getAttachments();
        this.getAttachmentsAdditional();
      }
      this.replaceResumeData();
    });
  }


  getAttachmentsAdditional() {

    this.attachmentsService
      .getAttachmentsByEntityAndEntityId(
        this.subProjectId,
        SUB_PROJECT_ENTITY_NAME
      )
      .subscribe((data) => {
        let newListAdditional: any[] = [];
        newListAdditional = data.filter(obj => !this.fileList.some(item => Number(item.uid) === Number(obj.id)));
        const nzUploadFiles = this.buildNzUploadFileArrayFromResponse(newListAdditional);
        this.resumeData.fileListAdditional = nzUploadFiles;
        this.fileListAdditional = nzUploadFiles;
        this.fileListAlreadyUploaded = nzUploadFiles;
      });
  }

  getAttachments() {
    this.projectsV2Service
    .getMandatoryDocsAttachments(
      this.subProjectId,
      SUB_PROJECT_ENTITY_NAME
    )
    .subscribe((data) => {
      if (data.length > 0) {
        this.quantDocumentosUploaded = data.length
        for (const doc of data) {
          if (doc.attachment) {
            const file: NzUploadFile = {
              description: doc.attachment.description,
              name: doc.attachment.name,
              filename: doc.attachment.name,
              uid: doc.attachment.id.toString() || '',
              thumbUrl: this.utilService.getIconFromFileName(doc.attachment.name)
            }
            this.fileList.push(file)
          }

          this.documents.map(document => {
            if (document.id === doc.mandatoryDoc.id) {
              document.statusUpload = doc.attachment ? 'uploded' : 'upload';
              document.fieluid = doc.attachment ? doc.attachment.id.toString() : undefined;
              document.fileName = doc.attachment ? doc.attachment.name : undefined;
            }
          })
        }
        this.replaceResumeData();
      }
    });
  }

  private buildNzUploadFileArrayFromResponse(data: AttachmentsModel[]) {
    const files: NzUploadFile[] = [];
    for (const image of data) {
      const file: NzUploadFile = {
        description: image.description,
        name: image.name,
        filename: image.name,
        uid: image.id?.toString() || '',
        thumbUrl: this.utilService.getIconFromFileName(image.name),
        status: 'done',
      };
      files.push(file);
    }
    return files;
  }

  getHintText(fileName: string): string {
    if (fileName.length > 60) {
      return fileName
    }
    return "";
  }

  // BOTAO SAIR PEDIDO DE REVISAO
  showCancelConfirm(): void {
    this.modal.confirm({
      ...(this.message.showMessageModalConfirmCancelRequisitionRevision() as any),
      nzOnOk: () => this.router.navigate(['/project-v2-analysis/' + this.projectId + '/' + this.subProjectId]),
      nzOnCancel: () => this.modal.closeAll()
    });
  }

  
  // ARQUIVOS OPCIONAIS
  downloadFileAdditional = (file: NzUploadFile): void => {
    if (file.uid === undefined) return;

    this.attachmentsService.getDownloadFile(+file.uid).subscribe({
      next: (resp) => this.attachmentsService.downloadFile(resp, file.name),
    });
  };

 
  beforeUploadAdditional = (file: NzUploadFile): boolean => {
    this.verifyIfIsValidFile(file).then(result => {
      if( this.isInvalidFile === false){
        if(this.fileValidationService.isExtensionForbidden(file.name)){
          this.message.showErrorMessage(`Extenção do arquivo ${file.name} não é suportada!`);
        } else {
          if (this.checkFileNameLength(file) && this.checkFileSize(file)) {
            file.thumbUrl = this.utilService.getIconFromFileName(file.name);
            this.fileListAdditional = this.fileListAdditional.concat(file);
          }
        }
      } else {
        this.isInvalidFile = false
      }
    })
    return false;

  };

  removeFilesAdditional = (file: NzUploadFile): boolean => {

    this.modal.confirm({
      ...(this.messageService.showMessageModalConfirmDelete('Confirmar exclusão') as any),
      nzOnOk: () => {

        this.attachmentsService
          .deleteAttachment(file.uid)
          .subscribe({
            next: (response) => {
              console.log("Arquivo adicional excluido")
            },
            complete: () => {
              this.arrayImageDeleteAdditional.push(file.uid);
              this.fileListAdditional = this.fileListAdditional.filter((item) => item !== file);
              this.fileListStorageRepiteAdditional = this.fileListStorageRepiteAdditional.filter(
                (item) => item !== file
              );
              this.filesToDelete.push(file.uid);
              this.resumeData.fileListAdditional = this.fileListAdditional
            },
            error: (err) => {
              this.message.showErrorMessage("Erro ao excluir o arquivo");
            }
          });

        return true
      }
    });
    return false
  };

  
  removeDuplicatedFiles() {
    this.fileLisToSave = this.fileListDuplicated
      .filter(
        (item1) =>
          !this.fileListAdditional.some(
            (item2) => item1.uid === item2.uid && item1.name === item2.name
          )
      )
      .concat(
        this.fileListAdditional.filter(
          (item2) =>
            !this.fileListDuplicated.some(
              (item1) => item2.uid === item1.uid && item2.name === item1.name
            )
        )
      );
  }

  private uploadFilesAndUpdateAssessment(payload: AssessmentUpdateDto, modalProgressComponent: ProgressModalComponent) {
    let progressPerFile = 100 / this.fileListAdditional.length;
    if (this.fileListAdditional.length) {
      this.loopThroughFiles(modalProgressComponent, progressPerFile, payload);
    } else {
      modalProgressComponent.progress = 100;
    }
  }

  private loopThroughFiles(modalProgressComponent: ProgressModalComponent,
    progressPerFile: number,
    payload: AssessmentUpdateDto) {
    for (let file of this.fileListAdditional) {
      if (!this.fileListAlreadyUploaded.some((item) => item.uid === file.uid)) {
        let attachment = this.buildFilePayload(file);
        attachment.entityId = this.subProjectId
        this.uploadAttachmentFile(attachment, modalProgressComponent, progressPerFile, payload);
      }
      this.modal.closeAll()
    }
  }

  private buildFilePayload(file: NzUploadFile) {
    const attachment: AttachmentSaveDto = {
      name: file.name,
      description: file.name,
      entityId: this.subProjectId,
      entityName: SUB_PROJECT_ENTITY_NAME,
      contextInfo: {
        phase: 'Cadastro'
      },
      file,
    };
    return attachment;
  }

  private uploadAttachmentFile(attachment: AttachmentSaveDto, modalProgressComponent: ProgressModalComponent, progressPerFile: number, payload: AssessmentUpdateDto) {
    this.attachmentsService
      .uploadAttachment(attachment)
      .subscribe({
        next: () => {
          modalProgressComponent.progress += progressPerFile;
          // this.message.showSucessMessage("Arquivos adicionais carregados com sucesso");
        },
        error: () => {
          console.log("Erro ao carregar arquivo")
        }
        });
  }

  modalProgress(payload: AssessmentUpdateDto): ProgressModalComponent {

    const modal = this.modal.create<ProgressModalComponent>({
      nzTitle: 'Arquivos adicionais',
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzWidth: 555,
      nzCentered: true,
      nzContent: ProgressModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });

    const instance = modal.getContentComponent();

    instance.subtitulo = 'Carregando arquivos adicionais... aguarde!'
    this.progress = 0;
    instance.progress = this.progress;
    console.log("instance progress", this.progress)
    return instance;

  }

}
