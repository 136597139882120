<div class="shadow">
<div *ngIf="resultAnalysisNlp && resultAnalysisNlp !== undefined" style="padding-bottom: 1px; margin-bottom: 12px;">
  <div class="nlp-indicators" nz-row>
    <div id="nlp-indicators-sections-found"
         class="nlp-indicators-item "
         nz-col
         nzXs="8"
         nzSm="8"
         nzMd="8"
         nzLg="8"
         nzXl="8">
      <div id="nlp-sections-found-spot"
           class="nlp-indicators-spot"></div>
      <div id="nlp-sections-found-text"
           class="nlp-indicators-text">
        <b>Seções encontradas</b>
      </div>
      <div id="nlp-sections-found-number"
           class="nlp-indicators-number {{ resultAnalysisNlp.sectionFoundAdherenceCssClass }}">
        {{ resultAnalysisNlp.nlpSectionCount }}/{{ resultAnalysisNlp.trSectionCount }}
      </div>
    </div>

    <div id="nlp-indicators-tr-adherence"
         class="nlp-indicators-item nlp-indicators-center-item"
         nz-col
         nzXs="8"
         nzSm="8"
         nzMd="8"
         nzLg="8"
         nzXl="8">
      <div id="nlp-tr-adherence-spot"
           class="nlp-indicators-spot"></div>
      <div id="nlp-tr-adherence-text"
           class="nlp-indicators-text">
        <b>Aderência ao TR</b>
      </div>
      <div id="nlp-tr-adherence-number"
           class="nlp-indicators-number {{ resultAnalysisNlp.trSectionsAdherenceCssClass }}">
        {{ resultAnalysisNlp.trSectionsAdherencePct | number }}%
      </div>
    </div>

    <div id="nlp-indicators-sections-adherence"
         class="nlp-indicators-item"
         nz-col
         nzXs="8"
         nzSm="8"
         nzMd="8"
         nzLg="8"
         nzXl="8">
      <div id="nlp-sections-adherence-spot"
           class="nlp-indicators-spot"></div>
      <div id="nlp-sections-adherence-text"
           class="nlp-indicators-text">
        <b>Aderência às seções encontradas</b>
      </div>
      <div id="nlp-sections-adherence-number"
           class="nlp-indicators-number {{ resultAnalysisNlp.nlpSectionsAdherenceCssClass }}">
        {{ resultAnalysisNlp.nlpSectionsAdherencePct | number }}%
      </div>
    </div>
  </div>
</div>
</div>
