import { Component, Input } from '@angular/core'
import {NlpResultResponseDto} from "../result-body/dto/nlp-result-response.dto";

@Component({
  selector: 'app-nlp-indicators',
  templateUrl: './nlp-indicators.component.html',
  styleUrls: ['./nlp-indicators.component.scss']
})
export class NlpIndicatorsComponent {

  @Input() resultAnalysisNlp?: NlpResultResponseDto;

  constructor(  ) { }

}
