import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {LOGS_API} from "../../modules/projects/constants/apiEndpointsConstants";
import {LogsResponseDto} from "../dto/logs-response.dto";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LogsService {
  constructor(private readonly http: HttpClient) {
  }

  getLogsByEntityIdAndEntityName(entityId: number, entityName: string):Observable<LogsResponseDto[]> {
    const url = `${LOGS_API}?id=${entityId}&origin=${entityName}`;
    return this.http.get<LogsResponseDto[]>(url);
  }
}
