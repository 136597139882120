import {Component, Input, OnInit} from '@angular/core';
import {NlpAnalysisResultService} from "../../nlp-analysis-result.service";
import {Observable} from "rxjs";
import {SectionCommentsDto} from "../dto/section-comments.dto";

@Component({
  selector: 'app-items-to-verify',
  templateUrl: './items-to-verify.component.html',
  styleUrls: ['./items-to-verify.component.scss']
})
export class ItemsToVerifyComponent implements OnInit {
  @Input() resultSectionId: number | undefined;
  public sectionComments$: Observable<SectionCommentsDto[]> | undefined;
  constructor(private nlpAnalysisResultService: NlpAnalysisResultService) { }

  ngOnInit(): void {
    this.sectionComments$ =  this.nlpAnalysisResultService.getSectionComments(this.resultSectionId);
  }

}
