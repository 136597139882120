<div class="mt-24 app-other-costs">
  <h2 class="mb-10">{{ this.disciplineTitle | translate }}
    <img *ngIf="hint"
         alt=""
         src="assets/icons/information-icon.svg"
         nz-tooltip
         nzTooltipTitle="{{hint}}"
         nzTooltipPlacement="bottom">
  </h2>
  <nz-table #outerCostsTable
            nzSize="small"
            nzTemplateMode
            [nzScroll]="{ y: '200px' }"
            [nzData]="listOfData"
            [nzFrontPagination]="false">
    <thead>
    <tr>
      <th nzWidth="41%" class="tableTh">Título</th>
      <th nzWidth="29%" class="tableTh">Categoria</th>
      <th nzWidth="25%" class="tableTh">Custo estimado (opcional)</th>
      <th nzWidth="0%" class="tableTh"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let cost of listOfData; let i = index">
        <td class="tableTdDiscipline" [width]="'39%'">
          <input class="inputCostStudyGrid"
                 *ngIf="editCache[cost.id].edit && this.outerCost" nz-input nzBorderless id="txtInputTitle"
                 placeholder="Informe..."
                 [(ngModel)]="editCache[cost.id].data.title">
          <nz-select *ngIf="editCache[cost.id].edit && !this.outerCost" id="selStudyType" [nzShowArrow] = "false" nzBorderless nzAllowClear class="gridSelectOption"
                     [(ngModel)]="editCache[cost.id].data.studyType" nzPlaceHolder="Seleciona o tipo de estudo"
                     [compareWith]="compareFn">
            <nz-option class="userItem selectItem" *ngFor="let study of studyType"
                       [nzLabel]="study.description | titlecase"
                       [nzValue]="study"></nz-option>
          </nz-select>
          <span *ngIf="!editCache[cost.id].edit" nz-tooltip [nzTooltipVisible]="isVisible(cost)" nzTooltipTitle="{{ this.outerCost ? cost.title : cost.studyType?.description }}" class="disciplineName">{{ this.outerCost ? cost.title : cost.studyType?.description }}</span>
        </td>
        <td class="tableTdDiscipline" [width]="'29%'">
          <nz-select *ngIf="editCache[cost.id].edit" id="selOuterCostCategory" class="gridSelectOption" [nzShowArrow] = "false"
                     nzBorderless nzAllowClear nzPlaceHolder="Seleciona a categoria"
                     [(ngModel)]="editCache[cost.id].data.category"
                     [compareWith]="compareFn">
            <nz-option class="userItem selectItem" *ngFor="let category of categoryList"
                       [nzLabel]="category.description | titlecase"
                       [nzValue]="category"></nz-option>
          </nz-select>
          <span *ngIf="!editCache[cost.id].edit">{{ cost.category?.description }}</span>
        </td>
        <td class="tableTdDiscipline" [width]="'18%'">
          <nz-input-number *ngIf="editCache[cost.id].edit" nzBorderless class="formatNumberCost"
                           [nzFormatter]="formatterReal"
                           [nzParser]="parserReal"
                           [nzStep]="1"
                           [nzMin]="1"
                           [nzInputMode]="'decimal'"
                           [(ngModel)]="editCache[cost.id].data.cost">
          </nz-input-number>
          <span *ngIf="!editCache[cost.id].edit"> R$ {{ cost.cost | number: '2.2-2':'pt' }}</span>
        </td>
        <td *ngIf="showEdit" style="margin-right: -5px !important" [width]="'0%'">
          <a
            nz-popover
            nzPopoverPlacement="bottom"
            nzPopoverTrigger="click"
            [nzPopoverContent]="popOverEditRiplaContent"
            [nzPopoverOverlayStyle]="customStyle"
            [(nzPopoverVisible)]="editCache[cost.id].popupedit">
            <img src="../../../../../assets/icons/icon_more_vertical.svg" alt="">
          </a>
        </td>
      <ng-template #popOverEditRiplaContent class="popOverMargin">
        <div class="btnEditRipla"
             id="btnEditRipla"
             (click)="startEdit(cost.id, true)">
          <img src="../../../../../assets/icons/icon_edit.svg" class="mr-10"
               alt=""> {{ 'projects.analysis.edit' | translate }}
        </div>
        <div class="btnEditRipla"
             id="btnExcludeRipla"
             (click)="removeStudy(cost.id)">
          <img src="../../../../../assets/icons/trash_document-icon.svg" class="mr-10"
               alt=""> {{ 'projects.analysis.exclude' | translate }}
        </div>
      </ng-template>
    </tr>
    <tr *ngIf="newStudy">
      <td></td>
      <td></td>
      <td></td>
      <td style="display: flex; justify-content: flex-end; padding-right: 0;">
        <div (click)="cancelEdit(this.studyId)">
          <button nz-button class="btn btn-green btnCancelDiscipline">Cancelar</button>
        </div>
        <div (click)="saveEdit(this.studyId)">
          <button nz-button nzType="primary" class="btnAddDiscipline">Adicionar</button>
        </div>
      </td>
    </tr>
    <tr>
      <td *ngIf="!newStudy" colspan="4">
        <div class="btn-new-content-grid" style="margin-right: -2px">
                  <span class="project-doc-icon" (click)="addNewstudy()">
                    <img src="../../../../../assets/icons/icon_plus_circle.svg"
                         alt="{{'projects.view.license.add-license'}}">
                  </span>
          <span class="lbl-new-content ml-5"
                (click)="addNewstudy()"> {{ this.outerCost ? "Adicionar novo custo" : "Adicionar novo estudo" }}</span>
        </div>
      </td>
    </tr>
    </tbody>
    <ng-template #contentTemplate>
      <div class="botoesMenu" (click)="addNewstudy()">
        {{ 'projects.ripla.preliminary' | translate }}
      </div>
      <div class="botoesMenu" (click)="addNewstudy()">
        {{ 'projects.ripla.definitive' | translate }}
      </div>
    </ng-template>
  </nz-table>
</div>


