<div nz-row nz-col nzSpan="24">
  <span class="modal-subtitle">Isso pode levar alguns minutos</span>

  <div class="loading-bar-container">
    <app-loading-bar 
      class="loading-bar-component-container"
      *ngFor="let loadingCall of loadingCalls"
      [progressionType]="loadingCall.state"
    ></app-loading-bar>
  </div>

  <span class="current-step-label">{{ currentLoadingLabel }}</span>

  <a href="{{ getCurrentUrl() }}" class="cancel-button">Cancelar</a>
</div>