import { UserService } from 'src/app/shared/services/user.service';
import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { ProjectsV2Service } from "../../../shared/services/projects-v2.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { SubProject, SubProjectPageResponse } from "../../../shared/dto/suprojects-page-response.dto";
import { LicenseResponseDto } from "../../../shared/dto/licenses-response.dto";
import { LicensesV2Service } from "../../../shared/services/licenses-v2.service";
import { DatePipe } from "@angular/common";
import { ProjectByIdResponseDto } from "../../../shared/dto/project-by-id-response.dto";
import { DateCalcHelper } from 'src/app/shared/helpers/date-calc.helper';
import {UtilService} from "../../../shared/util.service";
import { Store, select } from '@ngrx/store';
import { BreadcrumbState } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';
import { Subject, takeUntil } from 'rxjs';
import { BREADCRUMB_ACTIONS } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';
import { ProjectState } from 'src/app/shared/ngrx/breadcrumb/model';
import { selectRiplaProject } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.selector';
import { SCOPE_CHANGE_ENTITY_NAME, SUB_PROJECT_ENTITY_NAME } from '../../projects/constants/projectsNewConstants';
import { SuspensionCancellationModalComponent } from '../modal/suspension-cancellation-modal/suspension-cancellation-modal.component';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-project-v2-view',
  templateUrl: './project-v2-view.component.html',
  styleUrls: ['./project-v2-view.component.scss']
})
export class ProjectV2ViewComponent implements OnInit, OnDestroy {

  private readonly destroy$: Subject<any> = new Subject();
  stateStore: any[] = []

  customStyle = {
    background: 'white',
    'font-size': '12px',
  };
  popOverAddRipla1: boolean = false;
  popOverAddRipla2: boolean = false;
  projectId: number = 0;
  project: ProjectByIdResponseDto;
  loading: boolean = true;
  loadingSubProjects: boolean = true;
  subProjects: SubProject[] = [];
  subProjectsIdList: Array<any> = [];
  scopeChangeIdList: Array<any> = [];
  suspensionIdList: Array<any> = [];
  cancellationIdList: Array<any> = [];
  processIdList: Array<any> = [];
  licenses: LicenseResponseDto[] = [];
  deactiveRiplaRequisition: boolean = true;
  processList: any[] = [];
  btnNewRipla: boolean = true;
  btnNewProccesLicensing: boolean = true;
  riplarequisition: boolean = false;
  cadProcessLicensing: boolean = false;
  cadScopeChange: boolean = false;
  documents: boolean = false;
  projectLocation;

  constructor(
    private projectsV2Service: ProjectsV2Service,
    private licenseV2Service: LicensesV2Service,
    private readonly utilService: UtilService,
    private userService: UserService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private router: Router,
    public dateCalc: DateCalcHelper,
    private activatedRoute: ActivatedRoute,
    private readonly modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private store: Store<BreadcrumbState>,
  ) { }

  ngOnInit(): void {
    this.cadScopeChange = this.utilService.getAuthorization('insert', 'CADSCOPECHANGE');
    this.riplarequisition = this.utilService.getAuthorization( 'insert', 'RIPLAREQUISITION');
    this.cadProcessLicensing = this.utilService.getAuthorization('insert', 'CADPROCESSLICENSING');
    this.loadProjectFromStore();
    this.route.params.subscribe((params: Params) => {
      this.projectId = +params['projectId'];
      this.getProject(this.projectId);
    })
    this.getProcessList()
    this.ruleScopeChange();
  }

  // INICIO BREADCRUMB
  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private loadProjectFromStore(): void {
    this.store
      .pipe(
        takeUntil(this.destroy$),
        select(selectRiplaProject)
      ).subscribe((result: any) => {
        this.stateStore = result
      });
  }

  private dispatchProjectOnStore(projectRequest: any): void {
    let project: ProjectState = {
      name: 'Projetos',
      url: '/projects-v2',
      last: false,
      sublevels: true,
      project: {
        name: projectRequest.name,
        url: this.activatedRoute.snapshot.url[0].path + '/' + this.activatedRoute.snapshot.url[1].path,
        last: true,
      },
      subProject: null,
      parecerFinal: null,
      pedidoRevisao: null,
      comunicacoes: null,
      processos: null,
      atividades: null,
      subatividades: null,
      mudancaEscopo: null
    }
    this.store.dispatch(BREADCRUMB_ACTIONS.getRiplaProject({ project }));
  }
  // FINAL BREADCRUMB

  private getProject(projectId: number) {
    this.projectsV2Service.getProjectByProjectId(projectId).subscribe({
      next: (project: ProjectByIdResponseDto) => {
        this.project = project;
        this.projectLocation = `${project.region.description} / ${project.complex.description}`;
        const operacional = project.operationalUnit ? " / " + project.operationalUnit.description : "";
        this.projectLocation = this.projectLocation + operacional;
        this.loading = false;
        this.getSubProjects(project.id)
        this.dispatchProjectOnStore(this.project);
      }
    });
  }

  private getSubProjects(projectId: number) {
    this.projectsV2Service.getSubProjectsByProjectId(projectId).subscribe({
      next: (response: SubProjectPageResponse) => {
        this.subProjects = response.content;
        this.deactiveRiplaRequisition = this.subProjects.every(subPro => subPro.status === 'CON');
        this.loadingSubProjects = false;
        this.getLicenses(this.projectId);
        this.checkIfThereIsAnyRiplaInProgress();
        this.subProjects.forEach(element => {
          if (element.entityName === 'SCOPE_CHANGE') {
            this.scopeChangeIdList.push(element.subProjectId);
          } else if (element.entityName === 'SUSPENSION') {
            this.suspensionIdList.push(element.subProjectId);
          } else if (element.entityName === 'CANCEL') {
            this.cancellationIdList.push(element.subProjectId);
          } else {
            this.subProjectsIdList.push(element.subProjectId)
          }
        });
      }
    });
  }

  private checkIfThereIsAnyRiplaInProgress(){
    this.btnNewRipla = this.subProjects.every((item2) => item2.status === "CON");
    this.btnNewProccesLicensing = this.enableProcess();
  }

  private getLicenses(projectId: number) {
    this.licenseV2Service.getLicensesByProjectId(projectId).subscribe({
      next: (licensesResponse: LicenseResponseDto[]) => {
        if (licensesResponse) {
          this.licenses = licensesResponse;
        }
      }
    });
  }

  getStatusText(status: string): string {
    switch (status) {
      case 'INI':
        return 'A iniciar';
      case 'EAN':
        return 'Em Andamento';
      case 'EST':
        return "Em análise pelo órgão";
      default:
        return 'Desconhecido';
    }
  }

  navigateToNewLicensingProcess(projectId: number, processId: number = 0) {
    sessionStorage.setItem('origin', 'projectView');
    this.router.navigate([`/project/${projectId}/edit-processv2/${processId}`]);
  }

  getProcessList() {
    this.projectsV2Service.getProcessessList(this.projectId).subscribe({
      next: (data) => {
        if (data) {
          this.processList = data.content;
          this.processList.forEach(element => {
            this.processIdList.push(element.id)
          });
        }
      },
      error: (error) => console.error('error to load processess ', error),
    });
  }

  navigateToListProcess(projectId: number) {
    this.router.navigate([`/projects/${projectId}/processes`]);
  }

  createRIPLA(riplaType: string) {
    const extras = {
      queryParams: {
        projectId: this.projectId,
        projectName: this.project.name
      }
    };
    this.projectsV2Service.setProjectToCreateRipla(this.project);
    this.router.navigate(['/projects-v2-new', riplaType], extras);
  }

  createChangeScope() {
    this.router.navigate(['/scope-change/request', this.projectId]);
  }

  goToAnalysis(data: any, projectId: number, subProjectId: number, scopeChangeName: string){
    if (data.entityName === SCOPE_CHANGE_ENTITY_NAME) {
      if (data.status === "INI") {
        this.router.navigate(['/scope-change/request', projectId, 'edit', subProjectId]);
      } else {
        const params = { "scopeChangeName": scopeChangeName };
        this.router.navigate(['/scope-change/analysis', subProjectId], { queryParams: params });
      }
    } else if (data.entityName === 'SUSPENSION') {
      this.router.navigate(['/suspension', projectId, subProjectId]);
    } else if (data.entityName === 'CANCEL') {
      this.router.navigate(['/cancellation', projectId, subProjectId]);
    } else {
      this.router.navigate(['/project-v2-analysis', projectId, subProjectId]);
    }
  }

  gotoDocuments() {
    sessionStorage.setItem('documents', JSON.stringify({'projectId': this.projectId, 'projectName': this.project.name, 'origin': 'SUB_PROJECT', 'complex': this.project.complex, 'complexName': this.project.complex.description}))
    this.router.navigate([]).then(result => {  window.open(`/documents`, '_blank'); });
  }
  enableProcess(): boolean {
    var ocorrencia = this.subProjects.filter(sub => sub.status === 'CON' && sub.type === 'DEFINITIVA').length
    return this.btnNewRipla && ocorrencia > 0
  }
  ruleScopeChange(): boolean {
    let isDemandant = this.userService.isRoleLoggedUser('ROLE_DEMANDANTE');
    let isProjectProgress: Array<any> = this.subProjects.filter((element) => element.entityName == SUB_PROJECT_ENTITY_NAME && element.status === "EAN");
    let isScopeChangeProgress: Array<any> = this.subProjects.filter((element) => element.entityName === SCOPE_CHANGE_ENTITY_NAME && element.status === "EAN");
    return isDemandant && this.riplarequisition && isProjectProgress.length === 0 && isScopeChangeProgress.length === 0 ? true : false;
  }

  createModalSuspensionCancellation(typeModal: string) {
    const modal = this.modal.create<SuspensionCancellationModalComponent>({
      nzWidth: 780,
      nzContent: SuspensionCancellationModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzFooter: null,
      nzOnCancel: (instance: SuspensionCancellationModalComponent) => new Promise((resolve) => {
        this.getSubProjects(this.projectId)
        resolve();
      }),
    });

    const instance = modal.getContentComponent();
    instance.type = typeModal;
    instance.projectId = this.projectId

    modal.afterClose.subscribe((data) => {
      this.getSubProjects(this.projectId)
      if(data.type==="SUSPENSION"){
        this.router.navigate([`/suspension/${data.project.id}/${data.id}`])
      } else {
        this.router.navigate([`/cancellation/${data.project.id}/${data.id}`])
      }
    })
  }
}
