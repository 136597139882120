import { Component, Input, OnChanges} from '@angular/core';
import { FilterLastDaysHelper } from 'src/app/shared/helpers/filter-last-days/filter-last-days.helper';
import {UtilService} from "../../../shared/util.service";

@Component({
  selector: 'app-all-notifications',
  templateUrl: './all-notifications.component.html',
  styleUrls: ['./all-notifications.component.scss']
})
export class AllNotificationsComponent implements OnChanges {
@Input() listItems;
top7Days;
offsetDays;

  constructor(
    private readonly filter: FilterLastDaysHelper,
    private readonly utilService: UtilService
  ) { }

  ngOnChanges(){
    this.filterDays();
  }

  filterDays(){
    const groupDays = this.filter.filterTopDays(this.listItems, 7);
    this.top7Days = groupDays[0];
    this.offsetDays =  groupDays[1];
  }

  sanitizeInputAllowOnlyItalicTag(text: string): string{
    return this.utilService.sanitizeInputAllowOnlyItalicTag(text);
  }

}
