<form
  nz-form
  [formGroup]="form"
  (ngSubmit)="submitForm()">

  <div nz-row>
    <div
      nz-col
      nzXs="24"
      nzSm="24"
      nzMd="24"
      nzLg="24"
      nzXl="24"
      class="form-col">
      <nz-form-item class="sl-form-item">
        <nz-form-label
          [nzSm]="24"
          [nzXs]="24"
          nzFor="comments"
          nzRequired>
          <span class="sl-form-label">Comentário</span>
        </nz-form-label>
        <nz-form-control
          [nzSm]="24"
          [nzXs]="24"
          nzErrorTip="Por favor escreva um comentário.">
          <nz-textarea-count [nzMaxCharacterCount]="255">
            <textarea
              rows="6"
              formControlName="comments"
              id="comments"
              nz-input
              placeholder="Escreva um comentário..."></textarea>
          </nz-textarea-count>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

</form>

<div class="ant-modal-footer">
  <div nz-row>

    <div nz-col
         nzXs="24"
         nzSm="24"
         nzMd="24"
         nzLg="24"
         nzXl="24">
      <button class="btn-sl btn-sl-grey btn-cancel"
              id="btnCancelar"
              (click)="handleCancel()"
              [disabled]="isSaving">Cancelar</button>
      <button type="button"
              class="btn-sl btn-sl-green btn-save"
              id="btnSalvar"
              (click)="submitForm()"
              [disabled]="isSaving || form.invalid">{{isSaving ? 'Salvando...' : 'Salvar' }}</button>
    </div>
  </div>
</div>

