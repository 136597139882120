import { Component } from '@angular/core';

@Component({
  selector: 'app-project-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ProjectViewComponent {

  constructor() { }

}
