import { Component } from '@angular/core';

@Component({
  selector: 'app-simple-layout',
  templateUrl: './simple-layout.component.html',
})
export class SimpleLayoutComponent {

  constructor() { }

  onScroll() {
    document.body.click();
  }
}
