import { Component } from '@angular/core';
import * as models from 'powerbi-models';
import * as pbi from 'powerbi-client';
import { DashboardsService } from 'src/app/shared/services/dashboards.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-dashboard-estudos-ambientais',
  templateUrl: './dashboard-estudos-ambientais.component.html',
  styleUrls: ['./dashboard-estudos-ambientais.component.scss'],
})
export class DashboardEstudosAmbientaisComponent {

  dashboard: any = {}

  public readonly powerBiUrl

  constructor(
    private dashboardService: DashboardsService,
    private sanitizer: DomSanitizer
  ) {
    this.powerBiUrl = this.terminalDashboard();
  }

  terminalDashboard() : SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://app.powerbi.com/groups/d9f038e6-9f8c-4f04-841b-c7b89730e0f6/reports/e0750e05-2fd9-4402-ad27-f7d9c8942f09?ctid=e0793d39-0939-496d-b129-198edd916feb'
    );

  }

  showReport(accessToken) {

    this.dashboardService.getToken().subscribe( result => {
      //console.log("result getToken=>", result)
    })

    //https://api.powerbi.com/v1.0/myorg/groups/d9f038e6-9f8c-4f04-841b-c7b89730e0f6/reports/e0750e05-2fd9-4402-ad27-f7d9c8942f09/GenerateToken
    // let embedUrl =
    //   'https://app.powerbi.com/reportEmbed?reportId=' +
    //   'e0750e05-2fd9-4402-ad27-f7d9c8942f09' +
    //   '&groupId=' +
    //   'd9f038e6-9f8c-4f04-841b-c7b89730e0f6' +
    //   '&experience=power-bi';

    let embedUrl =
      'https://app.powerbi.com/reportEmbed?reportId=e0750e05-2fd9-4402-ad27-f7d9c8942f09&autoAuth=true&ctid=e0793d39-0939-496d-b129-198edd916feb';

    let embedReportId = 'e0750e05-2fd9-4402-ad27-f7d9c8942f09';
    let selectedPage = 'ReportSectiondc0bdd83f3d0f9ff4729';
    let config = {
      type: 'report',
      accessToken: 'b9720f6e-63cc-407e-937b-7f0f664c7cc5',
      embedUrl: embedUrl,
      pageName: selectedPage,
      id: embedReportId,
      permissions: models.Permissions.All,
      settings: {
        panes: {
          filters: {
            visible: false,
          },
          pageNavigation: {
            visible: false,
          },
        },
        bars: {
          statusBar: {
            visible: false,
          },
        },
      },
    };
    this.dashboard = config;
    let reportContainer = document.getElementById(
      'embedContainer'
    ) as HTMLElement;
    let powerbi = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory
    );

    let report = powerbi.embed(reportContainer, config);
    report.off('loaded');
    report.on('loaded', () => {
      console.log('Loaded');
    });
  }
}
