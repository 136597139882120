import {Component, Input, OnInit} from '@angular/core';
import {DisciplineAssessmentService} from "../../../../shared/services/discipline-assessment.service";
import {DisciplineAssessmentResponse} from "../../../../shared/dto/assessment-by-id-response.dto";
import moment from 'moment';

@Component({
  selector: 'app-assessment-info',
  templateUrl: './assessment-info.component.html',
  styleUrls: ['./assessment-info.component.scss']
})
export class AssessmentInfoComponent implements OnInit {
  @Input() assessmentId!: number;
  @Input() disciplineAssessment: DisciplineAssessmentResponse;
  loadingAssessment: boolean = false;

  constructor(private disciplineAssessmentService: DisciplineAssessmentService) { }

  ngOnInit(): void {
    if(this.disciplineAssessment === null){
      this.getDisciplineAssessment();
    }
  }

  private getDisciplineAssessment() {
    this.loadingAssessment = true;
    this.disciplineAssessmentService.getDisciplineAssessmentById(this.assessmentId).subscribe({
      next: (assessment: DisciplineAssessmentResponse) => {
        this.disciplineAssessment = assessment;
        this.loadingAssessment = false;
      }
    });
  }

  isDeadLineDate(discipline: any): boolean {
    let currentDate = moment(new Date().setHours(0,0,0,0)).utcOffset(0, true);
    let deadLineDate = moment(discipline.analysisDeadline).utcOffset(0, true);
    return currentDate > deadLineDate;
  }

}
