import { Injectable } from '@angular/core'
import {HttpClient} from '@angular/common/http'
import { Observable} from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class DashboardsService {

  constructor(private readonly http: HttpClient) { }

  getToken(): Observable<any> {
    return this.http.get<any>('https://api.powerbi.com/v1.0/myorg/groups/d9f038e6-9f8c-4f04-841b-c7b89730e0f6/reports/e0750e05-2fd9-4402-ad27-f7d9c8942f09/GenerateToken');
  }

}
