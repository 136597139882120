import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LicensingCreationBody } from "../../../licensing.interface";

@Component({
  selector: 'app-licensing-form-item',
  templateUrl: './licensing-form-item.component.html',
  styleUrls: ['./licensing-form-item.component.scss']
})
export class LicensingFormItem {
  @Input() id: string;
  @Input() placeholder: string;
  @Input() selectedModel: any;
  @Input() onChange: any;
  @Input() itemType: 'select' | 'select-object' | 'input' | 'date' | 'textarea-simple' | 'textarea-count';
  @Input() disabledDate: any;
  @Input() selectOptions: any;
  @Input() selectValueToShowName: string;
  @Input() projectLicensingCreateForm: LicensingCreationBody;
  @Input() changePageTitle: any;
  @Input() licensesTypes: any;
  @Input() disabled = false;
  @Output() update: EventEmitter<any> = new EventEmitter<any>();

  public changeValueOfItem(value) {
    this.onChange.apply(this, [this.id, value]);
    this.update.emit(true);
  }
}
