import { Component } from '@angular/core'
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NotificationsComponent } from 'src/app/modules/notifications/notifications/notifications.component';
import { UserProfileComponent } from 'src/app/modules/user-profile/user-profile.component';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent{
  private flagUserProfile = false;
  private drawerRef;
  private notificationsDrawer;
  public notificationActiveFlag = false;

  constructor(private drawerService: NzDrawerService){}

  notificationsToggle(){
    if (!this.notificationActiveFlag) {
      const bodyStyle = {
        padding: 20,
      };
      this.notificationsDrawer = this.drawerService.create<
        NotificationsComponent,
        { value: string },
        string
      >({
        nzContent: NotificationsComponent,
        nzOffsetX: 0,
        nzBodyStyle: bodyStyle,
        nzPlacement: 'right',
        nzTitle: 'Notificações',
        nzWidth: 680,
      });

      this.notificationsDrawer.afterOpen.subscribe(() => {
        this.notificationActiveFlag = true;
      });

      this.notificationsDrawer.afterClose.subscribe((data) => {
        this.notificationActiveFlag = false;
      });
    } else {
      this.notificationsDrawer.close();
      this.notificationsDrawer = null;
    }
  }

  userProfile() {
    if (!this.flagUserProfile) {
      const bodyStyle = {
        padding: 20,
      };
      this.drawerRef = this.drawerService.create<
        UserProfileComponent,
        { value: string },
        string
      >({
        nzContent: UserProfileComponent,
        nzOffsetX: 0,
        nzBodyStyle: bodyStyle,
        nzPlacement: 'right',
        nzTitle: 'Perfil',
        nzWidth: 300,
      });

      this.drawerRef.afterOpen.subscribe(() => {
        this.flagUserProfile = true;
      });

      this.drawerRef.afterClose.subscribe((data) => {
        this.flagUserProfile = false;
      });
    } else {
      this.drawerRef.close();
      this.drawerRef = null;
    }
  }
}
