import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NzDrawerService} from 'ng-zorro-antd/drawer';
import {NlpSidebarComponent} from '../nlp-sidebar/nlp-sidebar.component';
import {ProductNlpService} from "../services/product-nlp.service";
import {UserService} from "../../../shared/services/user.service";
import {NlpResultStatus, NlpResultStatusData} from "../interface/nlp-result-status";
import {Router} from "@angular/router";
import {take} from "rxjs";
import {NlpAnalysisResultService} from "../nlp-analysis-result/nlp-analysis-result.service";
import {DateCalcHelper} from "../../../shared/helpers/date-calc.helper";

@Component({
  selector: 'app-nlp-topmenu',
  templateUrl: './nlp-topmenu.component.html',
  styleUrls: ['./nlp-topmenu.component.scss']
})
export class NlpTopmenuComponent implements OnInit {
  value = 'ng';
  resultNlp: NlpResultStatus | undefined;
  @ViewChild('analiseResultados') analiseResultadosElementRef!: ElementRef;
  showRefreshButton: boolean = false;
  updateDateTime: Date = new Date();

  constructor(private drawerService: NzDrawerService,
              private productNlpService: ProductNlpService,
              private userService: UserService,
              private router: Router,
              private nlpAnalysisResultService: NlpAnalysisResultService,
              private dateCalcHelp: DateCalcHelper) {
  }


  openNlpSideBar(): void {
    const drawerRef = this.drawerService.create<NlpSidebarComponent, { value: string }, string>({
      nzContent: NlpSidebarComponent,
      nzContentParams: {
        value: this.value
      }
    });

    drawerRef.afterOpen.subscribe(() => {

    });

    drawerRef.afterClose.subscribe(data => {

      if (typeof data === 'string') {
        this.value = data;
      }
    });
  }

  ngOnInit(): void {
    this.getNlpResultStatus();
    this.nlpAnalysisResultService.showRefreshButtonData$.subscribe((result) => {
      this.showRefreshButton = result;
    });
  }


  getNlpResultStatus() {
    if (this.router.url === '/nlp/analise-resultados') {
      this.userService.productNlpObservable$.pipe(take(1))
      .subscribe(productNlp => {
        this.productNlpService.getNlpResultStatus(productNlp.id)
        .pipe(take(1))
        .subscribe(resultNlpStatus => {
          this.resultNlp = resultNlpStatus.data;
          this.getTimeZoneDate(resultNlpStatus);
          this.getNlpAnalysisResult(productNlp.id);
        });
      });
    }
  }

  private getTimeZoneDate(resultNlpStatus: NlpResultStatusData) {
    this.updateDateTime = this.dateCalcHelp.getTimeZoneDate(resultNlpStatus.data.analysisDate);
  }

  private getNlpAnalysisResult(productNlpId: number | undefined): void {
    if (this.router.url === '/nlp/analise-resultados') {
      this.nlpAnalysisResultService.getNlpAnalysisResult(productNlpId)
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          this.nlpAnalysisResultService.updateProductNlp(result);
        }
      });
    }
  }

  getStatus(status: string | undefined): string {
    if (status !== undefined) {
        return status;
    } else {
      return '';
    }
  }
}
