import { Component, Input } from '@angular/core';

export interface badgeConfig {
  badgeColor: 'warning' | 'info' | 'success', 
  badgeText: string, 
  badgeIcon: string
}

@Component({
  selector: 'app-badge-chips',
  templateUrl: './badge-chips.component.html',
  styleUrls: ['./badge-chips.component.scss'],
})
export class BadgeChipsComponent {
  @Input() badgeText!: string;
  @Input() badgeColor!: 'warning' | 'info' | 'success';
  @Input() badgeIcon?: string;
  @Input() badgeButton?: 'primary' | 'secondary' | 'transparent';
  @Input() badgeButtonText?: string;

  defaultStyleClass = 'info';

  constructor() {}
}
