<div nz-row nzJustify="space-between">
  <div nz-col class="topbar-logo">
    Smart Licensing
  </div>
  <div nz-col>
    <a (click)="notificationsToggle()">
      <app-svg-icon icon="notification-icon" fill="#007e7a" [disabled]="true"></app-svg-icon>
    </a>

    <a id="btnProfile" (click)="userProfile()">
      <app-svg-icon icon="user_profile-icon" fill="#007e7a" [disabled]="true"></app-svg-icon>
    </a>
  </div>
</div>