import { Component, Input, OnInit } from '@angular/core';
import { SubProjectByIdResponse } from "../../../../shared/dto/subproject-by-id-response.dto";
import {ReviewResponse} from "../../../../shared/models/project-v2-ripla-review-request.model";

@Component({
  selector: 'app-histories-review',
  templateUrl: './histories-review.component.html',
  styleUrls: ['./histories-review.component.scss']
})
export class HistoriesReviewComponent implements OnInit {
  @Input() subProject!: SubProjectByIdResponse;
  reviews: ReviewResponse[] = [];

  constructor() { }

  ngOnInit(): void {
    this.reviews = this.subProject.subProjectReviews ? this.subProject.subProjectReviews.filter((r) => r.status === 'CON') : [];
  }

}
