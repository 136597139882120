<div nz-row class="container">
  <div nz-col>
    <h1>Central de Ajuda</h1>
  </div>
</div>
<div nz-row [nzGutter]="[16, 16]" class="container video-scroll">
  <div nz-col [nzSpan]="8" class="gutter-row">
    <div class="video-card">
      <div class="video-mini" (click)="showVideo('Cadastro RIPLA',
       'https://stkeycloak.blob.core.windows.net/videos/CadastroRipla.mp4?sp=r&st=2024-04-23T19:42:41Z&se=2024-07-12T03:42:41Z&spr=https&sv=2022-11-02&sr=b&sig=rFUfTm%2BTCwSRTz%2FZKChplnTHaxd4Qefo2dHERke1ALQ%3D')">
        <img src="/assets/video/sample.png" alt="Cadastro RIPLA" style="max-width: 100%;"/>
        <div class="video-shadow">
        </div>
        <div class="video-play-icon">
          <img src="/assets/icons/play.svg" alt="tocar"/>
        </div>
      </div>
      <div class="video-body">
        <h2>Cadastro RIPLA</h2>
      </div>
    </div>
  </div>

  <div nz-col [nzSpan]="8" class="gutter-row">
    <div class="video-card">
      <div class="video-mini" (click)="showVideo('Análise da RIPLA',
       'https://stkeycloak.blob.core.windows.net/videos/AnaliseRipla.mp4?sp=r&st=2024-04-23T19:43:13Z&se=2024-07-12T03:43:13Z&spr=https&sv=2022-11-02&sr=b&sig=piXj7oAG%2BKf1Xfc87XUUtEwfou6FukxPIH3VgIDGuVs%3D')">
        <img src="/assets/video/sample.png" alt="Analise RIPLA" style="max-width: 100%;"/>
        <div class="video-shadow">
        </div>
        <div class="video-play-icon">
          <img src="/assets/icons/play.svg" alt="tocar"/>
        </div>
      </div>
      <div class="video-body">
        <h2>Análise da RIPLA</h2>
      </div>
    </div>
  </div>

  <div nz-col [nzSpan]="8" class="gutter-row ">
    <div class="video-card">
      <div class="video-mini" (click)="showVideo('Análise dos Especialistas',
       'https://stkeycloak.blob.core.windows.net/videos/AnaliseEspecialistas.mp4?sp=r&st=2024-04-23T19:43:34Z&se=2024-07-12T03:43:34Z&spr=https&sv=2022-11-02&sr=b&sig=Bb75LVvMNR6iOUAOQXuMQIu5EDCSML7V2jARc21g2ZA%3D')">
        <img src="/assets/video/sample.png" alt="Analise Especialista" style="max-width: 100%;"/>
        <div class="video-shadow">
        </div>
        <div class="video-play-icon">
          <img src="/assets/icons/play.svg" alt="tocar"/>
        </div>
      </div>
      <div class="video-body">
        <h2>Análise dos Especialistas</h2>
      </div>
    </div>
  </div>

  <div nz-col [nzSpan]="8" class="gutter-row">
    <div class="video-card">
      <div class="video-mini" (click)="showVideo('Documentos',
       'https://stkeycloak.blob.core.windows.net/videos/Documentos.mp4?sp=r&st=2024-04-23T19:42:13Z&se=2024-07-12T03:42:13Z&spr=https&sv=2022-11-02&sr=b&sig=M7NPzZfe7GaJ0dCK3pLt9mnqajqGQCmLW2pQL29pI78%3D')">
        <img src="/assets/video/sample.png" alt="Cadastro Ripla" style="max-width: 100%;"/>
        <div class="video-shadow">
        </div>
        <div class="video-play-icon">
          <img src="/assets/icons/play.svg" alt="tocar"/>
        </div>
      </div>
      <div class="video-body">
        <h2>Documentos</h2>
      </div>
    </div>
  </div>

  <div nz-col [nzSpan]="8" class="gutter-row">
    <div class="video-card">
      <div class="video-mini" (click)="showVideo('Publicação do parecer final da RIPLA',
       'https://stkeycloak.blob.core.windows.net/videos/PublicacaoParecerRIPLA.mp4?sp=r&st=2024-04-23T19:34:51Z&se=2024-07-12T03:34:51Z&spr=https&sv=2022-11-02&sr=b&sig=qZY8nzEEyIDnXnwxxvx06G6F%2FaC7hoBKgG4G2hlTg9E%3D')">
        <img src="/assets/video/sample.png" alt="Publicação do parecer final da RIPLA" style="max-width: 100%;"/>
        <div class="video-shadow">
        </div>
        <div class="video-play-icon">
          <img src="/assets/icons/play.svg" alt="tocar"/>
        </div>
      </div>
      <div class="video-body">
        <h2>Publicação do parecer final da RIPLA</h2>
      </div>
    </div>
  </div>

  <div nz-col [nzSpan]="8" class="gutter-row">
    <div class="video-card">
      <div class="video-mini" (click)="showVideo('Navegação Geral do Sistema',
       'https://stkeycloak.blob.core.windows.net/videos/NavegacaoGeralSL-1.mp4?sp=r&st=2024-04-23T19:41:11Z&se=2024-07-12T03:41:11Z&spr=https&sv=2022-11-02&sr=b&sig=WDKP9D8wq3mCEjWAvwoQFGEm3cMujjV18vdczEvT%2Bhg%3D')">
        <img src="/assets/video/sample.png" alt="Cadastro Ripla" style="max-width: 100%;"/>
        <div class="video-shadow">
        </div>
        <div class="video-play-icon">
          <img src="/assets/icons/play.svg" alt="tocar"/>
        </div>
      </div>
      <div class="video-body">
        <h2>Navegação Geral do Sistema</h2>
      </div>
    </div>
  </div>


</div>
