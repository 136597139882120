import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, inject } from '@angular/core';

import { Subject, takeUntil } from 'rxjs';

import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { MessageService } from 'src/app/shared/common/services/message.service';

import { ProjectsRequestReviewService } from 'src/app/modules/projects/projects-ripla/projects-request-revision/projects-request-review.service';
import moment from 'moment';

@Component({
  selector: 'app-suspension-cancellation-modal',
  templateUrl: './suspension-cancellation-modal.component.html',
  styleUrls: ['./suspension-cancellation-modal.component.scss']
})
export class SuspensionCancellationModalComponent implements OnInit, OnDestroy, OnChanges {
  @Output() handleFormChanges = new EventEmitter<any>();
  @Output() changeValue: EventEmitter<any> = new EventEmitter<any>();
  @Input() reviewId: number;
  @Input() type: string;
  @Input() projectId: number;

  private readonly destroy$: Subject<any> = new Subject();
  readonly #modal = inject(NzModalRef);

  compareFn = (o1: any, o2: any) => (o1 && o2 ? o1.description == o2.description : true);
  
  // Form Variables
  formRequestReview: FormGroup;
  subProjectId: number;
  
  editReview: boolean = false;
  reasonsIdsChecked: Array<number> = [];
  
  hasEstimative: boolean = false
  returnDateModel: string = ''
  errorMessage;

  listOfOption: Array<{id?: number | undefined; description: string}> = []
  listOfOptionSelected: Array<{id?: number | undefined; description: string}> = [];

  totalCaracters: number = 0
  reasonNameText: string = ''
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly modalService: NzModalService,
    private readonly messageService: MessageService,
    private readonly projectsService: ProjectsRequestReviewService,
  ) { }

  ngOnInit(): void {
    this.inicializarForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  inicializarForm(): void {
    this.formRequestReview = this.formBuilder.group({
      suspensionCancellationReason: [null, Validators.required],
      comments: [null, [Validators.required]],
      returnProject: [null, this.type === 'suspension' ? [Validators.required] : null],
      returnDate: [null],
    });
    this.reasonNameText =  (this.type === 'suspension') ? 'Motivo para a Suspensão' : 'Motivo para o cancelamento'
    this.getReasons();
  }

  checkInputForm(controlName: string): string {
    if (!this.formRequestReview.controls[controlName].valid
      ) {
      return 'error'
    }
    return ' '
  }
  onHandleFormChanges(): void {
    this.handleFormChanges.emit(this.formRequestReview);
  }

  ngOnChanges(): void {
    this.formRequestReview.controls['suspensionCancellationReason'].setValue(this.listOfOptionSelected);
  }

  getReasons(): void {
    const typeReason = this.type === 'suspension' ? 'SUSPENSION' : "CANCEL"
    this.projectsService.getSuspensionCancellationReasons(typeReason)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.listOfOption = response;
        }
      })
  }

  onCancel(): void {
    this.modalService.confirm({
      ...(this.messageService.showMessageModalConfirmCancelRequestGeneric("Cancelar pedido") as any),
      nzOnOk: () => {
        this.#modal.close();
      }
    });
  }
  
  onSubmit(): void {
    if (this.formRequestReview.valid) {
      this.createSuspensionCancellationRequest();
    } else {
        this.messageService.showErrorMessage("Existem campos obrigatórios pendentes de preenchimento. Favor verificar."); 
    }
  }

  createSuspensionCancellationRequest() {
    const payload = this.buildPayload(this.formRequestReview.value)
    this.projectsService.postSuspensionCancellation(payload)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: any) => {
          this.#modal.close(data);
          this.messageService.showSucessMessage(this.type === 'suspension' ? 'Pedido de Suspensão enviado com sucesso!' : 'Pedido de Cancelamento enviado com sucesso!')
        },
        error: (err) => {
          this.messageService.showErrorMessage(err.error.errors.details)
        }
      })
  }
  buildPayload(payload: any): any {
    const reasonsDescriptions = payload.suspensionCancellationReason.map(obj => obj.description);
    return {
      "isCancel": this.type === 'suspension' ?  false : true,
      "projectId": this.projectId,
      "reasons": reasonsDescriptions,
      "estimateResumeProject": payload.returnProject === "Sim" ? true : false,
      "expectedResumeDate": payload.returnDate ? moment(payload.returnDate).format("YYYY-MM-DD") : null,
      "reasonDetails": payload.comments
    }
  }

  public disabledDate = (current: Date) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return current < currentDate;
  };

  public getBannerText(): string {
    if(this.type === 'cancellation') {
      return `O pedido de cancelamento será analisado e todas as medidas necessárias serão informadas. O cancelamento só ocorrerá após confirmação e aceite das ações de cancelamento pelo solicitante.`;
    } else {
      return `Seu pedido de suspensão será analisado pelo time de licenciamento ambiental e todas as medidas necessárias para o procedimento serão informadas para a área solicitante. A suspensão só ocorrerá após confirmação e aceite das medidas, por parte da área solicitante.`;
    }
  }

  changeReturnProject() {
    if(this.formRequestReview.controls['returnProject'].value === 'Sim') {
      this.formRequestReview.controls['returnDate'].setValidators([Validators.required]);
      this.hasEstimative = true 
    } else {
      this.formRequestReview.controls['returnDate'].removeValidators
      this.hasEstimative = false
    } 
  }

  onChangeReason(array: any[]) {
    this.listOfOptionSelected = array;
    this.getReasons();
    
    if (array.length > 0) {
      this.listOfOption.push(array[array.length -1]); 
    }
    this.onHandleFormChanges();
  }
}
