import {ChangeDetectorRef, Component, Input, OnInit, ViewContainerRef} from '@angular/core';
import {NzButtonSize} from 'ng-zorro-antd/button';
import {SidebarService} from './service/sidebar.service';
import {GeneralInformation, GeneralInformationResponse} from '../interface/nlp-interface';
import {Observable} from 'rxjs';
import {NlpAddProjInformationsComponent} from '../nlp-add-proj-informations/nlp-add-proj-informations.component';
import {NzModalService} from 'ng-zorro-antd/modal';
import {Router} from '@angular/router';
import {UserService} from 'src/app/shared/services/user.service';
import {NzDrawerRef} from 'ng-zorro-antd/drawer';
import {DemandService} from "../../../shared/services/demand.service";

@Component({
  selector: 'app-nlp-sidebar',
  templateUrl: './nlp-sidebar.component.html',
  styleUrls: ['./nlp-sidebar.component.scss']
})
export class NlpSidebarComponent implements OnInit {

  size: NzButtonSize = 'large';
  isDataEmpty: boolean = false;
  projectInfo!: GeneralInformation;
  anexo!: Observable<any[]>;
  reportsSaved!: Observable<any[]>;
  modalClosedSuccessfully = false;
  @Input() docId: number | undefined;
  @Input() value = '';

  constructor(private service: SidebarService,
              private modal: NzModalService,
              private viewContainerRef: ViewContainerRef,
              private router: Router,
              private userService: UserService,
              private drawerRef: NzDrawerRef<string>,
              private cdr: ChangeDetectorRef,
              private demandService: DemandService,) {
  }

  ngOnInit() {
    this.userService.productNlpObservable$.subscribe(data => {
      if (Object.keys(data).length === 0) {
        this.isDataEmpty = true;
      } else {
        this.isDataEmpty = false;
        this.getGeneralInformations(data.id);
        this.getGeneralInformationsAnexo(data.id);
        this.getGeneralInformationReportsSaved(data.id);
      }
    });
  }

  getGeneralInformations(id: any) {
    this.service.getGeneralInformationSidebar(id).subscribe({
        next: (response) => {
          this.projectInfo = response.data;
          this.getStudy(response);
        },
        error: (error) => this.onError(error),
      }
    );
  }

  private getStudy(response: GeneralInformationResponse) {
    if (response.data.productDemandId === null) {
      this.demandService.getStudyTypes().subscribe((studies) => {
        let studyTypes = studies.filter((element: any) => {
          return element.id === this.projectInfo.studyTypeId;
        });
        this.projectInfo.studyType = studyTypes[0].description;
      });
    }
  }

  getGeneralInformationsAnexo(id: any) {
    this.anexo = this.service.getGeneralInformationSidebarAnexo(id);
  }

  getGeneralInformationReportsSaved(id: any) {
    this.reportsSaved = this.service.getGeneralInformationReportsSaved(id);
  }

  onError(error: any) {
    if (error?.status === 417) {
    }
  }

  showAddProjInformationsModal(): void {
    const modal = this.modal.create<NlpAddProjInformationsComponent>({
      nzTitle: 'Cadastro de produto NLP',
      nzWidth: 780,
      nzContent: NlpAddProjInformationsComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
      nzOkLoading: true,
      nzCancelText: 'No',
      nzOnCancel: () => {
        this.modalClosedSuccessfully = true
      },
    });

    modal.getContentComponent();

    modal.afterClose.subscribe(() => {
      if (this.modalClosedSuccessfully) {
        this.cdr.detectChanges();
        this.router.navigate([`nlp/termo-referencia`]);
      } else {
        this.cdr.detectChanges();
      }
      this.modalClosedSuccessfully = false;
    });
  }

  close(): void {
    this.drawerRef.close(this.value);
  }
}
