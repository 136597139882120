<button [id]="id" type="button" class="app-button-save-draft" [style.width.px]="width">
    <i class="">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="save">
          <path id="primary"
            d="M13.3337 4.66667V13.3333C13.3337 13.5101 13.2634 13.6797 13.1384 13.8047C13.0134 13.9298 12.8438 14 12.667 14H3.33366C3.15685 14 2.98728 13.9298 2.86225 13.8047C2.73723 13.6797 2.66699 13.5101 2.66699 13.3333V2.66667C2.66699 2.48986 2.73723 2.32029 2.86225 2.19526C2.98728 2.07024 3.15685 2 3.33366 2H10.667L13.3337 4.66667ZM5.33366 4.66667H6.66699H5.33366ZM10.667 10H5.33366V14H10.667V10Z"
            stroke="#007E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </g>
      </svg>
    </i>
    {{ name }}
</button>