import { NgModule } from '@angular/core';
import { RouterModule, type Routes } from '@angular/router';

import { NlpComponent } from "./modules/nlp/nlp.component";
import { TasksComponent } from './modules/tasks/tasks.component';
import { DocumentsComponent } from './modules/documents/documents.component';
import { DashboardsComponent } from './modules/dashboards/dashboards.component';
import { ProjectViewComponent } from './modules/projects-v2/view/view.component';
import { ProjectsV2Component } from './modules/projects-v2/projects-v2.component';
import { NlpReportComponent } from "./modules/nlp/nlp-report/nlp-report.component";
import { NlpWelcomeComponent } from './modules/nlp/nlp-welcome/nlp-welcome.component';
import { ProductsListComponent } from './modules/nlp/products-list/products-list.component';
import { ProcessesListComponent } from './modules/projects-v2/processes-list/processes-list.component';
import { ProjectV2ViewComponent } from "./modules/projects-v2/project-v2-view/project-v2-view.component";
import { ProjectsV2NewComponent } from './modules/projects-v2/projects-v2-new/projects-v2-new.component';
import { ProjectV2SuspensionComponent } from './modules/projects-v2/project-v2-suspension/project-v2-suspension.component';
import { NlpReferenceTermComponent } from "./modules/nlp/nlp-reference-term/nlp-reference-term.component";
import { NlpAnalysisResultComponent } from "./modules/nlp/nlp-analysis-result/nlp-analysis-result.component";
import { ProjectV2AnalysisComponent } from './modules/projects-v2/project-v2-analysis/project-v2-analysis.component';
import { ProjectV2AssessmentComponent } from "./modules/projects-v2/project-v2-assessment/project-v2-assessment.component";
import { NewLicensingProcessComponent } from './modules/projects/projects-licensing/new-project-licensing/new-project-licensing.component';
import { ProjectV2AnalysisConsolidationFinalComponent } from './modules/projects-v2/project-v2-analysis/project-v2-analysis-consolidation-final/project-v2-analysis-consolidation-final.component';
import { NewActivityComponent } from './modules/projects/projects-licensing/components/activities/new-activity/new-activity.component';
import { ProjectV2RequisitionRevisionComponent } from './modules/projects-v2/projects-v2-requisition-revision/project-v2-requisition-revision.component';
import { EditProcessv2Component } from './modules/projects/projects-licensing/components/edit-processv2/edit-processv2.component';
import { HelpCenterComponent } from "./modules/help-center/help-center.component";
import {AuthGuard} from "./core/auth/guards/auth.guard";


const routes: Routes = [
  { path: '', redirectTo: '/tasks', pathMatch: 'full' },
  {
    path: 'dashboards', canActivate: [AuthGuard],
    component: DashboardsComponent,
    data: { breadcrumb: 'Dashboards' },
  },
  {
    path: 'tasks', canActivate: [AuthGuard],
    component: TasksComponent,
    data: { breadcrumb: 'Tasks' },
  },
  {
    path: 'projects', canActivate: [AuthGuard],
    component: ProjectsV2Component,
    data: { breadcrumb: 'Inicial' },
  },
  {
    path: 'projects-v2', canActivate: [AuthGuard],
    component: ProjectsV2Component,
    data: { breadcrumb: 'Projetos' },
  },
  {
    path: 'communications',
    loadChildren: () => import('./modules/communications/communications.module').then((m) => m.CommunicationsModule),
  },
  {
    path: 'projects/:projectId', canActivate: [AuthGuard],
    component: ProjectViewComponent,
    children: [
      {
        path: '',
        component: ProjectV2ViewComponent,
        pathMatch: "full"
      },
      {
        path: 'processes',
        component: ProcessesListComponent,
      },
      {
        path: 'new-licensing-process',
        component: NewLicensingProcessComponent
      }
    ],
  },
  {
    path: 'projects/:projectId', canActivate: [AuthGuard],
    component: ProjectViewComponent,
    children: [
      /* HIDE GO LIVE */
      {
        path: '',
        component: ProcessesListComponent,
        pathMatch: "full"
      },
      {
        path: 'processes',
        component: ProcessesListComponent,
      },
      {
        path: 'new-licensing-process',
        component: NewLicensingProcessComponent
      },
      {
        path: 'process/:processId/new-activity',
        component: NewActivityComponent
      },
      {
        path: 'process/:processId/edit-activity/:activityId',
        component: NewActivityComponent
      },
      {
        path: 'process/:processId/new-sub-activity/:activitySubId/:product',
        component: NewActivityComponent
      }
    ],
  },
  {
    path: 'project/:projectId/edit-processv2/:processId',
    component: EditProcessv2Component,
  },
  {
    path: 'projects-v2-new/:riplaType', canActivate: [AuthGuard],
    component: ProjectsV2NewComponent,
    data: { breadcrumb: 'Nova RIPLA' },
  },
  {
    path: 'project-v2-view/:projectId', canActivate: [AuthGuard],
    component: ProjectV2ViewComponent,
    data: { breadcrumb: 'Projetos' },
  },
  {
    path: 'project-v2-analysis/:projectId/:subProjectId', canActivate: [AuthGuard],
    component: ProjectV2AnalysisComponent,
    data: { breadcrumb: 'Projects Analysis' },
  },
  {
    path: 'project-v2-analysis-consolidation-final/:projectId/:subProjectId', canActivate: [AuthGuard],
    component: ProjectV2AnalysisConsolidationFinalComponent,
    data: { breadcrumb: 'Projects Analysis Consolidation Final' },
  },
  {
    path: 'project-v2-assessment/:projectId/:subProjectId/:assessmentId', canActivate: [AuthGuard],
    component: ProjectV2AssessmentComponent,
    data: { breadcrumb: 'Project Assessment' },
  },
  {
    path: 'project-v2-requisition-revision/:projectId/:subProjectId/:revisionId', canActivate: [AuthGuard],
    component: ProjectV2RequisitionRevisionComponent,
    data: { breadcrumb: 'Project Revision' },
  },
  {
    path: 'suspension/:projectId/:suspensionId', canActivate: [AuthGuard],
    component: ProjectV2SuspensionComponent
  },
  {
    path: 'cancellation/:projectId/:suspensionId', canActivate: [AuthGuard],
    component: ProjectV2SuspensionComponent
  },
  {
    path: 'nlp', canActivate: [AuthGuard],
    component: NlpComponent,
    children: [
      { path: '', redirectTo: 'welcome', pathMatch: 'full' },
      {
        path: 'welcome',
        component: NlpWelcomeComponent,
        data: { breadcrumb: 'NLP' },
      },
      {
        path: 'termo-referencia',
        component: NlpReferenceTermComponent,
        data: { breadcrumb: 'Termo de Referência' },
      },
      {
        path: 'analise-resultados',
        component: NlpAnalysisResultComponent,
        data: { breadcrumb: 'Análise de Resultados' },
      },
      {
        path: 'relatorio',
        component: NlpReportComponent,
        data: { breadcrumb: 'Relatório' },
      },
      {
        path: 'list',
        component: ProductsListComponent,
      },
    ],
  },
  {
    path: 'documents', canActivate: [AuthGuard],
    component: DocumentsComponent,
    data: { breadcrumb: 'Documentos' },
    children: [{ path: '', redirectTo: 'documents', pathMatch: 'full' }],
  },
  {
    path: 'scope-change', canActivate: [AuthGuard],
    loadChildren: () => import('./modules/scope-change/scope-change.module').then((module) => module.ScopeChangeModule)
  },
  {
    path: 'help-center', canActivate: [AuthGuard],
    component: HelpCenterComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  providers: [AuthGuard],
  exports: [RouterModule],
})
export class AppRoutingModule { }
