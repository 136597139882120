<div class="container" *ngIf="!loadingSubProjects">

  <!-- MENU SUPERIOR -->
  <div nz-row class="mb-20">
    <div nz-col nzSpan="15" class="header">
      <a (click)="showCancelConfirm()"
         class="mr-10"
         id="goBackToProjectView">
        <img src="/assets/icons/icon_arrow-back.svg" alt="">
      </a>
      <h1 id="projectName">
        {{ 'projects.consolidation-final.title' | translate }}
      </h1>
    </div>
    <div nz-col nzSpan="9" class="action-box">
      <button class="btn-review" (click)="saveDraft()" *ngIf="statusRipla !== 'CON'">
        <img class="marginsSVG" src="assets/icons/icon_save.svg" alt="">
        {{ 'projects.consolidation-final.button-save-draft' | translate }}
      </button>

      <button class="btn-final" (click)="publishFinalAnalysis()">
        <img class="marginsSVG" src="assets/icons/icon_check_small.svg" alt="">
        {{ 'projects.consolidation-final.button-publish-consolidation' | translate }}
      </button>
    </div>
  </div>

  <div nz-row>

    <!-- CARD AVALIACAO EQUIPE LICENCIAMENTO -->
    <div nz-col class="rounded-row-avaliation-panel" (scroll)="onScroll()">

      <form nz-form [formGroup]="form">

        <!-- INICIO AVALIACAO EQUIPE DE LICENCIAMENTO -->
        <div class="cardLicensingTeam">

          <p class="cardsTitle">
            {{ 'projects.consolidation-final.card-title-licensing-team-assessment' | translate }}
          </p>

          <p class="avaliationSubtitle">
            {{ 'projects.consolidation-final.card-subtitle-licensing-team-assessment' | translate }}
          </p>

          <nz-form-item class="environmentalRestrictions">
            <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="selEnvironmentalRestrictions" nzRequired>
              <span class="avaliationSubtitle">{{ 'projects.consolidation-final.environmental-restrictions' | translate }}</span>
            </nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24">
              <nz-radio-group formControlName="environmentalRestrictions"
                              (ngModelChange)="showRestrictionsClick()">

                <label nz-radio nzValue="sim"><span class="card--radio__span">Sim</span></label>
                <label nz-radio nzValue="nao"><span class="card--radio__span">Não</span></label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>

          <div *ngIf="showRestrictions">
            <p class="avaliationSubtitle mt-20">
              <span style="color: #EA575E">* </span>{{ 'projects.consolidation-final.what-environmental-restrictions' | translate }}
            </p>

            <nz-form-item class="sl-form-item">
              <nz-form-control nzErrorTip="Por favor selecione as restrições" >
                <nz-select
                  nzBorderless
                  nzMode="multiple"
                  formControlName="selectedRestrictionsList"
                  [nzShowArrow]="false"
                  id="selSelectedRestrictionsList"
                  [nzLoading]="isRestrictionsListLoading"
                  (ngModelChange)="replaceResume()"
                  [nzMaxTagCount]="2"
                  [nzMaxTagPlaceholder]="moreTemplate"
                  class="selectRestrictions {{totalLengthRestriction > 93 ? 'heigth77' : totalLengthRestriction < 30 ? 'heigth32' : ''}}">
                  <nz-option
                    *ngFor="let item of restrictionsList"
                    nzLabel="{{ item?.description }}"
                    nzValue="{{ item?.id }}"></nz-option>
                </nz-select>
              </nz-form-control>
              <ng-template #moreTemplate let-selectedList>e mais {{ selectedList.length }} selecionados</ng-template>
            </nz-form-item>
          </div>

          <nz-form-item class="sl-form-item">
            <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="securityQuestions" nzRequired="true">
              <span class="avaliationSubtitle">{{ 'projects.consolidation-final.security-questions' | translate }}</span>
            </nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24">
              <nz-radio-group formControlName="securityQuestions" (ngModelChange)="replaceResume()">
                <label nz-radio nzValue="sim" id="securityQuestionSim">Sim</label>
                <label nz-radio nzValue="nao" id="securityQuestionNao">Não</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item class="sl-form-item">
            <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="demandByLicensing" nzRequired="true">
              <span class="avaliationSubtitle">{{ 'projects.consolidation-final.demand-by-licensing' | translate }}</span>
            </nz-form-label>
            <nz-form-control [nzSm]="24" [nzXs]="24">
              <nz-radio-group formControlName="demandByLicensing"  (ngModelChange)="replaceResume()">
                <label nz-radio nzValue="sim" id="demandByLicensingSim">Sim</label>
                <label nz-radio nzValue="nao" id="demandByLicensingNao">Não</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>
        <!-- FINAL AVALIACAO EQUIPE DE LICENCIAMENTO -->

      </form>

      <!-- INICIO BLOCO ESTRATEGIA DO LICENCIAMENTO -->
      <div class="cardLicensingStrategy">
        <p class="cardsTitle">
          {{ 'projects.consolidation-final.card-title-licensing-strategy' | translate }}
        </p>
      </div>

      <!-- TABELA PARA ADICIONAR NOVA ESTRATEGIA DO LICENCIAMENTO -->
      <nz-table nzTemplateMode  nzSize="small">
        <thead>
        <tr>
          <th class="tableTh">Órgão</th>
          <th class="tableTh">Processo de licenciamento</th>
          <th class="tableTh"></th>
        </tr>
        </thead>
        <tbody>
        <tr class="editable-row" id="riplaInfo" *ngFor="let licensing of licensingStrategy; let i = index">

          <td class="tableTdProcessStrategy" [width]="'50%'">
            <span *ngIf="editingRow !== i || !editStrategy">{{ licensing.competentOrganName ? licensing.competentOrganName : ''}}</span>
          </td>

          <td class="tableTd" [width]="'49%'">
            <span *ngIf="editingRow !== i || !editStrategy">{{ licensing.licenseName ? licensing.licenseName : '' }}</span>
          </td>

          <td style="padding: 0 !important; width: 1%;">
            <!-- TRES PONTOS MENU -->
            <div *ngIf="!addNewLicensingStrategy && !editStrategy">
              <button class="buttonMenuFlow" nz-button nz-dropdown [nzTrigger]="'click'" [nzDropdownMenu]="menu">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <ellipse cx="10.5" cy="10.5" rx="1.5" ry="1.5" transform="rotate(-90 10.5 10.5)"
                           fill="#007E7A" />
                  <ellipse cx="10.5" cy="4.5" rx="1.5" ry="1.5" transform="rotate(-90 10.5 4.5)"
                           fill="#007E7A" />Novo Processo
                  <ellipse cx="10.5" cy="16.5" rx="1.5" ry="1.5" transform="rotate(-90 10.5 16.5)"
                           fill="#007E7A" />
                </svg>
              </button>
            </div>

            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item class="itemMenuFlow" (click)="startEditStrategy(i, licensing)">
                  <img src="assets/icons/icon_edit.svg" class="mr-10 icone" alt="">
                  <span class="titleMenuItem">Editar</span>
                </li>
                <li
                  nz-menu-item class="itemMenuFlow" (click)="deleteLicesingProcess(licensing.organId)">
                  <img src="assets/icons/icon_delete.svg" class="mr-10 icone" alt="">
                  <span class="titleMenuItem">Excluir</span>
                </li>
              </ul>
            </nz-dropdown-menu>
          </td>
        </tr>

        <!-- BLOCO PARA ADICIONAR NOVO PROCESSO -->
        <tr *ngIf="addNewLicensingStrategy">

          <!-- LISTA DE ORGAOS COMPETENTES -->
          <td [width]="'50%'">
            <nz-select
              [(ngModel)]="newOrganStrategy"
              [compareWith]="compareFn"
              nzBorderless
              (ngModelChange)="getLicensingProcessByOrganId(false)"
              id="organSelectedStudy"
              class="formFieldBorderThin"
              nzPlaceHolder="Selecione"
              [nzShowArrow]="false">
              <nz-option *ngFor="let organ of organList"
                         nzValue="{{organ.id}}"
                         nzLabel="{{organ.code}}"></nz-option>
            </nz-select>
          </td>

          <!-- LISTA DE PROCESSOS DE LICENCIAMENTO -->
          <td colspan="2" [width]="'50%'">
            <nz-select
              [(ngModel)]="LicensingProcessValue"
              [compareWith]="compareFn"
              [nzDisabled]="newOrganStrategy === null"
              nzBorderless
              id="usersSelectOption"
              class="formFieldBorderThin"
              nzPlaceHolder="Selecione"
              [nzShowArrow]="false">
              <nz-option [nzValue]=" " nzLabel=" " [nzHide]="true"></nz-option>
              <nz-option *ngFor="let process of licensingProcessList"
                         [nzValue]="process.id"
                         [nzLabel]="process.description"></nz-option>
            </nz-select>
          </td>
        </tr>

        <!-- BOTOES SALVAR E CANCELAR -->
        <tr *ngIf="addNewLicensingStrategy || editStrategy">
          <td></td>
          <td colspan="2" style="display: flex; justify-content: flex-end;">
            <div (click)="cancelNewLicensingProcessClick()">
              <button nz-button class="btn btn-green" class="btnCancelDiscipline">Cancelar</button>
            </div>
            <div *ngIf="!editStrategy" (click)="addNewLicensingProcess()">
              <button button nz-button nzType="primary" class="btnAddDiscipline">Adicionar</button>
            </div>
            <div *ngIf="editStrategy" (click)="editStrategyClick()">
              <button button nz-button nzType="primary" class="btnAddDiscipline">Editar</button>
            </div>
          </td>
          <td></td>
        </tr>
        <!-- FIM BLOCO PARA ADICIONAR NOVA ESTRATEGIA DE LICENCIAMENTO -->

        <tr *ngIf="!editStrategy">
          <td colspan="3">
            <div class="addNewDisciplineButton" >
              <span class="project-doc-icon"  (click)="addNewProcessClick()">
                <img src="/assets/icons/icon_plus_circle.svg" alt="{{'projects.view.license.add-license'}}">
              </span>
              <span class="addNewDiscipline"  (click)="addNewProcessClick()">Novo processo</span>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
      <!-- FIM BLOCO ESTRATEGIA DO LICENCIAMENTO -->


      <!-- INICIO BLOCO ESTUDOS AMBIENTAIS NECESSARIOS -->
      <div class="cardLicensingStrategy">
        <p class="cardsTitle">
          {{ 'projects.consolidation-final.card-title-necessary-study' | translate }}
        </p>
      </div>

      <!-- TABELA PARA ADICIONAR NOVO ESTUDO AMBIENTAL NECESSARIO -->
      <nz-table nzSize="small" nzTemplateMode>
        <thead>
        <tr>
          <th class="tableTh" [width]="'40%'">Descrição</th>
          <th class="tableTh" [width]="'40%'">Processo de licenciamento</th>
          <th class="tableTh" [width]="'18%'">Custo Estimado</th>
          <th class="tableTh" [width]="'2%'"></th>
        </tr>
        </thead>
        <tbody>
        <tr class="editable-row" id="riplaInfo" *ngFor="let study of environmentalStudiesList; let i = index">

          <td class="tableTdProcessStrategy">
              <span class="disciplineName">
                {{ study.description }}
              </span>
          </td>

          <td>
            <nz-select
              [(ngModel)]="LicensingProcessValue"
              [compareWith]="compareFn"
              (ngModelChange)="updateLicenseForStudy(study, editingStudyRow)"
              nzBorderless
              id="licensingSelectOption"
              class="formFieldBorderThin"
              nzPlaceHolder="Selecione"
              [nzShowArrow]="false"
              *ngIf="editingStudyRow === i && editStudy">
              <nz-option [nzValue]=" " nzLabel=" " [nzHide]="true"></nz-option>
              <nz-option *ngFor="let process of licensingProcessListAll"
                         [nzValue]="process.id"
                         [nzLabel]="process.description"></nz-option>
            </nz-select>

            <!-- <select class="gridSelectOption"
                    *ngIf="editingStudyRow === i && editStudy"
                    [(ngModel)]="LicensingProcessValue"
                    (ngModelChange)="updateLicenseForStudy(study, editingStudyRow)">
              <option value=" " label=" "  [hidden]="true"></option>
              <option class="userItem selectItem"
                      *ngFor="let process of licensingProcessListAll"
                      label="{{ process.description }}"
                      [value]="process.id">{{ process.description }}
              </option>
            </select> -->
            <span *ngIf="editingStudyRow !== i || !editStudy">{{ study.license }}</span>
          </td>

          <td class="tableTd">
            <nz-input-number
              *ngIf="editingStudyRow === i && editStudy"
              nzBorderless
              [nzMin]="1"
              [nzFormatter]="formatterReal"
              [nzParser]="parserReal"
              [(ngModel)]="newEstimatedCost"
              class="formatNumberCost"></nz-input-number>
            <div class="editable-cell" *ngIf="editingStudyRow !== i || !editStudy">
              R$ {{ study.cost | number:'1.2-2':'pt' }}
            </div>
          </td>

          <td style="padding: 0 !important;">
            <!-- TRES PONTOS MENU -->
            <div *ngIf="!addNewNecessaryStudy && !editStudy">
              <button class="buttonMenuFlow" nz-button nz-dropdown [nzTrigger]="'click'" [nzDropdownMenu]="menu">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <ellipse cx="10.5" cy="10.5" rx="1.5" ry="1.5" transform="rotate(-90 10.5 10.5)" fill="#007E7A" />
                  <ellipse cx="10.5" cy="4.5" rx="1.5" ry="1.5" transform="rotate(-90 10.5 4.5)" fill="#007E7A" />
                  <ellipse cx="10.5" cy="16.5" rx="1.5" ry="1.5" transform="rotate(-90 10.5 16.5)" fill="#007E7A" />
                </svg>
              </button>
            </div>

            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu>
                <li nz-menu-item class="itemMenuFlow" (click)="startEditDiscipline(i, study)">
                  <img src="assets/icons/icon_edit.svg" class="mr-10 icone" alt="">
                  <span class="titleMenuItem">Editar</span>
                </li>
                <li nz-menu-item class="itemMenuFlow" (click)="deleteEnvironmentalStudy(study.id)">
                  <img src="assets/icons/icon_delete.svg" class="mr-10 icone" alt="">
                  <span class="titleMenuItem">Excluir</span>
                </li>
              </ul>
            </nz-dropdown-menu>
          </td>
        </tr>

        <!-- BLOCO PARA ADICIONAR NOVO PROCESSO -->
        <tr *ngIf="addNewNecessaryStudy">

          <!-- LISTA DE DESCRIÇÕES -->
          <td [width]="'40%'">
            <nz-select
              [(ngModel)]="newDescriptionNecessaryStudy"
              [compareWith]="compareFn"
              nzBorderless
              id="descriptionNecessaryStudySelected"
              class="formFieldBorderThinWidth"
              nzPlaceHolder="Selecione"
              [nzShowArrow]="false">
              <nz-option  *ngFor="let description of descriptionStudyList"
                         [nzValue]="description.id"
                         [nzLabel]="description.description"></nz-option>
            </nz-select>

            <!-- <select [(ngModel)]="newDescriptionNecessaryStudy" id="descriptionNecessaryStudySelected"
                    class="gridSelectOption">
              <option value=" " label=" "  [hidden]="true"></option>
              <option class="selectItem" [hidden]="description.id === 0"
                      *ngFor="let description of descriptionStudyList"
                      value="{{description.id}}"
                      label="{{description.description}}">
              </option>
            </select> -->
          </td>

          <!-- LISTA DE PROCESSOS DE LICENCIAMENTO -->
          <td [width]="'40%'">
            <nz-select
              [(ngModel)]="newLicensingProcessNecessaryStudy"
              [nzDisabled]="!newDescriptionNecessaryStudy"
              [compareWith]="compareFn"
              nzBorderless
              id="licensingProcessNecessaryStudySelected"
              class="formFieldBorderThinWidth"
              nzPlaceHolder="Selecione"
              [nzShowArrow]="false">
              <nz-option *ngFor="let process of licensingStudyList"
                         [nzValue]="process.id"
                         [nzLabel]="process.description"></nz-option>
            </nz-select>

            <!-- <select [(ngModel)]="newLicensingProcessNecessaryStudy" id="licensingProcessNecessaryStudySelected"
                    class="gridSelectOption">
              <option class="userItem selectItem"
                      *ngFor="let process of licensingStudyList"
                      value="{{process.id}}"
                      label="{{process.description}}">
              </option>
            </select> -->
          </td>

          <!-- ESTIMATIVA DE CUSTO -->
          <td width="20%" colspan="2">
            <nz-input-number nzBorderless
                             [nzMin]="1"
                             [nzFormatter]="formatterReal"
                             [nzParser]="parserReal"
                             [(ngModel)]="newEstimatedCost"
                             class="formatNumberCost">
            </nz-input-number>
          </td>
        </tr>

        <!-- BOTOES SALVAR E CANCELAR -->
        <tr *ngIf="addNewNecessaryStudy || startEditEnvironmentStudy">
          <td></td>
          <td></td>
          <td colspan="2" style="display: flex; justify-content: flex-end;">
            <div (click)="cancelNewNecessaryStudyClick()">
              <button nz-button class="btn btn-green" class="btnCancelDiscipline">Cancelar</button>
            </div>
            <div *ngIf="!startEditEnvironmentStudy" (click)="addNewNecessaryStudyDB(editingStudyRow)">
              <button button nz-button nzType="primary" class="btnAddDiscipline">Adicionar</button>
            </div>
            <div *ngIf="startEditEnvironmentStudy" (click)="editNecessaryStudyClick(editingStudyRow)">
              <button button nz-button nzType="primary" class="btnAddDiscipline">Editar</button>
            </div>
          </td>
        </tr>
        <!-- FIM BLOCO PARA ADICIONAR NOVA DISCIPLINA -->

        <tr *ngIf="editingDiscipline === null && startEditEnvironmentStudy === false">
          <td colspan="4">
            <div class="addNewDisciplineButton" (click)="addNewNecessaryStudyClick()">
                <span class="project-doc-icon">
                  <img src="/assets/icons/icon_plus_circle.svg" alt="{{'projects.view.license.add-license'}}">
                </span>
              <span class="addNewDiscipline">Novo estudo</span>
            </div>
          </td>
        </tr>
        </tbody>
      </nz-table>
      <!-- FIM BLOCO ESTUDOS AMBIENTAIS NECESSARIOS -->

      <!-- INICIO BLOCO DOCUMENTOS NECESSARIOS -->
      <div class="cardLicensingStrategy">
        <p class="cardsTitle">
          {{ 'projects.consolidation-final.card-title-necessary-documents' | translate }}
        </p>
      </div>

      <!-- ALERTA -->
      <nz-alert *ngIf="fileList.length > 0" nzType="warning" id="alertWarning"
                nzDescription="{{ 'projects.consolidation-final.necessary-documents-add-documents' | translate }}"
                [nzIcon]="customIconTemplate" nzShowIcon class="mb-20 mr-10">
      </nz-alert>

      <nz-alert *ngIf="fileList.length === 0" nzType="error" id="alertDanger"
                nzDescription="{{ 'projects.consolidation-final.necessary-documents-add-documents' | translate }}"
                [nzIcon]="customIconTemplateError" nzShowIcon class="mb-20 mr-10">
      </nz-alert>

      <!-- ICONE CUSTOMIZADO DO ALERT -->
      <ng-template #customIconTemplate>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M12.0003 8.99996V13V8.99996ZM10.2503 4.18996L2.6303 18C2.45398 18.3053 2.36153 18.6519 2.36231 19.0045C2.36309 19.3571 2.45708 19.7032 2.63475 20.0078C2.81242 20.3124 3.06745 20.5645 3.374 20.7388C3.68054 20.913 4.02771 21.0031 4.3803 21H19.6203C19.9729 21.0031 20.3201 20.913 20.6266 20.7388C20.9332 20.5645 21.1882 20.3124 21.3659 20.0078C21.5435 19.7032 21.6375 19.3571 21.6383 19.0045C21.6391 18.6519 21.5466 18.3053 21.3703 18L13.7503 4.18996C13.5773 3.87735 13.3238 3.61679 13.0161 3.43534C12.7083 3.2539 12.3576 3.1582 12.0003 3.1582C11.643 3.1582 11.2923 3.2539 10.9845 3.43534C10.6768 3.61679 10.4233 3.87735 10.2503 4.18996Z"
            stroke="#E9AB13" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M12.0502 17H11.9502" stroke="#E9AB13" stroke-width="2.5" stroke-linecap="round"
                stroke-linejoin="round" />
        </svg>
      </ng-template>

      <ng-template #customIconTemplateError>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M12.0003 8.99996V13V8.99996ZM10.2503 4.18996L2.6303 18C2.45398 18.3053 2.36153 18.6519 2.36231 19.0045C2.36309 19.3571 2.45708 19.7032 2.63475 20.0078C2.81242 20.3124 3.06745 20.5645 3.374 20.7388C3.68054 20.913 4.02771 21.0031 4.3803 21H19.6203C19.9729 21.0031 20.3201 20.913 20.6266 20.7388C20.9332 20.5645 21.1882 20.3124 21.3659 20.0078C21.5435 19.7032 21.6375 19.3571 21.6383 19.0045C21.6391 18.6519 21.5466 18.3053 21.3703 18L13.7503 4.18996C13.5773 3.87735 13.3238 3.61679 13.0161 3.43534C12.7083 3.2539 12.3576 3.1582 12.0003 3.1582C11.643 3.1582 11.2923 3.2539 10.9845 3.43534C10.6768 3.61679 10.4233 3.87735 10.2503 4.18996Z"
            stroke="#EA575E" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M12.0502 17H11.9502" stroke="#EA575E" stroke-width="2.5" stroke-linecap="round"
                stroke-linejoin="round" />
        </svg>
      </ng-template>
      <!-- FIM ICONE CUSTOMIZADO -->

      <!-- LISTA DE DOCUMENTOS SELECIONADOS -->
      <div class="documents">
        <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="form-col">
          <nz-upload id="btnUpArquivoV2"
                     nzType="drag"
                     [nzMultiple]="true"
                     [nzBeforeUpload]="beforeUpload"
                     [(nzFileList)]="fileList"
                     [nzRemove]="removeFiles"
                     nzListType="picture"
                     [nzShowUploadList]="{showDownloadIcon: false,  showRemoveIcon: true}"
                     [nzDownload]="downloadFile"
                     class="uploadProjectFiles">
            <p class="ant-upload-drag-icon" #uploadInput>
              <img src="assets/icons/upload-icon.svg" width="33.45" height="33.33" alt="upload" />
            </p>
            <p class="ant-upload-hint">
              <span class="text-upload">Clique aqui ou arraste e solte o(s) arquivo(s) para fazer o upload</span>
            </p>
          </nz-upload>
        </div>

      </div>

    </div>




    <!-- ******************** CARD RESUMO DO PARECER ******************** -->
    <div nz-col class="rounded-row" (scroll)="onScroll()">
      <p class="cardsTitle">
        {{ 'projects.consolidation-final.card-title-consolidation-summary' | translate }}
      </p>


      <!-- CASEO NAO HAJA DADOS PARA EXIBIR -->
      <div *ngIf="!showResume" class="boxHeight"
           style="display: flex; align-items: center; justify-content: center;">
        <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="contentTpl"></nz-empty>
        <ng-template #contentTpl>
          <span [innerHTML]="'projects.requisition.resume.no.informations' | translate">
          </span>
        </ng-template>
      </div>

      <div *ngIf="showResume" style="margin-bottom: 30px;">

        <!-- AVALIACAO DA EQUIPE DE LICENCIAMENTO' -->
        <p class="cardsSumaryTitle">
          {{ 'projects.consolidation-final.card-title-licensing-team-assessment' | translate }}
        </p>

        <nz-descriptions class="alignVertical" id="antDescription">
          <nz-descriptions-item nzTitle="Restrições ambientais?" class="fieldName">
            <span class="fieldDescription">
              {{ resumeData.environmentalRestrictions ? (resumeData.environmentalRestrictions) : null }}
            </span>
          </nz-descriptions-item>
        </nz-descriptions>

        <nz-descriptions class="alignVertical" id="antDescription">
          <nz-descriptions-item nzTitle="Quais as restrições ambientais?" class="fieldName">
            <span class="fieldDescription" *ngIf="resumeData.listOfRestrictions  && resumeData.listOfRestrictions.length >= 1">
              <div *ngFor="let item of resumeData.listOfRestrictions" style="margin-top: 4px !important;">
                <p style="line-height: 15px !important;">- {{ item }}</p>
              </div>
            </span>
            <span class="fieldDescription" *ngIf="resumeData.listOfRestrictions && resumeData.listOfRestrictions.length === 0">
                <p>-</p>
            </span>
          </nz-descriptions-item>
        </nz-descriptions>

        <nz-descriptions class="alignVertical" id="antDescription">
          <nz-descriptions-item nzTitle="Prioritário por questões legais?" class="fieldName">
            <span class="fieldDescription">
              {{ resumeData.securityQuestions ? (resumeData.securityQuestions) : null }}
            </span>
          </nz-descriptions-item>
        </nz-descriptions>

        <nz-descriptions class="alignVertical" id="antDescription">
          <nz-descriptions-item nzTitle="Demanda por licenciamento?" class="fieldName">
            <span class="fieldDescription">
              {{ resumeData.demandByLicensing ? (resumeData.demandByLicensing) : null }}
            </span>
          </nz-descriptions-item>
        </nz-descriptions>



        <!-- PROCESSO DE LICENCIAMENTO -->
        <p class="cardsSumaryTitle mt-24">
          {{ 'projects.consolidation-final.card-resume-necessary-process' | translate }}
        </p>

        <!-- TABELA PROCESSOS DE LICENCIAMENTO -->
        <nz-table nzTemplateMode nzSize="small" class="tableTh mt-20" #table>
          <thead>
          <tr>
            <th class="tableThSummary">Órgão</th>
            <th class="tableThSummary">Processo de licenciamento</th>
          </tr>
          </thead>
          <tbody>
          <tr id="riplaInfo" *ngFor="let licensing of licensingStrategy">

            <td class="tableSummary" [width]="'40%'">
                <span class="tableSummary">
                  {{ licensing.competentOrganName ? licensing.competentOrganName : '' }}
                </span>
            </td>

            <td class="tableSummary" [width]="'60%'">
              <div class="tableSummary" id="">
                {{ licensing.licenseName ? licensing.licenseName : '' }}
              </div>
            </td>

          </tr>
        </nz-table>



        <!-- ESTUDOS NECESSARIOS -->
        <p class="cardsSumaryTitle mt-40">
          {{ 'projects.consolidation-final.card-resume-necessary-study' | translate }}
        </p>

        <!-- TABELA DE ESTUDOS NECESSARIOS -->
        <nz-table nzTemplateMode nzSize="small" class="mt-20">
          <thead>
          <tr>
            <th class="tableThSummary">Descrição</th>
            <th class="tableThSummary">Processo de licenciamento</th>
          </tr>
          </thead>
          <tbody>
          <tr id="riplaInfo" *ngFor="let study of environmentalStudiesList">

            <td class="tableSummary" [width]="'50%'">
                <span class="tableSummary">
                  {{ study.description }}
                </span>
            </td>

            <td class="tableSummary" [width]="'50%'">
              <div class="tableSummary" id="">
                {{ study.license ? study.license : 'A definir' }}
              </div>
            </td>

          </tr>
        </nz-table>


        <!-- ESTIMATIVA DE CUSTOS -->
        <p class="cardsSumaryTitle mt-40">
          {{ 'projects.consolidation-final.card-resume-estimated-cost' | translate }}
        </p>

        <!-- TABELA DE ESTIMATIVA DE CUSTOS -->
        <nz-table nzTemplateMode nzSize="small" class="mt-20">
          <thead>
          <tr>
            <th class="tableThSummary">Processo de licenciamento</th>
            <th class="tableThSummary">Custo estimado</th>
            <th class="tableThSummary"></th>
          </tr>
          </thead>
          <tbody>
          <tr id="riplaInfo" *ngFor="let cost of resumeData.estimatedCostForStudies">
            <td class="tableSummary" [width]="'70%'">
                <span class="tableSummary">
                  {{ cost.category }}
                </span>
            </td>

            <td class="tableSummary" [width]="'29%'">
              <div class="tableSummary" id="">
                R$ {{ cost.costs.total | number:'1.2-2':'pt' }}
              </div>
            </td>
            <td class="tableSummary" [width]="'10px'">
              <div class="tableSummary" id="">
                <button class="buttonTooltip" [nzTooltipColor]="'#2E2E2E'" [nzTooltipTrigger]="'hover'" [nzTooltipOverlayStyle]=customStyle [nzTooltipTitle]="toolTipTitleTemplate" nzTooltipPlacement="bottomRight" nz-button nz-tooltip>
                  <img src="assets/icons/icon_information.svg" class="mr-10 icone" alt="">
                </button>
                <ng-template #toolTipTitleTemplate>
                  <div>
                    <div class="tooltipTitle">{{cost.category}}</div>
                    <div class="tooltipContent" *ngFor="let costitem of cost.costs">
                      <div>
                        {{ costitem.description}}
                      </div>
                      <div>
                        R$ {{ costitem.cost | number:'1.2-2':'pt'  }}
                      </div>
                    </div>
                  </div>

                </ng-template>

              </div>

            </td>
          </tr>

          <!-- COMPENSAÇÕES -->
          <tr id="riplaInfo" *ngFor="let cost of listOfCosts">
            <td class="tableSummary" [width]="'70%'">
                <span class="tableSummary">
                  Compensações
                </span>
            </td>

            <td class="tableSummary" [width]="'29%'">
              <div class="tableSummary" id="">
                R$ {{ cost.compensations.cost | number:'1.2-2':'pt' }}
              </div>
            </td>
            <td class="tableSummary" [width]="'10px'">
              <div class="tableSummary" id="" *ngIf="cost.compensations.itens.length > 0">
                <button class="buttonTooltip" [nzTooltipColor]="'#2E2E2E'" [nzTooltipTrigger]="'hover'" [nzTooltipOverlayStyle]=customStyle [nzTooltipTitle]="toolTipTitleTemplate" nzTooltipPlacement="bottomRight" nz-button nz-tooltip>
                  <img src="assets/icons/icon_information.svg" class="mr-10 icone" alt="">
                </button>
                <ng-template #toolTipTitleTemplate>
                  <div>
                    <div class="tooltipTitle">Compensações</div>
                    <div class="tooltipContent" *ngFor="let costitem of cost.compensations.itens">
                      <div>
                        {{ costitem.title}}
                      </div>
                      <div>
                        R$ {{ costitem.value | number:'1.2-2':'pt'  }}
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </td>
          </tr>

          <!-- OUTROS CUSTOS -->
          <tr id="riplaInfo" *ngFor="let cost of listOfCosts">
            <td class="tableSummary" [width]="'70%'">
                <span class="tableSummary">
                  Outros
                </span>
            </td>

            <td class="tableSummary" [width]="'29%'">
              <div class="tableSummary" id="">
                R$ {{ cost.otherCosts.cost | number:'1.2-2':'pt' }}
              </div>
            </td>
            <td class="tableSummary" [width]="'10px'">
              <div class="tableSummary" id=""  *ngIf="cost.otherCosts.itens.length > 0">
                <button class="buttonTooltip" [nzTooltipColor]="'#2E2E2E'" [nzTooltipTrigger]="'hover'" [nzTooltipOverlayStyle]=customStyle [nzTooltipTitle]="toolTipTitleTemplate" nzTooltipPlacement="bottomRight" nz-button nz-tooltip>
                  <img src="assets/icons/icon_information.svg" class="mr-10 icone" alt="">
                </button>
                <ng-template #toolTipTitleTemplate>
                  <div>
                    <div class="tooltipTitle">Outros</div>
                    <div class="tooltipContent" *ngFor="let costitem of cost.otherCosts.itens">
                      <div>
                        {{ costitem.title}}
                      </div>
                      <div>
                        {{ costitem.value | number:'1.2-2':'pt'  }}
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </td>
          </tr>

          <!-- TOTAL DE ESTIMATIVA DE CUSTOS -->
          <tr>
            <td class="tableSummary" [width]="'70%'">
              <span class="tableSummary bold">
                Total
              </span>
            </td>
            <td class="tableSummary" [width]="'26%'" colspan="2">
              <div class="tableSummary bold" id="">
                R$ {{ totalCosts | number:'1.2-2':'pt' }}
              </div>
            </td>
          </tr>
        </nz-table>


        <!-- DOCUMENTOS NECESSARIOS -->
        <p class="cardsSumaryTitle mt-40">
          {{ 'projects.consolidation-final.card-title-necessary-documents' | translate }}
        </p>

        <!-- LISTA DE DOCUMENTOS PARA DOWNLOAD -->
        <div class="fileList ant-upload-list-item-name">
          <a class="fileLink"
             *ngFor="let attachment of fileList"
             nz-popover
             (click)="downloadFile(attachment)"
             nzPopoverContent="{{ getHintText(attachment.name) }}">
            <img src="assets/icons/uploaded_document-icon.svg" alt="" class="mr-10">
            <span class="fileName">
              {{
                attachment.name.length  > 50 ? sliceAttachment(attachment.name) :  attachment.name
              }}
            </span>
          </a>
        </div>
      </div>
    </div>

  </div>

  <div class="spinner" *ngIf="loadingSubProjects">
    <nz-spin nzSimple id="spinner"></nz-spin>
  </div>
