<div class="licensing-form">
  <section class="licensing-form-wrapper">
    <div> <h2 class="h2-title">{{ 'projects.processes.processes-title' | translate }} <span class="break-space h2-title">{{ 'projects.processes.licenses-title' | translate }}</span></h2> </div>
    <nz-table
      [nzData]="['']"
      [nzFrontPagination]="false"
      class="sl-table fillable-form"
    >
      <thead>
        <tr>
          <th class="tableTh subProjectHeaderTitle">
            {{ 'licensing-process.creation-grid.organ' | translate }} <span class="mandatory-indicator">*</span>
          </th>
          <th class="tableTh subProjectHeaderTitle">
            {{ 'licensing-process.creation-grid.license' | translate }} <span class="mandatory-indicator">*</span>
          </th>
          <th class="tableTh subProjectHeaderTitle">
            {{ 'licensing-process.creation-grid.process-number' | translate }}
          </th>
          <th class="tableTh subProjectHeaderTitle">
            {{ 'licensing-process.creation-grid.responsible' | translate }}
          </th>
          <th class="tableTh subProjectHeaderTitle">
            {{ 'licensing-process.creation-grid.conclusion-date' | translate }}
          </th>
          <th class="tableTh subProjectHeaderTitle">
            {{ 'licensing-process.creation-grid.protocol-date' | translate }}
          </th>
          <th class="tableTh subProjectHeaderTitle">
            {{ 'licensing-process.creation-grid.process-status' | translate }}
          </th>
          <th class="tableTh subProjectHeaderTitle divider-cell"> </th>
          <th class="tableTh subProjectHeaderTitle">
            {{ 'licensing-process.creation-grid.document-number' | translate }}
          </th>
          <th class="tableTh subProjectHeaderTitle">
            {{ 'licensing-process.creation-grid.emission' | translate }}
          </th>
          <th class="tableTh subProjectHeaderTitle">
            {{ 'licensing-process.creation-grid.validity' | translate }}
          </th>
          <th class="tableTh subProjectHeaderTitle">
            {{ 'licensing-process.creation-grid.boundary-date' | translate }}
          </th>
          <th class="tableTh subProjectHeaderTitle">
            {{ 'licensing-process.creation-grid.license-status' | translate }}
          </th>

        </tr>
      </thead>

      <tbody>
        <tr>
          <td>
            <span>{{ process.competentOrgan.code }}</span>
          </td>
          <td>
          </td>
          <td>
            <span>{{ process.processNumber ? process.processNumber : 'Não protocolado'}}</span>
          </td>
          <td>
            <span>{{ (process.responsible ? process.responsible.name : '-') | titlecase}}</span>
          </td>
          <td>
            <span class="txt-warn">{{ process.deadline ? process.deadline : 'Pendente'}}</span>
          </td>
          <td>
            <span class="txt-warn">{{ process.protocolDate ? process.protocolDate : 'Não protocolado'}}</span>
          </td>
          <td>
            <app-badge [badgeText]="process.status"></app-badge>
          </td>
          <td>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0.292893 11.7071C-0.0976311 11.3166 -0.0976312 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976316 1.31658 -0.0976316 0.683417 0.292893 0.292893C0.683417 -0.0976315 1.31658 -0.0976315 1.70711 0.292893L7.41421 6L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071Z"
                fill="#969696" />
            </svg>
          </td>
          <td>{{ process.documentNumber ? process.documentNumber : 'Não emitida'}}</td>
          <td>{{ process.licenseEmissionDate ? process.licenseEmissionDate : 'Não emitida'}}</td>
          <td>{{ process.renewalDeadlineDate ? process.renewalDeadlineDate : 'Não emitida'}}</td>
          <td>{{ process.effectiveDate ? process.effectiveDate : 'Não emitida'}}</td>
          <td>
          </td>
        </tr>
      </tbody>
    </nz-table>

  </section>
</div>
