<form
  nz-form
  [formGroup]="form">
  <ng-container *ngIf="flagNewSection">
    <div nz-row>
      <div
        nz-col
        nzXs="24"
        nzSm="24"
        nzMd="24"
        nzLg="24"
        nzXl="24"
        class="form-col">
        <nz-form-item class="sl-form-item">
          <nz-form-label
            [nzSm]="24"
            [nzXs]="24"
            nzFor="nome"
            nzRequired>
            <span class="sl-form-label">Nome do documento</span>
          </nz-form-label>
          <nz-form-control
            [nzSm]="24"
            [nzXs]="24"
            nzErrorTip="Digite o nome do documento">
            <input
              nz-input
              id="txtName"
              formControlName="nome"
              nzBorderless
            />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

  </ng-container>

  <div nz-row>
    <div
      nz-col
      nzXs="24"
      nzSm="24"
      nzMd="24"
      nzLg="24"
      nzXl="24"
      class="form-col">
      <nz-form-item class="sl-form-item">
        <nz-form-label
          [nzSm]="24"
          [nzXs]="24"
          nzFor="title"
          nzRequired>
          <span class="sl-form-label">Título da seção:</span>
        </nz-form-label>
        <nz-form-control
          [nzSm]="24"
          [nzXs]="24"
          nzErrorTip="Digite o título da seção">
          <input
            nz-input
            maxlength="100"
            id="txtTitle"
            formControlName="title"
            nzBorderless
          />
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>


  <div nz-row>
    <div
      nz-col
      nzXs="24"
      nzSm="24"
      nzMd="24"
      nzLg="24"
      nzXl="24"
      class="form-col">
      <nz-form-item class="sl-form-item">
        <nz-form-label
          [nzSm]="24"
          [nzXs]="24"
          nzFor="content">
          <span class="sl-form-label">Conteúdo da Seção</span>
        </nz-form-label>
        <nz-form-control
          [nzSm]="24"
          [nzXs]="24">
          <nz-textarea-count [nzMaxCharacterCount]="10000">
            <textarea
              rows="17"
              formControlName="content"
              nz-input
            ></textarea>
          </nz-textarea-count>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

</form>

<div class="ant-modal-footer">
  <div nz-row>
    <div nz-col
         nzXs="18"
         nzSm="18"
         nzMd="18"
         nzLg="18"
         nzXl="18"
         [nzSpan]="18" [nzPush]="6">
      <button class="btn-sl btn-sl-grey btn-cancel"
              (click)="showCancelConfirm()">Cancelar
      </button>
      <button nz-button nzType="primary"
              class="btn-sl btn-sl-green btn-save"
              (click)="submitForm()">Salvar
      </button>

    </div>
    <div nz-col [nzSpan]="6" [nzPull]="18"></div>
  </div>
</div>

