import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface IselectMultipleTag {
  id?: number;
  description: any;
}

@Component({
  selector: 'app-select-multiple-tag',
  templateUrl: './select-multiple-tag.component.html',
  styleUrls: ['./select-multiple-tag.component.scss']
})
export class SelectMultipleTagComponent implements OnInit {
  
  @Input() controls: any;
  @Input() id: string = '';
  @Input() name: string = '';
  @Input() required: boolean = true;
  @Input() placeHolder: string = '';
  @Input() errorMessage: string = '';
  @Input() maxTagCount: number = 3;
  @Input() status: any = ' ' || 'error' || 'warning';
  @Input() listOfOption: Array<IselectMultipleTag> = [];

  @Output() changeValue: EventEmitter<any> = new EventEmitter<any>();
 
  constructor() {}
  
  ngOnInit(): void {return;}

  compareFn = (o1: any, o2: any) => (o1 && o2 ? o1.description == o2.description : false);

  onChangeValue(event: any[]): void {
    let lastIndex = event.length - 1;

    if (typeof event[lastIndex] === "string") {
      let lastDescription = event[lastIndex];
      event[lastIndex] = {id: Math.random(), description: lastDescription}
      this.changeValue.emit(event);
    } else {
      this.changeValue.emit(event);
    }
   
  }
  
}
