import { Subject } from "rxjs";
import { Location } from "@angular/common";
import { AfterContentChecked, AfterContentInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { NzModalService } from "ng-zorro-antd/modal";

import { MessageService } from "src/app/shared/common/services/message.service";
import { CommunicationByIdResponse } from "src/app/shared/dto/communication-by-id-response.dto";
import {UtilService} from "../../../shared/util.service";
import { ProjectState } from "src/app/shared/ngrx/breadcrumb/model";
import { BREADCRUMB_ACTIONS } from "src/app/shared/ngrx/breadcrumb/breadcrumb.actions";
import { BreadcrumbState } from "src/app/shared/ngrx/breadcrumb/breadcrumb.state.model";
import { Store } from "@ngrx/store";
import { NzUploadFile } from "ng-zorro-antd/upload";
import { AttachmentsModel } from "src/app/shared/models/attachments.model";

@Component({
  selector: 'app-communications-form',
  templateUrl: './communications-form.component.html',
  styleUrls: ['./communications-form.component.scss']
})
export class CommunicationsFormComponent implements OnInit, AfterContentInit, AfterContentChecked, OnDestroy {

  private readonly destroy$: Subject<any> = new Subject();

  pageTitle: string = '';
  pageId: string = '';
  communicationID: string = '';
  isSaving: Subject<boolean> = new Subject();
  communication: Subject<CommunicationByIdResponse> = new Subject();
  communicationAttachments: AttachmentsModel[] = [];
  cadCommunicationsInsert: boolean = false;
  cadCommunicationsEdit: boolean = false;

  constructor(
    private location: Location,
    private cdref: ChangeDetectorRef,
    private modal: NzModalService,
    private message: MessageService,
    private utilService: UtilService,
    private store: Store<BreadcrumbState>
  ) {
    this.pageTitle = '';
    this.pageId = '';
    this.communicationID = '';
  }


  ngOnInit(): void {
    this.dispatchProjectOnStore()
  }

  ngAfterContentChecked(): void {
    this.cadCommunicationsInsert = this.utilService.getAuthorization('insert', 'CADCOMMUNICATIONS')
    this.cadCommunicationsEdit = this.utilService.getAuthorization('insert', 'CADCOMMUNICATIONS')
    this.cdref.detectChanges();
  }

  changeData(communication: CommunicationByIdResponse) {
    this.communication.next(communication);
  }

  attachmentsUpdated(attachments: NzUploadFile[]) {
    this.communicationAttachments = attachments.map(attach => {
      return this.utilService.buildAttachmentModelFromNzUpload(attach);
    })
  }

  save() {
    this.isSaving.next(true);
  }

  goBack() {
    this.location.back();
  }

  ngAfterContentInit(): void {
    this.communication.subscribe(c => {
      this.pageTitle = c.title + '';
      this.pageId = c.identificationNumber !== undefined ? c.identificationNumber : '';
    });
  }

  showCancelConfirm(): void {
    this.modal.confirm({
      ...(this.message.showMessageModalConfirmCancel() as any),
      nzOnOk: () => {
        this.goBack();
      },
    });
  }

  onRemoveFocusInput() {
    document.body.click();
  }

  
  // INICIO BREADCRUMB

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }


  private dispatchProjectOnStore(): void {
    let project: ProjectState = {
      name: 'Comunicações com órgãos',
      url: '/communications',
      last: false,
      sublevels: true,
      project: null,
      subProject: {
        name: 'Nova comunicação', 
        url: '',
        last: true,
      },
      parecerFinal: null,
      pedidoRevisao: null,
      comunicacoes: null,
      processos: null,
      atividades: null,
      subatividades: null,
      mudancaEscopo: null
    }
    this.store.dispatch(BREADCRUMB_ACTIONS.getRiplaProject({project}));
  }
  // FINAL BREADCRUMB

  
}
