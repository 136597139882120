-- TBD --
<h2>Últimos 7 dias</h2>

<div
  class="content-line"
  *ngFor="let item of listItems"
  id="notification-all7-{{ item.id }}"
>
  <div class="title-notification">
    {{ item.type }} foi criado por <span class="user">{{ item.sentTo }}</span>
  </div>
  <div class="project-notification">{{ item.entityName }}</div>
  <div class="date-notification">{{ item.dateTimeNotification }}</div>
</div>

<h2>Mais antigas</h2>

<div
  class="content-line"
  *ngFor="let item of listItems"
  id="notification-all7-{{ item.id }}"
>
  <div class="title-notification">
    {{ item.type }} foi criado por <span class="user">{{ item.sentTo }}</span>
  </div>
  <div class="project-notification">{{ item.entityName }}</div>
  <div class="date-notification">{{ item.dateTimeNotification }}</div>
</div>
