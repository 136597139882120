<div class="filter-content">
  <div nz-row>
    <div nz-col
         [nzXs]="24"
         [nzSm]="24"
         [nzMd]="24"
         [nzLg]="12"
         [nzXl]="12">
      <div class="input-group">
        <ng-template #prefixIconSearch>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 17L13.1422 13.1422M15.2222 8.11111C15.2222 9.51756 14.8052 10.8924 14.0238 12.0618C13.2424
        13.2312 12.1318 14.1427 10.8324 14.6809C9.53303 15.2191 8.10322 15.36 6.7238 15.0856C5.34438 14.8112
        4.07731 14.1339 3.0828 13.1394C2.08829 12.1449 1.41102 10.8778 1.13664 9.49842C0.862257 8.119 1.00308
        6.68919 1.5413 5.38981C2.07953 4.09042 2.99098 2.97982 4.16039 2.19844C5.32981 1.41706 6.70467 1 8.11111
        1C9.9971 1 11.8058 1.7492 13.1394 3.0828C14.473 4.41639 15.2222 6.22513 15.2222 8.11111Z" stroke="#007E7A"
                  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </ng-template>
        <nz-input-group class="search-input" [nzPrefix]="prefixIconSearch">
          <input type="text"
                 id="txtSearch"
                 nz-input
                 placeholder="Pesquisar..."
                 [(ngModel)]="search"
                 (input)="searchFilter()"/>
        </nz-input-group>
      </div>
    </div>
  </div>
</div>

<div class="wrapper">


  <nz-card id="nlp-report-body">

    <div class="report-all-sections"
         *ngIf="!isProductNlpEmpty"
         id="nlp-report-pdf">
      <h1>Relatório de Análise</h1>
      <nz-divider></nz-divider>
      <div class="report-section"
           *ngFor="let section of reportNlp?.resultSectionNlpAndTrResponseList">
        <ng-container *ngIf="section.resultSections && section.resultSections.statusColor !== 'secaoNaoEncontrada'">
          <h2>{{section.resultSections.sectionTitle}}</h2>
          <div class="section-body" *ngIf="section.resultSections.sectionAnalysis">
            <div nz-row>
              <div nz-col nzSpan="8">Responsável:
                <b>{{section.resultSections.sectionAnalysis.userName}}</b>
              </div>
              <div nz-col nzSpan="8">Data/Hora: teste
                <b>{{getTimeZoneDate(section.resultSections.sectionAnalysis.analysisDate) | date: 'dd/MM/yyyy HH:mm'}}</b>
              </div>
              <div nz-col nzSpan="8">Status:
                <b
                  class="{{section.resultSections.sectionAnalysis.isApproved | lowercase}}">{{section.resultSections.sectionAnalysis.isApproved|titlecase}}</b>
              </div>
            </div>
            <div nz-row class="mt-20">
              <div nz-col nzSpan="24">Observações:</div>
            </div>
            <div nz-row class="mt-10">
              <div nz-col nzSpan="24">
                <p>{{section.resultSections.sectionAnalysis.comments}}</p>
              </div>
            </div>
          </div>
          <div class="section-body" *ngIf="!section.resultSections.sectionAnalysis">
            <div nz-row>
              <div nz-col nzSpan="8"></div>
              <div nz-col nzSpan="8"></div>
              <div nz-col nzSpan="8">Status:
                <b class="na">Não analisado</b>
              </div>
            </div>
          </div>
          <nz-divider></nz-divider>
        </ng-container>
      </div>

    </div>


  </nz-card>
</div>
