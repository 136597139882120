import { Component } from '@angular/core';

@Component({
  selector: 'app-project-status-legend',
  templateUrl: './project-status-legend.component.html',
  styleUrls: ['./project-status-legend.component.scss']
})
export class ProjectStatusLegendComponent {

  constructor() { }

}
