import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NlpTermReferenceModel } from 'src/app/shared/models/nlp-term-reference.model';
import { NLP_SELECT_TERM_REFERENCE_API, NLP_TERM_REFERENCE_LIST_API } from "../../../../projects/constants/apiEndpointsConstants";
import { SelectTermReferenceDto } from 'src/app/shared/dto/select-term-reference.dto';

@Injectable({
  providedIn: 'root'
})
export class NlpModalSelectTermReferenceService {

  flagDocumentSection = BehaviorSubject
  documentFlagSubject: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);
  message$ = this.documentFlagSubject.asObservable();

  constructor(private readonly http: HttpClient) {
  }

  getTermReferenceList(): Observable<NlpTermReferenceModel[]> {
    return this.http.get<NlpTermReferenceModel[]>(`${NLP_TERM_REFERENCE_LIST_API}`);
  }

  postSelectTermReference(selectTermReference: SelectTermReferenceDto): Observable<any> {
    return this.http.post<any>(`${NLP_SELECT_TERM_REFERENCE_API}`, selectTermReference);
  }
}
