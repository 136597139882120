import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ProcessesListResponseDto } from 'src/app/shared/dto/process-list-response.dto';

@Component({
  selector: 'app-form-new-linked-processes',
  templateUrl: './form-new-linked-processes.component.html',
  styleUrls: ['./form-new-linked-processes.component.scss']
})
export class FormNewLinkedProcessesComponent implements OnInit {
  @Input() selectedItem?;
  @Input() processesList: ProcessesListResponseDto[];
  @Input() processName!:any;
  @Output() delete: EventEmitter<number> = new EventEmitter<number>();
  @Output() saved: EventEmitter<any> = new EventEmitter<any>();
  form!: UntypedFormGroup;

  linkTypes = [
    {
      id: 1,
      type: "REN",
      name: "É uma renovação de"
    },
    {
      id: 2,
      type: "PRE",
      name: "Precisa de"
    },
    {
      id: 3,
      type: "NEC",
      name: "É necessária para"
    },
  ];

  constructor(private readonly fb: UntypedFormBuilder) { }


  ngOnInit() {
    this.initForm();
  }

  initForm(){
    let process = this.processName;
    let linkType = '';
    let linkedProcess = ''

    if (this.selectedItem) {
      linkType = this.selectedItem.linkType;
      linkedProcess = this.selectedItem.linkedProcess;
    }

    this.form = this.fb.group({
      process: [process],
      linkType: [linkType, [Validators.required]],
      linkedProcess: [linkedProcess, [Validators.required]],
    });

    this.form.controls['process'].disable();
  }


  
  deleteItem(){
    this.delete.emit(this.selectedItem.id);
  }

  handleSave(){
    if(!this.form.valid){
      Object.values(this.form.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });

      return;
    }

    const body = {
      linkedProcess: this.form.controls['linkedProcess'].value,
      linkType:this.form.controls['linkType'].value,
      id: this.selectedItem.id
    }
    this.saved.emit(body);
  }

  handleCancel(){
    //show popover confirm
  }
}
