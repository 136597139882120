<ul nz-menu nzMode="horizontal">
  <li nz-menu-item routerLinkActive="nzSelected" routerLink="/projects" [routerLinkActiveOptions]="{ exact: false }" >
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="#555" stroke-width="2"><path d="M9 19H2a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h5l2 2h9a1 1 0 0 1 1 1v1" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 19h9 0a1 1 0 0 0 1-1h0v-7"/><path d="M15 1H4a1 1 0 0 0-1 1v5h4l2 2h8V3l-2-2zm0 0v2h2l-2-2z" stroke-linejoin="round"/></svg>
    Projetos
  </li>
  <nz-divider nzType="vertical"></nz-divider>
  <li nz-menu-item routerLinkActive="nzSelected" routerLink="/environmental-studies" >
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="#555" stroke-width="2" stroke-linejoin="round"><path d="M6 1h12a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-3" stroke-linecap="round"/><path d="M15 10v5-5zm-4 5v-2 2zM1 13l2.83-2.83L1 13zm5-8a3 3 0 0 0-2.772 1.852 3 3 0 0 0 .65 3.269 3 3 0 0 0 3.269.65A3 3 0 0 0 9 8a3 3 0 0 0-3-3z"/></svg>
    Estudos Ambientais
  </li>
  <nz-divider nzType="vertical"></nz-divider>
  <li nz-menu-item routerLinkActive="nzSelected" routerLink="/licensing" >
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="#555" stroke-width="2"><path d="M9 19H2a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h5l2 2h9a1 1 0 0 1 1 1v1m-6 6l2 2 4-4" stroke-linecap="round" stroke-linejoin="round"/><path d="M15 1H4a1 1 0 0 0-1 1v5h4l2 2h8V3l-2-2zm0 0v2h2l-2-2z" stroke-linejoin="round"/></svg>
    Licenciamentos
  </li>
  <nz-divider nzType="vertical"></nz-divider>
  <li nz-menu-item routerLinkActive="nzSelected" routerLink="/map" >
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" stroke="#555" fill="none"><path d="M13 17.471l-6-2.059V1l6 2.059v14.412zm0-14.412L7 1 1 3.059v14.412l6-2.059 6 2.059 6-2.059V1l-6 2.059z" stroke-width="2" stroke-linejoin="round"/></svg>
    Mapa
  </li>
</ul>
