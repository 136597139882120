import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, type OnInit} from '@angular/core'
import {ActivePageService} from 'src/app/shared/common/services/active-page/active-page.service';
import {TopBreadcrumbService} from 'src/app/shared/common/services/top-breadcrumb/top-breadcrumb.service';
import {UserService} from 'src/app/shared/services/user.service';
import {NzTableFilterFn, NzTableFilterList, NzTableSortFn, NzTableSortOrder} from 'ng-zorro-antd/table';
import {TranslateService} from '@ngx-translate/core';
import {ProjectsV2Service} from 'src/app/shared/services/projects-v2.service';
import {Projects} from 'src/app/shared/models/projects-v2.model';
import {Router} from '@angular/router';
import {UserModel} from "../../shared/models/user.model";
import {MessageService} from "../../shared/common/services/message.service";
import { DateCalcHelper } from 'src/app/shared/helpers/date-calc.helper';
import {UtilService} from "../../shared/util.service";
import { Subject } from 'rxjs';
import { BreadcrumbState } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';
import { Store } from '@ngrx/store';
import { ProjectState } from 'src/app/shared/ngrx/breadcrumb/model';
import { BREADCRUMB_ACTIONS } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';

interface ColumnItem {
  name: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<Projects> | null;
  listOfFilter: NzTableFilterList;
  filterFn: NzTableFilterFn<Projects> | null;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[];
}

@Component({
  selector: 'app-projects-v2',
  templateUrl: './projects-v2.component.html',
  styleUrls: ['./projects-v2.component.scss']
})
export class ProjectsV2Component implements OnInit, AfterViewInit, OnDestroy {

  private readonly destroy$: Subject<any> = new Subject();
  stateStore: any[];

  isAddProjectVisible: boolean = false;
  isEditProjectVisible: boolean = false;
  isConfirmationVisible: boolean = false;
  value!: string;
  projectsList!: Projects[];
  dataList!: Projects[];
  request: any;
  totalElements: number = 0;
  totalPages!: number;
  pageSize: number = 0;
  pageIndexProject: number = 1;
  buttonActions;
  user: any;

  screenWidth: number = 0

  filterByStatus: string = '';

  customStyle = {
    background: 'white',
    'font-size': '12px',
  };
  popOverNotification: boolean = false;
  filterCorredor: any[] = [];
  filterComplexo: any[] = [];
  filterUnidadeOperacional: any[] = [];
  filterStatus: any[] = [];
  filterValues = {
    projectName: '',
    corredor: [] as string[] | null,
    complex: [] as string[] | null,
    operationalUnit: [] as string[] | null,
    status: [] as string[] | null,
  }
  isCheckedCorredor: boolean = false;
  private loggedUser: UserModel;


  constructor(private activePage: ActivePageService,
              private topBreadcrumb: TopBreadcrumbService,
              private projectsService: ProjectsV2Service,
              private readonly userService: UserService,
              private readonly utilService: UtilService,
              private translate: TranslateService,
              private messageService: MessageService,
              private cdr: ChangeDetectorRef,
              private _router: Router,
              public dateCalc: DateCalcHelper,
              private store: Store<BreadcrumbState>,
  ) {
  }

  currentLanguage: string = 'pt';

  listOfColumns: ColumnItem[] = [
    {
      name: 'Data de Cadastro',
      sortOrder: null,
      sortFn: (a: Projects, b: Projects) => a.registrationDate.localeCompare(b.registrationDate),
      listOfFilter: this.filterStatus,
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: false,
      filterFn: (list: string[], item: Projects) => list.some(name => item.registrationDate.indexOf(name) !== -1),
    },
  ];

  listOfData: any = [];

  listOfDataOriginal: Projects[] = [];
  listOfDataFiltered: Projects[] = []
  searchValue = '';
  visible = false;
  visibleCorredor = false;
  visibleComplexo = false;
  visibleUnidadeOperacional = false;
  visibleStatus = false;
  selectedCorredor = [];
  riplarequisition: boolean = false;
  cadProcessLicensing: boolean = false;
  allChecked = false;
  indeterminate = true;

  ngOnInit(): void {
    //this.changeLanguage()
    this.projectsService.setProjectToCreateRipla(null);
    this.activePage.setPage('projects');
    const userRecuperado = sessionStorage.getItem('user');
    this.user = userRecuperado ? JSON.parse(userRecuperado) : null;
    this.riplarequisition = this.utilService.getAuthorization('insert', 'RIPLAREQUISITION');
    this.topBreadcrumb.reorgLevels({
      level: 0,
      title: 'Projetos',
      route: '/projects'
    });
    this.checkLoggedUser();
  }

  private checkLoggedUser() {
    const loogedUser = sessionStorage.getItem("loggedUser");
    if (!loogedUser) {
      this.userService.getLoogedUser().subscribe((data) => {
        if (data) {
          this.loggedUser = data;
          sessionStorage.setItem('loggedUser', JSON.stringify(this.loggedUser));
          this.getProjectList();
        }
      });
    } else {
      this.loggedUser = JSON.parse(loogedUser);
      this.getProjectList();
    }
  }

  ngAfterViewInit(): void {
    const elemento: any = document.querySelector('.ant-spin-nested-loading');
    elemento.classList.add('height-spin-custom');
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }


  private dispatchProjectOnStore(): void {
      let project: ProjectState = {
      name: 'Projetos',
      url: '/projects-v2',
      last: true,
      sublevels: false,
      project: null,
      subProject: null,
      parecerFinal: null,
      pedidoRevisao: null,
      comunicacoes: null,
      processos: null,
      atividades: null,
      subatividades: null,
      mudancaEscopo: null
    }
    this.store.dispatch(BREADCRUMB_ACTIONS.getRiplaProject({ project }));
  }


  returnHeightOnResolution(): string {
    let width = window.screen.width;
    if (width > 1366) {
      return '620px'
    } else {
      return '500px'
    }
  }

  changeLanguage(): void {
    if (this.currentLanguage == 'pt') {
      this.translate.use('pt');
      this.currentLanguage = 'pt';
    } else {
      this.translate.use('en');
      this.currentLanguage = 'en';
    }
  }

  trackByName(_: number, item: ColumnItem): string {
    return item.name;
  }

  createFilters() {
    const addToFilterIfNotExist = (filterArray, element, property) => {
      const newElement = {
        label: element[property],
        value: element[property],
        checked: false,
        type: property
      };
      const existing = filterArray.some(result => result.label === element[property]);
      if (!existing && element[property]) {
        filterArray.push(newElement);
      }
    };

    this.listOfData.forEach(element => {
      addToFilterIfNotExist(this.filterCorredor, element, 'corridor');
      addToFilterIfNotExist(this.filterComplexo, element, 'complex');
      addToFilterIfNotExist(this.filterUnidadeOperacional, element, 'operationalUnit');

      const statusDescriptions = {
        RAS: 'Rascunho',
        ENV: 'Requisição Enviada',
        EAN: 'Em andamento',
        INI: 'A iniciar',
        REV: 'Em revisão',
        CAN: 'Cancelado',
        CON: 'Concluído'
      };

      const statusDescription = statusDescriptions[element.status] || null;
      if (statusDescription) {
        addToFilterIfNotExist(this.filterStatus, { statusDescription }, 'statusDescription');
      }
    });
  }

  filterBy(checkboxArray: any[], type: string) {
    this.listOfData = []
    this.filterValues = {
      projectName: '',
      corredor: [],
      complex: [],
      operationalUnit: [],
      status: []
    }
    let filterCheck: boolean = false;

    const filterCorredor = this.filterCorredor.filter(cor => cor.checked === true);
    const filterComplexo = this.filterComplexo.filter(cor => cor.checked === true);
    const filterUnidadeOperacional = this.filterUnidadeOperacional.filter(cor => cor.checked === true);
    const filterStatus = this.filterStatus.filter(cor => cor.checked === true);
    checkboxArray = filterCorredor.concat(filterComplexo, filterUnidadeOperacional, filterStatus);
    const totalChecked = checkboxArray.filter((element) => element.checked === true).length <= 0;
    if (totalChecked) {
      this.listOfData = this.listOfDataOriginal
      return
    }
    for (let element of checkboxArray) {
      if (element.type === 'corridor' && element.checked === true) {
        this.filterValues.corredor?.push(element.value);
        filterCheck = true;
      }
      if (element.type === 'complex' && element.checked === true) {
        this.filterValues.complex?.push(element.value);
        filterCheck = true;
      }
      if (element.type === 'operationalUnit' && element.checked === true) {
        this.filterValues.operationalUnit?.push(element.value);
        filterCheck = true;
      }
      if (element.type === 'statusDescription' && element.checked === true) {
        let statusDescription = this.getStatusDescValue(element.label);
        if (statusDescription != null) {
          this.filterValues.status?.push(statusDescription);
        }

        filterCheck = true;
      }
      this.listOfDataFiltered = [];
    }
    if (filterCheck) {
      this.filterValues.complex = this.filterValues.complex?.length == 0 ? null : this.filterValues.complex;
      this.filterValues.operationalUnit = this.filterValues.operationalUnit?.length == 0 ? null : this.filterValues.operationalUnit;
      this.filterValues.corredor = this.filterValues.corredor?.length == 0 ? null : this.filterValues.corredor;
      this.filterValues.status = this.filterValues.status?.length == 0 ? null : this.filterValues.status;
      this.getProjectListFiltered(this.filterValues);
    }
  }

  private getStatusDescValue(desc: string) {
    if (desc === 'Em andamento') return "EAN";
    else if (desc === 'Rascunho') return "RAS";
    else if (desc === 'Requisição Enviada') return "ENV";
    else if (desc === 'A iniciar') return "INI";
    else if (desc === 'Em revisão') return "REV";
    else if (desc === 'Cancelado') return "CAN";
    else if (desc === 'Concluído') return "CON";
    else return null;
  }

  createRIPLA(riplaType) {
    this.projectsService.setProjectToEdit(undefined);
    this._router.navigate(['/projects-v2-new', riplaType]);
  }

  getProjectList(filter?: any) {
    this.projectsService.getProjects(100, filter).subscribe({
      next: projectsList => {
        this.listOfData = projectsList.content;
        if (this.listOfData && this.listOfData.length > 0) {
          const listOfRasProjects: [] = this.listOfData.filter(pro => pro.status === 'INI');
          const listOfUserProject: [] = this.listOfData.filter(pro => pro.status !== 'INI');
          this.listOfData = listOfRasProjects.concat(listOfUserProject);
          this.listOfDataOriginal = this.listOfData;
          this.dispatchProjectOnStore()
        } else {
          this.listOfData = [];
          this.listOfDataOriginal = [];
          this.dispatchProjectOnStore()
        }
        this.createFilters();
      },
      error: error => {
        console.log(error.message)
      }
    });
  }

  refreshProjects() {
    this.getProjectList();
    this.cdr.detectChanges();
  }

  goToProjectView(project: Projects) {
    if (project.status !== 'INI') {
      this._router.navigate([`project-v2-view/${project.projectId}`]);
    }
    if (project.status === 'INI') {
      this.projectsService.setProjectToEdit(project);
      this._router.navigate(['/projects-v2-new', 'edit']);
    }
  }

  searchByProject() {
    this.listOfData = this.listOfDataOriginal;
    this.listOfData = this.listOfData.filter((element) => {
      const projeto = element.projectName || '';
      return (
        projeto ? projeto.toLowerCase().includes(this.searchValue.toLowerCase()) : null
      );
    });
    this.resetOtherFilters()
  }

  searchProjectWithEnter(evento: any) {
    if (evento.key === "Enter") {
      this.listOfData = this.listOfDataOriginal
      this.searchByProject()
    }
  }

  hasFilterActivated(filter: any[]): boolean {
    return filter.findIndex(item => item.checked) !== -1;
  }

  resetOtherFilters() {
    this.filterCorredor = this.filterCorredor.map(item => ({
      ...item,
      checked: false
    }));
    this.filterComplexo = this.filterComplexo.map(item => ({
      ...item,
      checked: false
    }));
    this.filterUnidadeOperacional = this.filterUnidadeOperacional.map(item => ({
      ...item,
      checked: false
    }));
    this.filterStatus = this.filterStatus.map(item => ({
      ...item,
      checked: false
    }));
  }

  resetSearch(filtro) {
    this.indeterminate = false;
    this.listOfData = this.listOfDataOriginal
    if (filtro === 'corredor') {
      this.filterCorredor = this.filterCorredor.map(item => ({
        ...item,
        checked: false
      }));
    } else if (filtro === 'complexo') {
      this.filterComplexo = this.filterComplexo.map(item => ({
        ...item,
        checked: false
      }));
    } else if (filtro === 'unidadeOperacional') {
      this.filterUnidadeOperacional = this.filterUnidadeOperacional.map(item => ({
        ...item,
        checked: false
      }));
    } else if (filtro === 'status') {
      this.filterStatus = this.filterStatus.map(item => ({
        ...item,
        checked: false
      }));
    }
  }

  resetSearchByProject(): void {
    this.searchValue = '';
    this.listOfData = this.listOfDataOriginal
  }

  private getProjectListFiltered(filter: any) {
    this.projectsService.getProjects(100, filter).subscribe({
      next: projectsList => {
        if (projectsList && projectsList.content.length > 0) {
          this.listOfData = this.listOfData.concat(projectsList.content)
        } else {
          this.listOfData = [];
        }
      },
      error: error => {
        console.log(error.message)
        this.messageService.showErrorMessage(error.message);
      }
    });
  }
}
