<div nz-row class="projects-list">
  <div
    nz-col
    nzXs="24"
    nzSm="24"
    nzMd="24"
    nzLg="18"
    nzXl="24"
    class="pl-20 pt-20"
  >

    <div>
      <h3>Produtos NLP Cadastrados</h3>
    </div>

    <div class="table-outer-border">
      <div>
        <nz-table
          #basicTable
          #borderedTable
          [nzShowPagination]="false"
          [nzData]="filteredList"
          [nzPageSize]="50"
          [nzScroll]="{ y: '26rem' }"
        >
          <tbody>
            <tr *ngFor="let item of filteredList">
              <td (click)="navigateProductNlp(item.id)" id="tblProjects">
                <div class="project-status-div">
                  <div class="project-status"></div>
                  <div class="project-infos">
                    <div nz-row class="title-cells">
                      <span nz-col [nzSpan]="12"
                        ><b id="txtProjectName {{ item.id }}">{{
                          item.description
                        }}</b></span
                      >
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </div>
    </div>
  </div>
</div>
