<div nz-row nz-col nzSpan="24">

  <div nz-col nzSpan="24" class="ali-center-flex">

    <div class="icone">
      <span nz-icon nzType="close-circle" nzTheme="outline" style="color:red; font-size: 45px; font-weight: 400;"></span>
    </div>
    <h1 class="titulo">{{errorMessage}}</h1>
    <small class="textoFixo" >{{subTitle}}</small>
  </div>

  <div nz-col nzSpan="24" class="ali-left">
    <button
      id="btnCancel"
      type="button"
      class="btn-sl btn-sl-grey buttonCancel"
      (click)="handleOk()">
      Ok
    </button>
  </div>
</div>
