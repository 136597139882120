<div class="rounded-row historiesReview" *ngIf="reviews.length > 0">
  <h2 class="mb-20">Histórico de pedidos de revisão da RIPLA</h2>
  <div *ngFor="let review of reviews; let i = index">
    <nz-divider *ngIf="i > 0"></nz-divider>
    <div class="reviewInfo">
      <span class="leftText">Data do pedido</span>
      <span class="rightText">{{ review.registrationDate | date: 'dd/MM/yyyy' }}</span>
    </div>
    <div class="reviewInfo">
      <span class="leftText">Data de resposta</span>
      <span class="rightText">{{ review.dateResponse ? (review.dateResponse | date: 'dd/MM/yyyy') : '-' }}</span>
    </div>
    <div class="reviewInfo">
      <span class="leftText">Solicitado por</span>
      <span class="rightText">{{ review.user.name }}</span>
    </div>
    <div class="reviewInfo">
      <span class="leftText">Respondido por</span>
      <span class="rightText">{{ review.userResponse ? review.userResponse.name : '-' }}</span>
    </div>
    <div class="reviewInfo">
      <span class="leftText">Motivos para revisão</span>
      <div class="rightText" >
        <p *ngFor="let reason of review.subProjectReviewReasons">- {{ reason.reason.description }}</p>
      </div>
    </div>
    <div class="reviewInfo">
      <span class="leftText">Descrição</span>
      <span class="rightText">{{ review.comments }}</span>
    </div>

  </div>


</div>
