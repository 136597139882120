import {
  Component,
  OnInit,
  inject,
  Input,
} from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { MessageService } from 'src/app/shared/common/services/message.service';
import { AttachmentsService } from 'src/app/shared/services/attachments.service';
import { UtilService } from 'src/app/shared/util.service';
import { LicensingService } from 'src/app/modules/projects/projects-licensing/service/licensing.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  readonly #modal = inject(NzModalRef);
  @Input() dataToAnalisys: any;
  @Input() projectId: number;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() buttonText: string = "Ir para o projeto";
  @Input() buttonAction: any;

  arrayImageDelete: any[] = [];
  fileList: NzUploadFile[] = [];
  fileListStorageRepite: NzUploadFile[] = [];
  bodyFormat: any;

  constructor(
  ) {
  }
  
  ngOnInit(): void {
    //console.log("dataToAnalisys", this.dataToAnalisys)
  }

  executeButtonAction() {
    this.buttonAction()
    this.#modal.destroy();
  }
  close(){
    this.#modal.destroy();
  }

}
