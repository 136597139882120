<h2 class="mb-20">{{ 'projects.requisition-resume.title-revision-solicitante-area' | translate }}</h2>

<nz-descriptions>
  <nz-descriptions-item id="descriptionItemId" class="fieldName" nzTitle="Data do pedido">
    <span class="fieldDescription">
      {{ riplaRevision ? (riplaRevision.registrationDate | date:'dd/MM/yyyy') : null }}
    </span>
  </nz-descriptions-item>
</nz-descriptions>

<nz-descriptions>
  <nz-descriptions-item id="descriptionItemId" class="fieldName" nzTitle="Prazo para retorno">
    <span class="fieldDescription">
      {{ riplaRevision ? (riplaRevision.deadline | date:'dd/MM/yyyy') : null }}
      <button *ngIf="riplaRevision" class="buttonTooltip" [nzTooltipColor]="'#2E2E2E'" [nzTooltipTrigger]="'hover'" [nzTooltipOverlayStyle]=customStyle [nzTooltipTitle]="toolTipTitleTemplate" nzTooltipPlacement="bottomLeft" nz-button nz-tooltip>
        <img src="assets/icons/icon_information.svg" class="mr-10 icone" alt="">
      </button>
      <ng-template #toolTipTitleTemplate>
        <div>
          <div class="tooltipTitle">RIPLAs sem resposta em até 120 dias corridos serão canceladas e arquivadas.</div>
        </div>

      </ng-template>
    </span>
  </nz-descriptions-item>
</nz-descriptions>

<nz-descriptions>
  <nz-descriptions-item id="descriptionItemId" class="fieldName" nzTitle="Solicitado por">
    <span class="fieldDescription">
      {{ riplaRevision ? riplaRevision.userResponsibleName : ''}}
    </span>
  </nz-descriptions-item>
</nz-descriptions>

<nz-descriptions>
  <nz-descriptions-item id="descriptionItemId" class="fieldName" nzTitle="Motivos para revisão">
    <span class="fieldDescription" *ngIf="riplaRevision && riplaRevision.subProjectReviewReasons.length >= 1">
      <div *ngFor="let item of riplaRevision ? riplaRevision.subProjectReviewReasons : []" style="margin-top: 3px !important;">
        <p class="fieldDescription lineheight-10">- {{ item.reason.description }}</p>
      </div>
    </span>
    <span class="fieldDescription" *ngIf="riplaRevision && riplaRevision.subProjectReviewReasons && riplaRevision.subProjectReviewReasons.length === 0">
        <p>-</p>
    </span>
  </nz-descriptions-item>
</nz-descriptions>

<nz-descriptions>
  <nz-descriptions-item id="descriptionItemId" class="fieldName" nzTitle="Descrição">
    <span class="fieldDescription">
      {{ riplaRevision ? riplaRevision.comments : null}}
    </span>
  </nz-descriptions-item>
</nz-descriptions>
