import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AmbientalStudiesModel, CategoryStudiesModel} from "../../../../shared/models/ambiental-studies.model";
import {log} from "ng-zorro-antd/core/logger";
import {DisciplineAssessmentService} from "../../../../shared/services/discipline-assessment.service";
import {take} from "rxjs";
import {CostStudyModel} from "../../../../shared/models/discipline-assessment.model";
import {StudyTypeModel} from "../../../../shared/models/study-type.model";
import {MessageService} from "../../../../shared/common/services/message.service";

@Component({
  selector: 'app-outer-costs',
  templateUrl: './outer-costs.component.html',
  styleUrls: ['./outer-costs.component.scss']
})
export class OuterCostsComponent implements OnInit, AfterViewInit {

  constructor(private readonly disciplineService: DisciplineAssessmentService, private readonly messageService: MessageService) {
  }

  @Input() outerCosts?: CostStudyModel[] = [];
  @Input() outerCost!: boolean;
  @Input() disciplineId?: any;
  @Input() edit?: boolean;
  @Output() sendCostStudy = new EventEmitter<CostStudyModel>();
  @Output() deleteCostStudy = new EventEmitter<number>();
  @Input() hint!: string;

  studyId: number = 0;
  categoryList: CategoryStudiesModel[] = []
  studyType: StudyTypeModel[] = []
  newStudy: boolean = false;
  editCache: { [key: string]: { edit: boolean; popupedit: boolean, data: AmbientalStudiesModel } } = {};
  listOfData: AmbientalStudiesModel[] = [];
  customStyle = {
    background: 'white',
    'font-size': '12px',
  };
  disciplineTitle: any;
  protected readonly log = log;
  editStudy: boolean = true;
  showEdit: boolean = true;

  compareFn = (o1: string, o2: string) => (o1 && o2 ? o1 == o2 : false);

  formatterReal = (value: number): string => `R$ ${value}`;
  parserReal = (value: string): string => value.replace('R$ ', '');

  ngOnInit(): void {
    this.disciplineTitle = this.outerCost ? 'projects.discipline.outer-costs' : 'projects.discipline.ambiental-studies';
    if (this.edit) {
      this.getOuterCostByDisciplineId(this.disciplineId);
    }
    if(!this.outerCost){
      this.getListOfStudyTypes();
    } else {
      this.getListOfCategory();
    }

  }

  ngAfterViewInit(): void {
    this.onScroll();
  }

  onScroll() {
    let antTableBodies: any = document.querySelectorAll('.ant-table-body');
    antTableBodies.forEach(antTableBody => {
      antTableBody.addEventListener('scroll', () => {
          antTableBody.click();
      });
    });
  }

  private setListOfData() {
    if (this.outerCosts && this.outerCosts?.length > 0) {
      this.outerCosts = this.outerCosts.filter(cs => cs.isOtherCost === this.outerCost);
      this.outerCosts.forEach(cs => {
        let studyType;
        if (!this.outerCost) {
          studyType = this.studyType.filter(st => st.id === cs.idStudyType)[0];
        }
        const costStudy: AmbientalStudiesModel = {
          category: this.categoryList.filter(ct => ct.id === cs.idCategory)[0],
          cost: cs.cost ? cs.cost : '0',
          id: cs.id ? cs.id : 0,
          otherCosts: cs.isOtherCost,
          studyType: this.outerCost ? null : studyType,
          title: cs.title
        }
        this.listOfData.push(costStudy);
      })
    }
  }

  startEdit(id: number, edit: boolean): void {
    this.editCache[id].edit = true;
    this.editCache[id].popupedit = false;
    this.studyId = id;
    this.newStudy = true;
    this.editStudy = edit;
    this.showEdit = false;
  }

  private getOuterCostByDisciplineId(disciplineId: any) {
     this.disciplineService.getDisciplinesById(disciplineId).pipe(take(1)).subscribe((data) => {
       if(data){
         this.outerCosts = data;
       }
     })
  }

  private getListOfCategory() {
    this.disciplineService.getAllCategories().pipe(take(1)).subscribe((data) =>{
      if(data){
        this.categoryList = data.content;
        this.categoryList = this.categoryList.filter(category => category.otherCosts == this.outerCost);
        this.setListOfData();
        this.updateList();
      }
    })

  }

  saveStudy(study: AmbientalStudiesModel) {
    if (this.outerCosts && this.outerCosts.length > 0 && this.editStudy) {
      const costStudy = this.outerCosts.filter(oc => oc.id === study.id)[0];
      costStudy.cost = study.cost ? study.cost !== '' ? study.cost : '0' : 0;
      costStudy.idCategory = study.category ? study.category.id : 1;
      costStudy.idStudyType = study.studyType ? study.studyType.id : null;
      costStudy.isOtherCost = this.outerCost;
      costStudy.title = study.studyType ? study.studyType.description : study.title
      costStudy.newStudy = false;
      costStudy.isAltered = true;
      this.sendCostStudy.emit(costStudy);
    } else {
      const costStudy: CostStudyModel = {
        cost: study.cost ? study.cost !== '' ? study.cost : '0' : 0,
        idCategory: study.category ? study.category.id : 1,
        idDisciplineAssessment: this.disciplineId,
        idStudyType: study.studyType ? study.studyType.id : null,
        isOtherCost: this.outerCost,
        title: study.studyType ? study.studyType.description : study.title,
        newStudy: true,
        isAltered: false
      }
      this.sendCostStudy.emit(costStudy);
    }
  }


  addNewstudy() {
    const id = this.listOfData ? this.getMaxId() + 1 : 1;
    this.newStudy = true;
    let newStudy: AmbientalStudiesModel = {
      category: undefined, cost: '', id: id, otherCosts: this.outerCost, title: ""
    }
    this.listOfData = [
      ...this.listOfData,
      newStudy
    ]
    this.updateList()
    this.startEdit(id, false)
  }

  saveEdit(id) {
    const index = this.listOfData.findIndex(item => item.id === id);
    this.editCache[id].data.cost = this.editCache[id].data.cost ? this.editCache[id].data.cost : '0';
    Object.assign(this.listOfData[index], this.editCache[id].data);
    this.editCache[id].edit = false;
    this.showEdit = true;
    this.newStudy = false;
    this.saveStudy(this.editCache[id].data);
  }

  updateList() {
    this.listOfData.forEach(cost => {
      this.editCache[cost.id] = {
        edit: false,
        popupedit:false,
        data: {...cost}
      }
    })
    this.listOfData = [
      ...this.listOfData
    ]
    this.showEdit = true;
  }

  cancelEdit(id: number) {
    const index = this.listOfData.findIndex(item => item.id === id);
    this.editCache[id] = {
      data: {...this.listOfData[index]},
      edit: false,
      popupedit: false
    };
    if (this.newStudy && !this.editStudy)
      this.listOfData.pop();
    this.listOfData = [
      ...this.listOfData
    ]
    this.newStudy = false;
    this.editStudy = true;
    this.updateList();
  }

  private getListOfStudyTypes() {
    this.disciplineService.getAllStudyTypes().pipe(take(1)).subscribe((data) => {
      if(data){
        this.studyType = data;
        // Removendo elemento da lista
        const index = this.studyType.findIndex((item) => item.description === 'Nenhum estudo necessário');
        this.studyType.splice(index, 1);
        this.getListOfCategory();
      }
    })
  }

  private getMaxId(): number {
    let maxId = 0
    this.listOfData.forEach(ld => {
      if(ld.id > maxId){
        maxId = ld.id;
      }
    })
    return maxId;
  }

  removeStudy(costId){
    this.listOfData = this.listOfData.filter(data => data.id !== costId);
    if(this.listOfData.length === 0){
      this.listOfData = []
    } else {
      this.updateList();
    }
    this.deleteCostStudy.emit(costId);
  }

  isVisible(cost: AmbientalStudiesModel) {
    if(this.outerCost && cost.title.length > 30){
      return true;
    } else if(!this.outerCost && ( cost.studyType?.description && cost.studyType?.description.length > 30)){
      return true;
    } else {
      return false;
    }
  }
}
