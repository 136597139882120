<div class="project__items__item" id="infoProjeto">
  <h3 class="scope__items__item__title">
    <!-- Informações básicas sobre o projeto -->
    {{"projects.scope-change.basic-info" | translate }}
  </h3>
  <div class="scope__items__item__row">
    <p class="scope__items__item__row__title">
      <!-- Nome do projeto -->
      {{"projects.scope-change.project-name" | translate }}
    </p>
    <p class="scope__items__item__row__description">
      {{ projectSummary.projectName }}
    </p>
  </div>

  <div class="scope__items__item__row">
    <p class="scope__items__item__row__title">
      <!-- Numero do projeto -->
      {{"projects.view.project-number" | translate }}
    </p>
    <p class="scope__items__item__row__description">
      {{ projectSummary.projectNumber }}
    </p>
  </div>

  <div class="scope__items__item__row">
    <p class="scope__items__item__row__title">
      <!-- Código do projeto -->
      {{"projects.scope-change.project-code" | translate }}
    </p>
    <p class="scope__items__item__row__description">
      {{ projectSummary.projectCode }}
    </p>
  </div>

  <div class="scope__items__item__row">
    <p class="scope__items__item__row__title">
      <!-- Localização -->
      {{"projects.scope-change.location" | translate }}
    </p>
    <p class="scope__items__item__row__description">
      {{ projectSummary.localization }}
    </p>
  </div>

  <div class="scope__items__item__row">
    <p class="scope__items__item__row__title">
      <!-- Área solicitante -->
      {{"projects.scope-change.requesting-area" | translate }}
    </p>
    <p class="scope__items__item__row__description">
      {{ projectSummary.areaRequest }}
    </p>
  </div>

  <div class="scope__items__item__row">
    <p class="scope__items__item__row__title">
      <!-- Responsável -->
      {{"projects.scope-change.responsible" | translate }}
    </p>
    <p class="scope__items__item__row__description">
      {{ projectSummary.responsible }}
    </p>
  </div>

  <div class="scope__items__item__row">
    <p class="scope__items__item__row__title">
      <!-- Classificação do projeto -->
      {{"projects.scope-change.project-rating" | translate }}
    </p>
    <p class="scope__items__item__row__description">
      {{ projectSummary.projectClassification | titlecase}}
    </p>
  </div>

  <div class="scope__items__item__row">
    <p class="scope__items__item__row__title">
      <!-- Breve descrição / Objetivo do projeto -->
      {{"projects.scope-change.description" | translate }}
    </p>
    <p class="scope__items__item__row__description">
      {{ projectSummary.projectDescription }}
    </p>
  </div>
</div>