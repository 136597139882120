import {Injectable} from '@angular/core';
import {DisciplineAssessmentDto} from "../dto/discipline-assessment.dto";
import {catchError, Observable, throwError} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {
  DISCIPLINE_ASSESSMENT_API,
  DISCIPLINE_ASSESSMENT_CATEGORIES,
  DISCIPLINE_ASSESSMENT_BASE_API
} from "../../modules/projects/constants/apiEndpointsConstants";
import {
  CostStudyModel,
  DisciplineAssessmentModel
} from "../models/discipline-assessment.model";
import {DisciplineAssessmentResponse} from "../dto/assessment-by-id-response.dto";
import {AssessmentUpdateDto} from "../dto/assessment-update.dto";


@Injectable({
  providedIn: 'root'
})
export class DisciplineAssessmentService {
  recoverDiscipline: any;

  constructor(private readonly http: HttpClient) {
  }

  saveAssessment(assessment: DisciplineAssessmentDto): Observable<DisciplineAssessmentModel> {
    return this.http.put<DisciplineAssessmentModel>(`${DISCIPLINE_ASSESSMENT_API}`, assessment)
    .pipe(catchError(errorResponse => {
      let errorMessage: string = 'Um erro desconhecido ocorreu!';
      if (!errorResponse.error
        || !errorResponse.error.errors
        || !errorResponse.error.errors.details) {
        return throwError(errorMessage)
      }
      errorMessage = errorResponse.error.errors.details;
      return throwError(errorMessage);
    }));
  }

  getAllCategories(): Observable<any>{
    return this.http.get(`${DISCIPLINE_ASSESSMENT_CATEGORIES}`)
  }

  getDisciplineAssessmentById(assessmentId: number): Observable<DisciplineAssessmentResponse> {
    return this.http.get<DisciplineAssessmentResponse>(`${DISCIPLINE_ASSESSMENT_BASE_API}/${assessmentId}`);
  }

  updateAssessment(payload: AssessmentUpdateDto): Observable<any> {
    return this.http.put(`${DISCIPLINE_ASSESSMENT_BASE_API}/update`, payload);
  }
  getAllStudyTypes(): Observable<any> {
    return this.http.get(`${DISCIPLINE_ASSESSMENT_BASE_API}/study-types`);
  }

  saveCostStudy(study: CostStudyModel): Observable<any>{
    return this.http.post(`${DISCIPLINE_ASSESSMENT_BASE_API}/cost-study`, study);
  }

  updateCostStudy(id, study: CostStudyModel): Observable<any>{
    return this.http.put(`${DISCIPLINE_ASSESSMENT_BASE_API}/cost-study/${id}`, study);
  }

  removeCostStudy(id): Observable<any>{
    return this.http.delete(`${DISCIPLINE_ASSESSMENT_BASE_API}/cost-study/${id}`);
  }

  getDisciplinesById(disciplineId: any): Observable<any> {
    return this.http.get(`${DISCIPLINE_ASSESSMENT_BASE_API}/cost-study/${disciplineId}`);
  }

  setDisciplineAssesment(data: any){
    this.recoverDiscipline = data;
  }

  getDisciplineAssesment(id:number){
    if(this.recoverDiscipline && this.recoverDiscipline.id === id){
      //recupera
      return this.recoverDiscipline;
    } else {
      //se não tem chama de novo
      return this.getDisciplineAssessmentById(id);
    }
  }
  
  deleteDisciplineAssessment(disciplineAssessmentId: number): Observable<any> {
    return this.http.delete(`${DISCIPLINE_ASSESSMENT_BASE_API}/${disciplineAssessmentId}`);
  }
}
