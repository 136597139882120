import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { UploadFilesHorzComponent } from "./components/upload-files-horz/upload-files-horz.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpLoaderFactory } from "../app.module";
import { HttpClient } from "@angular/common/http";
import { CommonModule } from "@angular/common";

import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzPopoverModule } from "ng-zorro-antd/popover";
import { NzFormModule } from "ng-zorro-antd/form";

import { UploadFilesComponent } from "./components/upload-files/upload-files.component";
import { SVGIconComponent } from "./components/icons/svg-icon/svg-icon.component";
import { BadgeComponent } from "./components/badge/badge.component";
import { InformationBannerComponent } from "./components/information-banner/information-banner.component";
import { ConfirmationModalComponent } from "./components/confirmation-modal/confirmation-modal.component";
import {ShortTextHintComponent} from "./components/text-shortner/short-text-hint.component";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import { BadgeChipsComponent } from "./components/badge-chips/badge-chips.component";
import { GenericResumeSidebarComponent } from "./components/generic-resume-sidebar/generic-resume-sidebar.component";
import { ProjectResumeInfosComponent } from "./components/project-resume-infos/project-resume-infos.component";
import { ResumeScopeChangeRequestContainerComponent } from "../modules/scope-change/components/scope-change-form/resume-scope-change-request-container/resume-scope-change-request-container.component";
import { AnalysisAboutComponent } from "../modules/scope-change/components/scope-change-analysis-view/analysis-about/analysis-about.component";
import { GenericNonEditableTableComponent } from "./components/generic-non-editable-table/generic-non-editable-table.component";

import { NzInputModule } from "ng-zorro-antd/input";
import { NzSelectModule } from "ng-zorro-antd/select";
import { NzAutocompleteModule } from "ng-zorro-antd/auto-complete";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzCheckboxModule } from "ng-zorro-antd/checkbox";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { NzDropDownModule } from "ng-zorro-antd/dropdown";
import { NzRadioModule } from "ng-zorro-antd/radio";
import { NzTableModule } from "ng-zorro-antd/table";
import { ButtonsLicensingModule } from "./components/buttons/buttons-licensing.module";
import { InputsLicensingModule } from "./components/inputs/inputs-licensing.module";
import { SelectsLicensingModule } from "./components/selects/selects-licensing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

const MODULES = [
    CommonModule,
    NzUploadModule,
    NzToolTipModule,
    NzFormModule,
    NzSelectModule,
    NzInputModule,
    NzRadioModule,
    NzTableModule,
    NzButtonModule,
    NzDividerModule,
    NzCheckboxModule,
    NzDropDownModule,
    NzAutocompleteModule,
    InputsLicensingModule,
    ButtonsLicensingModule,
    SelectsLicensingModule,
    NzPopoverModule,
];
const COMPONENTS = [
    UploadFilesHorzComponent,
    UploadFilesComponent,
    SVGIconComponent,
    BadgeComponent,
    ShortTextHintComponent,
    BadgeChipsComponent,
    GenericResumeSidebarComponent,
    InformationBannerComponent,
    ConfirmationModalComponent,
    ProjectResumeInfosComponent,
    AnalysisAboutComponent,
    ResumeScopeChangeRequestContainerComponent,
    GenericNonEditableTableComponent
];

@NgModule({
    declarations: [...COMPONENTS],
    exports: [
      ...COMPONENTS,
      NzUploadModule,
    ],
    imports: [
      ...MODULES,
      FormsModule,
      CommonModule,
      ReactiveFormsModule,
      TranslateModule.forChild({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      })
    ],
    providers: [],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA
    ]
  })
  export class SharedModule { }
