<div class="app-input-textarea sl__form">
    <div class="sl__form__label">
        <span class="sl__form__required" *ngIf="required">*</span>
        <span class="sl__form__title">{{ name }}</span>
    </div>

    <textarea
        nz-input 
        [style.resize]="'none'" 
        [placeholder]="placeholder"
        [rows]="rows"
        [nzStatus]="status"
        [formControl]="controls"
        (keyup)="onChangeValue($event)">
    </textarea>
    <div class="sl__form__char__count" *ngIf="charCount > 1">
        {{ textCount }} /{{ charCount }}
    </div>
</div>