<div class="container">
  <div nz-row class="mb-20">
    <div nz-col nzSpan="18" class="header">
      <a class="mr-10" (click)="goBack()">
        <img src="/assets/icons/icon_arrow-back.svg" alt="Voltar" />
      </a>
      <h1
        id="projectName"
        class="overflowText"
        nzTooltipTitle=" {{ processName + processBond }}"
        nzTooltipPlacement="bottomRight"
        nz-tooltip
      >
        {{ processName + processBond }}
      </h1>
      <h1 class="ml-5-mr-5">-</h1>
      <input
        type="text"
        [(ngModel)]="processNameManual"
        placeholder="Clique aqui para inserir a descrição"
        class="processNameManual overflowText"
        nzTooltipTitle="{{ processNameManual }}"
        nzTooltipPlacement="bottomRight"
        nz-tooltip
        (blur)="clickedOutsideProcessTitle()"
      />
    </div>
    <div nz-col nzSpan="6" class="action-box">
      <a
        class="btn-cancel-process"
        (click)="showConfirm()"
        *ngIf="cadProcessLicensingInsert && !isEditingSavedProcess()"
        >Cancelar</a>
      <a
        class="btn-save-process"
        (click)="saveProcess()"
        *ngIf="
          cadProcessLicensingInsert && !isEditingSavedProcess() && !addingChild">Salvar processo</a>
      <button
        nz-popover
        nzPopoverPlacement="bottom"
        nzPopoverTrigger="click"
        [nzPopoverContent]="contentTemplate"
        class="btn__primary btn__primary__green"
        [nzPopoverOverlayStyle]="customStyle"
        [(nzPopoverVisible)]="popOverNotification"
        *ngIf="cadProcessLicensingExclude && isEditingSavedProcess()">

        <span class="btn__primary__span"> Ações </span>
        <div class="btn__primary__line"></div>
        <span nz-icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.03436 6.63422C5.34678 6.3218 5.85331 6.3218 6.16573 6.63422L8.00005 8.46853L9.83436 6.63422C10.1468 6.3218 10.6533 6.3218 10.9657 6.63422C11.2782 6.94664 11.2782 7.45317 10.9657 7.76559L8.00005 10.7313L5.03436 7.76559C4.72194 7.45317 4.72194 6.94664 5.03436 6.63422Z"
              stroke="white"
              stroke-width="0.7"
              fill="white"
            />
          </svg>
        </span>
      </button>
      <ng-template #contentTemplate>
        <div
          class="botoesMenu"
          *ngIf="cadProcessLicensingExclude"
          (onClick)="modalConfirmDelete()"
        >
          Excluir processo
        </div>
      </ng-template>
    </div>
  </div>

  <div class="scrollableContent">
    <div class="rounded-row">
      <nz-table
        #editRowTable
        nzTemplateMode
        nzSize="small"
        [nzData]="listOfData"
        [nzFrontPagination]="false"
        [nzScroll]="{ x: '1056px' }"
      >
        <!-- TH da tabela principal Processo x Licenca -->
        <thead>
          <tr class="tableTitle">
            <th id="processTable" colspan="8" class="tableTitleTr">Processo</th>
            <th id="licenseTable" colspan="5" class="tableTitleTr">Licença</th>
          </tr>
          <tr id="processBody">
            <th id="organColumn" class="tableTh" colspan="1" nzWidth="150px">
              <span class="mandatory">* </span>Órgão
            </th>
            <th id="licenseColumn" class="tableTh" colspan="1" nzWidth="200px">
              <span class="mandatory">* </span>Tipo de Licença
            </th>
            <th id="processColumn" class="tableTh" colspan="1" nzWidth="100px">
              Número do processo
            </th>
            <th
              id="responsibleColumn"
              class="tableTh"
              colspan="1"
              nzWidth="150px"
            >
              Responsável pelo processo
            </th>
            <th
              id="conclusionColumn"
              class="tableTh"
              colspan="1"
              nzWidth="110px"
            >
              Previsão de conclusão
            </th>
            <th id="protocolDate" class="tableTh" colspan="1" nzWidth="110px">
              Data de protocolo
            </th>
            <th id="statusColumn" class="tableTh" colspan="2" nzWidth="200px">
              Status do processo
            </th>
            <th
              id="spaceColumn"
              class="tableTh"
              colspan="1"
              nzWidth="30px"
            ></th>
            <th id="docColumn" class="tableTh" colspan="1" nzWidth="85px">
              Número do documento
            </th>
            <th id="emisionColumn" class="tableTh" colspan="1" nzWidth="85px">
              Emissão
            </th>
            <th id="validityColumn" class="tableTh" colspan="1" nzWidth="85px">
              Vigência
            </th>
            <th
              id="limitDateColumn"
              class="tableTh"
              colspan="1"
              nzWidth="100px"
            >
              Data limite da renovação
            </th>
            <th
              id="statusLicenseColumn"
              class="tableTh"
              colspan="1"
              nzWidth="100px"
            >
              Status da licença
            </th>
          </tr>
        </thead>

        <tbody (clickOutside)="clickedOutsideProcessForm()">
          <tr
            *ngFor="let data of editRowTable.data"
            class="editable-row {{ data.child ? 'child' : '' }}"
            (dblclick)="startEdit(data.id, data.child, data.linkedLicenseId)"
          >
            <!--Processo principal-->
            <ng-container *ngIf="!editChildCache[data.id].edit">
              <td class="tableTd" colspan="1">
                <div
                  class="editable-cell {{
                    getTextColor(getCompetentOrgan(data))
                  }}"
                  [hidden]="editId === data.id"
                  nzTooltipTitle="{{ getCompetentOrgan(data) }}"
                  nzTooltipPlacement="bottomRight"
                  nz-tooltip
                >
                  {{ getCompetentOrgan(data) }}
                </div>
                <nz-select
                  [hidden]="editId !== data.id"
                  nzBorderless
                  class="selectItem"
                  nzPlaceHolder="Informe..."
                  [(ngModel)]="data.competentOrgan"
                  [compareWith]="compareFn"
                  [disabled]="disableDataEdit"
                  (ngModelChange)="
                    getLicensingTypesChangingOrgan(data.competentOrgan?.id)
                  "
                  id="processCompetentOrgan"
                >
                  <nz-option
                    class="userItem selectItem"
                    *ngFor="let competentOrgan of competentOrgans"
                    [nzLabel]="
                      competentOrgan.code !== undefined
                        ? competentOrgan.code
                        : ''
                    "
                    [nzValue]="competentOrgan"
                  ></nz-option>
                </nz-select>
              </td>
              <td class="tableTd" colspan="1">
                <div
                  class="editable-cell {{ getTextColor(getLicense(data)) }}"
                  [hidden]="editId === data.id"
                  nzTooltipTitle="{{ getLicense(data) }}"
                  nzTooltipPlacement="bottomRight"
                  nz-tooltip
                >
                  {{ getLicense(data) }}
                </div>
                <nz-select
                  [hidden]="editId !== data.id"
                  nzBorderless
                  class="selectItem"
                  nzPlaceHolder="Informe o órgão competente"
                  [(ngModel)]="data.license"
                  [compareWith]="compareFn"
                  [disabled]="disableDataEdit"
                  (ngModelChange)="enableUserResponsible(data, true)"
                >
                  <nz-option
                    class="userItem selectItem"
                    *ngFor="let licenceType of licensesTypes"
                    [nzLabel]="licenceType.description + ''"
                    [nzValue]="licenceType"
                  ></nz-option>
                </nz-select>
              </td>
              
              <td class="tableTd">
                <div
                  class="editable-cell {{
                    getTextColor(getProcessNumber(data))
                  }}"
                  [hidden]="editId === data.id"
                  nzTooltipTitle="{{ getProcessNumber(data) }}"
                  nzTooltipPlacement="bottomRight"
                  nz-tooltip
                >
                  {{ getProcessNumber(data) }}
                </div>

                <input
                  nz-input
                  nzBorderless
                  placeholder="Informe..."
                  [hidden]="editId !== data.id"
                  [(ngModel)]="data.processNumber"
                  (blur)="this.saveProcessEditOrNew()"
                  type="text"
                />
              </td>

              <td class="tableTd">
                <div
                  class="editable-cell {{ getTextColor(getUserLegal(data)) }}"
                  [hidden]="editId === data.id"
                  nzTooltipTitle="{{ getUserLegal(data) }}"
                  nzTooltipPlacement="bottomRight"
                  nz-tooltip
                >
                  {{ getUserLegal(data) }}
                </div>
                <nz-select
                  [hidden]="editId !== data.id"
                  nzBorderless
                  nzPlaceHolder="Informe..."
                  class="selectItem"
                  [(ngModel)]="data.userLegalRep"
                  [compareWith]="compareFn"
                  (ngModelChange)="saveProcessEditOrNew()"
                  [disabled]="disableUserResponsible"
                >
                  <nz-option
                    class="userItem selectItem"
                    *ngFor="let user of users"
                    [nzLabel]="user.name !== undefined ? user.name : ''"
                    [nzValue]="user"
                  ></nz-option>
                </nz-select>
              </td>
              <td class="tableTd" colspan="1">
                <div
                  class="editable-cell {{
                    getTextColor(getDeadline(data.deadline))
                  }}"
                  [hidden]="editId === data.id"
                  [innerHTML]="getDeadline(data.deadline)"
                  nzTooltipTitle="{{
                    data.deadline ? getDeadline(data.deadline) : 'Pendente'
                  }}"
                  nzTooltipPlacement="bottomRight"
                  nz-tooltip
                ></div>
                <div class="dateBorder" [hidden]="editId !== data.id">
                  <nz-date-picker
                    nzFormat="dd/MM/YYYY"
                    [(ngModel)]="data.deadline"
                    [nzSuffixIcon]="''"
                    nzSize="small"
                    nzBorderless
                    [nzDisabledDate]="disabledDate"
                  >
                  </nz-date-picker>
                </div>
              </td>
              <td class="tableTd" colspan="1">
                <div
                  class="editable-cell {{
                    getTextColor(getProtocolDate(data.protocolDate))
                  }}"
                  [hidden]="editId === data.id"
                  [innerHTML]="getProtocolDate(data.protocolDate)"
                  [nzTooltipTitle]="getProtocolDate(data.protocolDate)"
                  nzTooltipPlacement="bottomRight"
                  nz-tooltip
                ></div>
                <div class="dateBorder" [hidden]="editId !== data.id">
                  <nz-date-picker
                    nzBorderless
                    nzFormat="dd/MM/YYYY"
                    nzSize="small"
                    [(ngModel)]="data.protocolDate"
                    [nzSuffixIcon]="''"
                  >
                  </nz-date-picker>
                </div>
              </td>
              <td class="tableTd" colspan="2">
                <app-badge
                  [nzTooltipTitle]="
                    data.status === 'INF'
                      ? 'Pedido de informação complementar requisitado pelo órgão'
                      : ''
                  "
                  nzTooltipPlacement="top"
                  nz-tooltip
                  *ngIf="data.status"
                  [badgeText]="data.status"
                  [badgeColor]="data.expiredStatus ? 'red' : null"
                ></app-badge>
              </td>
              <td class="tableTd licenceArrowIcon">
                <img
                  src="assets/icons/icon_arrow_up.svg"
                  alt=""
                  style="width: 8px; padding-bottom: 7px"
                />
              </td>
              <td class="tableTd">
                <div
                  class="editable-cell {{
                    getTextColorForLicense(getLicenseDocumentNumber(data))
                  }}"
                >
                  {{ getLicenseDocumentNumber(data) }}
                </div>
              </td>
              <td class="tableTd">
                <div
                  class="editable-cell {{
                    getTextColorForLicense(getLicenseEmissionDate(data))
                  }}"
                >
                  {{ getLicenseEmissionDate(data) }}
                </div>
              </td>
              <td class="tableTd">
                <div
                  class="editable-cell {{
                    getTextColorForLicense(getLicenseEffectiveDate(data))
                  }}"
                >
                  {{ getLicenseEffectiveDate(data) }}
                </div>
              </td>
              <td class="tableTd">
                <div
                  class="editable-cell {{
                    getTextColorForLicense(getLicenseRenewDeadline(data))
                  }}"
                >
                  {{ getLicenseRenewDeadline(data) }}
                </div>
              </td>
              <td class="tableTd">
                <div class="editable-cell greyText">Não emitida</div>
              </td>
            </ng-container>
            <!--Processo principal-->

            <!--Form Processo vinculado-->
            <ng-container *ngIf="editChildCache[data.id].edit">
              <td colspan="14">
                <div class="formEdit">
                  <div class="formFieldChild">
                    <label for="processo">Processo</label>
                    <input
                      id="processo"
                      class="txtChild txtDisabled overflowText"
                      type="text"
                      disabled="disabled"
                      value="{{ listOfData[0].license?.description }}"
                      nzTooltipTitle="{{ listOfData[0].license?.description }}"
                      nzTooltipPlacement="bottomRight"
                      nz-tooltip
                    />
                  </div>

                  <div class="formFieldChild">
                    <label for="tipoVinculo"
                      >Tipo de vínculo <span class="mandatory">*</span></label
                    >
                    <select
                      id="tipoVinculo"
                      class="selectChild"
                      [(ngModel)]="data.type"
                      (change)="changeTitleBond(data.type)"
                      [compareWith]="compareFnString"
                      (ngModelChange)="
                        getLicensesByProjectIdForLink(
                          projectId,
                          data.type,
                          data)
                      "
                    >
                      <option
                        *ngFor="let linkType of linkTypes"
                        value="{{ linkType.type }}"
                      >
                        {{ linkType.name }}
                      </option>
                    </select>
                  </div>

                  <div class="formFieldChild">
                    <label for="validity"
                      >Processo/licença vinculado
                      <span class="mandatory">*</span></label
                    >
                    <select
                      id="validity"
                      class="selectChild"
                      [(ngModel)]="data.linkedLicenseId"
                    >
                      <option value=""></option>
                      <option
                        *ngFor="let license of licensesForLink"
                        value="{{ license.licenseId }}"
                      >
                        {{ license.numberAndTitle }}
                      </option>
                    </select>
                  </div>
                  <div class="formFieldChild deleteChild">
                    <a
                      (click)="deleteChildLicenceFromDb(data.id)"
                      *ngIf="isEditingSavedLinkedProcess()"
                    >
                      <img
                        src="assets/icons/trash_document-icon.svg"
                        alt="deletar"
                      />
                    </a>
                  </div>

                  <div class="formChildActions push">
                    <a
                      class="btnCancelChild"
                      (click)="cancelLinkedProcessRow(data.id)"
                      >Cancelar</a
                    >
                    <a
                      class="btnApplyChild"
                      (click)="applyLinkedLicense(data)"
                      >{{
                        isEditingSavedLinkedProcess()
                          ? "Salvar Edições"
                          : "Aplicar vínculo"
                      }}</a
                    >
                  </div>
                </div>
              </td>
            </ng-container>
            <!--Processo vinculado-->

          </tr>
        </tbody>
      </nz-table>

      <!--Botao para adicionar Processo vinculado-->
      <a
        (click)="addLinkedProcessRow(true)"
        *ngIf="cadProcessLicensingInsert && showChildLicense && !addingChild"
        class="btnAddChildLicence"
      >
        <img src="assets/icons/add_discipline-icon.svg" alt="" class="mr-5" />
        <b>Adicionar processo/licença vinculada</b>
      </a>

      <div class="showChildLicense">
        <a
          (click)="activateLinkedLicenses()"
          class="{{
            checkProcessIsFilled()
              ? 'showChildLicenseEnabled'
              : 'showChildLicenseDisabled'
          }}"
        >
          <span *ngIf="childLicenceCount > 0" class="mr-5"
            >{{ childLicenceCount | number : "2.0" }}
          </span>
          Processos/Licenças vinculadas
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="{{ showChildLicense ? 'open' : 'closed' }}"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.03412 6.63471C5.34654 6.32229 5.85307 6.32229 6.16549 6.63471L7.9998 8.46902L9.83412 6.63471C10.1465 6.32229 10.6531 6.32229 10.9655 6.63471C11.2779 6.94712 11.2779 7.45366 10.9655 7.76608L7.9998 10.7318L5.03412 7.76608C4.7217 7.45366 4.7217 6.94712 5.03412 6.63471Z"
            />
          </svg>
        </a>
      </div>
    </div>

    <div class="row-multiple-columns mt-10">
      <div class="left-column vert-column">
        <div class="vert-column">
          <app-activities-container
            [id]="processId"
            [projectId]="projectId"
            [disabledBlock]="false"
            [addingProcess]="!isEditingSavedProcess()"
            [dinamicTitle]="'edit-processv2'"
          >
          </app-activities-container>
        </div>
      </div>
      <div class="right-column">
        <div class="round-border vert-column">
          <app-communication-list
            [projectId]="projectId"
            [processId]="processId"
            [addingProcess]="!isEditingSavedProcess()"
          ></app-communication-list>
        </div>
      </div>
    </div>

  </div>
</div>
