import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NlpContentTermService } from './service/nlp-content-term.service';
import { Section, myParam } from '../../interface/nlp-interface';
import { mergeMap } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NlpModalNewSectionTermReferenceComponent } from '../nlp-modal-new-section-term-reference/nlp-modal-new-section-term-reference.component';
import { NlpModalSectionTermReferenceService } from '../nlp-modal-new-section-term-reference/service/nlp-modal-section-term-reference.service';
import { MessageService } from 'src/app/shared/common/services/message.service';
import { DeleteSectionModalComponent } from '../../shared/delete-section-modal/delete-section-modal.component';


@Component({
  selector: 'app-nlp-content-reference-term',
  templateUrl: './nlp-content-reference-term.component.html',
  styleUrls: ['./nlp-content-reference-term.component.scss'],
})
export class NlpContentReferenceTermComponent implements OnInit {
  sectionsNumbers = 0;
  sectionsTitles = [];
  sections: Section[] = [];
  sectionsOriginal: Section[] = [];
  @ViewChild('content') contentElement!: ElementRef;
  modalClosedSuccessfully = false;
  value!: string;

  constructor(
    private modalService: NzModalService,
    private userService: UserService,
    private service: NlpContentTermService,
    private readonly viewContainerRef: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private serviceSelectTermReference: NlpModalSectionTermReferenceService,
    private readonly message: MessageService,
    private modal: NzModalService
  ) {}

  ngOnInit(): void {
    this.userService.updateFlagReferenceTerm(false);
    this.listSection();
  }

  searchFilter() {
    if (this.value !== ''){
      this.sections = this.sectionsOriginal;
      this.sections = this.sections.filter((element: any) => {
        return element.description.toLowerCase().includes(this.value.toLowerCase()) ||
        element.title.toLowerCase().includes(this.value.toLowerCase());
      });
    } else {
      this.sections = this.sectionsOriginal;
    }
  }

  scrollTo(element1: any): void {
    const contentDiv = this.contentElement.nativeElement;
    const element = document.getElementById(element1)?.offsetTop;
    if (element) {
      const scrollOffset = element + 200 - contentDiv.offsetTop;
      contentDiv.scroll({
        top: scrollOffset,
        behavior: 'smooth',
        inline: 'nearest',
      });
    }
  }

  newSection(typeSave: myParam, item?: Section) {
    this.userService.isFlagNlpNewSection = false;
    this.userService.selectSection = item;
    const modal =
      this.modalService.create<NlpModalNewSectionTermReferenceComponent>({
        nzTitle: 'Cadastrar nova seção do Termo de Referência',
        nzWidth: 900,
        nzContent: NlpModalNewSectionTermReferenceComponent,
        nzViewContainerRef: this.viewContainerRef,
        nzFooter: null,
        nzCentered: true,
        nzOkLoading: true,
        nzCancelText: 'No',
        nzComponentParams: {
          typeSave: typeSave,
        },
        nzOnCancel: () => {
          this.modalClosedSuccessfully = true;
        },
      });

    modal.afterClose.subscribe(() => {
      this.listSection();
      this.cdr.detectChanges();
    });
  }

  public delete(item?: Section) {
    let modal = this.modal.create<DeleteSectionModalComponent>({
      nzTitle: 'Excluir Arquivo',
      nzWidth: 600,
      nzContent: DeleteSectionModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
      nzOkLoading: true,
      nzCentered: true,
      nzCancelText: 'No',
      nzOnCancel: () => {
        modal.destroy();
        this.ngOnInit();
      },
    });

    const instance = modal.getContentComponent();
    instance.section = item;

    modal.afterClose.subscribe(() => {
      modal.destroy();
      this.listSection();
      this.cdr.detectChanges();
    });
  }
  onError(error: any) {
    if (error?.status === 417) {
      this.onErrorDeletarNlp(error);
    }
  }

  listSection() {
    this.userService.productNlpObservable$
      .pipe(
        mergeMap((dataTerm) => this.service.getListContentTerm(dataTerm.id))
      )
      .subscribe((data) => {
        this.sectionsNumbers = data.sections.length;
        this.sectionsTitles = data.sections.map(
          (obj: { title: string }) => obj.title
        );
        this.sections = data.sections;
        this.sectionsOriginal = data.sections;
      });
  }

  private onErrorDeletarNlp(error: any) {
    this.message.showErrorMessage(`Erro ao deletar projeto: ${error.message}`);
  }
}

