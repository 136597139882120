import {
  Component,
  Input,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-revision-ripla-requesting-area',
  templateUrl: './revision-ripla-requesting-area.component.html',
  styleUrls: ['./revision-ripla-requesting-area.component.scss'],
})
export class RevisionRiplaRequestingAreaComponent {
  @Input() riplaRevision: any;

  customStyle = {
    'font-size': '12px',
    'width': '300px',
    'max-width': '300px',
    'max-heigth': 'auto'
  };

  constructor(
    private translate: TranslateService
  ) {  }


}
