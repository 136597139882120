<span
  *ngIf="showHint; else noTooltip"
  [nzTooltipTitle]="originalText"
  nzTooltipPlacement="bottom"
  nz-tooltip>
  {{ textOutput }}
</span>
<ng-template #noTooltip>
  <span>
    {{ originalText }}
  </span>
</ng-template>