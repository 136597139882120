import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {LICENSES_V2, LICENSES_V2_BY_COMPETENT_ORGAN, LICENSES_V2_ENVIROMENTAL_STUDIES, LICENSES_V2_FINAL_ANALYSIS, LICENSES_V2_INITIAL_LICENSE_STRATEGY, LICENSING_TYPES_LIST, UPDATE_SUB_PROJECT_REVIEWS} from "../../modules/projects/constants/apiEndpointsConstants";
import {Observable} from "rxjs";
import {LicenseResponseDto} from "../dto/licenses-response.dto";
import { RequestFinalAnalysis, RequestInitialLicenseStrategy } from "../models/final-analysis";
import { LicencingModel } from "../models/licencsing,model";

@Injectable({
  providedIn: 'root'
})
export class LicensesV2Service {
  constructor(private readonly http: HttpClient) {
  }

  getFinalAnalysisBySubProjectId(subprojectId: number): Observable<any[]> {
    const url = `${LICENSES_V2_FINAL_ANALYSIS}/${subprojectId}`;
    return this.http.get<any[]>(url);
  }

  getLicensesByProjectId(projectId: number): Observable<LicenseResponseDto[]> {
    const url = `${LICENSES_V2}/${projectId}`;
    return this.http.get<LicenseResponseDto[]>(url);
  }

  getLicensingList(): Observable<any> {
    const url = `${LICENSING_TYPES_LIST}`;
    return this.http.get<any>(url)
  }

  getLicensesByCompetentOrganId(competentOrganId: number): Observable<LicenseResponseDto[]> {
    const url = `${LICENSES_V2_BY_COMPETENT_ORGAN}/${competentOrganId}`;
    return this.http.get<LicenseResponseDto[]>(url);
  }

  saveDraftFinalAnalysis(subProjectId: number, request: RequestFinalAnalysis):Observable<any>{
    const url = `${LICENSES_V2_FINAL_ANALYSIS}/${subProjectId}`;
    return this.http.patch<RequestFinalAnalysis>(url, request);
  }

  publishFinalAnalysis(subProjectId: number, request: RequestFinalAnalysis):Observable<any>{
    const url = `${LICENSES_V2_FINAL_ANALYSIS}/${subProjectId}`;
    return this.http.patch<RequestFinalAnalysis>(url, request);
  }


  getInitialStrategy(subProjectId: number):Observable<any>{
    const url = `${LICENSES_V2_INITIAL_LICENSE_STRATEGY}/${subProjectId}`;
    return this.http.get<RequestInitialLicenseStrategy>(url);
  }

  postInitialStrategyList(subProjectId: Number, request: any):Observable<any>{
    const url = `${LICENSES_V2_INITIAL_LICENSE_STRATEGY}/${subProjectId}`;
    return this.http.post<any>(url, request);
  }

  postEnviromentalStudyList(subProjectId: Number, request: any):Observable<any>{
    const url = `${LICENSES_V2_ENVIROMENTAL_STUDIES}/${subProjectId}`;
    return this.http.post<any>(url, request);
  }

  getLicenses():Observable<any>{
    const url = `${LICENSING_TYPES_LIST}`;
    return this.http.get<any>(url);
  }

}
