import {Component, type OnInit, Input, OnDestroy} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { ActivePageService } from 'src/app/shared/common/services/active-page/active-page.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy{
  @Input() expanded!: boolean;

  public currentPage: string = '';
  public projectsPage: boolean = false;
  public communicationsPage: boolean = false;
  public dashboardsPage: boolean = false;
  public nlpPage: boolean = false;
  public homePage: boolean = false;
  public documentsPage: boolean = false;
  public helpCenterPage: boolean = false;
  public taskPage: boolean = false;
  public helpPage: boolean = false;
  private pageSubscription: Subscription;

  customStyle = {
    'font-size': '12px',
    'width': '170px',
    'padding': '15px',
    'gap': '12px',
    'background': 'black'
  };

  constructor(
    private activePage: ActivePageService,
    private router: Router
  ) {
    this.pageSubscription =  this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.changeActiveIcon(event.url);
    });
  }

  ngOnInit(): void { return; }

  changeActiveIcon(page) {
    this.nlpPage = false;
    this.projectsPage = false;
    this.communicationsPage = false;
    this.documentsPage = false;
    this.dashboardsPage = false;
    this.taskPage = false;
    this.helpPage = false;

    if (page.includes('/tasks')) {
      this.taskPage = true;
    }

    if (page.includes('/nlp')) {
      this.nlpPage = true;
    }

    if (page.includes('/documents')) {
      this.documentsPage = true;
    }

    if (page.includes('/communications')) {
      this.communicationsPage = true;
    }

    if (page.includes('/project')) {
      this.projectsPage = true;
    }

    if (page.includes('/dashboards')) {
      this.dashboardsPage = true;
    }

    if (page.includes('/help-center')) {
      this.helpPage = true;
    }

  }

  ngOnDestroy() {
    this.pageSubscription.unsubscribe();
  }

  gotoDocuments(){
    sessionStorage.removeItem('documents')
    this.router.navigate(['/documents'])
  }
}
