<div class="perfil">
  <div nz-row>
    <div nz-col [nzSpan]="24" class="item">
      <label class="txtlabel">Nome</label>
      <div class="formatText">{{user?.name ? user?.name : user?.preferred_username}}</div>
    </div>

    <div nz-col [nzSpan]="24" class="item">
      <label class="txtlabel">E-mail</label>
      <div class="formatText">{{user.email}}</div>
    </div>

    <div nz-col [nzSpan]="24" class="item">
      <label class="txtlabel">Região</label>
      <div class="formatText">
        <span class="regionTag" *ngFor="let complex of userRegionNames">
          <app-short-text-hint [originalText]="complex" [maxChars]="40"></app-short-text-hint>
        </span>
      </div>
    </div>

    <div nz-col [nzSpan]="24" class="item">
      <label class="txtlabel">Perfil de usuário</label>
      <div class="formatText" *ngFor="let perfil of roles">{{perfil.description | titlecase}}</div>
    </div>

    <div class="message">
      Caso precise requisitar alguma alteração, <br/>
      por gentileza abrir chamado no Vale Support Center
    </div>
  </div>

  <div class="logout" (click)="logout()">
    <app-svg-icon icon="icon_arrow_circle"></app-svg-icon>{{ 'commons.logout' | translate}}
  </div>
</div>
