import { Component, ViewChild, type OnInit } from '@angular/core';
import { ActivePageService } from 'src/app/shared/common/services/active-page/active-page.service';
import { DashboardsEnum } from '../../shared/enum/dashboards.enum';

import { TopBreadcrumbService } from 'src/app/shared/common/services/top-breadcrumb/top-breadcrumb.service';

import { PowerBIReportEmbedComponent } from 'powerbi-client-angular';
import {
  IReportEmbedConfiguration,
  service,
  Embed,
} from 'powerbi-client';

import * as models from 'powerbi-models';

@Component({
  selector: 'app-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.scss'],
})
export class DashboardsComponent implements OnInit {
  reportConfig: IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: '',
    tokenType: models.TokenType.Embed,
    accessToken: '',
    settings: undefined,
  };

  @ViewChild(PowerBIReportEmbedComponent)
  reportObj!: PowerBIReportEmbedComponent;

  eventHandlersMap = new Map([
    [
      'loaded',
      () => {
        const report = this.reportObj.getReport();
        report.setComponentTitle('Embedded report');
      },
    ],
    ['rendered', () => console.log('Report has rendered')],
    [
      'error',
      (event?: service.ICustomEvent<any>) => {
        if (event) {
          console.error(event.detail);
        }
      },
    ],
    ['visualClicked', () => console.log('visual clicked')],
    ['pageChanged', (event) => ''],
  ]) as Map<
    string,
    (event?: service.ICustomEvent<any>, embeddedEntity?: Embed) => void | null
  >;

  constructor(
    private activePage: ActivePageService,
    private topBreadcrumb: TopBreadcrumbService
  ) {}
  dashboardTypeLicensing: boolean = true;
  dashboardTypeEia: boolean = false;
  dashboardTypeManager: boolean = false;
  dashboardTypeSupplier: boolean = false;

  dashboardToDisplay: string = '';
  dashboardNameToDisplayBreadcrumb;


  ngOnInit(): void {
    this.activePage.setPage('dashboards');

    this.dashboardTypeLicensing = true;
    this.dashboardNameToDisplayBreadcrumb = 'Licenciamento';
    this.updateBradcrumb();

  }

  updateBradcrumb() {
    this.topBreadcrumb.reorgLevels({
      level: 0,
      title: 'Dashboards',
      route: 'dashboards',
    });
    this.topBreadcrumb.reorgLevels({
      level: 1,
      title: this.dashboardNameToDisplayBreadcrumb,
      route: 'dashboards',
    });
  }

  handleCheckClick(event) {
    this.dashboardTypeLicensing = false;
    this.dashboardTypeEia = false;
    this.dashboardTypeManager = false;
    this.dashboardTypeSupplier = false;
    if (event === DashboardsEnum.LIC) {
      this.dashboardTypeLicensing = true;
      this.dashboardNameToDisplayBreadcrumb = 'Licenciamento';
      this.dashboardToDisplay = 'ReportSectiondc0bdd83f3d0f9ff4729';
    } else if (event === DashboardsEnum.EIA) {
      this.dashboardTypeEia = true;
      this.dashboardNameToDisplayBreadcrumb = 'Estudos Ambientais';
      this.dashboardToDisplay = 'ReportSectiona271643cba2213c935be';
    } else if (event === DashboardsEnum.MAN) {
      this.dashboardTypeManager = true;
      this.dashboardNameToDisplayBreadcrumb = 'Gerencial';
      this.dashboardToDisplay = 'ReportSectiona271643cba2213c935be';
    } else if (event === DashboardsEnum.SUP) {
      this.dashboardTypeSupplier = true;
      this.dashboardNameToDisplayBreadcrumb = 'Fornecedores';
      this.dashboardToDisplay = 'ReportSection476aef7e0a344328dfe5';
    } else {
      this.dashboardTypeLicensing = true;
      this.dashboardNameToDisplayBreadcrumb = 'Licenciamento';
      this.dashboardToDisplay = 'ReportSectiondc0bdd83f3d0f9ff4729';
    }
    this.updateBradcrumb();
  }

}
