<ol class="breadcrumb">
  <li><a routerLink="" class="breadcrumb">Início</a></li>
  <li *ngFor="let breadcrumb of breadcrumbs">
    <span class="level">
      {{ breadcrumbs ? '>' : '' }}
    </span>
    <a [routerLink]="[breadcrumb.url && breadcrumb.url !== '' ? breadcrumb.url : ' ']">
      <span><app-short-text-hint [originalText]="breadcrumb.label ? breadcrumb.label : breadcrumb.label==='' ? '...' : breadcrumb.label" [maxChars]="30"></app-short-text-hint></span>
    </a>
  </li>
</ol>
