<section
  class="app-communications-container communicationsContainer"
  *ngIf="loadingCommunications"
>
  <header class="communicationsContainer__header">
    <h3 class="communicationsContainer__header__title">
      {{ "projects.communication.list-title" | translate }}
    </h3>
  </header>
  <div class="communicationsContainer__card">
    <span><img src="/assets/icons/empty_box-icon.svg" alt="Sem dados" /></span>
    <p class="communicationsContainer__card__info">
      {{ "projects.communication.empty-placeholder" | translate }}
    </p>
  </div>
</section>

<app-communication-list
  [communicationsList]="communicationsList"
  [projectId]="params.projectIdParam"
  [processId]="params.projectLicensingIdParam"
  [activityId]="params.activityIdParam"
  *ngIf="!loadingCommunications"
>
</app-communication-list>
