import {Injectable} from '@angular/core';
import {TASK_CREATE, TASK_LIST} from '../../projects/constants/apiEndpointsConstants';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {TaskList} from '../tasks.model';

@Injectable({
  providedIn: 'root'
})
export class TasksService {
  showErrorMessage(arg0: string) {
    throw new Error(arg0);
  }

  constructor(private readonly http: HttpClient) {
  }

  priorityList = [
    {
      name: "Prioritário",
      value: 'SIM'
    },
    {
      name: "Não Prioritário",
      value: 'NAO',
    },
  ]

  updateTaskPriority(params): Observable<Task> {
    return this.http.patch<any>(`${TASK_LIST}/${params.id}`, params);
  }

  updateTaskStatus(params): Observable<Task> {
    return this.http.patch<any>(`${TASK_LIST}/${params.id}`, params);
  }

  updateTaskDeadline(params): Observable<Task> {
    return this.http.patch<any>(`${TASK_LIST}/${params.id}`, params);
  }

  createTask(payload): Observable<any> {
    return this.http.post(`${TASK_CREATE}`, payload)
  }

  getTasksList(userId: string): Observable<TaskList> {
    return this.http.get<any>(`${TASK_LIST}?size=1000&userId=${userId}`);
  }

}
