import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.component.scss']
})
export class InputTextareaComponent implements OnInit {

  @Input() controls: any;
  @Input() id: string = '';
  @Input() name: string = '';
  @Input() placeholder: string = '';
  @Input() errorMessage: string = '';
  @Input() status: any = ' ' || 'error' || 'warning';
  @Input() required: boolean = false;
  @Input() styleResize: string = '';
  @Input() rows: string = '5';
  @Input() charCount: number = 1;
  @Output() changeValue: EventEmitter<string> = new EventEmitter<string>();
  textReturn:string = '';
  textCount:number = 0;
  
  constructor() { }

  ngOnInit() {
    this.textCount = this.controls?.value?.length;
  }

  onChangeValue(event) {
    this.textReturn = event.target.value;
    this.limitChars(event.target.value);
    this.changeValue.emit(this.textReturn)
  }

  limitChars(text){
    this.textCount = text.length;
    if(this.charCount && this.charCount > 1 && text.length > this.charCount){
      this.textReturn = text.substring(0, this.charCount);
      this.status = 'error';
      return true;
    }
    this.status = ' ';
    return false
  }

}
