<app-filter-notifications (filteredList)="listFilter($event)"></app-filter-notifications>

<nz-tabset nzCentered>
  <nz-tab nzTitle="Todas">
    <app-all-notifications [listItems]="listContent"></app-all-notifications>
  </nz-tab>
  <nz-tab nzTitle="Atribuídas" [nzDisabled]="true">
    <!-- <app-assigned-notifications [listItems]="listAssigned"></app-assigned-notifications>     -->
  </nz-tab>
  <nz-tab nzTitle="Menções" [nzDisabled]="true">
    <!-- <app-mentions-notifications [listItems]="listContent"></app-mentions-notifications> -->
  </nz-tab>
</nz-tabset>
