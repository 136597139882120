<div class="licensing-form">
  <section class="licensing-form-wrapper">
    <nz-table
      [nzData]="['']"
      [nzFrontPagination]="false"
      class="fillable-form"
    >
      <thead>
        <strong class="table-title">{{ 'licensing-process.creation-grid.title' | translate }}</strong>
        <tr>
          <th class="tableTh subProjectHeaderTitle">
            {{ 'licensing-process.creation-grid.organ' | translate }} <span class="mandatory-indicator">*</span>
          </th>
          <th class="tableTh subProjectHeaderTitle">
            {{ 'licensing-process.creation-grid.license' | translate }} <span class="mandatory-indicator">*</span>
          </th>
          <th class="tableTh subProjectHeaderTitle">
            {{ 'licensing-process.creation-grid.process-number' | translate }}
          </th>
          <th class="tableTh subProjectHeaderTitle">
            {{ 'licensing-process.creation-grid.responsible' | translate }}
          </th>
          <th class="tableTh subProjectHeaderTitle">
            {{ 'licensing-process.creation-grid.conclusion-date' | translate }}
          </th>
          <th class="tableTh subProjectHeaderTitle">
            {{ 'licensing-process.creation-grid.protocol-date' | translate }}
          </th>
          <th class="tableTh subProjectHeaderTitle">
            {{ 'licensing-process.creation-grid.process-status' | translate }}
          </th>
          <th class="tableTh subProjectHeaderTitle divider-cell"> </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>
            <app-licensing-form-item id="competentOrganId" placeholder="Informe o órgão"
            [onChange]="handleChange.bind(this)" itemType="select" [selectOptions]="competentOrgans"
              selectValueToShowName="code" [projectLicensingCreateForm]="projectLicensingCreateForm"
            ></app-licensing-form-item>

            <span *ngIf="isSavedProcess">{{ process.competentOrgan.code }}</span>
          </td>
          <td>
            <app-licensing-form-item id="licenseTypeId" placeholder="Informe a licença"
              [onChange]="handleChange.bind(this)" itemType="select" [selectOptions]="licensesTypes"
              selectValueToShowName="description" [projectLicensingCreateForm]="projectLicensingCreateForm"
              [licensesTypes]="licensesTypes" [changePageTitle]="changePageTitle"
            ></app-licensing-form-item>

            <span *ngIf="isSavedProcess">{{ process.license.description }}</span>
          </td>
          <td>
            <app-licensing-form-item id="processNumber" placeholder="Informe o número do processo"
              [onChange]="handleChange.bind(this)" itemType="input"
              [projectLicensingCreateForm]="projectLicensingCreateForm"
            ></app-licensing-form-item>

            <span *ngIf="isSavedProcess">{{ process.processNumber ? process.processNumber : 'Não protocolado'}}</span>
          </td>
          <td>
            <app-licensing-form-item id="userLegalRepId" placeholder="Informe o responsável"
              [onChange]="handleChange.bind(this)" itemType="select" [selectOptions]="users"
              selectValueToShowName="name" [projectLicensingCreateForm]="projectLicensingCreateForm" [disabled]="disableLicenseType"
            ></app-licensing-form-item>

            <span *ngIf="isSavedProcess">{{ (process.userLegalRep ? process.userLegalRep.name : '-') | titlecase}}</span>
          </td>
          <td>
            <app-licensing-form-item id="deadline" placeholder="Informe a data prevista"
              [onChange]="handleChange.bind(this)" itemType="date" [disabledDate]="disabledDate"
              [projectLicensingCreateForm]="projectLicensingCreateForm"
            ></app-licensing-form-item>

            <span *ngIf="isSavedProcess" class="txt-warn">{{ process.deadline ? process.deadline : 'Pendente'}}</span>
          </td>
          <td>
            <app-licensing-form-item id="protocolDate" placeholder="Informe a data do protocolo"
              [onChange]="handleChange.bind(this)" itemType="date"
              [projectLicensingCreateForm]="projectLicensingCreateForm"
            ></app-licensing-form-item>

            <span *ngIf="isSavedProcess" class="txt-warn">{{ process.protocolDate ? process.protocolDate : 'Não protocolado'}}</span>
          </td>
          <td>
            <app-badge badgeText="A iniciar"></app-badge>
          </td>
          <td>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0.292893 11.7071C-0.0976311 11.3166 -0.0976312 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976316 1.31658 -0.0976316 0.683417 0.292893 0.292893C0.683417 -0.0976315 1.31658 -0.0976315 1.70711 0.292893L7.41421 6L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071Z"
                fill="#969696" />
            </svg>
          </td>
        </tr>
      </tbody>
    </nz-table>

    <nz-table
      [nzData]="['']"
      [nzFrontPagination]="false"
    >
      <thead>
        <strong class="table-title">{{ 'licensing-process.creation-grid.license' | translate }}</strong>
        <tr>
          <th class="tableTh subProjectHeaderTitle">
            {{ 'licensing-process.creation-grid.document-number' | translate }}
          </th>
          <th class="tableTh subProjectHeaderTitle">
            {{ 'licensing-process.creation-grid.emission' | translate }}
          </th>
          <th class="tableTh subProjectHeaderTitle">
            {{ 'licensing-process.creation-grid.validity' | translate }}
          </th>
          <th class="tableTh subProjectHeaderTitle">
            {{ 'licensing-process.creation-grid.boundary-date' | translate }}
          </th>
          <th class="tableTh subProjectHeaderTitle">
            {{ 'licensing-process.creation-grid.license-status' | translate }}
          </th>
        </tr>
      </thead>
      <tbody class="licensing-form-table-body">
        <tr>
          <td><input nz-input class="licensing-form-input" nzSize="small" [disabled]="true" nzBorderless placeholder="Não emitida"></td>
          <td><input nz-input class="licensing-form-input" nzSize="small" [disabled]="true" nzBorderless placeholder="Não emitida"></td>
          <td><input nz-input class="licensing-form-input" nzSize="small" [disabled]="true" nzBorderless placeholder="Não emitida"></td>
          <td><input nz-input class="licensing-form-input" nzSize="small" [disabled]="true" nzBorderless placeholder="Não emitida"></td>
          <td>
            <app-badge badgeText="A iniciar"></app-badge>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </section>
</div>
