import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss']
})
export class LoadingBarComponent implements OnInit {
  @Input() progressionType: string;
  BAR_PROGRESSION_TYPE = {
    notStarted: "not_started",
    inProgress: "in_progress",
    finished: "finished"
  }

  constructor(){

  }

  ngOnInit(): void {
    //console.log(this.progressionType)
  }
}