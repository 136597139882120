<div nz-row class="nlp-modal-update-tr-result">
    <div
      nz-col
      nzXs="24"
      nzSm="24"
      nzMd="24"
      nzLg="24"
      nzXl="24"
      >
      Confirme a exclusão da seção ?
      </div>
</div>
<div class="ant-modal-footer">
    <div nz-row>
        <div nz-col   
        nzXs="18"
        nzSm="18"
        nzMd="18"
        nzLg="18"
        nzXl="18"
         [nzSpan]="18" [nzPush]="6">
            <button class="btn-sl btn-sl-grey btn-cancel"
            (click)="handleCancel()">Cancelar</button>
            <button button nz-button nzType="primary"
            class="btn-sl btn-sl-green btn-save"
            (click)="handleDelete()">Confirmar</button>
        </div>
        <div nz-col [nzSpan]="6" [nzPull]="18"></div>
      </div>
</div>
