export interface SummaryScopeChange {
    change: Array<{id: number, description: string}>;
    description: string
}

export interface SummaryAboutProject {
    projectName: string;
    projectCode: string;
    projectClassification: string;
    projectNumber: string;
    projectDescription: string;
    localization: string;
    areaRequest: string;
    responsible: string;
}