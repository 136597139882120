import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-colaborative-link',
  templateUrl: './collaborative-link.component.html',
  styleUrls: ['./collaborative-link.component.scss']
})
export class CollaborativeLinkComponent {
  @Input() collaborativeLink: string = '';
  @Input() subProjectId: number;

  constructor() { }


}
