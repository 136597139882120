import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { EDIT_SUB_PROJECT_REVIEWS, SUB_PROJECT_REVIEWS } from 'src/app/modules/projects/constants/apiEndpointsConstants'

import { SubProjectReviewDto, SubprojectSaveDto } from 'src/app/shared/dto/subproject-save.dto';

@Injectable({
  providedIn: 'root',
})
export class ProjectsRequestReviewService {
  baseUrl: string = environment.apiUrl;
  constructor(private readonly http: HttpClient) { }

  getProjectsRequestReviewReasons(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/reasons`);
  }

  
  getSuspensionCancellationReasons(typeReason: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/requisition-reasons?type=${typeReason}`);
  }



  postSubprojectReviews(subProject: SubprojectSaveDto): Observable<any> {
    return this.http.post<any>(`${SUB_PROJECT_REVIEWS}`, subProject);
  }

  putSubProjectReviews(id: number, payload: SubProjectReviewDto): Observable<any> {
    return this.http.put<any>(`${EDIT_SUB_PROJECT_REVIEWS}/${id}`, payload)
  }

  //SUSPENSAO E CANCELAMENTO
  postSuspensionCancellation(payload: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/suspensions-project`, payload)
  }

}
