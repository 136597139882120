import {
  Component,
  OnInit,
  inject,
  Input,
} from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { MessageService } from 'src/app/shared/common/services/message.service';
import { AttachmentsService } from 'src/app/shared/services/attachments.service';
import { UtilService } from 'src/app/shared/util.service';
import { LicensingService } from 'src/app/modules/projects/projects-licensing/service/licensing.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirmation-send-draft-modal',
  templateUrl: './confirmation-send-draft-modal.component.html',
  styleUrls: ['./confirmation-send-draft-modal.component.scss'],
})
export class ConfirmationSendDraftModalComponent implements OnInit {
  readonly #modal = inject(NzModalRef);
  @Input() dataToAnalisys: any;

  arrayImageDelete: any[] = [];
  fileList: NzUploadFile[] = [];
  fileListStorageRepite: NzUploadFile[] = [];
  bodyFormat: any;

  //DADOS DA TASK MODAL
  taskToEdit;

  progressBarValue = 0

  constructor(
    private readonly modal: NzModalService,
    private readonly message: MessageService,
    private readonly utilService: UtilService,
    private readonly attachmentsService: AttachmentsService,
    private readonly service: LicensingService,
    private readonly _router: Router,
    private readonly translate: TranslateService
  ) {
  }
  
  ngOnInit(): void {
    //console.log("save consolidation ripla draft")
  }

  goToProject() {
    this.#modal.destroy();
  }
  close(){
    this.#modal.destroy();
  }

}
