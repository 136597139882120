import { formatDate } from '@angular/common';
import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ProjectsV2Service } from 'src/app/shared/services/projects-v2.service';
import { UtilService } from 'src/app/shared/util.service';
import moment from 'moment';
import { NzTableFilterFn, NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';
import { ProcessesListResponseDto } from 'src/app/shared/dto/process-list-response.dto';
import { Subject } from 'rxjs';
import { BreadcrumbState } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';
import { Store } from '@ngrx/store';
import { ProjectState } from 'src/app/shared/ngrx/breadcrumb/model';
import { BREADCRUMB_ACTIONS } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';
import { ProjectModel } from 'src/app/shared/models/project.model';
import { ProjectsService } from '../../projects/projects.service';
import {SubProjectPageResponse} from "../../../shared/dto/suprojects-page-response.dto";
interface ColumnItem {
  name: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<ProcessesListResponseDto> | null;
  visible: boolean;
  sortDirections: NzTableSortOrder[];
  filterMultiple: boolean;
  listOfFilter: Array<{ value: string }>;
  filterFn: NzTableFilterFn<ProcessesListResponseDto> | null;
}

@Component({
  selector: 'app-processes-list',
  templateUrl: './processes-list.component.html',
  styleUrls: ['./processes-list.component.scss'],
})
export class ProcessesListComponent implements OnInit, OnDestroy, AfterContentChecked {

  dateFormat = 'dd/MM/YYYY';
  projectId: any = 1;
  listOfData: any;
  listOfDataOriginal: any[] = [];
  filterOrgan: any[] = [];
  filterResponsible: any[] = [];
  filterConclusionDate: any[] = [];
  filterProtocolDate: any[] = [];
  filterProcessStatus: any[] = [];
  filterValidity: any[] = [];
  filterLicenseStatus: any[] = [];
  filterVisible = {
    title: false,
    organ: false,
    responsibleId: false,
    conclusionDate: false,
    protocolDate: false,
    status: false,
    validity: false,
    licenseStatus: false
  }
  filterValues = {
    title: "",
    organ: [],
    responsibleId: [],
    conclusionDate: "",
    protocolDate: "",
    status: [],
    validity: "",
    licenseStatus: []
  }
  searchValue = '';
  screenWidth;
  marginLeftForLicense: number = 0
  datesFilter;
  dateType: 'conclusionDate' | 'protocolDate' | 'validity' | null = null;
  btnNewProcess: boolean = false;

  private readonly destroy$: Subject<any> = new Subject();
  project: ProjectModel
  sidebar: boolean | null
  cadProcessLicensing: boolean = false;



  constructor(
    private readonly serviceProjects: ProjectsV2Service,
    private projectService: ProjectsService,
    private router: Router,
    private route: ActivatedRoute,
    private readonly utilService: UtilService,
    private readonly changeDetector: ChangeDetectorRef,
    private store: Store<BreadcrumbState>
    ) {}

  // COLUNAS PARA FILTRO E ORDENACAO
  listOfColumns: ColumnItem[] = [
    {
      name: 'process-list-title',
      sortOrder: null,
      sortFn: null,
      sortDirections: ['ascend', 'descend', null],
      visible: false,
      filterMultiple: false,
      listOfFilter: [],
      filterFn: null
    },
    {
      name: 'process-list-organ',
      sortOrder: null,
      sortFn: (a: ProcessesListResponseDto, b: ProcessesListResponseDto) => a.competentOrgan.description.localeCompare(b.competentOrgan.description),
      sortDirections: ['ascend', 'descend', null],
      visible: false,
      filterMultiple: false,
      listOfFilter: [],
      filterFn: (list: string[], item: ProcessesListResponseDto) => list.some(name => item.competentOrgan['description'].indexOf(name) !== -1)
    },
    {
      name: 'process-list-responsible',
      sortOrder: null,
      sortFn: (a: ProcessesListResponseDto, b: ProcessesListResponseDto) => (a.responsible && b.responsible) ? ( a.responsible.name.localeCompare(b.responsible.name)) : 0,
      sortDirections: ['ascend', 'descend', null],
      visible: false,
      filterMultiple: false,
      listOfFilter: [],
      filterFn: (list: string[], item: ProcessesListResponseDto) => list.some(name => item.responsible.name.indexOf(name) !== -1),
    },
    {
      name: 'process-list-conclusion-date',
      sortOrder: null,
      sortFn: (a: ProcessesListResponseDto, b: ProcessesListResponseDto) => a.deadline ? a.deadline.localeCompare(b.deadline) : 0,
      sortDirections: ['ascend', 'descend', null],
      visible: false,
      filterMultiple: false,
      listOfFilter: [],
      filterFn: (list: string[], item: ProcessesListResponseDto) => list.some(name => item.deadline.indexOf(name) !== -1)
    },
    {
      name: 'process-list-protocol-date',
      sortOrder: null,
      sortFn: (a: ProcessesListResponseDto, b: ProcessesListResponseDto) => a.protocolDate? a.protocolDate.localeCompare(b.protocolDate) : 0,
      sortDirections: ['ascend', 'descend', null],
      visible: false,
      filterMultiple: false,
      listOfFilter: [],
      filterFn: (list: string[], item: ProcessesListResponseDto) => list.some(name => item.deadline.indexOf(name) !== -1)
    },

    {
      name: 'process-list-status',
      sortOrder: null,
      sortFn: null,
      sortDirections: ['ascend', 'descend', null],
      visible: false,
      filterMultiple: false,
      listOfFilter: [],
      filterFn: null
    },
    {
      name: 'process-list-vigencia',
      sortOrder: null,
      sortFn: (a: ProcessesListResponseDto, b: ProcessesListResponseDto) =>  a.effectiveDate ? a.effectiveDate.localeCompare(b.effectiveDate) : 0,
      sortDirections: ['ascend', 'descend', null],
      visible: false,
      filterMultiple: false,
      listOfFilter: [],
      filterFn: (list: string[], item: ProcessesListResponseDto) => list.some(name => item.effectiveDate.indexOf(name) !== -1)
    },
    {
      name: 'statusLicense',
      sortOrder: null,
      sortFn: null,
      sortDirections: ['ascend', 'descend', null],
      visible: false,
      filterMultiple: false,
      listOfFilter: [],
      filterFn: null
    },
  ];

  ngOnInit() {
    this.cadProcessLicensing = this.utilService.getAuthorization('insert', 'CADPROCESSLICENSING');
    this.sidebar = window.localStorage.getItem('sidebar') === 'false' ? false : true;
    this.getProject();
  }

  ngAfterContentChecked(): void {
    this.sidebar = window.localStorage.getItem('sidebar') === 'false' ? false : true;
    this.changeDetector.detectChanges();
  }

  // INICIO BREADCRUMB
  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private dispatchProjectOnStore(projectRequest: any): void {
    let project: ProjectState = {
      name: 'Projetos',
      url: '/projects-v2',
      last: false,
      sublevels: true,
      project: {
        name: projectRequest ? projectRequest.name : '',
        url: 'project-v2-view/' + projectRequest.id,
        last: false,
      },
      subProject: null,
      parecerFinal: null,
      pedidoRevisao: null,
      comunicacoes: null,
      processos: {
        name: 'Processos',
        url: 'projects/' + projectRequest.id + '/processes' ,
        last: true,
      },
      atividades: null,
      subatividades: null,
      mudancaEscopo: null
    }
    this.store.dispatch(BREADCRUMB_ACTIONS.getRiplaProject({ project }));
  }
  // FINAL BREADCRUMB

  getProject(){
    this.route.parent?.params.subscribe((params: Params) => {
      this.projectId = params['projectId'];
      this.getProjectData();
      this.getProcessList();
    });
  }

  getProjectData() {
    this.projectService.getProject(this.projectId).subscribe({
      next: (project) => {
        this.project = project;
        console.log(this.project)
        this.dispatchProjectOnStore(this.project)
        this.getSubProjectsById(this.projectId);
      }
    });
  }

  getProcessList() {
    this.serviceProjects.getProcessessList(this.projectId).subscribe({
      next: (data) => {
        this.listOfDataOriginal = data.content;
        this.listOfData = data.content;
        this.listOfData.sort((a, b) => {
          if (a.registrationDate < b.registrationDate) return -1;
          if (a.registrationDate > b.registrationDate) return 1;
          if (a.status === 'PEM' && b.status !== 'PEM') return 1;
          if (a.status !== 'PEM' && b.status === 'PEM') return -1;
          return 0;
        });
        this.createFilters();
      },
      error: (error) => console.error('error to load licenses ', error),
    });
  }

  getPageableProcessList() {
    console.log(this.filterValues)
    const validFilters = this.getValidFilters(this.filterValues);
    console.log(validFilters)

    this.serviceProjects.getPageableProcessesList(this.projectId, validFilters).subscribe({
      next: (data) => {
        if (this.datesFilter && this.datesFilter.length > 0) {
          this.listOfData = data.content;
          this.filterDateElements(this.datesFilter, this.dateType);
        } else {
          this.listOfData = data.content;
        }
      },
      error: (error) => console.error('error to load licenses ', error),
    })
  }

  private getValidFilters(filters) {
    return {
      title: filters.title,
      organ: filters.organ.length > 0 ? filters.organ : null,
      responsibleId: filters.responsibleId.length > 0 ? filters.responsibleId : null,
      conclusionDate: filters.conclusionDate,
      protocolDate: filters.protocolDate,
      status: filters.status.length > 0 ? filters.status : null,
      validity: filters.validity,
      licenseStatus: filters.licenseStatus.length > 0 ? filters.licenseStatus : null,
    }
  }

  filterDateElements(datas, tipo) {
    this.datesFilter = datas;
    this.dateType = tipo;
    if (datas.length >= 2) {
      const dateInitial = moment(datas[0]).format("YYYY-MM-DD");
      const dateFinal = moment(datas[1]).format("YYYY-MM-DD");
      let currentDate
      this.listOfData = this.listOfData.filter((result) => {
        if(tipo === 'conclusionDate') {
          currentDate = moment(result.deadline).format("YYYY-MM-DD");
        } else if(tipo === 'protocolDate') {
          currentDate = moment(result.protocolDate).format("YYYY-MM-DD")
        } else if(tipo === 'validity'){
          currentDate = moment(result.effectiveDate).format("YYYY-MM-DD")
        }
        this.listOfData = currentDate >= dateInitial && currentDate <= dateFinal;
        return currentDate >= dateInitial && currentDate <= dateFinal;
      });
    } else {
      this.dateType = null;
      this.datesFilter = undefined;
      this.getPageableProcessList();
    }
  }

  updateFilterAndCallEndpoint(filter: any = null, shouldGetChecked: boolean = false) {
    if (shouldGetChecked) {
      filter = this.getCorrectValue(filter);
    }

    if (filter) {
      this.filterValues = {
        ...this.filterValues,
        [filter.key]: filter.value
      }

      this.getPageableProcessList();
    }
  }

  getCorrectValue(filter: any) {
    let valueChecked: any[] = [];
    for (let filterValue of filter.value) {
      if (filterValue.checked) valueChecked.push(filterValue.value);
    }

    filter.value = valueChecked;
    return filter;
  }

  goToProjectView(id){
    sessionStorage.setItem('origin', 'processList');
    this.router.navigate([`/project/${this.projectId}/edit-processv2/${id}`]);
  }

  navigateBack(){
    this.router.navigate([`/project-v2-view/${this.projectId}`]);
  }

  navigateToNewLicensingProcess() {
    sessionStorage.setItem('origin', 'processList');
    this.router.navigate([`/project/${this.projectId}/edit-processv2/0`]);
  }

  createFilters() {
    const addToFilterIfNotExist = (filterArray, element, property, isDate = false) => {
      let value = this.utilService.getNestedObjectByString(element, property);
      const newElement = {
        label: value,
        value: value,
        checked: false
      };

      if (isDate && newElement.value) {
        value = formatDate(newElement.value, 'dd/MM/yyyy', 'pt-BR');
        newElement.value = value;
        newElement.label = value;
      }

      const existing = filterArray.some(result => result.label === value);
      if (!existing && this.utilService.getNestedObjectByString(element, property)) {
        filterArray.push(newElement);
      }

    };

    const addToFilterStatusProcessIfNotExist = (filterArray, element, property) => {
      let statusDescription = this.utilService.getStatusProcess(element, property);
      if (statusDescription && statusDescription?.length > 3) {
        const newElementStatus = {
          label: statusDescription,
          value: element.status,
          checked: false
        }
        const existingStatus = filterArray.some(result => result.label === statusDescription);
        if (!existingStatus) {
          filterArray.push(newElementStatus);
        }
        };
    };

    const addToFilterStatusLicenseIfNotExist = (filterArray, element, property) => {
      let statusDescription = this.utilService.getStatusLicense(element, property);
      if (statusDescription && statusDescription?.length > 3) {
        const newElementStatusLicense = {
          label: statusDescription,
          value: element.status,
          checked: false
        }
        const existingStatusLicense = filterArray.some(result => result.label === statusDescription);
        if (!existingStatusLicense) {
          filterArray.push(newElementStatusLicense);
        }
        };
    };

    const addToFilterResponsibleIfNotExist = (filterArray, element, property) => {
      if(element.responsible){
        const newElementResponsible = {
          label: element.responsible.name,
          value: element.responsible.id,
          checked: false
        }

        const existingResponsible = filterArray.some(result => result.label === element.responsible.name);
        if (!existingResponsible) {
          filterArray.push(newElementResponsible);
        }
      }
    };

    this.listOfData.forEach(element => {
      addToFilterIfNotExist(this.filterOrgan, element, 'competentOrgan.code');
      addToFilterResponsibleIfNotExist(this.filterResponsible, element, 'responsible.name');
      addToFilterIfNotExist(this.filterConclusionDate, element, 'deadline', true);
      addToFilterIfNotExist(this.filterProtocolDate, element, 'protocolDate', true);
      addToFilterStatusProcessIfNotExist(this.filterProcessStatus, element, 'status');
      addToFilterIfNotExist(this.filterValidity, element, 'duration', true);
      addToFilterStatusLicenseIfNotExist(this.filterLicenseStatus, element, 'licenseStatus');
    });
  }

  searchProjectWithEnter(evento: any) {
    if (evento.key === "Enter") {
      this.filterValues.title = this.searchValue;
      this.getPageableProcessList()
    }
  }

  resetSearch(filtro) {
    this.listOfData = this.listOfDataOriginal

    const validFilterName = !!(filtro === 'filterOrgan' || filtro === 'filterResponsible' || filtro === 'filterProcessStatus' || filtro === 'filterValidity' || filtro === 'filterLicenseStatus')

    this.resetFilterType(filtro)

    if (!validFilterName) return;

    this[filtro] = this[filtro].map(item => ({
      ...item,
      checked: false
    }))
    this.getPageableProcessList()
  }

  resetFilterType(filter){
    if(filter === 'filterOrgan') this.filterValues.organ = []
    if(filter === 'filterTitle') this.filterValues.title = ''
    if(filter === 'filterResponsible') this.filterValues.responsibleId = []
    if(filter === 'filterProcessStatus') this.filterValues.status = []
    if(filter === 'filterLicenseStatus') this.filterValues.licenseStatus = []
    if(filter === 'filterValidity') this.filterValues.validity = ''
  }

  resetSearchByProject(): void {
    this.searchValue = '';
    this.resetFilterType('filterTitle')
    this.listOfData = this.listOfDataOriginal
  }

  returnHeightOnResolution(): string {
    let width = window.screen.width;
    if (width > 1366) {
      this.screenWidth = 1920
      this.alignLeftMarginLicense(this.screenWidth)
      return '630px'
    } else {
      this.screenWidth = 1366
      this.alignLeftMarginLicense(this.screenWidth)
      return '450px'
    }
  }

  trackByName(_: number, item: ColumnItem): string {
    return item.name;
  }

  alignLeftMarginLicense(widthScreen: number) {
    if(widthScreen == 1366) {
      if(this.sidebar === true) {
        this.marginLeftForLicense = 125
      } else {
        this.marginLeftForLicense = -65
      }
    }
    if(widthScreen == 1920) {
      if(this.sidebar === true) {
        this.marginLeftForLicense = -105
      } else {
        this.marginLeftForLicense = -160
      }
    }
  }

  private getSubProjectsById(projectId: any) {
    this.serviceProjects.getSubProjectsByProjectId(projectId).subscribe({
      next: (response: SubProjectPageResponse) => {
        this.btnNewProcess = this.enableProcess(response.content);
      }
    });

  }

  enableProcess(subProjects): boolean {
    var ocorrencia = subProjects.filter(sub => sub.status === 'CON' && sub.type === 'DEFINITIVA').length
    return ocorrencia > 0
  }
}
