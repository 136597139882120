<div class="projects">
  <header class="projects__subheader">
    <h1 class="projects__subheader__title">
      {{ 'projects.projects.title' | translate}}
    </h1>
    <button *ngIf="riplarequisition" nz-popover nzPopoverPlacement="top" nzPopoverTrigger="click" [nzPopoverContent]="contentTemplate"
            class="btn__primary btn__primary__green" [nzPopoverOverlayStyle]="customStyle"
            [(nzPopoverVisible)]="popOverNotification">
      <span class="btn__primary__span">
        {{ 'projects.button-new-requisition' | translate }}
      </span>
      <div class="btn__primary__line"></div>
      <span nz-icon>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M5.03436 6.63422C5.34678 6.3218 5.85331 6.3218 6.16573 6.63422L8.00005 8.46853L9.83436 6.63422C10.1468 6.3218 10.6533 6.3218 10.9657 6.63422C11.2782 6.94664 11.2782 7.45317 10.9657 7.76559L8.00005 10.7313L5.03436 7.76559C4.72194 7.45317 4.72194 6.94664 5.03436 6.63422Z"
                stroke="white" stroke-width="0.7" fill="white" />
        </svg>
      </span>
    </button>
    <ng-template #contentTemplate>
      <div class="botoesMenu" (click)="createRIPLA('preliminar')">
        {{ 'projects.ripla.preliminary' | translate}}
      </div>
      <div class="botoesMenu" (click)="createRIPLA('definitiva')">
        {{ 'projects.ripla.definitive' | translate}}
      </div>
    </ng-template>
  </header>

  <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="outer-wrapper list-block">
    <nz-table #filterTable #sortTable [nzData]="listOfData" #borderedTable nzTableLayout="fixed"
              [nzFrontPagination]="false" [nzScroll]="{ y: returnHeightOnResolution() }" class="project-table">
      <thead>
      <tr>
        <th nzCustomFilter class="columnProject tableTitle" id="projectTitle">
          {{ 'projects.project.title' | translate}}
          <nz-filter-trigger nz-dropdown nzTrigger="click" [nzPlacement]="'bottomCenter'" [(nzVisible)]="visible" [nzActive]="searchValue.length > 0" [nzDropdownMenu]="menu">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.46677 1.8001C4.74157 1.8001 4.03266 2.01514 3.42968 2.41804C2.82669 2.82094 2.35673 3.3936 2.07921 4.06359C1.80169 4.73359 1.72907 5.47083 1.87055 6.1821C2.01203 6.89336 2.36125 7.5467 2.87404 8.05949C3.38683 8.57228 4.04017 8.9215 4.75143 9.06298C5.4627 9.20446 6.19994 9.13184 6.86994 8.85432C7.53993 8.5768 8.11259 8.10684 8.51549 7.50385C8.91838 6.90088 9.13343 6.19196 9.13343 5.46676C9.13343 4.4943 8.74712 3.56167 8.05949 2.87404C7.37186 2.18641 6.43923 1.8001 5.46677 1.8001ZM2.76299 1.42028C3.56331 0.885523 4.50423 0.600098 5.46677 0.600098C6.75749 0.600098 7.99534 1.11283 8.90802 2.02551C9.82069 2.93819 10.3334 4.17604 10.3334 5.46676C10.3334 6.4293 10.048 7.37022 9.51325 8.17054C9.44752 8.26891 9.37839 8.36461 9.30603 8.4575L11.2244 10.3758C11.4587 10.6101 11.4587 10.99 11.2244 11.2244C10.99 11.4587 10.6101 11.4587 10.3758 11.2244L8.4575 9.30603C8.11443 9.57327 7.73527 9.79476 7.32916 9.96298C6.43989 10.3313 5.46137 10.4277 4.51733 10.2399C3.57329 10.0521 2.70613 9.58863 2.02551 8.90802C1.3449 8.2274 0.881393 7.36024 0.693611 6.4162C0.50583 5.47216 0.602206 4.49364 0.970553 3.60437C1.3389 2.7151 1.96267 1.95504 2.76299 1.42028Z"
                    fill="#969696" />
            </svg>
          </nz-filter-trigger>
        </th>
        <th nzCustomFilter class="tableTitle" id="corridorTitle">
          {{ 'projects.corridor.title' | translate}}
          <nz-filter-trigger [(nzVisible)]="visibleCorredor" [nzActive]="searchValue.length > 0"
                             [nzDropdownMenu]="menuCorredor">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M1.81407 8.9407e-07L1.8148 0L10.1861 4.29153e-07C10.4165 0.000284922 10.643 0.065204 10.8386 0.188491C11.0344 0.311866 11.1918 0.489083 11.2899 0.700659C11.388 0.912399 11.4218 1.14826 11.3864 1.37946C11.351 1.61045 11.2484 1.82477 11.093 1.99734C11.0929 1.99745 11.0931 1.99723 11.093 1.99734L7.83107 5.62986V10.2C7.83107 10.4295 7.70021 10.6388 7.49395 10.7393L5.03202 11.9393C4.84606 12.03 4.62653 12.0184 4.45111 11.9088C4.27569 11.7991 4.16913 11.6069 4.16913 11.4V5.62986L0.907479 1.99767C0.907338 1.99752 0.907196 1.99736 0.907054 1.9972C0.751715 1.82465 0.649139 1.61038 0.613767 1.37946C0.578351 1.14826 0.61218 0.9124 0.710315 0.700659C0.808373 0.489084 0.96579 0.311867 1.16158 0.188491C1.35722 0.0652042 1.58371 0.000284922 1.81407 8.9407e-07ZM1.35319 1.596L1.79933 1.19537C1.79915 1.19489 1.79898 1.19455 1.79887 1.19436L1.35319 1.596ZM1.80553 1.20171L5.21555 4.99912C5.31443 5.10923 5.36913 5.25201 5.36913 5.4V10.4401L6.63107 9.82497V5.4C6.63107 5.25201 6.68576 5.10923 6.78464 4.99912L10.1947 1.20171C10.192 1.20073 10.1886 1.20002 10.1848 1.2H1.81541C1.81159 1.20002 1.80824 1.20073 1.80553 1.20171Z"
                    fill="#969696" [ngClass]="hasFilterActivated(filterCorredor) ? 'green-filter-icon' : ''" />
            </svg>
          </nz-filter-trigger>
        </th>
        <th nzCustomFilter class="tableTitle" id="complexTitle" nzWidth="170px">
          {{ 'projects.complex.title' | translate}}
          <nz-filter-trigger [(nzVisible)]="visibleComplexo" [nzActive]="searchValue.length > 0"
                             [nzDropdownMenu]="menuComplexo">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M1.81407 8.9407e-07L1.8148 0L10.1861 4.29153e-07C10.4165 0.000284922 10.643 0.065204 10.8386 0.188491C11.0344 0.311866 11.1918 0.489083 11.2899 0.700659C11.388 0.912399 11.4218 1.14826 11.3864 1.37946C11.351 1.61045 11.2484 1.82477 11.093 1.99734C11.0929 1.99745 11.0931 1.99723 11.093 1.99734L7.83107 5.62986V10.2C7.83107 10.4295 7.70021 10.6388 7.49395 10.7393L5.03202 11.9393C4.84606 12.03 4.62653 12.0184 4.45111 11.9088C4.27569 11.7991 4.16913 11.6069 4.16913 11.4V5.62986L0.907479 1.99767C0.907338 1.99752 0.907196 1.99736 0.907054 1.9972C0.751715 1.82465 0.649139 1.61038 0.613767 1.37946C0.578351 1.14826 0.61218 0.9124 0.710315 0.700659C0.808373 0.489084 0.96579 0.311867 1.16158 0.188491C1.35722 0.0652042 1.58371 0.000284922 1.81407 8.9407e-07ZM1.35319 1.596L1.79933 1.19537C1.79915 1.19489 1.79898 1.19455 1.79887 1.19436L1.35319 1.596ZM1.80553 1.20171L5.21555 4.99912C5.31443 5.10923 5.36913 5.25201 5.36913 5.4V10.4401L6.63107 9.82497V5.4C6.63107 5.25201 6.68576 5.10923 6.78464 4.99912L10.1947 1.20171C10.192 1.20073 10.1886 1.20002 10.1848 1.2H1.81541C1.81159 1.20002 1.80824 1.20073 1.80553 1.20171Z"
                    fill="#969696" [ngClass]="hasFilterActivated(filterComplexo) ? 'green-filter-icon' : ''" />
            </svg>
          </nz-filter-trigger>
        </th>

        <th nzCustomFilter class="tableTitle" id="operationalUnitTitle" nzWidth="200px">
          {{ 'projects.operationalUnit.title' | translate}}
          <nz-filter-trigger [(nzVisible)]="visibleUnidadeOperacional" [nzActive]="searchValue.length > 0"
                             [nzDropdownMenu]="menuUnidadeOperacional">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M1.81407 8.9407e-07L1.8148 0L10.1861 4.29153e-07C10.4165 0.000284922 10.643 0.065204 10.8386 0.188491C11.0344 0.311866 11.1918 0.489083 11.2899 0.700659C11.388 0.912399 11.4218 1.14826 11.3864 1.37946C11.351 1.61045 11.2484 1.82477 11.093 1.99734C11.0929 1.99745 11.0931 1.99723 11.093 1.99734L7.83107 5.62986V10.2C7.83107 10.4295 7.70021 10.6388 7.49395 10.7393L5.03202 11.9393C4.84606 12.03 4.62653 12.0184 4.45111 11.9088C4.27569 11.7991 4.16913 11.6069 4.16913 11.4V5.62986L0.907479 1.99767C0.907338 1.99752 0.907196 1.99736 0.907054 1.9972C0.751715 1.82465 0.649139 1.61038 0.613767 1.37946C0.578351 1.14826 0.61218 0.9124 0.710315 0.700659C0.808373 0.489084 0.96579 0.311867 1.16158 0.188491C1.35722 0.0652042 1.58371 0.000284922 1.81407 8.9407e-07ZM1.35319 1.596L1.79933 1.19537C1.79915 1.19489 1.79898 1.19455 1.79887 1.19436L1.35319 1.596ZM1.80553 1.20171L5.21555 4.99912C5.31443 5.10923 5.36913 5.25201 5.36913 5.4V10.4401L6.63107 9.82497V5.4C6.63107 5.25201 6.68576 5.10923 6.78464 4.99912L10.1947 1.20171C10.192 1.20073 10.1886 1.20002 10.1848 1.2H1.81541C1.81159 1.20002 1.80824 1.20073 1.80553 1.20171Z"
                    fill="#969696" [ngClass]="hasFilterActivated(filterUnidadeOperacional) ? 'green-filter-icon' : ''" />
            </svg>
          </nz-filter-trigger>
        </th>

        <th nzCustomFilter class="tableTitle columnStatus" id="statusTitle">
          {{ 'projects.status.title' | translate}}
          <nz-filter-trigger [(nzVisible)]="visibleStatus" [nzActive]="searchValue.length > 0"
                             [nzDropdownMenu]="menuStatus">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M1.81407 8.9407e-07L1.8148 0L10.1861 4.29153e-07C10.4165 0.000284922 10.643 0.065204 10.8386 0.188491C11.0344 0.311866 11.1918 0.489083 11.2899 0.700659C11.388 0.912399 11.4218 1.14826 11.3864 1.37946C11.351 1.61045 11.2484 1.82477 11.093 1.99734C11.0929 1.99745 11.0931 1.99723 11.093 1.99734L7.83107 5.62986V10.2C7.83107 10.4295 7.70021 10.6388 7.49395 10.7393L5.03202 11.9393C4.84606 12.03 4.62653 12.0184 4.45111 11.9088C4.27569 11.7991 4.16913 11.6069 4.16913 11.4V5.62986L0.907479 1.99767C0.907338 1.99752 0.907196 1.99736 0.907054 1.9972C0.751715 1.82465 0.649139 1.61038 0.613767 1.37946C0.578351 1.14826 0.61218 0.9124 0.710315 0.700659C0.808373 0.489084 0.96579 0.311867 1.16158 0.188491C1.35722 0.0652042 1.58371 0.000284922 1.81407 8.9407e-07ZM1.35319 1.596L1.79933 1.19537C1.79915 1.19489 1.79898 1.19455 1.79887 1.19436L1.35319 1.596ZM1.80553 1.20171L5.21555 4.99912C5.31443 5.10923 5.36913 5.25201 5.36913 5.4V10.4401L6.63107 9.82497V5.4C6.63107 5.25201 6.68576 5.10923 6.78464 4.99912L10.1947 1.20171C10.192 1.20073 10.1886 1.20002 10.1848 1.2H1.81541C1.81159 1.20002 1.80824 1.20073 1.80553 1.20171Z"
                    fill="#969696" [ngClass]="hasFilterActivated(filterStatus) ? 'green-filter-icon' : ''" />
            </svg>
          </nz-filter-trigger>
        </th>

        <th class="tableTitle" *ngFor="let column of listOfColumns; trackBy: trackByName"
            [(nzSortOrder)]="column.sortOrder" [nzShowFilter]="false" [nzSortFn]="column.sortFn" id="columName">
          <div style="display: flex; flex-direction: row; justify-content: space-between;">
            {{ column.name }}
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M3.52941 0C3.91926 0 4.23529 0.316034 4.23529 0.705882V9.58997L5.85381 7.97145C6.12947 7.69579 6.57641 7.69579 6.85208 7.97145C7.12774 8.24712 7.12774 8.69406 6.85208 8.96972L4.02855 11.7933C3.75288 12.0689 3.30594 12.0689 3.03028 11.7933L0.206748 8.96972C-0.0689161 8.69406 -0.0689161 8.24712 0.206748 7.97145C0.482412 7.69579 0.929352 7.69579 1.20502 7.97145L2.82353 9.58997V0.705882C2.82353 0.316034 3.13956 0 3.52941 0ZM5.64706 2.11765C5.64706 1.7278 5.96309 1.41176 6.35294 1.41176H13.4118C13.8016 1.41176 14.1176 1.7278 14.1176 2.11765C14.1176 2.5075 13.8016 2.82353 13.4118 2.82353H6.35294C5.96309 2.82353 5.64706 2.5075 5.64706 2.11765ZM7.76471 5.64706C7.76471 5.25721 8.08074 4.94118 8.47059 4.94118H13.4118C13.8016 4.94118 14.1176 5.25721 14.1176 5.64706C14.1176 6.03691 13.8016 6.35294 13.4118 6.35294H8.47059C8.08074 6.35294 7.76471 6.03691 7.76471 5.64706ZM9.17647 9.17647C9.17647 8.78662 9.49251 8.47059 9.88235 8.47059H13.4118C13.8016 8.47059 14.1176 8.78662 14.1176 9.17647C14.1176 9.56632 13.8016 9.88235 13.4118 9.88235H9.88235C9.49251 9.88235 9.17647 9.56632 9.17647 9.17647Z"
                    fill="#007E7A" />
            </svg>
          </div>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let data of filterTable.data" (click)="goToProjectView(data)">
        <td class="colunaProjeto" style="cursor: pointer;" nz-tooltip nzTooltipTitle="{{data.projectName}}" [ngClass]="{'draft': data.status === 'INI'}">
          {{ data.projectName ||'-' }}
        </td>
        <td class="colunasDemais" [ngClass]="{'draft': data.status === 'INI'}">
          {{ data.corridor ||'-' }}
        </td>
        <td class="colunasDemais" [ngClass]="{'draft': data.status === 'INI'}">
          {{ data.complex ||'-' }}
        </td>
        <td class="colunasDemais" [ngClass]="{'draft': data.status === 'INI'}">
          {{ data.operationalUnit ||'-' }}
        </td>
        <td class="colunasDemais">
          <app-badge [badgeText]="data.status" [badgeColor]="dateCalc.isDateMoreThanXDays(data.registrationDate, 15) ? 'red' : 'blue'" *ngIf="data.status === 'EAN'"></app-badge>
          <app-badge [badgeText]="data.status" *ngIf="data.status !== 'EAN'"></app-badge>
        </td>
        <td class="colunasDemais" [ngClass]="{'draft': data.status === 'INI'}">
          {{ data.registrationDate | date: 'dd/MM/YYYY' }}
        </td>
      </tr>
      </tbody>
    </nz-table>
  </div>

  <nz-dropdown-menu #menu="nzDropdownMenu">
    <div class="ant-table-filter-dropdown">
      <div class="search-box">
        <nz-input-group [nzPrefix]="suffixIconSearch">
          <input type="text" nz-input placeholder="Pesquisar" autofocus class="inputGroup"
                 (keyup)="searchProjectWithEnter($event)" style="font-size: 12px !important;" [(ngModel)]="searchValue" />
        </nz-input-group>
        <ng-template #suffixIconSearch>
          <span nz-icon nzType="search"></span>
        </ng-template>
        <div style="display: flex; justify-content: flex-end; align-items: center;">
          <button nz-button nzSize="small" (click)="resetSearchByProject()"
                  style="border: none; color: #007E7A !important; font-size: 12px; margin-right: 20px;">
            Limpar busca
          </button>
          <button nz-button nzSize="small" (click)="searchByProject()"
                  style="border-radius: 6px; display: inline-flex; font-size: 12px; padding: 8px; justify-content: center; align-items: center; height: 30px; color: #007E7A !important;">
            Buscar
          </button>
        </div>
      </div>
    </div>
  </nz-dropdown-menu>

  <nz-dropdown-menu #menuCorredor="nzDropdownMenu">
    <div class="ant-table-filter-dropdown popoverCorredor">
      <div class="search-box">
        <nz-checkbox-group [(ngModel)]="filterCorredor" (ngModelChange)="filterBy($event, 'corredor')">
          <label nz-checkbox *ngFor="let option of filterCorredor">
            {{ option.value }}
          </label>
        </nz-checkbox-group>
        <nz-divider class="divider16"></nz-divider>
        <button class="btn__text" (click)="resetSearch('corredor')">
          <span class="btn__text__span">Limpar filtro</span>
        </button>
      </div>
    </div>
  </nz-dropdown-menu>

  <nz-dropdown-menu #menuComplexo="nzDropdownMenu">
    <div class="ant-table-filter-dropdown popoverComplexo">
      <div class="search-box">
        <nz-checkbox-group [(ngModel)]="filterComplexo" (ngModelChange)="filterBy($event, 'complexo')">
        </nz-checkbox-group>
        <nz-divider class="divider16"></nz-divider>
        <button class="btn__text" (click)="resetSearch('complexo')">
          <span class="btn__text__span">Limpar filtro</span>
        </button>
      </div>
    </div>
  </nz-dropdown-menu>

  <nz-dropdown-menu #menuUnidadeOperacional="nzDropdownMenu">
    <div class="ant-table-filter-dropdown popoverUnidadeOperacional">
      <div class="search-box">
        <nz-checkbox-group [(ngModel)]="filterUnidadeOperacional"
                           (ngModelChange)="filterBy($event, 'unidadeOperacional')">
        </nz-checkbox-group>
        <nz-divider class="divider16"></nz-divider>
        <button class="btn__text" (click)="resetSearch('unidadeOperacional')">
          <span class="btn__text__span">Limpar filtro</span>
        </button>
      </div>
    </div>
  </nz-dropdown-menu>

  <nz-dropdown-menu #menuStatus="nzDropdownMenu">
    <div class="ant-table-filter-dropdown popoverComplexo">
      <div class="search-box">
        <nz-checkbox-group [(ngModel)]="filterStatus" (ngModelChange)="filterBy($event, 'status')">
        </nz-checkbox-group>
        <nz-divider class="divider16"></nz-divider>
        <button class="btn__text" (click)="resetSearch('status')">
          <span class="btn__text__span">Limpar filtro</span>
        </button>
      </div>
    </div>
  </nz-dropdown-menu>
</div>
