<nz-select [id]="id" nzBorderless nzAllowClear class="gridSelectOption licensing-form-item"
  [nzPlaceHolder]="placeholder" nzSize="small" [nzShowArrow]="false" [disabled]="disabled"
  [(ngModel)]="projectLicensingCreateForm[id]"
  (ngModelChange)="changeValueOfItem($event)"
  *ngIf="itemType == 'select'"
>
  <nz-option class="userItem selectItem" *ngFor="let option of selectOptions" 
    [nzLabel]="option[selectValueToShowName] || null"
    [nzValue]="option.id">
  </nz-option>
</nz-select>

<nz-select [id]="id" nzBorderless nzAllowClear class="gridSelectOption licensing-form-item"
  [nzPlaceHolder]="placeholder" nzSize="small" [nzShowArrow]="false" [disabled]="disabled"
  [(ngModel)]="projectLicensingCreateForm[id]"
  (ngModelChange)="changeValueOfItem($event)"
  *ngIf="itemType == 'select-object'"
>
  <nz-option class="userItem selectItem" *ngFor="let option of selectOptions" 
    [nzLabel]="option[selectValueToShowName] || null"
    [nzValue]="{id:option.id, data: option[selectValueToShowName]}">
  </nz-option>
</nz-select>

<input 
  nz-input class="licensing-form-input" 
  [id]="id"
  nzBorderless 
  nzSize="small" 
  [placeholder]="placeholder" 
  [(ngModel)]="projectLicensingCreateForm[id]" 
  (ngModelChange)="changeValueOfItem($event)" 
  *ngIf="itemType == 'input'"
>

<nz-date-picker
  [id]="id"
  class="licensing-form-item"
  nzFormat="dd/MM/YYYY"
  [nzPlaceHolder]="placeholder"
  [nzDisabledDate]="disabledDate"
  [nzSuffixIcon]="''"
  nzSize="small"  
  [(ngModel)]="projectLicensingCreateForm[id]"
  (ngModelChange)="changeValueOfItem($event)"
  *ngIf="itemType == 'date'"
></nz-date-picker>

<nz-textarea-count [nzMaxCharacterCount]="255" *ngIf="itemType == 'textarea-count'">
  <textarea [id]="id" rows="5" placeholder="Informe..."
    nz-input nzBorderless></textarea>
</nz-textarea-count>

<textarea [id]="id" rows="1" class="oneline-txt" placeholder="Informe..."
  nz-input nzBorderless *ngIf="itemType == 'textarea-simple'"></textarea>