import { MessageService } from './../../../shared/common/services/message.service';
import {Component, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {UserService} from "../../../shared/services/user.service";
import {ProductNlpService} from "../services/product-nlp.service";
import {NlpModalUpdateTRResultComponent} from "./nlp-modal-update-tr-result/nlp-modal-update-tr-result.component";
import {NzModalService} from "ng-zorro-antd/modal";
import {NlpAnalysisResultService} from "./nlp-analysis-result.service";
import {NlpResultResponseDto} from "./result-body/dto/nlp-result-response.dto";
import {Router} from "@angular/router";
import {take} from "rxjs";
import {NzMessageService} from "ng-zorro-antd/message";
import {BreadCumbNlpService} from "../services/bread-cumb-nlp.service";

@Component({
  selector: 'app-nlp-analysis-result',
  templateUrl: './nlp-analysis-result.component.html',
  styleUrls: ['./nlp-analysis-result.component.scss']
})
export class NlpAnalysisResultComponent implements OnInit,OnDestroy {
  isProductNlpEmpty: boolean = false;
  thereAnyChange?: boolean;
  productNlpId: number | undefined = 0;
  resultAnalysisNlp?: NlpResultResponseDto;

  projectName: string | null = '';
  projectId: string | null = '';
  licenseId: string | null = '';
  licenseName: string | null = '';
  demandId: string | null = '';
  productNlpIdLocal: string | null = '';

  emptyResult = {
    nlpSectionCount: 0,
    trSectionCount:0,
    trSectionsAdherencePct: 0,
    nlpSectionsAdherencePct: 0,
    sectionFoundAdherenceCssClass:"baixaAderencia",
    trSectionsAdherenceCssClass:"baixaAderencia",
    nlpSectionsAdherenceCssClass:"baixaAderencia",
    resultSectionNlpAndTrResponseList: [],
  };

  constructor(private userService: UserService,
              private productNlpService: ProductNlpService,
              private modalService: NzModalService,
              private viewContainerRef: ViewContainerRef,
              private nlpAnalysisResultService: NlpAnalysisResultService,
              private router: Router,
              private readonly msg: NzMessageService,
              private breadCumbNlpService: BreadCumbNlpService,
              private message: MessageService) {
  }

  ngOnInit(): void {
    this.resultAnalysisNlp = this.emptyResult;
    this.getProductNlp();

  }

  private getProductNlp() {
    let prodNlpId: string | null = localStorage.getItem('productNlpId');
    if (typeof prodNlpId !== 'undefined' && prodNlpId !== null) {
      this.productNlpService.getProductNlpById(+prodNlpId)
        .pipe(take(1))
        .subscribe(productNlpUpdated => {
          this.userService.updateProductNlp(productNlpUpdated);
          this.userService.updateValueTopMenu(true);
        },
        (error) => this.msg.error(`Erro ao selecionar produto NLP: ${error.message}`),
        () => this.getResultData()
      );
    } else {
      this.getResultData();
    }

  }

  getResultData() {
    this.nlpAnalysisResultService.updateProductNlp({});
    this.nlpAnalysisResultService.updateRefreshButton(true);
    this.getSelectedProductNlpId();
  }

  private getSelectedProductNlpId(): void {

    this.userService.productNlpObservable$
      .pipe(take(1))
      .subscribe(productNlp => {

      if (Object.keys(productNlp).length === 0) {
        this.isProductNlpEmpty = true;
        this.router.navigate([`nlp/welcome`]);
      } else {

        this.isProductNlpEmpty = false;

        this.productNlpId = productNlp.id;

        this.getLatestProductNlp(productNlp.id);

        this.getNlpAnalysisResult(productNlp.id);
      }
    });

    this.nlpAnalysisResultService.resultNlpData$.subscribe({
      next: (result)=>{
        this.resultAnalysisNlp = result;
      },
      error: (error) => {
        this.message.showErrorMessage('Retorno do produto NLP pendente');     }
    });
  }

  private getNlpAnalysisResult(productNlpId: number | undefined): void {
    if (this.router.url === '/nlp/analise-resultados') {
      this.nlpAnalysisResultService.getNlpAnalysisResult(productNlpId)
        .pipe(take(1))
        .subscribe({
          next: (result) => {
            this.nlpAnalysisResultService.updateProductNlp(result);
          },
          error: () => {
            this.isProductNlpEmpty = true
          }
        });
    }
  }

  private getLatestProductNlp(productNlpId: number | undefined): void {
    if (productNlpId !== undefined) {
      this.productNlpService.getProductNlpById(productNlpId)
        .pipe(take(1))
        .subscribe(productNlpUpdated => {

        this.thereAnyChange = productNlpUpdated.thereAnyChange;

        this.productNlpId = productNlpUpdated.id;

        this.showUpdateResultTrModal(productNlpUpdated.thereAnyChange);
      });
    }
  }

  private showUpdateResultTrModal(productNlpUpdated: boolean | undefined): void {
    if (productNlpUpdated && this.router.url === '/nlp/analise-resultados') {
      this.updateTRResult();
    }
  }

  private updateTRResult(): void {
    let modal = this.modalService.create<NlpModalUpdateTRResultComponent>({
      nzWidth: 600,
      nzContent: NlpModalUpdateTRResultComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
      nzOkLoading: true,
      nzCancelText: 'No',
      nzOnCancel: () => {
        modal.destroy();
      },
    });

    modal.afterClose.subscribe(() => {
      this.getNlpAnalysisResult(this.productNlpId);
      modal.destroy();
    });
  }

  ngOnDestroy(): void {
    this.nlpAnalysisResultService.updateRefreshButton(false);
  }

}
