import {ActivatedRoute, Params, Router} from '@angular/router';
import {Component, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';

import {NzModalService} from 'ng-zorro-antd/modal';

import {differenceInCalendarDays} from 'date-fns';
import {DateCalcHelper} from 'src/app/shared/helpers/date-calc.helper';

import {UserModel} from 'src/app/shared/models/user.model';
import {ReviewResponse} from 'src/app/shared/models/project-v2-ripla-review-request.model';
import {DisciplineAssessmentList} from 'src/app/shared/models/discipline-assessment.model';

import {UtilService} from 'src/app/shared/util.service';
import {UserService} from 'src/app/shared/services/user.service';
import {ProjectsV2Service} from 'src/app/shared/services/projects-v2.service';
import {DisciplinesService} from 'src/app/shared/services/disciplines.service';
import {FinalAnalysisService} from 'src/app/shared/services/final-analysis.service';

import {FinalAnalysis} from 'src/app/shared/dto/final-analysis-reponse.dto';
import {ProjectByIdResponseDto} from 'src/app/shared/dto/project-by-id-response.dto';
import {
  SubProjectByIdResponse,
  UserLegalRepresentative,
} from 'src/app/shared/dto/subproject-by-id-response.dto';
import {DisciplineBySubProjectListResponse} from 'src/app/shared/dto/discipline-by-subproject-list-response.dto';
import {DatePipe} from '@angular/common';
import moment from 'moment';
import {
  RequestReviewAnalisysModalComponent
} from './../modal/request-review-analisys-modal/request-review-analisys-modal.component';
import {MessageService} from "src/app/shared/common/services/message.service";
import {Subject, takeUntil} from 'rxjs';
import {Store, select} from '@ngrx/store';
import {selectRiplaProject} from 'src/app/shared/ngrx/breadcrumb/breadcrumb.selector';
import {ProjectState} from 'src/app/shared/ngrx/breadcrumb/model';
import {BREADCRUMB_ACTIONS} from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';
import {BreadcrumbState} from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';
import {DisciplineAssessment} from "../../../shared/dto/assessment-by-id-response.dto";
import {DisciplineAssessmentService} from "../../../shared/services/discipline-assessment.service";
import {AssessmentUpdateDto} from "../../../shared/dto/assessment-update.dto";

@Component({
  selector: 'app-project-v2-analysis',
  templateUrl: './project-v2-analysis.component.html',
  styleUrls: ['./project-v2-analysis.component.scss'],
})
export class ProjectV2AnalysisComponent implements OnInit, OnDestroy {

  private readonly destroy$: Subject<any> = new Subject();
  stateStore: any[] = []

  projectId: number;
  subProjectId: number;
  loadingSubProjects: boolean;
  listOfSubProjects: SubProjectByIdResponse[] = [];
  subProject: SubProjectByIdResponse;
  subProjectDiscipline: any[] = [];
  editId: string | null = null;
  selectedUserResponsibleId: string = '';
  users: UserModel[] = [];
  usersSpecialist: UserModel[] = [];
  customStyle = {
    background: 'white',
    'font-size': '12px',
  };
  popOverEditRipla: boolean = false;

  popOverEditDiscipline: boolean = false;

  colaborativeArchive: string = '';
  hasColaborativeArchive: boolean = false;

  editLink: boolean = false;
  link: string = '';
  editingRow = null;

  disciplineListByProject: DisciplineBySubProjectListResponse[] = [];
  disciplinesList;
  disciplinesAssessmentList: DisciplineAssessmentList[] = [];
  addNewDiscipline: boolean = false;
  editIdDiscipline: string | null = null;
  selectedUserResponsibleIdDiscipline: number = 0;
  editDateDeadlineDiscipline: string = '';

  newUserResponsibleIdDiscipline?: string = '';
  newIdDiscipline?: string = '';
  newDateDeadlineDiscipline?: string = '';
  newStatusDiscipline: string = '';
  project: ProjectByIdResponseDto;
  today = new Date();
  originalLinkColaborative: string = '';
  finalAnalysisResponse: FinalAnalysis;

  reviewId: number = 0;
  reviewSection: boolean = false;
  containerRef: ViewContainerRef;

  reviewData: ReviewResponse;
  enableEditAndCancelReview: boolean = false;
  enabledConsolidadeFinal: boolean = false;
  enableResponseReview: boolean = false;
  discipleAnalisys: boolean = false;
  riplaFinalProtocol: boolean = false;
  riplaRevisioRequisition: boolean = false;
  riplaAnswerRequisition: boolean = false;
  createDisciplineStudy: boolean = false;
  editResponsible: boolean = false;
  compareFn = (o1: string, o2: string) => (o1 && o2 ? o1 == o2 : false);
  isEditing: boolean;
  private disciplineInEditMode: any;

  constructor(
    private route: ActivatedRoute,
    private projectsV2Service: ProjectsV2Service,
    private userService: UserService,
    private disciplinesService: DisciplinesService,
    private readonly utilService: UtilService,
    private dateCalcHelper: DateCalcHelper,
    private router: Router,
    private finalAnalysisService: FinalAnalysisService,
    private readonly modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    public dateCalc: DateCalcHelper,
    private readonly message: MessageService,
    private datePipe: DatePipe,
    private store: Store<BreadcrumbState>,
    private disciplineAssessmentService: DisciplineAssessmentService
  ) {
  }

  ngOnInit(): void {
    this.discipleAnalisys = this.utilService.getAuthorization('insert', 'DISCIPLINEANALISYS');
    this.riplaFinalProtocol = this.utilService.getAuthorization('insert', 'RIPLAFINALPROTOCOL');
    this.createDisciplineStudy = this.utilService.getAuthorization('edit', 'CREATEDISCIPLINESTUDY');
    this.editResponsible = this.utilService.getAuthorization('insert', 'CREATEDISCIPLINESTUDY');
    this.riplaRevisioRequisition = this.utilService.getAuthorization('insert', 'RIPLAREVISION');
    this.riplaAnswerRequisition = this.utilService.getAuthorization('insert', 'REVISIONANSWER');
    this.loadProjectFromStore()
    this.handleRoutes();
    this.containerRef = this.viewContainerRef;
  }


  // INICIO BREADCRUMB
  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private loadProjectFromStore(): void {
    this.store
      .pipe(
        takeUntil(this.destroy$),
        select(selectRiplaProject)
      ).subscribe((result: any) => {
      this.stateStore = result

    });
  }

  private dispatchProjectOnStore(): void {
    let project: ProjectState = {
      name: 'Projetos',
      url: 'projects-v2/',
      last: false,
      sublevels: true,
      project: {
        name: this.project.name,
        url: 'project-v2-view/' + this.project.id,
        last: false,
      },
      subProject: {
        name: 'RIPLA',
        url: this.route.snapshot.url[0].path + '/' + this.route.snapshot.url[1].path + '/' + this.route.snapshot.url[2].path,
        last: true,
        discipline: null
      },
      parecerFinal: null,
      pedidoRevisao: null,
      comunicacoes: null,
      processos: null,
      atividades: null,
      subatividades: null,
      mudancaEscopo: null
    }
    this.store.dispatch(BREADCRUMB_ACTIONS.getRiplaProject({project}));
  }

  // FINAL BREADCRUMB


  handleRoutes() {
    this.route.params.subscribe((params: Params) => {
      this.projectId = +params['projectId'];
      this.subProjectId = +params['subProjectId'];
      this.getSubProjects(this.subProjectId);
      this.getProjectAndUsers(this.projectId);
    });
  }

  isDeadLineDate(discipline: any): boolean {
    let currentDate = moment(new Date().setHours(0, 0, 0, 0)).utcOffset(0, true);
    let deadLineDate = moment(discipline.analysisDeadline).utcOffset(0, true);
    return currentDate > deadLineDate;
  }

  disabledDate = (current: Date): boolean =>
    // Can not select days before today and today
    differenceInCalendarDays(current, this.today) < 0;

  getUsersListByComplexId(complexId: number) {
    this.userService
      .getUsersByComplexAndRole(complexId, 'ROLE_LICENCIAMENTO')
      .subscribe({
        next: (usersResponse) => (this.users = usersResponse),
      });
  }

  getUsersEspecialistByComplexId(complexId: number) {
    this.userService.getUsersByComplexAndRole(complexId, 'ROLE_ESPECIALISTA').subscribe({
      next: (usersResponse) => this.usersSpecialist = usersResponse
    });
  }

  getSubProjects(subProjectId: number) {
    this.loadingSubProjects = true;
    this.projectsV2Service.getSubProjectBySubProjectId(subProjectId).subscribe({
      next: (response: SubProjectByIdResponse) => {
        this.subProject = response;
        this.checkPermissionsToEditAndCancelReview();
        this.loadingSubProjects = false;
        this.selectedUserResponsibleId = response.userLegalRepresentative ? response.userLegalRepresentative.id + "" : '';

        // ARQUIVO COLABORATIVO
        this.colaborativeArchive = response.analysisDocumentUrl;
        this.hasColaborativeArchive = !!this.colaborativeArchive;

        this.originalLinkColaborative = this.colaborativeArchive;

        this.getDisciplinesBySubProjectId(this.projectId, this.subProjectId);
        this.getFinalAnalysis();
        this.getSubProjectsReviewsList(this.subProject.subProjectReviews);
      },
    });
  }

  getFinalAnalysis(): void {
    this.finalAnalysisService.getFinalAnalysis(this.subProject.id).subscribe({
      next: (response) => (this.finalAnalysisResponse = response),
      error: (err) => {
        if (err) {
          console.log(err)
        }
      }
    });
  }

  checkIfFinalAnalysisIsSketch() {
    return (
      this.finalAnalysisResponse.asmtLicenseRequired !== null ||
      this.finalAnalysisResponse.asmtRestrictions !== null ||
      this.finalAnalysisResponse.asmtPriority !== null
    );
  }

  getSubProjectsReviewsList(subProjectReviews: ReviewResponse[] | undefined) {
    if (subProjectReviews && subProjectReviews.length > 0) {
      let subProjectReview = subProjectReviews.filter(
        (spr) => spr.status === 'EAN'
      );
      if (subProjectReview.length > 0) {
        this.reviewData = subProjectReview[0];
        this.reviewId = subProjectReview[0].id;
        this.reviewSection = true;
      }
    }
  }

  getDisciplinesBySubProjectId(projectId: number, subprojectId: number) {
    this.projectsV2Service
      .getDisciplineAssessmentList(projectId, subprojectId)
      .subscribe({
        next: (response: DisciplineBySubProjectListResponse[]) => {
          this.disciplineListByProject = response;
          this.loadingSubProjects = false;
        },
        error: () => {
          this.loadingSubProjects = false;
        },
      });
  }

  getDisciplines() {
    this.disciplinesService.getDisciplines().subscribe({
      next: (response: any) => {
        this.disciplinesList = response.disciplines;
        this.disciplineListByProject.forEach((element: any, i) => {
          const discipline = this.disciplinesList.filter(
            (disc) => disc.id === element.discipline.id
          );
          if (discipline.length > 0) {
            this.disciplinesList.splice(
              this.disciplinesList.indexOf(discipline[0]),
              1
            );
          }
        });
      },
    });
  }

  startEdit(id: string): void {
    this.editId = id;
  }

  stopEdit(): void {
    this.editId = null;
  }

  selectNewUserAndSave() {
    let userSelected = this.users.filter((element: any) => {
      return element.id === +this.selectedUserResponsibleId;
    });

    if (userSelected[0].name && userSelected[0].id) {
      this.updateSubProjectUser();
      let userLegalRepresentative = new (class
        implements UserLegalRepresentative {
        id: number;
        name: string;
      })();
      userLegalRepresentative.id = +userSelected[0].id;
      userLegalRepresentative.name = userSelected[0].name;

      this.subProject.userLegalRepresentative = userLegalRepresentative;
    }
  }

  getDeadLineDate(date: string) {
    return this.dateCalcHelper.addDaysToDate(date, 15);
  }

  private getProjectAndUsers(projectId: number): void {
    this.projectsV2Service.getProjectByProjectId(projectId).subscribe({
      next: (project: ProjectByIdResponseDto) => {
        this.project = project;
        this.dispatchProjectOnStore()
        this.getUsersListByComplexId(this.project.complex.id);
      },
    });
  }

  private updateSubProjectUser() {
    if (
      this.subProject.userLegalRepresentative === null ||
      this.subProject.userLegalRepresentative.id !=
      +this.selectedUserResponsibleId
    ) {
      this.projectsV2Service
        .updateSubProjectUser(this.subProjectId, {
          userLegalRepId: this.selectedUserResponsibleId,
        })
        .subscribe({
          next: () => console.log('saved!'),
        });
    }
  }

  private getLoggedUsedFromSession() {
    const userSession = sessionStorage.getItem('user');
    return userSession ? JSON.parse(userSession) : null;
  }

  // DADOS DO LINK DO ARQUIVO COLABORATIVO
  addLinkArchiveClick() {
    const validateLink =
      this.colaborativeArchive.includes('http://') ||
      this.colaborativeArchive.includes('https://')
        ? true
        : false;

    if (this.colaborativeArchive && validateLink) {
      this.projectsV2Service
        .updateSubProjectColaborativeDocument(
          this.subProjectId,
          this.colaborativeArchive
        )
        .subscribe({
          next: (result) => {
            this.colaborativeArchive = result.analysisDocumentUrl;
            this.originalLinkColaborative = result.analysisDocumentUrl;
            this.hasColaborativeArchive = true;
          },
          error: (error) => console.log(error.message),
        });
      this.hasColaborativeArchive = true;
    } else {
      this.colaborativeArchive = this.originalLinkColaborative;
    }
  }

  editLinkClick() {
    if (this.colaborativeArchive) {
      this.addLinkArchiveClick();
    }
    this.editLink = !this.editLink;
  }

  addNewDisciplineClick() {
    this.addNewDiscipline = true;
    this.newStatusDiscipline = "EAN"
    this.newIdDiscipline = undefined;
    this.newUserResponsibleIdDiscipline = undefined;
    this.newDateDeadlineDiscipline = undefined;
    this.getUsersEspecialistByComplexId(this.project.complex.id)
    this.getDisciplines()
    this.stopEditDiscipline()
  }

  addNewDisciplineDB() {
    if (!this.newIdDiscipline || !this.newUserResponsibleIdDiscipline || !this.newDateDeadlineDiscipline) {
      this.message.showErrorMessage("Campos obrigatorios não preenchidos");
      return;
    }
    const requestBody = {
      disciplineItems: [
        {
          idDiscipline: this.newIdDiscipline,
          idUserAssessment: this.newUserResponsibleIdDiscipline,
          requiresFieldVisit: true,
          analysisDeadline: this.utilService
            .DateFormatter(this.newDateDeadlineDiscipline)
            .split('T')[0],
        },
      ],
      observation: '',
    };
    this.projectsV2Service
      .addNewDisciplineSubProject(requestBody, this.subProjectId)
      .subscribe({
        next: (result) => {
          this.getDisciplinesBySubProjectId(this.projectId, this.subProjectId);
        },
      });

    this.getDisciplines();
    this.newDateDeadlineDiscipline = '';
    this.newIdDiscipline = '';
    this.newStatusDiscipline = '';
    this.newUserResponsibleIdDiscipline = '';
    this.addNewDiscipline = false;
  }

  cancelNewDisciplineClick() {
    this.addNewDiscipline = false;
    this.newDateDeadlineDiscipline = undefined;
    this.newIdDiscipline = undefined;
    this.newStatusDiscipline = '';
    this.newUserResponsibleIdDiscipline = undefined;
  }

  // EDICAO DADOS DE UMA DISCIPLINA
  startEditDiscipline(id,discipline): void {
    this.getUsersEspecialistByComplexId(this.project.complex.id)
    this.editingRow = id;
    this.isEditing = true;
    this.editIdDiscipline = id;
    if(discipline !== null) {
      this.editDateDeadlineDiscipline = discipline.analysisDeadline+'T00:00:00';
      this.selectedUserResponsibleIdDiscipline = discipline.userAssessment.id;
    }
    this.disciplineInEditMode = discipline;
  }

  stopEditDiscipline(): void {
    this.editingRow = null;
    this.editIdDiscipline = null;
    this.isEditing = false;
  }

  selectNewUserDisciplineAndSave() {
    this.stopEditDiscipline();
  }

  selectNewUserDisciplineAndUpdate(discipline) {
    const requestBody: AssessmentUpdateDto = {
      analysisDeadlineAfter: this.utilService.DateFormatter(discipline.analysisDeadline).split('T')[0],
      costStudyList: [],
      changeUser: true,
      disciplineAssessmentQuestion: [],
      idDiscipline: discipline.discipline.id,
      idSubProject: this.subProjectId,
      idUserAssessment: this.selectedUserResponsibleIdDiscipline,
      requiresFieldVisit: false,
      status: discipline.status
    }

    this.disciplineAssessmentService.updateAssessment(requestBody).subscribe({
      next: (result) => {
        this.getDisciplinesBySubProjectId(this.projectId, this.subProjectId)
        this.refreshData();
      }, error: (err) => {
        this.message.showErrorMessage("Ocorreu um erro inesperado, por gentileza, tente novamente.");
        this.refreshData();
      }
    });
  }

  updateDiscipline() {
    let discipline = this.disciplineInEditMode;
    const requestBody =
      {
        idSubProject: this.subProjectId,
        id: discipline.id,
        idDiscipline: discipline.discipline.id,
        requiresFieldVisit: true,
        idUserAssessment: this.selectedUserResponsibleIdDiscipline,
        analysisDeadline: this.editDateDeadlineDiscipline,
        status: discipline.status,
        costStudyList: discipline.costStudies,
        disciplineAssessmentQuestion: discipline.answeredQuestions
      }
    this.disciplineAssessmentService.updateAssessment(requestBody).subscribe({
      next: (result) => {
        this.getDisciplinesBySubProjectId(this.projectId, this.subProjectId)
        this.refreshData();
      }, error: (err) => {
        this.message.showErrorMessage("Ocorreu um erro inesperado, por gentileza, tente novamente.");
        this.refreshData();
      }
    });
  }

  private refreshData() {
    this.getDisciplines();
    this.newDateDeadlineDiscipline = '';
    this.newIdDiscipline = '';
    this.newStatusDiscipline = '';
    this.newUserResponsibleIdDiscipline = '';
    this.addNewDiscipline = false;
    this.stopEditDiscipline();
  }

  deleteDiscipline(assessmentIdToDelete: number) {
    this.projectsV2Service
      .deleteDisciplineBySubProjectId(
        this.subProjectId,
        Number(assessmentIdToDelete)
      )
      .subscribe({
        next: (result) => {
          this.getDisciplinesBySubProjectId(this.projectId, this.subProjectId);
        },
      });
  }

  updateSubProjectUserDiscipline() {
    if (
      this.subProject.userLegalRepresentative.id !=
      +this.selectedUserResponsibleIdDiscipline
    ) {
      this.projectsV2Service
        .updateSubProjectUserByDiscipline(this.subProjectId, {
          userLegalRepId: this.selectedUserResponsibleIdDiscipline,
        })
        .subscribe({
          next: () => {
            //console.log('saved!'),
          }
        });
    }
  }

  callAnalysisConsolidateFinal() {
    this.router.navigate([
      'project-v2-analysis-consolidation-final/',
      this.projectId,
      this.subProjectId,
    ]);
  }

  callRequisitionRevision() {

    this.router.navigate(['project-v2-requisition-revision/', this.projectId, this.subProjectId])
  }

  goToAssessment(disciplineAssessmentId: number) {
    let loggedUser = this.getLoggedUsedFromSession();

    /*this.userService.getUsersByComplexAndRole(this.project.complex.id,'ROLE_LICENCIAMENTO').subscribe({
      next: (users)=> {
        if(users.some((user) => user.email === loggedUser.email)){

        }
      }
    })*/
    this.router.navigate([
      `project-v2-assessment/${this.projectId}/${this.subProjectId}/${disciplineAssessmentId}`,
    ]);
  }

  projectHasOpenReview(): boolean {
    const subProjectReviews = this.subProject.subProjectReviews || null;
    if (!subProjectReviews) return false;

    return subProjectReviews.filter(rev => rev.status === 'EAN').length > 0;
  }

  showOpenRequestReviewAnalisysModal(editmode: boolean): void {
    const totalSubProjectsReview = this.subProject.subProjectReviews ? this.subProject.subProjectReviews?.length : 0
    if (this.subProject.status === 'REV' && totalSubProjectsReview > 0 && !editmode) {
      const revisionId = this.subProject.subProjectReviews?.filter(rev => rev.status === 'EAN')[0]?.id

      if (!revisionId) {
        this.message.showErrorMessage("Revisão em andamento não encontrada!")
      }
      this.router.navigate(['project-v2-requisition-revision/', this.projectId, this.subProjectId, revisionId])
    } else {
      const modal = this.modal.create<RequestReviewAnalisysModalComponent>({
        nzWidth: 780,
        nzContent: RequestReviewAnalisysModalComponent,
        nzViewContainerRef: this.viewContainerRef,
        nzClosable: false,
        nzMaskClosable: false,
        nzKeyboard: false,
        nzFooter: null,
        nzOnCancel: (instance: RequestReviewAnalisysModalComponent) => new Promise((resolve, reject) => {
          resolve();
        }),
      });

      if (this.reviewId) {
        modal.getContentComponent().reviewId = this.reviewId;
      }

      if (this.reviewId) {
        modal.getContentComponent().reviewId = this.reviewId;
      }

      modal.afterClose.subscribe((data) => {
        this.getSubProjectsReviewsList(data);
        //this.router.navigate(['project-v2-requisition-revision/', this.projectId, this.subProjectId, data.id])
        if (data) this.handleRoutes();
      })
    }
  }

  updateModalReview() {
    this.showOpenRequestReviewAnalisysModal(true);
  }

  clearReviewItems() {
    this.reviewSection = false;
    this.reviewId = 0;
    this.handleRoutes();
  }

  checkPermissionsToEditAndCancelReview() {
    const projectComplexId = this.subProject.projectComplex.id;
    const loggedUser = sessionStorage.getItem('loggedUser');
    const configRoles = sessionStorage.getItem('configRoles');

    if (loggedUser && configRoles) {
      const user = JSON.parse(loggedUser);
      const filteredComplex = user.complexes.filter((complex) => {
        return complex.id === projectComplexId;
      });

      const roles = JSON.parse(configRoles);

      const filteredRoles = roles.filter((role) => role.description === 'LICENCIAMENTO');
      const filteredDemandante = roles.filter((role) => role.description === 'AREA DEMANDANTE');

      this.enableEditAndCancelReview = filteredComplex.length > 0 && filteredRoles.length > 0;
      this.enableResponseReview = (filteredComplex.length > 0 && filteredRoles.length > 0 && !this.projectHasOpenReview()) || (filteredDemandante.length > 0 && this.projectHasOpenReview());
      this.enabledConsolidadeFinal = roles.filter(role => role.description === 'GESTOR').length > 0;
    }
  }
}
