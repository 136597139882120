import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ACTIVITIES_BY_PROJECT_LICENSE_ID_API} from "../../modules/projects/constants/apiEndpointsConstants";
import {ActivitesByProcessidResponseDto} from "../dto/activites-by-processid-response.dto";

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService{
  constructor(private readonly http: HttpClient) { }
  getActivitiesByProjectLicenceId(projectLicenceId: number):Observable<ActivitesByProcessidResponseDto[]> {
    return this.http.get<ActivitesByProcessidResponseDto[]>(`${ACTIVITIES_BY_PROJECT_LICENSE_ID_API}/${projectLicenceId}`)
  }
}
