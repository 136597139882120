import { Component, Input, OnInit, DoCheck } from '@angular/core';
import {LogsService} from "../../../../shared/services/logs.service";
import {LogsResponseDto} from "../../../../shared/dto/logs-response.dto";
import {Observable, delay } from "rxjs";

@Component({
  selector: 'app-project-v2-logs',
  templateUrl: './project-v2-logs.component.html',
  styleUrls: ['./project-v2-logs.component.scss']
})
export class ProjectV2LogsComponent implements OnInit, DoCheck {
  @Input() projectId!: number;
  @Input() subProjectId!: Array<any>;
  @Input() processId!: Array<any>;
  @Input() scopeChangeIdList: Array<any>;
  @Input() suspensionIdList: Array<any>;
  @Input() cancellationIdList: Array<any>;

  logs: LogsResponseDto[] = [];
  historicObs$: Observable<any>;

  constructor(private logsService: LogsService) {
  }

  ngOnInit(): void {
    this.getLogs()
  }

  ngDoCheck(): void {
    this.orderLogsByRegistrationDate()
  }

  getLogs() {
    this.logsService.getLogsByEntityIdAndEntityName(this.projectId, 'PROJECT')
      .pipe(
        delay(1000)
      )
      .subscribe({
        next: (projectLogList) => {
          if (projectLogList) {
            this.logs = this.logs.concat(projectLogList);
            this.getSubProjectLogs();
            this.getProcessLogs();
            this.getScopeChangeLogs();
          }
        }
      });
  }

  getSubProjectLogs(): void {
    this.subProjectId.forEach(id => {
      this.logsService.getLogsByEntityIdAndEntityName(id, 'SUB_PROJECT')
        .subscribe({
          next: (subProjectList) => {
            if (subProjectList) {
              this.logs = this.logs.concat(subProjectList);
            }
          }
        })
    });
  }

  getProcessLogs() {
    this.processId.forEach(id => {
      this.logsService.getLogsByEntityIdAndEntityName(id, 'PROJ_LICENSING').subscribe({
        next: (processLog) => {
          if (processLog) {
            this.logs = this.logs.concat(processLog);
          }
        }
      })
    });
  }

  getScopeChangeLogs() {
    this.scopeChangeIdList.forEach(id => {
      this.logsService.getLogsByEntityIdAndEntityName(id, 'SCOPE_CHANGE').subscribe({
        next: (scopeChangeLog) => {
          if (scopeChangeLog) {
            this.logs = this.logs.concat(scopeChangeLog);
          }
        }
      })
    });
  }

  private orderLogsByRegistrationDate() {
    this.logs.sort((a, b) => {
      const dateA: any = new Date(a.registrationDate);
      const dateB: any = new Date(b.registrationDate);
      return dateB - dateA;
    });
  }
}