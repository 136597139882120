<div class="container">
  <div nz-row class="h1-row">
    <div class="item arrow-back cursor-pointer" (click)="navigateBack()"> </div>
    <div class="item fill"><h1 class="h1-title">{{ 'projects.processes.processes-title' | translate }}</h1></div>
    <div class="item">
      <button *ngIf="cadProcessLicensing && btnNewProcess" class="btn-sl btn-sl-green" (click)="navigateToNewLicensingProcess()">{{ 'projects.processes.new-process' | translate }}</button>
    </div>
  </div>

  <div class="content-wrapper-process round-border">
    <div class="titulos">
      <div class="h2-title-process tituloProcesso" [ngStyle]="{ 'width': screenWidth === 1920 ? '1446px' : '1045px'}">{{ 'projects.processes.processes-title' | translate }}</div>
      <div class="h2-title-process tituloLicenca"
        [ngStyle]="{ 'margin-left': this.marginLeftForLicense + 'px' }">{{ 'projects.processes.licenses-title' | translate }}</div>
    </div>

    <div class="tabela">
      <nz-table #filterTable #sortTable
          borderedTable
          style="font-size: 12px !important; box-shadow: inset 0 0 1em black, 0 0 -51em cyan;"
          [nzFrontPagination]="false"
          [nzData]="listOfData"
          class="sl-table communications__table"
          [nzScroll]="{ x: '1200px', y: returnHeightOnResolution() }">
        <thead>
        <tr>
          <th nzCustomFilter id='columnProject-tableTitle' nzCustomFilter class="columnProject tableTitle" nzWidth="{{ screenWidth === 1920 ? '400px' : '300px'}}">
            {{ 'commons.title' | translate }}

            <nz-filter-trigger [(nzVisible)]="filterVisible.title" [nzActive]="searchValue.length > 0" [nzDropdownMenu]="menu">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M5.46677 1.8001C4.74157 1.8001 4.03266 2.01514 3.42968 2.41804C2.82669 2.82094 2.35673 3.3936 2.07921 4.06359C1.80169 4.73359 1.72907 5.47083 1.87055 6.1821C2.01203 6.89336 2.36125 7.5467 2.87404 8.05949C3.38683 8.57228 4.04017 8.9215 4.75143 9.06298C5.4627 9.20446 6.19994 9.13184 6.86994 8.85432C7.53993 8.5768 8.11259 8.10684 8.51549 7.50385C8.91838 6.90088 9.13343 6.19196 9.13343 5.46676C9.13343 4.4943 8.74712 3.56167 8.05949 2.87404C7.37186 2.18641 6.43923 1.8001 5.46677 1.8001ZM2.76299 1.42028C3.56331 0.885523 4.50423 0.600098 5.46677 0.600098C6.75749 0.600098 7.99534 1.11283 8.90802 2.02551C9.82069 2.93819 10.3334 4.17604 10.3334 5.46676C10.3334 6.4293 10.048 7.37022 9.51325 8.17054C9.44752 8.26891 9.37839 8.36461 9.30603 8.4575L11.2244 10.3758C11.4587 10.6101 11.4587 10.99 11.2244 11.2244C10.99 11.4587 10.6101 11.4587 10.3758 11.2244L8.4575 9.30603C8.11443 9.57327 7.73527 9.79476 7.32916 9.96298C6.43989 10.3313 5.46137 10.4277 4.51733 10.2399C3.57329 10.0521 2.70613 9.58863 2.02551 8.90802C1.3449 8.2274 0.881393 7.36024 0.693611 6.4162C0.50583 5.47216 0.602206 4.49364 0.970553 3.60437C1.3389 2.7151 1.96267 1.95504 2.76299 1.42028Z"
                      fill="#969696" />
              </svg>
            </nz-filter-trigger>
          </th>
          <th nzCustomFilter class="tableTitle" id="organTitle" nzWidth="120px"
            [(nzSortOrder)]="listOfColumns[1].sortOrder" [nzSortFn]="listOfColumns[1].sortFn" [nzShowFilter]="false">
            <span class="process_list__table__th__span">
              {{ 'commons.organ' | translate }}
              <img src="assets/icons/icon_sort_down.svg" alt="Ordenar por {{ listOfColumns[1].name | translate}}">
            </span>
            <nz-filter-trigger [(nzVisible)]="filterVisible.organ" [nzActive]="searchValue.length > 0"
                              [nzDropdownMenu]="menuOrgan">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M1.81407 8.9407e-07L1.8148 0L10.1861 4.29153e-07C10.4165 0.000284922 10.643 0.065204 10.8386 0.188491C11.0344 0.311866 11.1918 0.489083 11.2899 0.700659C11.388 0.912399 11.4218 1.14826 11.3864 1.37946C11.351 1.61045 11.2484 1.82477 11.093 1.99734C11.0929 1.99745 11.0931 1.99723 11.093 1.99734L7.83107 5.62986V10.2C7.83107 10.4295 7.70021 10.6388 7.49395 10.7393L5.03202 11.9393C4.84606 12.03 4.62653 12.0184 4.45111 11.9088C4.27569 11.7991 4.16913 11.6069 4.16913 11.4V5.62986L0.907479 1.99767C0.907338 1.99752 0.907196 1.99736 0.907054 1.9972C0.751715 1.82465 0.649139 1.61038 0.613767 1.37946C0.578351 1.14826 0.61218 0.9124 0.710315 0.700659C0.808373 0.489084 0.96579 0.311867 1.16158 0.188491C1.35722 0.0652042 1.58371 0.000284922 1.81407 8.9407e-07ZM1.35319 1.596L1.79933 1.19537C1.79915 1.19489 1.79898 1.19455 1.79887 1.19436L1.35319 1.596ZM1.80553 1.20171L5.21555 4.99912C5.31443 5.10923 5.36913 5.25201 5.36913 5.4V10.4401L6.63107 9.82497V5.4C6.63107 5.25201 6.68576 5.10923 6.78464 4.99912L10.1947 1.20171C10.192 1.20073 10.1886 1.20002 10.1848 1.2H1.81541C1.81159 1.20002 1.80824 1.20073 1.80553 1.20171Z"
                      fill="#969696" />
              </svg>
            </nz-filter-trigger>
          </th>
          <th nzCustomFilter class="tableTitle" id="responsibleTitle"  nzWidth="{{ screenWidth === 1920 ? '7vw' : '9vw'}}"
            [(nzSortOrder)]="listOfColumns[2].sortOrder" [nzSortFn]="listOfColumns[2].sortFn" [nzShowFilter]="false">
            <span class="process_list__table__th__span">
              {{ 'commons.responsible' | translate }}
              <img src="assets/icons/icon_sort_down.svg" alt="Ordenar por {{ listOfColumns[1].name | translate}}">
            </span>
            <nz-filter-trigger [(nzVisible)]="filterVisible.responsibleId" [nzActive]="searchValue.length > 0"
                              [nzDropdownMenu]="menuResponsible">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M1.81407 8.9407e-07L1.8148 0L10.1861 4.29153e-07C10.4165 0.000284922 10.643 0.065204 10.8386 0.188491C11.0344 0.311866 11.1918 0.489083 11.2899 0.700659C11.388 0.912399 11.4218 1.14826 11.3864 1.37946C11.351 1.61045 11.2484 1.82477 11.093 1.99734C11.0929 1.99745 11.0931 1.99723 11.093 1.99734L7.83107 5.62986V10.2C7.83107 10.4295 7.70021 10.6388 7.49395 10.7393L5.03202 11.9393C4.84606 12.03 4.62653 12.0184 4.45111 11.9088C4.27569 11.7991 4.16913 11.6069 4.16913 11.4V5.62986L0.907479 1.99767C0.907338 1.99752 0.907196 1.99736 0.907054 1.9972C0.751715 1.82465 0.649139 1.61038 0.613767 1.37946C0.578351 1.14826 0.61218 0.9124 0.710315 0.700659C0.808373 0.489084 0.96579 0.311867 1.16158 0.188491C1.35722 0.0652042 1.58371 0.000284922 1.81407 8.9407e-07ZM1.35319 1.596L1.79933 1.19537C1.79915 1.19489 1.79898 1.19455 1.79887 1.19436L1.35319 1.596ZM1.80553 1.20171L5.21555 4.99912C5.31443 5.10923 5.36913 5.25201 5.36913 5.4V10.4401L6.63107 9.82497V5.4C6.63107 5.25201 6.68576 5.10923 6.78464 4.99912L10.1947 1.20171C10.192 1.20073 10.1886 1.20002 10.1848 1.2H1.81541C1.81159 1.20002 1.80824 1.20073 1.80553 1.20171Z"
                      fill="#969696" />
              </svg>
            </nz-filter-trigger>
          </th>

          <th nzCustomFilter class="tableTitle" id="conclusionDate"  nzWidth="{{ screenWidth === 1920 ? '9vw' : '15vw'}}"
            [(nzSortOrder)]="listOfColumns[3].sortOrder" [nzSortFn]="listOfColumns[3].sortFn" [nzShowFilter]="false">
            <span class="process_list__table__th__span">
              {{ 'commons.conclusion-forecast' | translate }}
              <img src="assets/icons/icon_sort_down.svg" alt="Ordenar por {{ listOfColumns[1].name | translate}}">
            </span>
            <nz-range-picker  [nzPlaceHolder]="['Inicio','Fim']"
                              [nzFormat]="dateFormat"
                              [(ngModel)]="filterConclusionDate"
                              (ngModelChange)="filterDateElements($event, 'conclusionDate')"
                              (nzOnCalendarChange)="filterDateElements($event, 'conclusionDate')"></nz-range-picker>
          </th>

          <th nzCustomFilter class="tableTitle" id="protocolDate"  nzWidth="{{ screenWidth === 1920 ? '9vw' : '15vw'}}"
          [(nzSortOrder)]="listOfColumns[4].sortOrder" [nzSortFn]="listOfColumns[4].sortFn" [nzShowFilter]="false">
            <span class="process_list__table__th__span">
              {{ 'commons.protocol-date' | translate }}
              <img src="assets/icons/icon_sort_down.svg" alt="Ordenar por {{ listOfColumns[1].name | translate}}">
            </span>
            <nz-range-picker  [nzPlaceHolder]="['Inicio','Fim']"
                              [nzFormat]="dateFormat"
                              [(ngModel)]="filterProtocolDate"
                              (ngModelChange)="filterDateElements($event, 'protocolDate')"
                              (nzOnCalendarChange)="filterDateElements($event, 'protocolDate')"></nz-range-picker>
          </th>

          <th nzCustomFilter class="tableTitle" id="processStatus"  nzWidth="{{ screenWidth === 1920 ? '250px' : '200px'}}">
            {{ 'commons.process-status' | translate }}
            <nz-filter-trigger [(nzVisible)]="filterVisible.status" [nzActive]="searchValue.length > 0"
                              [nzDropdownMenu]="menuProcessStatus">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M1.81407 8.9407e-07L1.8148 0L10.1861 4.29153e-07C10.4165 0.000284922 10.643 0.065204 10.8386 0.188491C11.0344 0.311866 11.1918 0.489083 11.2899 0.700659C11.388 0.912399 11.4218 1.14826 11.3864 1.37946C11.351 1.61045 11.2484 1.82477 11.093 1.99734C11.0929 1.99745 11.0931 1.99723 11.093 1.99734L7.83107 5.62986V10.2C7.83107 10.4295 7.70021 10.6388 7.49395 10.7393L5.03202 11.9393C4.84606 12.03 4.62653 12.0184 4.45111 11.9088C4.27569 11.7991 4.16913 11.6069 4.16913 11.4V5.62986L0.907479 1.99767C0.907338 1.99752 0.907196 1.99736 0.907054 1.9972C0.751715 1.82465 0.649139 1.61038 0.613767 1.37946C0.578351 1.14826 0.61218 0.9124 0.710315 0.700659C0.808373 0.489084 0.96579 0.311867 1.16158 0.188491C1.35722 0.0652042 1.58371 0.000284922 1.81407 8.9407e-07ZM1.35319 1.596L1.79933 1.19537C1.79915 1.19489 1.79898 1.19455 1.79887 1.19436L1.35319 1.596ZM1.80553 1.20171L5.21555 4.99912C5.31443 5.10923 5.36913 5.25201 5.36913 5.4V10.4401L6.63107 9.82497V5.4C6.63107 5.25201 6.68576 5.10923 6.78464 4.99912L10.1947 1.20171C10.192 1.20073 10.1886 1.20002 10.1848 1.2H1.81541C1.81159 1.20002 1.80824 1.20073 1.80553 1.20171Z"
                      fill="#969696" />
              </svg>
            </nz-filter-trigger>
          </th>

          <th nzWidth="40px" id="separator"></th>

          <th nzCustomFilter class="tableTitle" id="validity"  nzWidth="{{ screenWidth === 1920 ? '9vw' : '15vw'}}"
            [(nzSortOrder)]="listOfColumns[6].sortOrder" [nzSortFn]="listOfColumns[6].sortFn" [nzShowFilter]="false">
            <span class="process_list__table__th__span">
              {{ 'commons.validity' | translate }}
              <img src="assets/icons/icon_sort_down.svg" alt="Ordenar por {{ listOfColumns[1].name | translate}}">
            </span>
            <nz-range-picker  [nzPlaceHolder]="['Inicio','Fim']"
              [nzFormat]="dateFormat"
              [(ngModel)]="filterValidity"
              (ngModelChange)="filterDateElements($event, 'validity')"
              (nzOnCalendarChange)="filterDateElements($event, 'validity')">
            </nz-range-picker>
          </th>
          <th nzCustomFilter class="tableTitle" id="licenseStatus" nzWidth="{{ screenWidth === 1920 ? '250px' : '150px'}}">
            {{ 'commons.licenses-status' | translate }}
            <nz-filter-trigger [(nzVisible)]="filterVisible.licenseStatus"
                              [nzActive]="searchValue.length > 0"
                              [nzDropdownMenu]="menuLicenseStatus">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M1.81407 8.9407e-07L1.8148 0L10.1861 4.29153e-07C10.4165 0.000284922 10.643 0.065204 10.8386 0.188491C11.0344 0.311866 11.1918 0.489083 11.2899 0.700659C11.388 0.912399 11.4218 1.14826 11.3864 1.37946C11.351 1.61045 11.2484 1.82477 11.093 1.99734C11.0929 1.99745 11.0931 1.99723 11.093 1.99734L7.83107 5.62986V10.2C7.83107 10.4295 7.70021 10.6388 7.49395 10.7393L5.03202 11.9393C4.84606 12.03 4.62653 12.0184 4.45111 11.9088C4.27569 11.7991 4.16913 11.6069 4.16913 11.4V5.62986L0.907479 1.99767C0.907338 1.99752 0.907196 1.99736 0.907054 1.9972C0.751715 1.82465 0.649139 1.61038 0.613767 1.37946C0.578351 1.14826 0.61218 0.9124 0.710315 0.700659C0.808373 0.489084 0.96579 0.311867 1.16158 0.188491C1.35722 0.0652042 1.58371 0.000284922 1.81407 8.9407e-07ZM1.35319 1.596L1.79933 1.19537C1.79915 1.19489 1.79898 1.19455 1.79887 1.19436L1.35319 1.596ZM1.80553 1.20171L5.21555 4.99912C5.31443 5.10923 5.36913 5.25201 5.36913 5.4V10.4401L6.63107 9.82497V5.4C6.63107 5.25201 6.68576 5.10923 6.78464 4.99912L10.1947 1.20171C10.192 1.20073 10.1886 1.20002 10.1848 1.2H1.81541C1.81159 1.20002 1.80824 1.20073 1.80553 1.20171Z"
                      fill="#969696" />
              </svg>
            </nz-filter-trigger>
          </th>
        </tr>
        </thead>
        <tbody>


        <tr *ngFor="let data of filterTable.data"
            (click)="goToProjectView(data.id)"
            class="cursor-pointer projectRow">
          <td class="h3-title" nzTooltipTitle="{{data.title}}" nzTooltipPlacement="topCenter" nz-tooltip>
            {{ screenWidth === 1920 ? (sidebar ? (data.title | truncate: 48) : (data.title | truncate: 46)) :
            (sidebar ? (data.title | truncate: 38) : (data.title | truncate: 38)) }}
            <span class="linkedProcesses"
                  *ngIf="data.linkedProcesses.length > 0"
                  [nzTooltipTitle]="linkedTemplate"
                  nzTooltipPlacement="bottomLeft"
                  nz-tooltip>
                <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" id="connect-icon">
                  <path fill-rule="evenodd" fill="#969696" clip-rule="evenodd" d="M3.1 4.92754C4.19093 4.6588 5 3.67388 5 2.5C5 1.11929 3.88071 0 2.5 0C1.11929 0 0 1.11929 0 2.5C0 3.67388 0.809071 4.6588 1.9 4.92754V7.5C1.9 7.90061 1.9865 8.50628 2.27206 9.03509C2.57165 9.5899 3.12258 10.1 4 10.1H7.07207C7.34081 11.1909 8.32612 12 9.5 12C10.8807 12 12 10.8807 12 9.5C12 8.11929 10.8807 7 9.5 7C8.32612 7 7.3412 7.80907 7.07246 8.9H4C3.67742 8.9 3.47835 8.74343 3.32794 8.46491C3.1635 8.16039 3.1 7.76606 3.1 7.5V4.92754ZM2.5 3.8C3.21797 3.8 3.8 3.21797 3.8 2.5C3.8 1.78203 3.21797 1.2 2.5 1.2C1.78203 1.2 1.2 1.78203 1.2 2.5C1.2 3.21797 1.78203 3.8 2.5 3.8ZM9.5 10.8C10.218 10.8 10.8 10.218 10.8 9.5C10.8 8.78203 10.218 8.2 9.5 8.2C8.78203 8.2 8.2 8.78203 8.2 9.5C8.2 10.218 8.78203 10.8 9.5 10.8Z"/>
                </svg>{{ data.linkedProcesses.length | number:'2.0' }}
              <ng-template #linkedTemplate class="darkPopover">
                  <p class="h3-title">{{ 'projects.processes.linked-processes' | translate }}</p>
                  <ul>
                    <li *ngFor="let link of data.linkedProcesses"> {{ link.label }}</li>
                  </ul>
                </ng-template>
              </span>
          </td>
          <td class="fontSize">{{ data.competentOrgan?.code }}</td>
          <td class="fontSize">{{ data.responsible?.name || '-' }}</td>
          <td [ngClass] = "(data.deadline !== null && data.deadline !== '') ? 'fontSize' : 'fontSize pendingProcessText'">{{ (data.deadline  | date : "dd/MM/yyyy") || 'Pendente' }}</td>
          <td class="fontSize">{{ (data.protocolDate  | date : "dd/MM/yyyy")  || 'Não protocolado'  }}</td>
          <td><app-badge [badgeText]="data.status" *ngIf="data.status"></app-badge></td>
          <td>
            <div class="alignIcon">
              <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 15.7071C-0.0976311 15.3166 -0.0976311 14.6834 0.292893 14.2929L6.58579 8L0.292893 1.70711C-0.0976309 1.31658 -0.0976309 0.683417 0.292893 0.292893C0.683418 -0.0976314 1.31658 -0.0976314 1.70711 0.292893L9.41421 8L1.70711 15.7071C1.31658 16.0976 0.683417 16.0976 0.292893 15.7071Z" fill="#969696"/>
              </svg>
            </div>
          </td>
          <td class="fontSize">{{(data.effectiveDate ? (data.effectiveDate  | date : "dd/MM/yyyy") : 'Não Emitida')}}</td>
          <td><app-badge [badgeText]="data.licenseStatus" *ngIf="data.licenseStatus"></app-badge></td>
        </tr>
        </tbody>
      </nz-table>
    </div>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <nz-input-group [nzPrefix]="suffixIconSearch" style="border-radius: 6px; font-size: 12px">
            <input type="text" nz-input placeholder="Pesquisar" autofocus 
                   (keyup)="searchProjectWithEnter($event)" style="font-size: 12px !important;" [(ngModel)]="searchValue" />
          </nz-input-group>
          <ng-template #suffixIconSearch>
            <span nz-icon nzType="search" style="color: #007E7A !important; font-size: 14px !important"></span>
          </ng-template>
          <div style="margin-top: 18px; display: flex; justify-content: flex-end; align-items: center;">
            <button nz-button nzSize="small" (click)="resetSearchByProject()"
                    style="border: none; color: #007E7A !important; font-size: 12px; margin-right: 20px;">
              Limpar busca
            </button>
            <button nz-button nzSize="small" (click)="updateFilterAndCallEndpoint({ key: 'title', value: searchValue })"
                    style="border-radius: 6px; display: inline-flex; font-size: 12px; padding: 8px; justify-content: center; align-items: center; height: 30px; color: #007E7A !important;">
              <span style="margin-top: 3px">Buscar</span> 
            </button>
          </div>
        </div>
      </div>
    </nz-dropdown-menu>

    <nz-dropdown-menu #menuOrgan="nzDropdownMenu">
      <div class="ant-table-filter-dropdown popoverFilter">
        <div class="search-box">
          <nz-checkbox-group [(ngModel)]="filterOrgan"
                            (ngModelChange)="updateFilterAndCallEndpoint({ key: 'organ', value: $event}, true)">
          </nz-checkbox-group>
          <nz-divider class="divider16"></nz-divider>
          <button class="btn__text" (click)="resetSearch('filterOrgan')">
            <span class="btn__text__span">Limpar filtro</span>
          </button>
        </div>
      </div>
    </nz-dropdown-menu>

    <nz-dropdown-menu #menuResponsible="nzDropdownMenu">
      <div class="ant-table-filter-dropdown popoverFilter">
        <div class="search-box">
          <nz-checkbox-group [(ngModel)]="filterResponsible"
                            (ngModelChange)="updateFilterAndCallEndpoint({ key: 'responsibleId', value: $event}, true)">
          </nz-checkbox-group>
          <nz-divider class="divider16"></nz-divider>
          <button class="btn__text" (click)="resetSearch('filterResponsible')">
            <span class="btn__text__span">Limpar filtro</span>
          </button>
        </div>
      </div>
    </nz-dropdown-menu>
    <nz-dropdown-menu #menuConclusionDate="nzDropdownMenu">
      <div class="ant-table-filter-dropdown popoverFilter">
        <div class="search-box">
          teste
          <nz-checkbox-group [(ngModel)]="filterConclusionDate"
                            (ngModelChange)="updateFilterAndCallEndpoint({ key: 'conclusionDate', value: $event}, true)">
          </nz-checkbox-group>
          <nz-divider class="divider16"></nz-divider>
          <button class="btn__text" (click)="resetSearch('filterConclusionDate')">
            <span class="btn__text__span">Limpar filtro</span>
          </button>
        </div>
      </div>
    </nz-dropdown-menu>
    <nz-dropdown-menu #menuProtocolDate="nzDropdownMenu">
      <div class="ant-table-filter-dropdown popoverFilter">
        <div class="search-box">
          <nz-checkbox-group [(ngModel)]="filterProtocolDate"
                            (ngModelChange)="updateFilterAndCallEndpoint({ key: 'protocolDate', value: $event}, true)">
          </nz-checkbox-group>
          <nz-divider class="divider16"></nz-divider>
          <button class="btn__text" (click)="resetSearch('filterProtocolDate')">
            <span class="btn__text__span">Limpar filtro</span>
          </button>
        </div>
      </div>
    </nz-dropdown-menu>
    <nz-dropdown-menu #menuProcessStatus="nzDropdownMenu">
      <div class="ant-table-filter-dropdown popoverFilter">
        <div class="search-box">
          <nz-checkbox-group [(ngModel)]="filterProcessStatus"
                            (ngModelChange)="updateFilterAndCallEndpoint({ key: 'status', value: $event}, true)">
          </nz-checkbox-group>
          <nz-divider class="divider16"></nz-divider>
          <button class="btn__text" (click)="resetSearch('filterProcessStatus')">
            <span class="btn__text__span">Limpar filtro</span>
          </button>
        </div>
      </div>
    </nz-dropdown-menu>
    <nz-dropdown-menu #menuValidity="nzDropdownMenu">
      <div class="ant-table-filter-dropdown popoverFilter">
        <div class="search-box">
          <nz-checkbox-group [(ngModel)]="filterValidity"
                            (ngModelChange)="updateFilterAndCallEndpoint({ key: 'validity', value: $event}, true)">
          </nz-checkbox-group>
          <nz-divider class="divider16"></nz-divider>
          <button class="btn__text" (click)="resetSearch('filterValidity')">
            <span class="btn__text__span">Limpar filtro</span>
          </button>
        </div>
      </div>
    </nz-dropdown-menu>
    <nz-dropdown-menu #menuLicenseStatus="nzDropdownMenu">
      <div class="ant-table-filter-dropdown popoverFilter">
        <div class="search-box">
          <nz-checkbox-group [(ngModel)]="filterLicenseStatus"
                            (ngModelChange)="updateFilterAndCallEndpoint({ key: 'licenseStatus', value: $event}, true)">
          </nz-checkbox-group>
          <nz-divider class="divider16"></nz-divider>
          <button class="btn__text" (click)="resetSearch('filterLicenseStatus')">
            <span class="btn__text__span">Limpar filtro</span>
          </button>
        </div>
      </div>
    </nz-dropdown-menu>
  </div>
</div>
