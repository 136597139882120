<div class="container project-v2-view-container">
  <div nz-row class="mb-20" *ngIf="!loading">
    <div nz-col nzSpan="20" class="header">
      <a [routerLink]="['/projects-v2']" class="mr-8" id="goBack">
        <img src="/assets/icons/icon_arrow-back.svg" alt="Voltar">
      </a>
      <h1 class="h1-title" id="projectName"><app-short-text-hint [originalText]="project.name"
          [maxChars]="100"></app-short-text-hint></h1>
    </div>
    <div nz-col nzSpan="4" class="action-box">
      <button class="btn-sl btn-sl-green" id="btnNewRipla" nz-popover nzPopoverPlacement="top" nzPopoverTrigger="click"
        [nzPopoverContent]="popOverAddRiplaContent" [nzPopoverOverlayStyle]="customStyle"
        [(nzPopoverVisible)]="popOverAddRipla1" *ngIf="riplarequisition && btnNewRipla">
        {{ 'projects.button-new-requisition' | translate }}
        <span class="btnNewRiplaIcon" nz-icon>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
            class="btnNewRiplaIconSvg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M5.03436 6.63422C5.34678 6.3218 5.85331 6.3218 6.16573 6.63422L8.00005 8.46853L9.83436 6.63422C10.1468 6.3218 10.6533 6.3218 10.9657 6.63422C11.2782 6.94664 11.2782 7.45317 10.9657 7.76559L8.00005 10.7313L5.03436 7.76559C4.72194 7.45317 4.72194 6.94664 5.03436 6.63422Z"
              fill="white" />
          </svg>
        </span>
      </button>
    </div>
  </div>
  <div class="scrollableContent">
    <div class="rounded-row" *ngIf="!loading">
      <div class="subtitle-header">
        <h2>{{ 'projects.view.basic-information' | translate }}</h2>
        <a (click)="gotoDocuments()" class="project-doc-link">{{ 'projects.view.project-documents' | translate }}</a>
      </div>

      <div nz-row nzJustify="space-evenly" nzAlign="middle" class="mt-10 alignProjectItems">
        <div nz-col nzSpan="4">
          <h3 class="projectNumberText">{{ 'projects.view.project-number' | translate }}</h3>
          <span id="projectNumber">
            <app-short-text-hint
              [originalText]="project.number !== undefined && project.number !== '' ? project.number : '-'"
              [maxChars]="22"></app-short-text-hint></span>
        </div>
        <div nz-col nzSpan="7" class="locationInfo">
          <h3 class="locationTitle">{{ 'projects.view.location' | translate }}</h3>
          <span id="location"> <app-short-text-hint [originalText]="projectLocation"
              [maxChars]="41"></app-short-text-hint> </span>
        </div>
        <div nz-col nzSpan="2">
          <h3 class="classificationTitle">{{ 'projects.view.classification' | translate }}</h3>
          <span id="classification">{{ project.classificationType | titlecase }}</span>
        </div>
        <div nz-col nzSpan="2">
          <h3 class="areaTitle">{{ 'projects.view.applicant-area' | translate }}</h3>
          <app-short-text-hint id="area" [originalText]="project.area.description"
            [maxChars]="15"></app-short-text-hint>
        </div>
        <div nz-col nzSpan="5">
          <h3 class="userLegalRepresentativeTitle">{{ 'projects.view.responsible-requesting-area' | translate }}</h3>
          <span id="userLegalRepresentative">{{ project.userLegalRepresentative.name }}</span>
        </div>
        <div nz-col nzSpan="4">
          <h3 class="codeTitle">{{ 'projects.view.project-code' | translate }}</h3>
          <span id="code">{{ project.code }}</span>
        </div>
      </div>
    </div>
    <div>
      <div nz-row>
        <div nz-col nzSpan="20" class="riplaAndProcess">
          <div class="rounded-row " *ngIf="!loading">
            <div class="subtitle-header">
              <h2 class="project-requirements">{{ 'projects.view.project-requirements' | translate }}</h2>
              <a href="" class="project-doc-link" nz-popover nzPopoverPlacement="top" nzPopoverTrigger="click"
                [nzPopoverContent]="popOverAddRiplaContent" [nzPopoverOverlayStyle]="customStyle"
                [(nzPopoverVisible)]="popOverAddRipla2" *ngIf="riplarequisition && btnNewRipla">
                <img src="/assets/icons/icon_plus_circle.svg" alt="{{'projects.view.add-ripla'}}">
              </a>
            </div>
            <div class="mt-24">
              <nz-table #riplaTable nzSize="small" [nzScroll]="{ y: '150px' }" [nzData]="subProjects"
                [nzFrontPagination]="false" class="">
                <thead>
                  <tr>
                    <th class="tableTh subProjectHeaderTitle" id="titleRequirements" nzWidth="35%">
                      {{'projects.view.title-requirements' | translate}}
                    </th>
                    <th class="tableTh subProjectResponsibleTitle" id="userResponsible">
                      {{'projects.view.user-responsible' | translate}}
                    </th>
                    <th class="tableTh subProjectRegistrationDateTitle" id="registeredDate" nzWidth="13%">
                      {{'projects.view.registered-date' | translate}}
                    </th>
                    <th class="tableTh deadlineAnalysisTitle" id="deadlineDate" nzWidth="13%">
                      {{ 'projects.view.deadline-date' | translate }}
                    </th>
                    <th class="tableTh subProjectStatusTitle" id="status">
                      {{ 'projects.view.status' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of riplaTable.data" class="subProjects"
                    (click)="goToAnalysis(data, data.projectId, data.subProjectId, data.name)">
                    <td class="tableTd tdBold subProjectTitle">{{ data.name }}</td>
                    <td class="tableTd subProjectResponsible">{{ data.userResponsibleName ? data.userResponsibleName : "-" }}</td>
                    <td class="tableTd subProjectRegistrationDate">{{ data.registrationDate | date: 'dd/MM/YYYY' }}</td>
                    <td class="tableTd deadlineAnalysis">{{ data.analysisDeadLine | date: 'dd/MM/YYYY' }}</td>
                    <td class="tableTd subProjectStatus">
                      <app-badge [badgeText]="data.status" [badgeColor]="data.overdue ? 'red' : 'blue'"
                        *ngIf="data.status === 'EAN'"></app-badge>
                      <app-badge [badgeText]="data.status" *ngIf="data.status !== 'EAN'"></app-badge>
                    </td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
          <!-- HIDE GO LIVE -->
          <div class="rounded-row mb-10" *ngIf="!loading">
            <div class="subtitle-header">
              <h2 (click)="navigateToListProcess(projectId)" class="license-header-title">{{
                'projects.view.license.header-title' | translate }}</h2>
              <a  *ngIf="cadProcessLicensing && btnNewProccesLicensing" (click)="navigateToNewLicensingProcess(projectId)" class="project-doc-link">
                <img src="/assets/icons/icon_plus_circle.svg" alt="{{'projects.view.add-ripla'}}">
              </a>
            </div>
            <div class="mt-24">
              <nz-table #processTable nzSize="small" [nzScroll]="{ y: '150px' }" nzTableLayout="fixed" [nzFrontPagination]="false"
                [nzData]="processList">
                <thead>
                  <tr>
                    <th class="tableTh licenseTitle" id="processTitle" nzWidth="270px">
                      {{'projects.view.license.title' | translate }}
                    </th>
                    <th class="tableTh licenseResponsible" id="licenseResponsible">
                      {{'projects.view.license.responsible' | translate }}
                    </th>
                    <th class="tableTh licenseRegistrationDate" id="licenseRegistrationDate">
                      {{ 'projects.view.license.registrationDate' | translate}}
                    </th>
                    <th class="tableTh licenseDeadline" id="licenseDeadline">
                      {{ 'projects.view.license.deadline' | translate }}
                    </th>
                    <th class="tableTh licenseStatus" id="licenseStatus"nzWidth="130px">
                      {{'projects.view.license.status' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let process of processList" class="licenses cursor-pointer"
                    (click)="navigateToNewLicensingProcess(projectId, process.id)">
                    <td class="tableTd tdBold licenseTitleItem" nz-tooltip nzTooltipTitle="{{ process.title }}">{{
                      process.title }}</td>
                    <td class="tableTd licenseResponsibleItem">
                      {{ process.responsible ? process.responsible.name : '-' }}</td>
                    <td class="tableTd licenseRegistrationDateItem">
                      <span *ngIf="process.protocolDate"> {{process.protocolDate | date: 'dd/MM/YYYY'}}</span>
                      <span *ngIf="!process.protocolDate"> {{ 'commons.not-registered' | translate }}</span>
                    </td>
                    <td class="tableTd licenseDeadlineRegistrationItem">
                      <span *ngIf="process.deadline"> {{process.deadline | date: 'dd/MM/YYYY'}} </span>
                      <span *ngIf="!process.deadline" class="pendingProcessText"> {{ 'commons.pending' | translate }}
                      </span>
                    </td>
                    <td class="tableTd LicenseStatus">
                      <app-badge [badgeText]="process.status"></app-badge>
                    </td>
                  </tr>
                </tbody>
              </nz-table>
            </div>
          </div>
        </div>
        <div nz-col nzSpan="4" *ngIf="!loading">
          <app-project-v2-logs 
            [projectId]="projectId" 
            [subProjectId]="subProjectsIdList" 
            [scopeChangeIdList]="scopeChangeIdList" 
            [suspensionIdList]="suspensionIdList"
            [cancellationIdList]="cancellationIdList" 
            [processId]="processIdList"  
            style="block-size: 95.5% !important; display: block;"
          >
          </app-project-v2-logs>
        </div>
      </div>
    </div>
  </div>
  <div class="rounded-row spinner-container" *ngIf="loading">
    <nz-spin nzSimple id="spinner"></nz-spin>
  </div>

</div>
<ng-template #popOverAddRiplaContent class="popOverMargin">
  <div class="btnNewRipla" id="btnNewRiplaPreliminar" (click)="createRIPLA('preliminar')">
    {{ 'projects.ripla.preliminary' | translate }}
  </div>
  <div class="btnNewRipla" (click)="createRIPLA('definitiva')" id="btnNewRiplaDefinitiva">
    {{ 'projects.ripla.definitive' | translate }}
  </div>
  <div class="btnNewRipla" *ngIf="cadScopeChange && ruleScopeChange()" (click)="createChangeScope()" id="btnChangeScope">
    {{ 'projects.scope-change.request' | translate }}
  </div>
  <div class="btnNewRipla" *ngIf="ruleScopeChange()" (click)="createModalSuspensionCancellation('suspension')" id="btnChangeScope">
    {{ 'projects.suspension-cancellation.modal.title.suspension' | translate }}
  </div>
  <div class="btnNewRipla" *ngIf="ruleScopeChange()" (click)="createModalSuspensionCancellation('cancellation')" id="btnChangeScope">
    {{ 'projects.suspension-cancellation.modal.title.cancellation' | translate }}
  </div>
</ng-template>
