import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { SummaryAboutProject } from "../../scope-change/interface/summary-scope-change";
import { ProjectV2ViewResponseDto } from "src/app/shared/dto/project-v2-view-response.dto";
import { ProjectsV2Service } from "src/app/shared/services/projects-v2.service";
import { ProjectSuspension } from "src/app/shared/dto/project-suspension.dto";
import moment from "moment";
import { AnalysisInformations } from "src/app/shared/dto/analysis-info.dto";
import { UserService } from "src/app/shared/services/user.service";
import { MessageService } from "src/app/shared/common/services/message.service";
import { LicensingService } from "../../projects/projects-licensing/service/licensing.service";

@Component({
  selector: 'app-project-v2-suspension',
  templateUrl: './project-v2-suspension.component.html',
  styleUrls: ['./project-v2-suspension.component.scss']
})
export class ProjectV2SuspensionComponent implements OnInit {
  projectId: number;
  suspensionId: number;
  projectSummary: SummaryAboutProject;
  suspension: ProjectSuspension;
  label: string;
  analysisInfo: AnalysisInformations;
  licensingProcessesIds: number[] = [];
  licensingProcessList: Array<{ [key: string]: any }> = [];
  studiesList: Array<{ [key: string]: any }> = [];

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private readonly projectService: ProjectsV2Service,
    private readonly userService: UserService,
    private readonly messageService: MessageService,
    private readonly licensingService: LicensingService
  ) {}

  ngOnInit(): void {
    this.projectId = this.route.snapshot?.params['projectId'];
    this.suspensionId = this.route.snapshot?.params['suspensionId'];

    this.getSuspensionInfo();
    this.getLicensingProcessList();
  }

  getSuspensionInfo() {
    this.projectService.getProjectSuspension(this.suspensionId).subscribe({
      next: (suspension) => {
        this.suspension = {
          ...suspension,
          expectedResumeDate: suspension.expectedResumeDate ? this.capitalizeFirstLetter(
            moment(suspension.expectedResumeDate, "YYYY-MM-DD").locale('pt-br').format('MMM/YYYY')
          ) : null
        }
        this.getProjectSummary();
        this.getAnalysisInfo();
        
        if (this.suspension.type == 'SUSPENSION') {
          this.label = 'projects.suspension.suspension-label'
        } else {
          this.label = 'projects.suspension.cancel-label'
        }
      }
    })
  }

  getAnalysisInfo() {
    this.analysisInfo = {
      number: this.suspension.number,
      responsible: this.suspension.responsibleUser,
      registrationDate: this.suspension.registrationDate,
      deadline: this.suspension.deadline,
      status: this.suspension.status
    }
  }

  getUserListForAnalysis = () => {
    return this.userService.getUsersByRoleAndRegion('ROLE_LICENCIAMENTO', 
      this.suspension.project?.region.id);
  }

  getProjectSummary() {
    this.projectSummary = {
      projectName: this.suspension.project?.name || "",
      projectCode: this.suspension.project?.code || "",
      projectNumber: this.suspension.project?.number || "",
      projectClassification: this.suspension.project?.classificationType || "",
      projectDescription: this.suspension.project?.comments || "",
      localization: this.getLocalization(this.suspension.project),
      areaRequest: this.suspension.project?.area || "",
      responsible: this.suspension.project?.userLegalRepresentative?.name || ""
    }
  }

  private capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  private getLocalization(project: any | null): string {
    let localization = "";

    if (project && project.complex && project.region) {
      localization = `${project.region.description} / ${project.complex.description}`
      if (project.operationalUnit) {
        localization += ` / ${project.operationalUnit}`
      }
    }
    
    return localization;
  }

  getValueChanged = (userId) => {
    this.projectService.updateSuspensionResponsible(this.suspensionId, userId).subscribe({
      next: (updatedSuspension) => {
        this.messageService.showSucessMessage("Atualizado com sucesso!");
        this.suspension = updatedSuspension;
        this.getAnalysisInfo()
      }
    })
  }

  getLicensingProcessList() {
    this.licensingService.getLicensingList(this.projectId).subscribe({
      next: (licenses) => {
        const listForLicenses: any[] = [];

        licenses.content.forEach(licenseData => {
          const newLicensingProcessToList = { 
            'Título do licenciamento': ['text', licenseData.title], 
            'Status': ['status', licenseData.status],
            'Link': `/project/${this.projectId}/edit-processv2/${licenseData.id}`
          }

          this.licensingProcessesIds.push(licenseData.id);
          listForLicenses.push(newLicensingProcessToList)
        })

        this.licensingProcessList = listForLicenses;
        this.getStudiesList();
      }
    })
  }

  getStudiesList() {
    this.licensingService.getStudiesByLicensingProcessList(this.licensingProcessesIds).subscribe({
      next: (studies) => {
        studies.forEach(study => {
          const newStudyToList = { 
            'Estudo': ['text', study.title], 
            'Status': ['status', study.status],
            'Link': study.route
          }

          this.studiesList.push(newStudyToList);
        })
      }
    })
  }

  goBack(){
    this.location.back();
  }

  redirectToFinalAnalysis() {

  }
}