import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CompetentOrgan } from "src/app/shared/models/competent-organ.model";
import { CompetentOrganService } from "src/app/shared/services/competent-organ.service";
import { ProjectsService } from "../../../projects.service";
import { ProjectModel } from "src/app/shared/models/project.model";
import { UserService } from "src/app/shared/services/user.service";
import { UserModel } from "src/app/shared/models/user.model";
import { LicensingCreationBody } from "../../licensing.interface";
import { LicensingService } from "../../service/licensing.service";

@Component({
  selector: 'app-new-project-licensing-form',
  templateUrl: './new-project-licensing-form.component.html',
  styleUrls: ['./new-project-licensing-form.component.scss']
})
export class NewProjectLicensingForm implements OnInit {
  @Input() changePageTitle: (title: string) => void;
  @Input() isSavedProcess: boolean;
  @Input() process: any
  @Input() projectId: number;
  @Output() update: EventEmitter<any> = new EventEmitter<any>();

  disableLicenseType = true;
  competentOrgans: CompetentOrgan[] = [];
  project: ProjectModel;
  users: UserModel[] = [];
  licensesTypes: any[] = [];
  projectLicensingCreateForm: LicensingCreationBody = {
    projectId: -1,
    competentOrganId: -1,
    licenseTypeId: -1,
    userId: 1, //TODO -- GET CURRENT USER
    title: ""
  };
  editMode = false;

  constructor(
    private readonly competentOrganService: CompetentOrganService,
    private readonly projectService: ProjectsService,
    private readonly userService: UserService,
    private readonly licensingService: LicensingService
  ){}

  ngOnInit(): void {
    this.isEditMode();
    this.getProject();
    this.getCompetentOrgans();
  }

  getLicensingTypes(id) {
    this.licensingService.getLicensesByOrgan(id).subscribe({
      next: (licensesTypes) => {
        this.licensesTypes = licensesTypes;
        this.disableLicenseType = false;
      }
    });
  }

  private getCompetentOrgans() {
    this.competentOrganService.getCompetentOrgans().subscribe({
      next: (organs) => {
        this.competentOrgans = organs;
      }
    })
  }

  private getUsers() {
    if (this.project.region) {
      this.userService.getUsersByRoleAndRegion("ROLE_LICENCIAMENTO", this.project.region?.id).subscribe({
        next: (users) => {
          this.users = users;
          this.initForm();
        }
      })
    }
  }

  private getProject() {
      this.projectService.getProject(this.projectId).subscribe({
        next: (project) => {
          this.project = project;
          this.getUsers();
        }
      });
  }

  private initForm() {
    const loggedUser = sessionStorage.getItem("loggedUser");
    const user = loggedUser ?  JSON.parse(loggedUser) : null;

    if(this.editMode){
      this.projectLicensingCreateForm = {
        projectId: this.projectId,
        competentOrganId: this.process.competentOrgan.id,
        licenseTypeId: this.process.license.id,
        userId: 1, //TODO -- GET CURRENT USER
        title: "ampadopadmd", //TODO AFTER BACK ADJUST
        processNumber: this.process.processNumber ? this.process.processNumber : ""
      };
    }

    this.projectLicensingCreateForm = {
      ...this.projectLicensingCreateForm,
      projectId: this.projectId,
      userId: user.id,
    }

  }


  public handleChange(itemName: string, itemValue: number | string): void {
    this.onChangeForm(itemName, itemValue);
    this.update.emit(true);
  }

  public onChangeForm(formItemName: string, formItemValue: number | string) {
    if (formItemName === "competentOrganId") {
      this.getLicensingTypes(formItemValue);
      this.changePageTitle('Novo processo de licenciamento');
      this.onChangeForm("licenseTypeId", -1);
    }

    if (formItemName === "licenseTypeId") {
      for (let licenseType of this.licensesTypes) {
        if (licenseType.id == formItemValue) {
          this.changePageTitle(licenseType.description);
          break;
        }
      }
    }

    this.projectLicensingCreateForm[formItemName] = formItemValue;
  }

  public disabledDate = (current: Date) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return current < currentDate;
  };

  isEditMode(){
    if(this.process && this.process.status && this.process.competentOrgan.description){
      this.editMode = true;
    }
  }
}
