import {Component, inject} from '@angular/core';
import {NzModalRef} from "ng-zorro-antd/modal";

@Component({
  selector: 'app-confirm-process-delete',
  templateUrl: './confirm-process-delete.component.html',
  styleUrls: ['./confirm-process-delete.component.scss']
})
export class ConfirmProcessDeleteComponent {
  readonly #modal = inject(NzModalRef);
  constructor() { }

  handleOk() {
    this.#modal.destroy(true);
  }

  handleCancel() {
    this.#modal.destroy(false);
  }

}
