import {Component, OnInit} from '@angular/core';
import {UserService} from "../../../shared/services/user.service";
import {NlpResultResponseDto} from "../nlp-analysis-result/result-body/dto/nlp-result-response.dto";
import {NlpAnalysisResultService} from "../nlp-analysis-result/nlp-analysis-result.service";
import {AttachmentsService} from "../../../shared/services/attachments.service";
import {take} from 'rxjs';
import {MessageService} from 'src/app/shared/common/services/message.service';
import {Router} from "@angular/router";
import {TopBreadcrumbService} from "../../../shared/common/services/top-breadcrumb/top-breadcrumb.service";
import {NzMessageService} from "ng-zorro-antd/message";
import {SidebarService} from "../nlp-sidebar/service/sidebar.service";
import {ProductNlpService} from "../services/product-nlp.service";
import {BreadCumbNlpService} from "../services/bread-cumb-nlp.service";
import {DateCalcHelper} from "../../../shared/helpers/date-calc.helper";

@Component({
  selector: 'app-nlp-report',
  templateUrl: './nlp-report.component.html',
  styleUrls: ['./nlp-report.component.scss']
})
export class NlpReportComponent implements OnInit {
  isProductNlpEmpty: boolean = false;
  productNlpId?: number;
  reportNlp?: NlpResultResponseDto;
  reportNlpOriginal?: NlpResultResponseDto;
  search!: string;
  productDemandId: number = 0;

  projectName: string | null = '';
  projectId: string | null = '';
  licenseId: string | null = '';
  licenseName: string | null = '';
  demandId: string | null = '';
  productNlpIdLocal: string | null = '';

  isLoadingSaveReport = false;

  constructor(private userService: UserService,
              private nlpAnalysisResultService: NlpAnalysisResultService,
              private attachmentService: AttachmentsService,
              private readonly message: MessageService,
              private router: Router,
              private topBreadcrumb: TopBreadcrumbService,
              private readonly msg: NzMessageService,
              private sideBarService: SidebarService,
              private productNlpService: ProductNlpService,
              private breadCumbNlpService: BreadCumbNlpService,
              private dateCalcHelp: DateCalcHelper) {
  }

  ngOnInit(): void {
    this.getProductNlp();
    this.getProductDemandId();
  }

  private getProductNlp() {
    let prodNlpId: string | null = localStorage.getItem('productNlpId');
    if (typeof prodNlpId !== 'undefined' && prodNlpId !== null) {
      this.productNlpService.getProductNlpById(+prodNlpId)
        .pipe(take(1))
        .subscribe(productNlpUpdated => {
            this.userService.updateProductNlp(productNlpUpdated);
            this.userService.updateValueTopMenu(true);
          },
          (error) => this.msg.error(`Erro ao selecionar produto NLP: ${error.message}`),
          () => this.getReportBySelectedProductNlpId()
        );
    } else {
      this.getReportBySelectedProductNlpId();
    }

  }

  searchFilter(): void {
    this.reportNlp = JSON.parse(JSON.stringify(this.reportNlpOriginal));
    if (this.search === '') {
      this.reportNlp = this.reportNlpOriginal;
      return;
    }

    if (!this.reportNlp) {
      return;
    }
    if (this.reportNlp?.resultSectionNlpAndTrResponseList !== undefined) {
      this.reportNlp.resultSectionNlpAndTrResponseList = this.reportNlp?.resultSectionNlpAndTrResponseList
        .filter((resultSection) =>
          resultSection.resultSections ?
            resultSection.resultSections.sectionTitle.toLowerCase().includes(this.search.toLowerCase()) : false
        );
    }

  }

  private getReportBySelectedProductNlpId(): void {
    this.userService.productNlpObservable$.pipe(take(1)).subscribe(productNlp => {
      if (Object.keys(productNlp).length === 0) {
        this.isProductNlpEmpty = true;
        this.router.navigate([`nlp/welcome`]);
      } else {
        this.isProductNlpEmpty = false;
        this.productNlpId = productNlp.id;
        this.getNlpAnalysisReport(productNlp.id);
      }
    });

  }

  private getProductDemandId(): void {
    this.userService.selectedProductDemandId$.pipe(take(1)).subscribe(productDemandId => {
      if (productDemandId !== 0) {
        this.productDemandId = productDemandId;
      }
    });
  }

  private getNlpAnalysisReport(productNlpId: number | undefined): void {
    if (this.router.url === '/nlp/relatorio') {
      this.nlpAnalysisResultService.getNlpAnalysisResult(productNlpId)
        .pipe(take(1))
        .subscribe(result => {
        this.reportNlp = result;
        this.reportNlpOriginal = result;
      });
    }
  }

  getTimeZoneDate(date: string): Date {
    return this.dateCalcHelp.getTimeZoneDate(date);
  }

}
