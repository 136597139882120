<div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="app-resume-scope-change-request-container">
  <!-- <div [classList]="['scope', shouldHavePadding && 'no-padding']"> -->
  <div class="scope">
    <h2 class="scope__title" *ngIf="shouldShowTitle">
      <!-- Resumo da requisição -->
      {{"projects.scope-change.resume-title" | translate }}
    </h2>

    <div class="scope__items">
      <div class="scope__items__item" id="resumoEscopo" *ngIf="scopeSummary">
        <h3 class="scope__items__item__title">
          <!-- Sobre a mudança de escopo -->
          {{ "projects.scope-change.requisition-resume-about" | translate }}
        </h3>

        <div class="scope__items__item__row">
          <p class="scope__items__item__row__title">
            <!-- Mudanças -->
            {{ "projects.scope-change.changes" | translate }}
          </p>
          <ul>
            <li class="scope__items__item__row__description" *ngFor="let summary of sortScopeChangeSummary()">
              <span class="bullet">&#x2022;</span>
              {{summary.description}}
            </li>
          </ul>
        </div>

        <div class="scope__items__item__row">
          <p class="scope__items__item__row__title">
            <!-- Observações -->
            {{ "projects.scope-change.comments" | translate }}
          </p>
          <p class="scope__items__item__row__description">
            {{ scopeSummary.description }}
          </p>
        </div>
      </div>

      <div class="scope__items__item" *ngIf="suspension">
        <h3 *ngIf="suspensionLabel" class="scope__items__item__title">{{ suspensionLabel | translate }}</h3>

        <div class="scope__items__item__row">
          <p class="scope__items__item__row__title">
            {{ "projects.suspension.changes" | translate }}
          </p>
          <ul>
            <li class="scope__items__item__row__description" *ngFor="let reason of suspension.reasons">
              <span class="bullet">&#x2022;</span>
              {{ reason.description }}
            </li>
          </ul>
        </div>

        <div class="scope__items__item__row" *ngIf="suspension.type === 'SUSPENSION' && suspension.estimateResumeProject">
          <p class="scope__items__item__row__title">
            {{ "projects.suspension.expected-resume" | translate }}
          </p>
          <p class="scope__items__item__row__description">
            {{ suspension.expectedResumeDate }}
          </p>
        </div>

        <div class="scope__items__item__row">
          <p class="scope__items__item__row__title">
            {{ "projects.suspension.details" | translate }}
          </p>
          <p class="scope__items__item__row__description">
            {{ suspension.reasonDetails }}
          </p>
        </div>
      </div>

      <app-project-resume-infos *ngIf="projectSummary" [projectSummary]="projectSummary">
      </app-project-resume-infos>

      <div class="scope__items__item" *ngIf="attachments && attachments.length > 0">
        <h3 class="scope__items__item__title">
          <!-- Anexos -->
          {{'projects.scope-change.attachments' | translate }}
        </h3>
        <div class="fileList">
          <div class="document mt-05" *ngFor="let attachment of attachments">
            <img class="demand-file-icon" *ngIf="attachment.status === 'done'" src=" {{ getIconFromFileName(attachment.name) }}" alt="some file" />
            <app-svg-icon *ngIf="attachment.status === 'error'" icon="atention-yellow-icon" [width]="16" [height]="16" class="iconAlignCenter"></app-svg-icon>
            <div
              class="fileName"
              [class.fileName-error]="attachment.status === 'error'"
              (click)="downloadFile(attachment)">
              {{attachment.name.length > 40 ?
                (getAttachmentTruncatedName(attachment.name)) :
                (attachment.name)
              }}
            </div>
          </div>



        </div>
      </div>
    </div>
  </div>
</div>
