<div nz-row nz-col nzSpan="24" style="height: 140px !important;">

    <h2 class="titulo" style="margin-top: 16px;">Salvando rascunho</h2>
    <small class="textoFixo">Isso pode levar alguns minutos</small>
  
    <nz-progress [nzPercent]="progressBarValue" [nzWidth]="555" [nzShowInfo]="false" class="progressBar" ></nz-progress>
  
    <div nz-col nzSpan="24" class="ali-left">
      <button
        id="btnCancel"
        type="button"
        class="btn-sl btn-sl-grey buttonCancel"
        (click)="close()"
      >
        Cancelar
      </button>
    </div>
</div>
