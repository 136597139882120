<div nz-row class="nlp-modal-update-tr-result">
  <div
    nz-col
    nzXs="24"
    nzSm="24"
    nzMd="24"
    nzLg="24"
    nzXl="24"
  >

    <svg class="nlp-modal-update-tr-result-icon" width="85" height="85" viewBox="0 0 85 85" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M39.4948 56.4948C41.1545 54.8351 43.8455 54.8351 45.5052 56.4948L54.0052 64.9948C55.6649 66.6545 55.6649 69.3455 54.0052 71.0052L45.5052 79.5052C43.8455 81.1649 41.1545 81.1649 39.4948 79.5052C37.8351 77.8455 37.8351 75.1545 39.4948 73.4948L44.9896 68L39.4948 62.5052C37.8351 60.8455 37.8351 58.1545 39.4948 56.4948Z"
            fill="#007E7A"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M2.48959 15.2396C4.08365 13.6455 6.24566 12.75 8.5 12.75H17C19.3472 12.75 21.25 14.6528 21.25 17C21.25 19.3472 19.3472 21.25 17 21.25L8.5 21.25V63.75H51C53.3472 63.75 55.25 65.6528 55.25 68C55.25 70.3472 53.3472 72.25 51 72.25H8.5C6.24567 72.25 4.08366 71.3545 2.48959 69.7604C0.89553 68.1663 0 66.0043 0 63.75V21.25C0 18.9957 0.895533 16.8337 2.48959 15.2396Z"
            fill="#007E7A"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M45.5052 5.4948C47.1649 7.15452 47.1649 9.84548 45.5052 11.5052L40.0104 17L45.5052 22.4948C47.1649 24.1545 47.1649 26.8455 45.5052 28.5052C43.8455 30.1649 41.1545 30.1649 39.4948 28.5052L30.9948 20.0052C29.3351 18.3455 29.3351 15.6545 30.9948 13.9948L39.4948 5.4948C41.1545 3.83507 43.8455 3.83507 45.5052 5.4948Z"
            fill="#007E7A"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M29.75 17C29.75 14.6528 31.6528 12.75 34 12.75H76.5C78.7543 12.75 80.9164 13.6455 82.5104 15.2396C84.1045 16.8337 85 18.9957 85 21.25V63.75C85 66.0043 84.1045 68.1663 82.5104 69.7604C80.9163 71.3545 78.7543 72.25 76.5 72.25H68C65.6528 72.25 63.75 70.3472 63.75 68C63.75 65.6528 65.6528 63.75 68 63.75H76.5L76.5 21.25L34 21.25C31.6528 21.25 29.75 19.3472 29.75 17Z"
            fill="#007E7A"/>
    </svg>

    <span><br>Os estudos anexados serão comparados com o documento de referência,<br> via
      processamento de linguagem natural (NLP).<br><br>

      Para realizar uma primeira análise ou para reprocessar uma análise já efetuada<br>
      favor clicar em <b>Avançar</b>. Caso contrário, clicar em <b>Cancelar</b>.<br><br>

      A atualização dos resultados pode demorar para ser concluída.<br>
      Deseja continuar?
    </span>

  </div>
</div>

<div class="ant-modal-footer">
  <div nz-row>
    <div nz-col
         nzXs="18"
         nzSm="18"
         nzMd="18"
         nzLg="18"
         nzXl="18"
         [nzSpan]="18" [nzPush]="6">
      <button class="btn-sl btn-sl-grey btn-cancel"
              (click)="handleCancel()">Cancelar
      </button>
      <button type="button"
              class="btn-sl btn-sl-green btn-save"
              (click)="submitForm()">Avançar
      </button>
    </div>
    <div nz-col [nzSpan]="6" [nzPull]="18"></div>
  </div>


  <ng-template #indicatorTemplate><span nz-icon nzType="loading"></span></ng-template>
  <nz-spin nzSimple [nzIndicator]="indicatorTemplate" *ngIf="loading"></nz-spin>

</div>

