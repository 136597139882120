import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-menu-dashboards',
  templateUrl: './menu-dashboards.component.html',
  styleUrls: ['./menu-dashboards.component.scss'],
})
export class MenuDashboardsComponent {

  @Output() public selectedTypeDisplay = new EventEmitter();

  subCurrent = 'dasboards';
  currentPage = '';

  constructor() {}

  setCurrentPage(selectedOptionDisplay: string) {
     this.selectedTypeDisplay.emit(selectedOptionDisplay);
  }
}
