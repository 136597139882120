<div class="hor-scroll">
  <nz-table #tableListEdit
                nzSize="small"
                [nzData]="listOfData"
                [nzFrontPagination]="false" *ngIf="listOfData.length > 0" class="sl-table table-light">      
        <tbody>
        <tr *ngFor="let item of listOfData"  (dblclick)="startEdit(item.id)">
          <ng-container *ngIf="!editCache[item.id].edit; else newItemEdit" >
        <td>{{ item.organ }}</td>
        <td>{{ item.type }}</td>
        <td>{{ item.code ? item.code : 'Não Protocolado' }}</td>
        <td>{{ item.username ? item.username : '' }}</td>
        <td>{{ item.deadline ? item.deadline : 'Não Protocolado' }}</td>
        <td>{{ item.protocolDate ? item.protocolDate : 'Não Protocolado'}}</td>
        <td><app-badge [badgeText]="item.status" ></app-badge></td>
        <td class="separator"> <app-svg-icon class="pt-25" icon="arrow_right-icon" stroke="#A9A9A9"></app-svg-icon> </td>
        <td>{{ item.docCode ? item.docCode : 'Não Vinculado' }}</td>
        <td>{{ item.licenseEmissionDate  ? item.licenseEmissionDate : 'Não Vinculado' }}</td>
          </ng-container>
          <ng-template #newItemEdit>
            <td colspan="9">
            <app-form-new-linked-processes [processName]="processTitle" [selectedItem]="item" [processesList]="links" (delete)="deleteFromList($event)" (saved)="convertList($event)"  #formEdit class="fw"></app-form-new-linked-processes>
            </td>
          </ng-template>
        </tr>
        </tbody>
      </nz-table>
</div>