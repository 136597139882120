import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  ViewContainerRef,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators,} from '@angular/forms';
import {NzI18nService, pt_BR} from 'ng-zorro-antd/i18n';
import {ScopeModel} from '../../../shared/models/scopeModel';
import {NzUploadFile} from 'ng-zorro-antd/upload';
import {UserModel} from '../../../shared/models/user.model';
import {ProjectTypeModel} from '../../../shared/models/project-type.model';
import {CorridorModel} from '../../../shared/models/corridor.model';
import {ComplexModel} from '../../../shared/models/complex.model';
import {AttachmentsModel} from '../../../shared/models/attachments.model';
import {UtilService} from '../../../shared/util.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {AttachmentSaveDto} from '../../../shared/dto/attachment-save.dto';
import {MessageService} from 'src/app/shared/common/services/message.service';
import {Subject, take } from 'rxjs';
import {PROJECT_SUCCESS_SAVE, SUB_PROJECT_ENTITY_NAME,} from '../../projects/constants/projectsNewConstants';
import {UserService} from "../../../shared/services/user.service";
import {AttachmentsService} from "../../../shared/services/attachments.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ProjectsV2Service} from 'src/app/shared/services/projects-v2.service';
import {Projects} from 'src/app/shared/models/projects-v2.model';
import {RequestingArea} from 'src/app/shared/models/requestingArea.model';
import {RegionModel} from 'src/app/shared/models/region.model';
import {OperacionalUnitModel} from 'src/app/shared/models/operacionalUnit.model';
import {DisciplinesService} from 'src/app/shared/services/disciplines.service';
import {MandatoryDocumentsModel} from "../../../shared/models/mandatory-documents.model";
import {
  ConfirmationSendAnalisysModalComponent
} from '../modal/confirmation-send-analisys-modal/confirmation-send-analisys-modal.component';
import {LoadingComponent} from 'src/app/shared/components/loading-modal/loading-modal.component';
import {
  ConfirmationSendDraftModalComponent
} from '../modal/confirmation-send-draft-modal/confirmation-send-draft-modal.component';
import {TranslateService} from '@ngx-translate/core';
import {ProjectsService} from "../../projects/projects.service";
import {ProjectState} from 'src/app/shared/ngrx/breadcrumb/model';
import {BREADCRUMB_ACTIONS} from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';
import {BreadcrumbState} from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';
import {Store} from '@ngrx/store';
import {FileValidationService} from "../../../shared/services/file-validation.service";

@Component({
  selector: 'app-projects-v2-new',
  templateUrl: './projects-v2-new.component.html',
  styleUrls: ['./projects-v2-new.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectsV2NewComponent implements OnInit, OnDestroy {

  private readonly destroy$: Subject<any> = new Subject();

  @Output() isVisibleModal = new EventEmitter<boolean>();
  @Output() requestData = new EventEmitter<any>();

  @ViewChild('uploadInput') divClick!: ElementRef;

  @Input() project!: any;
  @Input() editMode: boolean = false;
  @Input() isEditProjectVisible: boolean = false;
  @Input() data: any;

  form!: UntypedFormGroup;
  scopes: ScopeModel[] = [];
  users: UserModel[] = [];
  options: Projects[] = [];
  resquestingAreas: RequestingArea[] = [];
  regions: RegionModel[] = [];
  complexes: ComplexModel[] = [];
  operacionalUnits: OperacionalUnitModel[] = [];

  disableInputs: boolean = false;
  isRegionsLoading: boolean = false
  isRequestingAreaLoading: boolean = false
  isComplexesLoading: boolean = false
  isOperacionalUnitLoading: boolean = false
  isListOfDisciplinesLoading = true;
  riplarequisition: boolean = false;

  resumeData: any = []

  corridors: CorridorModel[] = [];
  fileList: NzUploadFile[] = [];
  fileListStorageRepite: NzUploadFile[] = [];
  arrayImageDelete: string[] = [];

  projectTypes: ProjectTypeModel[] = [];
  isCorridorsLoading: boolean = false
  isScopesLoading: boolean = false
  isUsersLoading: boolean = false
  isProjectTypesLoading: boolean = false

  isAddProjectVisible: boolean = true;
  isFormInit: boolean = true;
  defaultEnterpriseID = 1;


  riplaType: string = ''
  riplaTypeDescription: string = ''

  inputValue?: string;
  inputValueAreaSolicitante?: string;
  filteredOptions: any[] = [];
  resquestingAreasFiltered: any[] = [];
  bodyFormat: any;

  documents: MandatoryDocumentsModel[] = []
  oldDocumentsList: MandatoryDocumentsModel[] = []
  projectId;
  subProjectId;

  originalRegion;
  originalComplex;
  originalOperationalUnit;

  quantDocumentosObrigatorios: number = 0;
  quantDocumentosUploaded: number = 0;
  isOpenSelect: boolean;
  isOpenSelectProject: boolean;
  recoverProject = false;

  positionSelectFocus: number = 0;
  elementRefFocusId: string = '';
  formTouched = false;

  regex = '/[A-Za-z]+[0-9]+([+-]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*))(?:[Ee]([+-]?\d+))?/i';
  compareFn = (o1: string, o2: string) => (o1 && o2 ? o1 == o2 : false);
  private codeValid: boolean = false;
  private projectToCreateRipla: any;

  hasNavigationExtras: any[] = [];
  isInvalidFile: boolean = false;


  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly i18n: NzI18nService,
    private readonly projectsV2Service: ProjectsV2Service,
    private readonly projectService: ProjectsService,
    private readonly attachmentsService: AttachmentsService,
    private readonly userService: UserService,
    private readonly message: MessageService,
    private readonly utilService: UtilService,
    private readonly fileValidationService: FileValidationService,
    private readonly modal: NzModalService,
    private readonly disciplinesService: DisciplinesService,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private store: Store<BreadcrumbState>,
    private ActivatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    const extras = this.ActivatedRoute.snapshot;
    if (extras && extras.queryParams['projectId'] !== undefined) {
      this.hasNavigationExtras.push(extras.queryParams)
      this.editMode = false;
    }
    if (this.projectsV2Service.getProject()) {
      this.editMode = true;
      this.riplarequisition = this.utilService.getAuthorization('edit', 'RIPLAREQUISITION');
      this.subProjectId = this.projectsV2Service.getProject().subProjectId;
      this.getProjectById(this.subProjectId);
      this.initForm();
      this.dispatchProjectOnStore(this.project)
    } else {
      this.riplaType = this.activatedRoute.snapshot.paramMap.get('riplaType') || '';
      this.riplaTypeDescription = this.activatedRoute.snapshot.paramMap.get('riplaType') || '';
      this.riplaType = 'RIPLA ' + this.riplaType.replace(this.riplaType.charAt(0), this.riplaType.charAt(0).toUpperCase()) + ' - Requisição Inicial para Licenciamento Ambiental'
      this.riplarequisition = this.utilService.getAuthorization('insert','RIPLAREQUISITION');
      this.initForm();
      this.dispatchProjectOnStore(this.project)
    }
  }
   // INICIO BREADCRUMB
   ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private dispatchProjectOnStore(projectRequest: any): void {
    const newRoute = this.hasNavigationExtras.length > 0 ? this.hasNavigationExtras[0].projectId : ''
    let project: ProjectState = {
      name: 'Projetos',
      url: '/projects-v2',
      last: false,
      sublevels: true,
      project: {
        name: this.hasNavigationExtras.length > 0 ? this.hasNavigationExtras[0].projectName : '',
        url: '/project-v2-view/' + newRoute,
        last: true,
      },
      subProject: {
        name: 'Nova RIPLA',
        url: this.activatedRoute.snapshot.url[0].path + '/' + this.activatedRoute.snapshot.url[1].path,
        last: true,
      },
      parecerFinal: null,
      pedidoRevisao: null,
      comunicacoes: null,
      processos: null,
      atividades: null,
      subatividades: null,
      mudancaEscopo: null
    }
    this.store.dispatch(BREADCRUMB_ACTIONS.getRiplaProject({project}));
  }

  // FINAL BREADCRUMB

  onRemoveFocusInput() {
    let elementRefById = document.getElementById('hideListDropdown');
    document.body.click();
  }

  getProjectById(entityId) {
    this.projectsV2Service.getProjectById(entityId).pipe().subscribe(data => {
      if (data) {
        this.project = data;
        this.project.subProjectId = data.id;
        if (!this.projectsV2Service.getProjectToCreateRipla()) {
          this.riplaTypeDescription = this.project.type
        }
        this.riplaType = this.ajustRyplaTypeNumber(this.project.title)
        if (this.recoverProject) {
          this.projectsV2Service.setProjectToCreateRipla(data);
          this.changeEditOnRecoveredProject();
        } else {
          this.initForm();
        }
      }
    })
  }

  realizarAnalise() {
    if (!this.form.valid) {
      this.message.showErrorMessage("Campos obrigatório não preenchidos");
      this.formTouched = true;
      return
    }
    if (this.hasErrorInAttachments()) {
      this.message.showErrorMessage(`Anexos inseridos contém erro`);
      return;
    }
    this.deleteOldDocumentsIfNecessary();

    if (Number(this.quantDocumentosObrigatorios) == Number(this.fileList.length)) {
      const modal =
        this.modal.create<LoadingComponent>({
          nzTitle: 'Enviando requisição',
          nzWidth: 555,
          nzClosable: false,
          nzCentered: true,
          nzKeyboard: false,
          nzMaskClosable: false,
          nzContent: LoadingComponent,
          nzViewContainerRef: this.viewContainerRef,
          nzFooter: null,
        });

      var data: any = this.buildPayload();
      data.status = "EAN";

      let apiCallsAndStates: any[] = []

      if (!this.project || this.projectsV2Service.getProjectToCreateRipla()) {
        apiCallsAndStates.push({
          state: 'not_started',
          call: () => this.sendProjectToDraft.apply(this, [data]),
          label: "Enviando a requisição..."
        })
      } else {
        apiCallsAndStates.push({
          state: 'not_started',
          call: () => this.updateProjectDraft.apply(this, [data]),
          label: "Atualizando dados do projeto..."
        })
      }

      this.createUploadFilesCalls(null, apiCallsAndStates);

      apiCallsAndStates.push({
        state: 'not_started',
        call: (entityId) => this.sendProjectToAnalisys.apply(this, [entityId]),
        label: "Finalizando envio...",
        lastCall: true
      })

      const instance = modal.getContentComponent();
      instance.loadingCalls = apiCallsAndStates;
      instance.savedEntityIdPath = "id";
      instance.requiredValuePath = "projectId";
      modal.afterClose.subscribe(({status, requiredValue}) => {
        if (status === false) {
          this.message.showErrorMessage("Erro ao enviar para análise!");
        }
        this.modalAnaliseRealizada(requiredValue)
      })
    } else {
      this.message.showErrorMessage("Documentos necessários são obrigatórios")
    }
  }

  updateProjectDraft(dataToAnalisys: any) {
    return this.projectsV2Service
      .updateSubProjectRascunho(dataToAnalisys, this.subProjectId);
  }

  sendProjectToAnalisys(entityId) {
    return this.projectsV2Service
      .saveRiplaToAnalisys(entityId ? entityId : this.project.id);
  }

  sendProjectToDraft(dataToAnalisys: any) {
    return this.projectsV2Service
      .saveRipla(dataToAnalisys);
  }

  callSubProjectRascunhoUpdate(body, projectId) {
    return this.projectsV2Service
      .editSubProjectRascunho(body, projectId);
  }

  deleteOldDocumentsIfNecessary() {
    if (!this.project) return;

    if (this.form.controls['projectClassification'].value.toUpperCase() !=
      this.project.projectClassification) {
      this.oldDocumentsList.forEach(document => {
        this.deleteMandatoryDoc(document);
      });
    }
  }

  salvarRascunho() {

    if(!this.form.controls['projectName'].value.split("_")[0]){
      this.message.showErrorMessage(`Necessário inserir o nome do projeto`);
      return;
    }

    var body = this.buildPayload();
    const apiCallsAndStates: any[] = [];
    if (this.hasErrorInAttachments()) {
      this.message.showErrorMessage(`Anexos inseridos contém erro`);
      return;
    }

    if (this.editMode) {
      apiCallsAndStates.push({
        state: 'not_started',
        call: () => this.updateProjectDraft.apply(this, [body])
      })

      this.deleteOldDocumentsIfNecessary();
    } else {
      apiCallsAndStates.push({state: 'not_started', call: () => this.sendProjectToDraft.apply(this, [body])})
    }

    this.createUploadFilesCalls(null, apiCallsAndStates);

    const modal =
      this.modal.create<LoadingComponent>({
        nzClosable: false,
        nzTitle: 'Salvando rascunho',
        nzWidth: 555,
        nzContent: LoadingComponent,
        nzKeyboard: false,
        nzViewContainerRef: this.viewContainerRef,
        nzCentered: true,
        nzMaskClosable: false,
        nzFooter: null,
      });

    const instance = modal.getContentComponent();
    instance.savingMessage = "Salvando o rascunho...";
    instance.loadingCalls = apiCallsAndStates;
    instance.savedEntityIdPath = "id";
    modal.afterClose.subscribe(({entityId, status}) => {
      if (status === false) {
        this.message.showErrorMessage("Erro ao salvar rascunho!");
      }

      if (this.editMode) {
        this.modalDraftRealizada()
      } else {
        this.modalDraftRealizada()
        this.editMode = true
        this.getProjectById(entityId)
      }
    })

  }

  private hasErrorInAttachments() {
    let errorDoc = this.documents.filter(doc => doc.statusUpload === "error");
    return errorDoc.length > 0;
  }

  modalAnaliseRealizada(entityId: number) {
    const modal = this.modal.create<ConfirmationSendAnalisysModalComponent>({
      nzTitle: '',
      nzClosable: false,
      nzWidth: 555,
      nzCentered: true,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzContent: ConfirmationSendAnalisysModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });

    const instance = modal.getContentComponent();
    instance.projectId = entityId ? entityId : this.projectId;

    modal.afterClose.subscribe(() => {
      this.router.navigate([`/project-v2-view/${entityId}`]);
    })
  }

  modalDraftRealizada() {
    const modal = this.modal.create<ConfirmationSendDraftModalComponent>({
      nzTitle: '',
      nzClosable: false,
      nzMaskClosable: false,
      nzWidth: 555,
      nzCentered: true,
      nzKeyboard: false,
      nzContent: ConfirmationSendDraftModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });

    const instance = modal.getContentComponent();

    modal.afterClose.subscribe(() => {
      this.router.navigate(['/projects-v2'])
    })
  }

  getSubprojectDetails($event) {
    if ($event) {
      if ($event.split("_")[1] && $event.split("_")[1] != 0) {
        this.subProjectId = $event.split("_")[1]
        this.recoverProject = true
        this.getProjectById(this.subProjectId);
      }
    }
    this.setProjectNameResume($event);
  }

  setProjectNameResume(value) {
    if (value) {
      let projectName = value.split("_")[0]
      this.resumeData.showResume = true;
      this.resumeData.projectName = projectName;
    }
  }

  changeEditOnRecoveredProject() {
    this.project.projectCode ? this.form.controls['projectCode'].setValue(this.project.projectCode) : '';
    this.project.projectClassification ? this.form.controls['projectClassification'].setValue(this.utilService.formatString(this.project.projectClassification)) : '';
    this.project.userLegalRepresentative ? this.form.controls['responsavelAreaSolicitante'].setValue(this.project.userLegalRepresentative.id) : '';
    this.project.area ? this.form.controls['areaSolicitante'].setValue(this.project.area.id) : '';
    this.project.comments ? this.form.controls['comments'].setValue(this.project.comments) : '';
    this.getMandatoryDocuments();

    this.form.controls['corredor'].setValue(this.project.projectComplex.region.id)
    this.form.controls['complexo'].setValue(this.project.projectComplex.id)
    this.form.controls['unidadeOperacional'].setValue(this.project.projectOperationalUnit?.id);
    this.form.controls['responsavelAreaSolicitante'].setValue(this.project.projectUserLegal.id)
    if (this.project.projectClassification === "GREENFIELD") {
      this.operacionalUnits.push({id: -1, description: 'Não se aplica'});
      this.form.controls['unidadeOperacional'].setValue(-1);
    }

    this.enableDisableFields(false);
  }

  replaceResumeData() {
    this.cdr.detectChanges();
    this.resumeData.showResume = true;
    this.resumeData.projectName = this.form.controls['projectName'].value?.split("_")[0];
    if (this.form.controls['areaSolicitante'].value) {
      if (Number(this.form.controls['areaSolicitante'].value) && this.resquestingAreas.length > 0) {
        this.resumeData.requestingArea = this.resquestingAreas.filter(area => area.id == this.form.controls['areaSolicitante'].value)[0].description || null;
      } else {
        this.resumeData.requestingArea = this.form.controls['areaSolicitante'].value;
      }
    } else {
      this.resumeData.requestingArea = "-";
    }
    this.resumeData.projectClassification = this.form.controls['projectClassification'].value;
    this.resumeData.comments = this.form.controls['comments'].value;
    if (this.form.controls['corredor'].value && this.regions.length > 0) {
      this.resumeData.corredor = this.regions.filter(region => region.id == this.form.controls['corredor'].value)[0].description || null
    } else {
      this.resumeData.corredor = '-'
    }
    if (this.form.controls['responsavelAreaSolicitante'].value && this.users.length > 0) {
      this.resumeData.responsavelAreaSolicitante = this.users.filter(users => users.id == this.form.controls['responsavelAreaSolicitante'].value)[0].name || null
    } else {
      this.resumeData.responsavelAreaSolicitante = "-";
    }
    if (this.complexes.length > 0 && this.form.controls['complexo'].value) {
      this.resumeData.complexo = ' / ' + this.complexes.filter(complex => complex.id == this.form.controls['complexo'].value)[0].description || null
    } else {
      this.resumeData.complexo = ''
    }
    if (this.form.controls['unidadeOperacional'].value && this.operacionalUnits.length > 0) {
      this.resumeData.unidadeOperacional = ' / ' + this.operacionalUnits.filter(unit => unit.id == this.form.controls['unidadeOperacional'].value)[0].description || null
    } else {
      this.resumeData.unidadeOperacional = ''
    }
    this.resumeData.documents = this.documents;
    let projectCode = this.form.controls['projectCode'].value.toUpperCase();
    if (projectCode.length >= 7 && !this.codeValid) {
      projectCode = projectCode.replace("-", '');
      const codWithMask = projectCode.substr(0, 5) + "-" + projectCode.substr(5, 2);
      if (!this.validateCode(codWithMask)) {
        this.message.showErrorMessage("Codigo invalido, Insira conforme o padrão")
        this.form.controls['projectCode'].reset('');
        this.resumeData.projectCode = ""
        this.codeValid = false;
      } else {
        this.codeValid = true;
        this.resumeData.projectCode = codWithMask
      }
    } else if (projectCode.length < 7) {
      this.codeValid = false;
      this.form.controls['projectCode'].reset('');
      this.resumeData.projectCode = ""
    }
  }

  private validateCode(projectCode: string) {
    let regex = /^$|^[a-zA-z][0-9]{4}-[0-9]{2}$/;
    return regex.test(projectCode);
  }

  buildPayload() {
    let projectId;
    const areaDescription = this.resquestingAreasFiltered.filter(area => area.id === this.form.controls['areaSolicitante'].value)?.[0]?.description
    if (this.options && this.options.length > 0) {
      const projects = this.options.filter(area => area.name === this.form.controls['projectName'].value)
      if (projects.length > 0) {
        projectId = projects[0].projectId;
      }
    }
    let projectCode = this.form.controls['projectCode'].value.replace("-", "");
    if (projectCode !== '') {
      projectCode = projectCode.substr(0, 5) + '-' + projectCode.substr(5, 2)
    }


    this.bodyFormat = {
      projectName: this.form.controls['projectName'].value.split("_")[0],
      projectCode: projectCode,
      projectId: projectId ? projectId : null,
      areaId: this.form.controls['areaSolicitante'].value,
      areaDescription: areaDescription,
      userLegalRepId: this.form.controls['responsavelAreaSolicitante'].value,
      classificationType: this.form.controls['projectClassification'].value === '' ? null : this.form.controls['projectClassification'].value.toUpperCase(),
      comments: this.form.controls['comments'].value,
      corridorId: this.form.controls['corredor'].value,
      complexId: this.form.controls['complexo'].value,
      operationalUnitId:
        this.form.controls['unidadeOperacional'].value === -1 ?
          null : this.form.controls['unidadeOperacional'].value,
      type: this.riplaTypeDescription.toUpperCase(),
      status: "INI"
    };
    return this.bodyFormat;
  }

  onChange(project): void {
    const value = project.activatedValue;
    if (value && value.projectId != 0) {
      this.setRiplaToProject(value);
    }
    if (value && value.name.length >= 0) {
      this.replaceResumeData()
    }
  }

  initForm(): void {

    this.i18n.setLocale(pt_BR);

    let projectName = '';
    let projectCode = '';
    let areaSolicitante = '';
    let responsavelAreaSolicitante = '';
    let projectClassification = '';
    let comments = '';
    let corredor = '';
    let complexo = '';
    let unidadeOperacional = '';
    let projType = '';
    let userResponsible = '';
    let corridor = '';
    let complex = '';

    if (this.editMode) {
      projectName = this.project?.projectName ? this.project?.projectName + "_" + this.project.id : '';
      projectCode = this.project?.projectCode ? this.project?.projectCode : '';
      areaSolicitante = this.project?.area ? this.project?.area.id : '';
      responsavelAreaSolicitante = this.project?.projectUserLegal ? this.project?.projectUserLegal.id : '';
      projectClassification = this.project?.projectClassification ? this.utilService.formatString(this.project?.projectClassification) : '';
      comments = this.project?.comments ? this.project?.comments : '';
      complexo = this.project?.projectComplex ? this.project?.projectComplex.id : '';
      corredor = this.project?.projectComplex?.region ? this.project?.projectComplex?.region.id : '';
      unidadeOperacional = this.project?.projectOperationalUnit ? this.project?.projectOperationalUnit.id : '';
      projType = this.project?.type ? this.project?.type : '';
      userResponsible = String(this.project?.user?.id) ?? '';
      corridor = String(this.project?.region?.id) ?? '';
      complex = String(this.project?.complex?.id) ?? '';
      this.inputValue = projectName;
      this.inputValueAreaSolicitante = areaSolicitante;
      this.originalComplex = complexo
      this.originalRegion = corredor
      this.originalOperationalUnit = unidadeOperacional;
    }

    this.getProjects()
    this.getRequestingAreas()
    this.getRegions()
    this.getUsers()

    if (projectClassification !== '') {
      this.getMandatoryDocuments()
    }

    if ((corredor && complexo) && (corredor !== '' && complexo !== '')) {
      this.onChangeRegions(parseInt(corredor));
      this.onChangeComplex(parseInt(complexo));
    }

    this.form = this.fb.group({
      projectName: [projectName, [Validators.required, Validators.minLength(3)]],
      projectCode: [projectCode],
      areaSolicitante: [areaSolicitante, [Validators.required]],
      responsavelAreaSolicitante: [responsavelAreaSolicitante, [Validators.required]],
      projectClassification: [projectClassification, [Validators.required]],
      comments: [comments, [Validators.required, Validators.maxLength(2000)]],
      corredor: [corredor, [Validators.required]],
      complexo: [complexo, [Validators.required]],
      unidadeOperacional: [unidadeOperacional, [Validators.required]],
    });

    if (this.project?.projectClassification == "GREENFIELD") {
      this.operacionalUnits.push({id: -1, description: 'Não se aplica'});
      this.form.controls['unidadeOperacional'].setValue(-1);
    }

    if (this.projectsV2Service.getProjectToCreateRipla()) {
      let subproject = this.projectsV2Service.getProjectToCreateRipla()
      this.projectToCreateRipla = subproject.project;
      this.setFormValues(this.projectToCreateRipla)
    }
  }

  private setFormValues(projectToCreateRipla: any) {
    this.form.controls['corredor'].setValue(projectToCreateRipla.region.id)
    this.form.controls['projectCode'].setValue(projectToCreateRipla.code)
    this.form.controls['projectClassification'].setValue(projectToCreateRipla.classificationType.toUpperCase())
    this.onChangeRegions(projectToCreateRipla.region.id);
    this.onChangeComplex(projectToCreateRipla.complex.id);
    this.getMandatoryDocuments();
    this.form.controls['complexo'].setValue(projectToCreateRipla.complex.id)
    this.form.controls['unidadeOperacional'].setValue(projectToCreateRipla.operationalUnit ? projectToCreateRipla.operationalUnit.id : null)
    this.filteredOptions = [];
    this.filteredOptions.push(projectToCreateRipla);
    this.enableDisableFields(false);

    this.documents.forEach(document => {
      document.statusUpload = 'upload'
      document.fileName = ''
      document.fieluid = ''
    })
  }

  getProjects(): void {
    this.projectsV2Service
      .getProjects(1000)
      .pipe(take(1))
      .subscribe((items) => {
        if (items) {
          this.options = items.content;
          if (this.options && this.options.length > 0) {
            this.filteredOptions = this.options.filter(pro => pro.status === 'CON');
            if (this.editMode) {
              const projetoASerEditado: Projects = {
                complex: "", corridor: "", operationalUnit: "",
                projectId: this.project?.id, registrationDate: "",
                name: this.project?.name,
                status: this.project?.status, subProjectId: this.project?.subProjectId,
                projectName: this.project?.projectName, edit: true
              }
              this.filteredOptions.push(projetoASerEditado)
              this.options.push(projetoASerEditado)
            }
            if (this.projectToCreateRipla) {
              let filteredProj = this.filteredOptions.filter(proj => proj.projectId == this.projectToCreateRipla.id)[0]
              if (filteredProj != null) {
                let projectValue = this.projectToCreateRipla.name + "_" + filteredProj.subProjectId
                this.form.controls['projectName'].setValue(projectValue)
              }
            }
            this.replaceResumeData();
          } else {
            this.options = []
            this.filteredOptions = []
          }
        }
      })
  }

  getRequestingAreas(): void {
    this.projectsV2Service
      .getAreas()
      .pipe(take(1))
      .subscribe((areas: any) => {
        this.resquestingAreas = areas;
        this.resquestingAreasFiltered = areas;
      })
  }

  getRegions(): void {
    this.projectsV2Service
      .getRegions()
      .pipe(take(1))
      .subscribe((regions: any) => {
        this.regions = regions;
      })
  }

  getMandatoryDocuments() {
    const params = {
      PARAM_PROJ_TYPE: this.riplaTypeDescription.toUpperCase(),
      PARAM_CLASSIFICATION:
        this.form.controls['projectClassification'].value ?
          this.form.controls['projectClassification'].value.toUpperCase() :
          this.project?.projectClassification?.toUpperCase(),
    }
    if (this.form.controls['projectClassification'].value === "Greenfield") {
      this.operacionalUnits.push({id: -1, description: 'Não se aplica'});
      this.form.controls['unidadeOperacional'].setValue(-1);
    } else {
      this.onChangeComplex(this.form.controls['complexo'].value);
      this.form.controls['unidadeOperacional'].reset();
    }

    if (this.editMode && this.documents.length > 0 && this.fileList.length > 0) {
      this.oldDocumentsList = this.documents;
    } else if (this.documents.length > 0 && this.fileList.length > 0) {
      this.documents.forEach(document => {
        this.message.showSucessMessage("Anexo(s) removido(s) com sucesso, ao alterar a classificação da requisição em rascunho.");
        document.statusUpload = 'upload'
        document.fileName = ''
        document.fieluid = ''
      })
      this.fileList = [];
    }
    this.listMandadoryDocs(params);
  }

  private listMandadoryDocs(params: { PARAM_CLASSIFICATION: string; PARAM_PROJ_TYPE: string }) {
    this.projectsV2Service
      .getMandatoryDocuments(params)
      .subscribe((documents: [MandatoryDocumentsModel]) => {
        this.documents = documents;
        this.quantDocumentosObrigatorios = documents.length
        this.documents.forEach(doc => {
          const types = doc.types?.split(';');
          doc.types = ''
          types?.forEach(type => {
            if (doc.types === '') {
              doc.types = '.' + type.toLowerCase();
            } else {
              doc.types = doc.types + "," + "." + type.toLowerCase();
            }
          })
        })
        this.isListOfDisciplinesLoading = false;
        if (this.editMode) {
          this.getAttachments();
        }
        this.replaceResumeData();
      });
  }

  onChangeRegions(idRegion: number): void {
    if (!this.editMode) {
      this.complexes = [];
      if (this.form.controls['projectClassification'].value !== 'Greenfield') {
        this.operacionalUnits = [];
        this.form.get('unidadeOperacional')?.reset();
      }
      this.form.get('complexo')?.reset();
      this.replaceResumeData()
    }
    if (this.editMode && this.originalRegion !== idRegion) {
      this.complexes = [];
      if (this.form.controls['projectClassification'].value !== 'Greenfield') {
        this.operacionalUnits = [];
        this.form.get('unidadeOperacional')?.reset();
      }
      this.form.get('complexo')?.reset();
      this.replaceResumeData()
    }
    if (idRegion) {
      this.projectsV2Service
        .getComplexById(idRegion)
        .pipe(take(1))
        .subscribe((complex: any) => {
          this.complexes = complex;
          this.replaceResumeData()
        })
    }
  }

  onChangeComplex(idComplex: number): void {
    if (!this.editMode) {
      if (this.form.controls['projectClassification'].value !== 'Greenfield') {
        this.operacionalUnits = [];
        this.form.get('unidadeOperacional')?.reset();
      }
      this.replaceResumeData()
    }
    if (this.editMode && this.originalComplex !== idComplex) {
      if (this.form.controls['projectClassification'].value !== 'Greenfield') {
        this.operacionalUnits = [];
        this.form.get('unidadeOperacional')?.reset();
      }
      this.replaceResumeData()
    }
    if(idComplex !== null){
    this.projectsV2Service
      .getOperationalUnits(idComplex)
      .pipe(take(1))
      .subscribe((operacionalUnits: any) => {
        if (this.form.controls['projectClassification'].value !== 'Greenfield') {
          this.operacionalUnits = operacionalUnits;
        } else {
          this.form.controls['unidadeOperacional'].setValue(-1);
        }
        this.replaceResumeData()
      })
    }
  }

  showCancelConfirm(): void {
    this.modal.confirm({
      ...(this.message.showMessageModalConfirmCancelRequisition() as any),
      nzOnOk: () => {
        if (this.projectToCreateRipla) {
          this.router.navigate(['/project-v2-view/' + this.projectToCreateRipla.id])
        } else {
          this.router.navigate(['/projects-v2'])
        }
      },
    });
  }

  private compareFileSize(a: NzUploadFile, b: NzUploadFile) {
    return (a.size || 0) - (b.size || 0);
  }

  private createUploadFilesCalls(entityId, calls: any[]) {
    this.fileList = this.fileList.sort(this.compareFileSize);

    if (this.fileList.length) {
      for (const [index, file] of this.fileList.entries()) {
        const attachment: AttachmentSaveDto = {
          name: file.name,
          description: file.name,
          idMandatoryDoc: this.getMandadoryDocId(file.uid),
          entityId: entityId,
          entityName: SUB_PROJECT_ENTITY_NAME,
          contextInfo: {
            phase: 'Cadastro'
          },
          file: file.originFileObj ? file.originFileObj : file,
        };
        if (file.originFileObj) {
          calls.push({
            state: 'not_started',
            call: (entityId) => this.callAttachmentUpload.apply(this, [attachment, entityId]),
            label: `Salvando ${index + 1}º anexo...`
          })
        }
      }
    }
  }

  callAttachmentUpload(attachment: AttachmentSaveDto, id) {
    attachment.entityId = id ? id : this.project.id;

    return this.attachmentsService
      .uploadAttachmentMandadoryDock(attachment);
  }

  private uploadFiles(entityId) {
    if (this.fileList.length) {
      for (const file of this.fileList) {
        const attachment: AttachmentSaveDto = {
          name: file.name,
          description: file.name,
          idMandatoryDoc: this.getMandadoryDocId(file.uid),
          entityId,
          entityName: SUB_PROJECT_ENTITY_NAME,
          contextInfo: {
            phase: 'Cadastro'
          },
          file: file.originFileObj,
        };

        this.attachmentsService
          .uploadAttachmentMandadoryDock(attachment)
          .pipe(take(1))
          .subscribe({
            next: () => {
              this.message.showSucessMessage(`Arquivo enviado com sucesso: ${attachment.name}`);
              this.utilService.updateAttachment(true);
            },
            error: () => {
              this.message.showErrorMessage(`Erro ao salvar o arquivo: ${attachment.name}`);
              this.utilService.updateAttachment(false);
            }
          });
      }
    }
    this.close();
  }

  private getMandadoryDocId(uid: string) {
    return this.documents.filter(doc => doc.fieluid === uid)[0].id;

  }

  onBrowserFilesToUpload(): void {
    this.divClick.nativeElement.click();
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    if (this.checkFileNameLength(file) && this.checkFileSize(file)) {
      file.thumbUrl = this.utilService.getIconFromFileName(file.name);
      this.fileList = this.fileList.concat(file);
    }
    return false;
  };

  onChangeFile(fileEvent, document) {
    if (fileEvent.type == 'start') {
      let existName = this.checkIfFileNameExist(fileEvent.file.name);

      if (this.documents.length > 0) {
        this.documents.map(value => {
          if (value.id === document.id) {
            if (existName) {
              value.statusUpload = 'error';
              this.message.showErrorMessage(`Arquivo com o nome ${fileEvent.file.name} já existente!`);
            } else {
              this.verifyIfIsValidFile(fileEvent.file.originFileObj).then(result => {
                if (this.isInvalidFile === false) {
                  if (this.fileValidationService.isExtensionForbidden(fileEvent.file.name)) {
                    value.statusUpload = 'error';
                    this.message.showErrorMessage(`Extenção do arquivo ${fileEvent.file.name} não é suportada!`);
                  } else {
                    value.statusUpload = 'ok';
                    value.fieluid = fileEvent.file.originFileObj.uid
                    value.fileName = fileEvent.file.name;
                    this.replaceResumeData();
                    this.isInvalidFile = false
                  }
                } else {
                  const fileUid = fileEvent.file.uid
                  const index = this.fileList.findIndex(file => file.uid === fileUid);
                  this.fileList.splice(index, 1);
                  this.isInvalidFile = false
                }
              })
            }
          }
        })
      }
    }
  }

  private checkIfFileNameExist(newFileName: string) {
    let existingDocument = this.documents.find(doc => doc.fileName === newFileName);

    return !!existingDocument
  }

  async verifyIfIsValidFile(file) {
    await this.fileValidationService.isExeFile(file).then(result => {
      if (result === true) {
        this.isInvalidFile = true
        this.message.showErrorMessage(`Tipo de arquivo ${file.name} não é suportado!`);
      }
    })
    await this.fileValidationService.isZipFile(file).then(result => {
      if (result === true) {
        this.isInvalidFile = true
        this.message.showErrorMessage(`Conteúdo ou extensão do arquivo ${file.name} não é suportada!`);
      }
    })
  }


  private checkFileSize(file: NzUploadFile): boolean {
    if (file && file.size && file.size > 400 * 1024 * 1024) {
      this.message.showErrorMessage('O arquivo é muito grande. Por favor, selecione um arquivo com menos de 350 MB.');
      return false;
    }
    return true;
  }

  private checkFileNameLength(file: NzUploadFile): boolean {
    if (file.name.length > 200) {
      this.message.showErrorMessage('Nome do arquivo muito grande. Máximo é 200 caracteres.');
      return false;
    }
    return true;
  }

  removeFiles = (file: NzUploadFile): boolean => {
    this.arrayImageDelete.push(file.uid);
    this.fileList = this.fileList.filter((item) => item !== file);
    this.fileListStorageRepite = this.fileListStorageRepite.filter(
      (item) => item !== file
    );
    return false;
  };


  deleteAttachment(fileListToDelete: string[]): void {
    for (const file of fileListToDelete) {
      this.attachmentsService
        .deleteAttachment(file)
        .pipe(take(1))
        .subscribe(() => {
          this.message.showSucessMessage(`Arquivo deletado com sucesso`);
        });
    }
  }

  handleCancel(): void {
    this.showCancelConfirm();
  }

  private close() {
    this.isVisibleModal.emit(false);
    this.isAddProjectVisible = false;
  }

  private getUsers() {
    this.isUsersLoading = true;
    this.userService
      .getUsersByRole('ROLE_DEMANDANTE')
      .pipe(take(1))
      .subscribe((users) => {
        this.users = users;
        this.isUsersLoading = false;
      });
  }


  private onSuccessCreateOrEditProject(
    projectSaved: any,
    fileList: AttachmentsModel[]
  ) {
    this.message.showSucessMessage(
      `${projectSaved.name} ${PROJECT_SUCCESS_SAVE}`
    );

    if (this.arrayImageDelete.length > 0) {
      this.deleteAttachment(this.arrayImageDelete);
    }

    if (projectSaved.id && fileList.length) {
      this.uploadFiles(projectSaved.id);
      this.message.showMessageUploadAttachment();
    }

    this.router.navigate([`projects/${projectSaved.id}/ripla`]);
    this.close();
  }


  removeFilesRepite() {
    const fileListToSave: AttachmentsModel[] = [];
    const diferencia = this.fileListStorageRepite
      .filter(
        (item1) =>
          !this.fileList.some(
            (item2) => item1.uid === item2.uid && item1.name === item2.name
          )
      )
      .concat(
        this.fileList.filter(
          (item2) =>
            !this.fileListStorageRepite.some(
              (item1) => item2.uid === item1.uid && item2.name === item1.name
            )
        )
      );
    diferencia.forEach((file) => {
      const attachment: AttachmentsModel = {
        description: file.name,
        name: file.name,
      };
      fileListToSave.push(attachment);
    });
    return fileListToSave;
  }

  getAttachments() {
    this.projectsV2Service
      .getMandatoryDocsAttachments(
        this.project.subProjectId,
        SUB_PROJECT_ENTITY_NAME
      )
      .subscribe((data) => {
        if (data.length > 0) {
          this.quantDocumentosUploaded = data.length
          for (const doc of data) {
            if (doc.attachment) {
              const file: NzUploadFile = {
                description: doc.attachment.description,
                name: doc.attachment.name,
                filename: doc.attachment.name,
                uid: doc.attachment.id.toString() || '',
                thumbUrl: this.utilService.getIconFromFileName(doc.attachment.name)
              }
              this.fileList.push(file)
            }

            this.documents.map(document => {
              if (document.id === doc.mandatoryDoc.id) {
                document.statusUpload = doc.attachment ? 'uploded' : 'upload';
                document.fieluid = doc.attachment ? doc.attachment.id.toString() : undefined;
                document.fileName = doc.attachment ? doc.attachment.name : undefined;
              }
            })
          }
          this.replaceResumeData();
        }
      });
  }

  addAreaSolicitante(selectAreaSolicitante) {
    if (!selectAreaSolicitante.searchValue) return

    const newAreaRequest = {
      description: selectAreaSolicitante.searchValue
    }
    const filter = this.resquestingAreasFiltered.filter(area => area.description === newAreaRequest.description);
    if (filter.length > 0) {
      this.message.showErrorMessage("Existe uma área com o mesmo nome.")
      return;
    }
    this.projectsV2Service
      .createArea(newAreaRequest)
      .pipe(take(1))
      .subscribe((area: any) => {
        this.form.controls['areaSolicitante'].setValue(area.id);
        this.resquestingAreasFiltered.push(area);
        this.isOpenSelect = false;
        this.replaceResumeData();
      })
  }

  addNewProject(selectedValue: any) {
    const inputValue = selectedValue.searchValue
    if (!inputValue || inputValue?.length <= 0) {
      return;
    }
    this.projectService.evaluateProjectName(inputValue).pipe().subscribe({
        next: (data) => {
          this.setNewProjectValues(inputValue, true);
        },
        error: (err) => {
          this.setNewProjectValues(inputValue, false);
        }
      }
    )

  }

  private setNewProjectValues(inputValue, nameOK: boolean) {
    if (!nameOK) {
      this.message.showErrorMessage("Nome do projeto já existe, por gentileza, altere o nome e tente novamente");
      return;
    }

    if (this.options.some(pro => pro.new || pro.edit)) {
      this.options.forEach(pro => {
        if (pro.new || pro.edit) {
          pro.projectName = inputValue;
        }
      })
      this.setSelectValue(inputValue + "_0");
      return;
    }
    if (this.filteredOptions.some(pro => pro.new || pro.edit)) {
      this.filteredOptions.forEach(pro => {
        if (pro.new || pro.edit) {
          pro.projectName = inputValue;
        }
      })
      this.setSelectValue(inputValue + "_0");
      return;
    }
    const novoProjeto: Projects = {
      complex: "",
      corridor: "",
      operationalUnit: "",
      projectId: 0,
      registrationDate: "",
      status: "",
      subProjectId: 0,
      name: inputValue,
      projectName: inputValue,
      new: true
    }
    this.options.push(novoProjeto);
    this.filteredOptions.push(novoProjeto);
    this.setSelectValue(inputValue + "_0");
  }

  private setSelectValue(inputValue) {
    this.isOpenSelectProject = false;
    this.form.controls['projectName'].setValue(inputValue);
    this.replaceResumeData();
  }

  downloadFile(id, name) {
    const idFile = this.fileList.filter(file => file.uid === id)[0].uid
    if (idFile === '' || !idFile) {
      return
    }
    this.attachmentsService.getDownloadFile(id === idFile ? id : 0).subscribe({
      next: (resp) => {
        this.attachmentsService.downloadFile(resp, name)
      },
      error: () => this.handleError(),
    })
  }

  handleError() {
    this.message.showSucessMessage(
      `Erro realizar download`
    );

  }

  deleteMandatoryDoc(document) {
    if (this.editMode && document.statusUpload === 'uploded') {
      this.fileList = this.fileList.filter(file => file.uid !== document.fieluid);
      this.attachmentsService.deleteAttachment(document.fieluid).subscribe((data) => {
        this.message.showSucessMessage("Anexo(s) removido(s) com sucesso.");
        this.documents.forEach(doc => {
          if (doc.id === document.id) {
            doc.statusUpload = 'upload'
            doc.fileName = ''
            doc.fieluid = ''
          }
        })
      })
    } else {
      this.fileList = this.fileList.filter(file => file.uid !== document.fieluid);
      this.documents.forEach(doc => {
        if (doc.id === document.id) {
          this.message.showSucessMessage("Anexo(s) removido(s) com sucesso.");
          doc.statusUpload = 'upload'
          doc.fileName = ''
          doc.fieluid = ''
        }
      })
    }
  }

  private setRiplaToProject(value: any) {

  }

  private enableDisableFields(enable: boolean) {
    if (enable) {
      this.form.get('corredor')?.enable()
      this.form.get('complexo')?.enable()
      this.form.get('unidadeOperacional')?.enable()
      this.form.get('projectName')?.enable()
      this.form.get('projectCode')?.enable()
      this.form.get('projectClassification')?.enable()
    } else {
      this.form.get('corredor')?.disable()
      this.form.get('complexo')?.disable()
      this.form.get('unidadeOperacional')?.disable()
      this.form.get('projectName')?.disable()
      this.form.get('projectCode')?.disable()
      this.form.get('projectClassification')?.disable()
    }
  }

  private ajustRyplaTypeNumber(title: string) {
    console.log(title)
    if (title.includes(this.riplaTypeDescription.toUpperCase())) {
      const regex = /(\d+)/g;
      return title.replace(regex, (match) => {
        const numero = parseInt(match);
        return (numero + 1).toString();
      });
    } else {
      return this.riplaType;
    }
  }
}
