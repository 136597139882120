<div nz-row class="nlp-modal-update-tr-result">
  <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
    Gostaria de copiar este link?
  </div>
</div>

<div nz-row>
  <nz-input-group
    class="formatField"
    [nzSuffix]="suffixTemplateInfo"
    [nzPrefix]="prefixTemplateUser"
  >
    <input type="label" nz-input placeholder="Link para copiar" [(ngModel)]="uriLink" readonly/>
  </nz-input-group>
  <ng-template #prefixTemplateUser
    ><span nz-icon nzType="user"></span
  ></ng-template>
  <ng-template #suffixTemplateInfo>
    <span
      (click)="copiarLink()"
      id="iconCopyLink"
      nz-icon
      nz-tooltip
      nzTooltipTitle="Copiar Link para Area de Transferência"
      nzType="copy"
    ></span>
  </ng-template>
</div>

<div class="ant-modal-footer">
  <div nz-row>
    <div
      nz-col
      nzXs="24"
      nzSm="24"
      nzMd="24"
      nzLg="24"
      nzXl="24"
      [nzSpan]="24"
      [nzPush]="0"
    >
      <button class="btn-sl btn-sl-grey btn-cancel" (click)="handleCancel()">
        Cancelar
      </button>
      <button
        (click)="copiarLink()"
        button
        nz-button
        nzType="primary"
        class="btn-sl btn-sl-green btn-save"
        (click)="submitForm()"
      >
        Copiar Link
      </button>
    </div>
    <div nz-col [nzSpan]="6" [nzPull]="18"></div>
  </div>
</div>
