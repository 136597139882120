import {ChangeDetectorRef, Component, Input, OnInit, ViewContainerRef} from '@angular/core';
import {NzButtonSize} from 'ng-zorro-antd/button';
import {NlpAddProjInformationsComponent} from '../nlp-add-proj-informations/nlp-add-proj-informations.component';
import {NzModalService} from 'ng-zorro-antd/modal';
import {Router} from '@angular/router';
import {UserService} from 'src/app/shared/services/user.service';
import {TopBreadcrumbService} from 'src/app/shared/common/services/top-breadcrumb/top-breadcrumb.service';
import {SidebarService} from "../nlp-sidebar/service/sidebar.service";

@Component({
  selector: 'app-nlp-welcome',
  templateUrl: './nlp-welcome.component.html',
  styleUrls: ['./nlp-welcome.component.scss']
})
export class NlpWelcomeComponent implements OnInit {
  size: NzButtonSize = 'large';
  modalClosedSuccessfully = false;
  @Input() value = '';

  constructor(
    private userService: UserService,
    private readonly modal: NzModalService,
    // private drawerRef: NzDrawerRef<string>,
    private cdr: ChangeDetectorRef,
    private readonly router: Router,
    private readonly viewContainerRef: ViewContainerRef,
    private topBreadcrumb: TopBreadcrumbService,
    private sideBarService: SidebarService) {
  }

  ngOnInit(): void {
    this.userService.updateValueTopMenu(false);
    this.updateBreadcrumb();
    this.sideBarService.selectedProjectId = 0;

    localStorage.setItem('projectName', '');
    localStorage.setItem('projectId', '');
    localStorage.setItem('licenseId', '');
    localStorage.setItem('licenseName', '');
    localStorage.setItem('demandId', '');
    localStorage.setItem('productNlpId', '');
  }

  showAddProjInformationsModal(): void {

    const modal = this.modal.create<NlpAddProjInformationsComponent>({
      nzTitle: 'Cadastro de produto NLP',
      nzWidth: 780,
      nzContent: NlpAddProjInformationsComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
      nzOkLoading: true,
      nzCancelText: 'No',
      nzOnCancel: () => {
        this.modalClosedSuccessfully = true
      },
    });

    modal.afterClose.subscribe(() => {
      if (this.modalClosedSuccessfully) {
        this.cdr.detectChanges();

      } else {
        if (!this.userService.isFlagCancelModalProj) {
          this.userService.updateValueTopMenu(true);
          this.userService.updateFlagReferenceTerm(true);
          this.cdr.detectChanges();
          this.router.navigate([`nlp/termo-referencia`]);
        }

      }
      this.modalClosedSuccessfully = false;
    });
  }

  close(): void {
  }

  updateBreadcrumb() {
    this.topBreadcrumb.reorgLevels({
      level: 0,
      title: 'Análise de Documentos',
      route: 'nlp/welcome'
    });
  }

}
