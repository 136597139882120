import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// COMPONENTS
import { ButtonSendComponent } from './button-send/button-send.component';
import { ButtonCancelComponent } from './button-cancel/button-cancel.component';
import { ButtonSaveDraftComponent } from './button-save-draft/button-save-draft.component';
import { ButtonIconPrimaryComponent } from './button-icon-primary/button-icon-primary.component';
import { ButtonIconSecondaryComponent } from './button-icon-secondary/button-icon-secondary.component';

const COMPONENTS = [
  ButtonSendComponent,
  ButtonCancelComponent,
  ButtonSaveDraftComponent,
  ButtonIconPrimaryComponent,
  ButtonIconSecondaryComponent
]

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  imports: [
    CommonModule
  ]
})
export class ButtonsLicensingModule { }
