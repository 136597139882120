import {ChangeDetectorRef, Component, OnChanges, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {CompetentOrganService} from "../../../../../shared/services/competent-organ.service";
import {CompetentOrgan} from "../../../../../shared/models/competent-organ.model";
import {LicensingService} from "../../service/licensing.service";
import {ProjectsService} from "../../../projects.service";
import {UserService} from "../../../../../shared/services/user.service";
import {ProjectModel} from "../../../../../shared/models/project.model";
import {UserModel} from "../../../../../shared/models/user.model";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {MessageService} from "../../../../../shared/common/services/message.service";
import {LicenseByProjectIdForLinkResponse} from "../../licensing.interface";
import {
  Connections,
  LicencingModel,
  ProcessData,
  LicenseByOrganResponse
} from "../../../../../shared/models/licencsing,model";
import {LinkedProcessesDTO} from "../../../../../shared/dto/process-list-response.dto";
import {DatePipe, Location} from "@angular/common";
import {NzModalService} from "ng-zorro-antd/modal";
import {ConfirmProcessDeleteComponent} from "./confirm-process-delete/confirm-process-delete.component";
import {Subject, take} from "rxjs";
import {BREADCRUMB_ACTIONS} from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';
import {ProjectState} from 'src/app/shared/ngrx/breadcrumb/model';
import {BreadcrumbState} from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';
import {Store} from '@ngrx/store';
import {LicensesV2Service} from 'src/app/shared/services/licenses-v2.service';
import {NzMessageDataOptions} from "ng-zorro-antd/message/typings";
import {UtilService} from "../../../../../shared/util.service";
import { LicenseResponseDto } from 'src/app/shared/dto/licenses-response.dto';


@Component({
  selector: 'app-edit-processv2',
  templateUrl: './edit-processv2.component.html',
  styleUrls: ['./edit-processv2.component.scss']
})
export class EditProcessv2Component implements OnInit, OnDestroy {
  projectId: number;

  listOfData: ProcessData[] = [];
  listOfActivity: any[] = []
  listOfDataComplete: ProcessData[] = [];
  competentOrgans: CompetentOrgan[] = [];
  licensesTypes: LicenseByOrganResponse[] = [];
  licensesForLink: LicenseByProjectIdForLinkResponse[] = [];
  licensesForLinkCache: LicenseByProjectIdForLinkResponse[] = [];
  users: UserModel[] = [];
  editChildCache: { [key: string]: { edit: boolean; data: ProcessData } } = {};
  editId: string | null = null;
  showChildLicense: boolean = false;
  disableLicenseType = true;
  disableUserResponsible = true;
  project: ProjectModel;
  i = 0;
  linkTypes = [
    {
      id: 1,
      type: "REN",
      name: "É uma renovação de"
    },
    {
      id: 2,
      type: "PRE",
      name: "Precisa de"
    },
    {
      id: 3,
      type: "NEC",
      name: "É necessária para"
    },
    {
      id: 4,
      type: "REP",
      name: "Está sendo renovada por"
    },
  ];
  processName: string = 'Novo processo de licenciamento';
  processBond: string = '';
  processNameManual: string = '';
  licenseCurrentUpdatedTitle: string = '';
  user: any;
  addingChild: boolean = false;
  childLicenceCount: number = 0;
  processId = 0;
  customStyle = {
    background: 'white',
    'font-size': '12px',
  };
  popOverNotification: boolean = false;
  licenseProcess: LicencingModel;
  firsTimeLoadingLinkedProcess = true;
  gestor = false;
  compareFn = (o1: any, o2: any): boolean => (o1 && o2 ? o1.id === o2.id : o1 === o2);
  compareFnString = (o1: string, o2: string): boolean => (o1 && o2 ? o1 === o2 : o1 === o2);

  private readonly destroy$: Subject<any> = new Subject();
  license: any;
  licenseTitle: string = '';
  cadProcessLicensingInsert: boolean = false;
  cadProcessLicensingEdit: boolean = false;
  cadProcessLicensingExclude: boolean = false;
  cadActivityInsert: boolean = false;
  cadCommunicationsInsert: boolean = false;

  startEditProcess: boolean = false
  disableDataEdit: boolean = false
  isNew: boolean = true
  InitialNameOfProcess: string;

  constructor(private competentOrganService: CompetentOrganService,
              private licensingService: LicensingService,
              private projectService: ProjectsService,
              private userService: UserService,
              private utilService: UtilService,
              private route: ActivatedRoute,
              private message: MessageService,
              private router: Router,
              private datePipe: DatePipe,
              private modal: NzModalService,
              private viewContainerRef: ViewContainerRef,
              private location: Location,
              private licensesV2Service: LicensesV2Service,
              private cdr: ChangeDetectorRef,
              private store: Store<BreadcrumbState>) {
  }

  ngOnInit(): void {

    // http://localhost:4200/project/9/edit-processv2/52
    // http://localhost:4200/project/9/edit-processv2/0

    this.cadProcessLicensingInsert = this.utilService.getAuthorization('insert', 'CADPROCESSLICENSING');
    this.cadProcessLicensingEdit = this.utilService.getAuthorization('edit', 'CADPROCESSLICENSING');
    this.cadProcessLicensingExclude = this.utilService.getAuthorization('exclude', 'CADPROCESSLICENSING');
    this.cadActivityInsert = this.utilService.getAuthorization('insert', 'CADACTIVITY');
    this.cadCommunicationsInsert = this.utilService.getAuthorization('insert', 'CADCOMMUNICATIONS')
    this.route.params.subscribe((params: Params) => {
      this.projectId = +params['projectId'];
      this.processId = +params['processId'];

      this.clearData();

      this.addProcessRow();

      this.updateEditCache();

      this.getCompetentOrgans();

      this.getLoggedUser();

      this.getProject();

      if (this.isEditingSavedProcess()) {
        this.activateLinkedLicenses();
        this.getProcessToEdit();
        this.isNew = false
        this.addingChild = false;
        this.showChildLicense = false;
      }
    });

  }

  // INICIO BREADCRUMB
  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private dispatchProjectOnStore(projectRequest: any): void {
    let project: ProjectState = {
      name: 'Projetos',
      url: '/projects-v2',
      last: false,
      sublevels: true,
      project: {
        name: projectRequest ? projectRequest.name : 'Novo Processo de Licenciamento',
        url: 'project-v2-view/' + this.route.snapshot.url[1].path,
        last: false,
      },
      subProject: null,
      parecerFinal: null,
      pedidoRevisao: null,
      comunicacoes: null,
      processos: {
        name: this.licenseProcess ? this.licenseProcess.title : 'Novo Processo de Licenciamento',
        url: this.route.snapshot.url[0].path + '/' + this.route.snapshot.url[1].path + '/' + this.route.snapshot.url[2].path + '/' + this.route.snapshot.url[3].path,
        last: true,
      },
      atividades: null,
      subatividades: null,
      mudancaEscopo: null
    }
    this.store.dispatch(BREADCRUMB_ACTIONS.getRiplaProject({project}));
  }

  // FINAL BREADCRUMB

  clearData() {
    this.listOfDataComplete = [];
    this.listOfData = [];
    this.i = 0;
  }

  goBack() {
    let origin = sessionStorage.getItem('origin');

    if (origin !== null && origin !== '' && origin !== undefined) {
      if (origin === 'projectView') {
        this.router.navigate([`/project-v2-view/${this.projectId}`]);
      } else if (origin === 'processList') {
        this.router.navigate([`/projects/${this.projectId}/processes`]);
      } else {
        this.location.back();
      }
    } else {
      this.location.back();
    }

  }

  getProcessToEdit(): void {
    this.licensingService.getLicencing(this.processId).subscribe({
      next: (license) => {
        this.licenseProcess = license;

        if (license?.connections !== undefined) {
          this.childLicenceCount = license?.connections.filter(c => c.active === 'A')?.length;
        }

        this.disableDataEdit = true

        this.addLinkedLicenceData(this.listOfData[0], license);

        this.getLicenceTitle(license);

        this.getLicensingTypes(license.competentOrgan?.id);

        this.enableUserResponsible(this.listOfData[0], false);

        this.dispatchProjectOnStore(this.project)

        this.getActivitysList();

      }
    });
  }

  private getLicenceTitle(license: LicencingModel): void {
    if (license.title !== undefined && license.title !== '') {
      const title = license?.title.split("-");

      this.getProcessName(title);

      this.licenseCurrentUpdatedTitle = title[1] ? title[1].trim() : '';
      this.processNameManual = title[1] ? title[1].trim() : '';

      this.dispatchProjectOnStore(this.project)
    }
  }

  private getProcessName(title: string[] | undefined) {
    if (title === undefined) return;

    if (title[0].search("(Renovação)") === -1) {
      this.processName = title[0].trim();
    } else {
      this.processName = title[0].replace('(Renovação)', '').trim();
      this.processBond = '(Renovação)';
    }
  }

  private getConnections(license: LicencingModel) {
    if (license.connections !== undefined) {
      license.connections.forEach((connection, i) => {
        this.getLinkedLicenseDataAndAddToTheGrid(connection);
      });

    }
  }

  private getLinkedLicenseDataAndAddToTheGrid(connection: Connections) {
    if (connection.active !== 'I') {
      this.licensingService.getLicencing(connection.idProjectLicensingConnection).subscribe({
        next: (license) => {
          this.addLicenceConnectedAsChild(license, connection);
        }
      });
    }
  }

  private addLicenceConnectedAsChild(license: LicencingModel, connection: Connections) {
    this.addLinkedProcessRow(false);
    this.addingChild = false;
    const index = this.i - 1;
    this.listOfData[index].type = connection.connectionType;
    this.listOfData[index].linkedLicenseId = connection.idProjectLicensingConnection + "";
    this.addLinkedLicenceData(this.listOfData[index], license);
  }

  startEdit(id: string, child: boolean, linkedLicenseId: string): void {
    if (!this.cadProcessLicensingEdit) {
      this.message.showErrorMessage("Sem permissão para edição do processo de licenciamento");
      return;
    }
    if (child) {
      this.editChildCache[id].edit = true;
      if(this.licensesForLinkCache.length === 0){
        this.getLicensesByProjectIdForLink(this.projectId, this.listOfData[id].type, this.listOfData[id]);
      }
      this.updateLicenceForLink(linkedLicenseId);
    } else {
      this.editId = id;
    }
  }

  stopEdit(): void {
    this.editId = null;

    if (this.isEditingSavedProcess()) {
      this.resetProcessForm();
    } else {
      let temp: ProcessData[] = [];
      this.listOfData.forEach(val => temp.push(Object.assign({}, val)));
      this.listOfDataComplete = temp;
    }

  }

  private resetProcessForm() {
    this.listOfData[0].competentOrgan = this.listOfDataComplete[0].competentOrgan;
    this.listOfData[0].license = this.listOfDataComplete[0].license;
    this.listOfData[0].processNumber = this.listOfDataComplete[0].processNumber;
    this.listOfData[0].userLegalRep = this.listOfDataComplete[0].userLegalRep;
    this.listOfData[0].deadline = this.listOfDataComplete[0].deadline;
    this.listOfData[0].protocolDate = this.listOfDataComplete[0].protocolDate;
  }

  clickedOutsideProcessForm() {
    if (this.editId != null && this.processId != 0) {
      this.saveProcess();
    }
  }

  clickedOutsideProcessTitle() {
    if(this.isNew){
      if (this.processNameManual !== '' && this.processNameManual !== this.licenseCurrentUpdatedTitle && this.isEditingSavedLinkedProcess()) {
        this.saveProcess();
      }
    } else {
      let processNameToSearch = this.processName + " - " + this.processNameManual
      if(this.InitialNameOfProcess === processNameToSearch) return
      this.licensesV2Service.getLicensesByProjectId(this.projectId).subscribe({
        next: (licensesResponse: LicenseResponseDto[]) => {
          const newArray = licensesResponse['content']
          const returnlicensesResponse = newArray.filter(license => license.title === processNameToSearch)
          if(returnlicensesResponse.length >= 1){
            this.message.showErrorMessage("Erro ao atualizar processo: Esse título já existe para esse projeto.")
            return
          } else {
            if (this.processNameManual !== '' && this.processNameManual !== this.licenseCurrentUpdatedTitle && this.isEditingSavedLinkedProcess()) {
              this.saveProcess();
            }
          }
        }
      });
    }
  }

  addProcessRow(): void {
    this.listOfData = [
      ...this.listOfData,
      {
        id: `${this.i}`,
        type: ``,
        linkedLicenseId: ``,
        child: false,
        status: 'A iniciar',
      }
    ];
    this.listOfDataComplete = this.copyObject(this.listOfData);
    this.i++;
  }

  addLinkedProcessRow(edit: boolean): void {
    this.addingChild = true;
    let newChild = {
      id: `${this.i}`,
      type: ``,
      linkedLicenseId: ``,
      child: true,
    };

    this.listOfData = [
      ...this.listOfData,
      newChild
    ];

    this.editChildCache[this.i] = {
      edit: edit,
      data: {...newChild}
    };

    this.listOfDataComplete = this.copyObject(this.listOfData);

    this.i++;
    this.childLicenceCount++;
    this.stopEdit();
  }

  deleteLinkedProcessRow(id: string): void {

    this.addingChild = false;
    this.listOfData = this.listOfData.filter(d => d.id !== id);
    this.childLicenceCount--;
    this.updateEditCache();
  }

  deleteChildLicenceFromDb(id: string) {

    this.deleteLinkedProcessRow(id);

    this.updateLinkedProcesses();

    this.updateLicenceForLink('');
  }

  deleteLicense() {
    this.licensingService.deleteProcess(this.processId).subscribe({
      next: () => {
        this.message.showSucessMessage('Processo excluído com sucesso!');
        this.router.navigate([`/projects/${this.projectId}/processes`]);
      },
      error: (error) => {
        this.message.showErrorMessage('Erro ao deletar processo: ' + error.error.errors.details);
      }
    });
  }

  modalConfirmDelete(): void {
    const modal = this.modal.create<ConfirmProcessDeleteComponent>({
      nzTitle: '',
      nzClosable: true,
      nzWidth: 555,
      nzCentered: true,
      nzContent: ConfirmProcessDeleteComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });

    modal.afterClose.subscribe((result) => {
      if (result) {
        this.deleteLicense();
      }
    });
  }

  private getIndex(id: string) {
    return this.listOfData.findIndex(item => item.id === id);
  }

  private updateLinkedProcesses() {
    if (!this.isEditingSavedProcess()) {
      return;
    }
    // @ts-ignore
    let linkedProcessesDTO: LinkedProcessesDTO[] = this.listOfData.filter(item => item.child).map(item => {
      return {
        processId: item.linkedLicenseId,
        type: item.type,
        label: ''
      }
    });

    this.licensingService.updateProcessLinks(this.processId, linkedProcessesDTO).subscribe({

      next: () => {
        this.message.showSucessMessage('Edição salva com sucesso!');
      },
      error: (error) => {
        this.message.showErrorMessage('Erro ao atualizar processo: ' + error.error.errors.details);
      }
    });
  }

  cancelLinkedProcessRow(id: string): void {
    let index = this.getIndex(id);

    if (this.listOfData[index].type === 'REN') {
      this.processBond = '';
    }

    if (this.isEditingSavedLinkedProcess()) {

      if (this.listOfData[index].license === undefined) {
        this.deleteLinkedProcessRow(id);
        return;
      }
      // @ts-ignore
      this.resetLinkedLicenceForm(index);
      this.editChildCache[id].edit = false;

    } else {
      this.deleteLinkedProcessRow(id);
    }


    this.updateLicenceForLink('');
  }


  private resetLinkedLicenceForm(index: number) {
    this.listOfData[index].type = this.listOfDataComplete[index].type;
    this.listOfData[index].linkedLicenseId = this.listOfDataComplete[index].linkedLicenseId;
  }

  getLicensingTypes(id) {
    this.licensingService.getLicensesByOrgan(id).subscribe({
      next: (licensesTypes) => {
        this.licensesTypes = licensesTypes;
        this.disableLicenseType = false;
      }
    });
  }

  getLicensingTypesChangingOrgan(id) {
    this.listOfData[0].license = {};
    this.getLicensingTypes(id);
  }

  getLicensesByProjectIdForLink(projectId, type, data) {
    data.linkedLicense = '';
    this.licensingService.getLicensesByProjectIdForLink(projectId, type, this.licenseProcess.license?.licenseCode)
    .subscribe({
      next: (licenses) => {
        this.disableLicenseType = false;
        if (this.isEditingSavedProcess()) {
          this.licensesForLink = licenses.filter(l => l.licenseId !== this.processId);
          this.licensesForLinkCache = licenses.filter(l => l.licenseId !== this.processId);
        } else {
          this.licensesForLink = licenses;
          this.licensesForLinkCache = licenses;
        }
      }
    });
  }

  updateLicenceForLink(linkedLicenseId: string): void {

    this.licensesForLink = this.licensesForLinkCache;

    this.listOfData.forEach(item => {
      if (item.child && item.linkedLicenseId !== linkedLicenseId) {
        this.licensesForLink = this.licensesForLink.filter(licence => licence.licenseId !== +item.linkedLicenseId);
      }
    })
  }

  cancelEdit(id: string) {
    const index = this.listOfData.findIndex(item => item.id === id);
    this.editChildCache[id] = {
      data: {...this.listOfData[index]},
      edit: false
    };
  }

  updateEditCache(): void {
    this.listOfData.forEach(item => {
      this.editChildCache[item.id] = {
        edit: false,
        data: {...item}
      };
    });
  }

  activateLinkedLicenses() {
    this.stopEdit();
    if (this.checkProcessIsFilled()) {
      this.showChildLicense = !this.showChildLicense;

      if (this.isEditingSavedProcess() && this.firsTimeLoadingLinkedProcess && this.childLicenceCount > 0) {
        this.childLicenceCount = 0;
        this.getConnections(this.licenseProcess);
        this.firsTimeLoadingLinkedProcess = false;
      } else {
        this.toggleLinkedLicenses();
      }

    }
  }

  private toggleLinkedLicenses() {
    let temp: ProcessData[] = [];
    this.listOfDataComplete.forEach(val => temp.push(Object.assign({}, val)));
    if (this.showChildLicense) {
      this.listOfData = temp;
    } else {
      this.listOfData = temp.filter((i) => !i.child);
    }
  }

  enableUserResponsible(data: ProcessData,  startEditProcess: boolean) {
    if (data?.license) {
      // @ts-ignore
      this.processName = data?.license?.description;
    }
    this.disableUserResponsible = false;
  }

  checkProcessIsFilled(): boolean {
    if (this.listOfData[0].license?.description && this.listOfData[0].competentOrgan?.description) {
      return true;
    } else {
      return false;
    }
  }

  saveProcessEditOrNew() {
    if(this.isNew !== true) { this.saveProcess() }
  }

  saveProcess(): void {
    this.editId = null;
    this.disableDataEdit = true
    if (this.listOfData[0].competentOrgan === undefined || this.listOfData[0].license === undefined || this.listOfData[0].license.description === undefined) {
      this.message.showErrorMessage('Favor preencher os campos obrigatórios');
      this.disableDataEdit = false
      return;
    }
    if (this.processNameManual === '') {
      this.message.showErrorMessage('Favor preencher a descrição do processo de licenciamento');
      this.disableDataEdit = false
      return;
    }


    if (this.isEditingSavedProcess()) {
      this.updateProcess();
    } else {
      this.createProcess();
    }

  }

  private createProcess() {
    this.licensingService.createProcessLicensing(this.buildPayload()).subscribe({
      next: (processCreated) => {
        this.message.showSucessMessage('Processo criado com sucesso!');
        this.router.navigate([`/project/${this.projectId}/edit-processv2/${processCreated.id}`])
      },
      error: (error) => {
        let options: NzMessageDataOptions = {nzDuration: 10, nzAnimate: true, nzPauseOnHover: true};
        if (error.error.errors !== undefined) {
          this.message.showErrorMessage('Erro ao criar processo: ' + error.error.errors.details);
        } else if (error.error.message !== undefined) {
          this.message.showErrorMessage('Erro ao criar processo: ' + error.error.message);
        }
      }
    });
  }

  private updateProcess() {
    this.licensingService.updateProcessLicensing(this.buildPayload(), this.processId).subscribe({
      next: (processCreated) => {
        this.licenseCurrentUpdatedTitle = this.processNameManual;
        this.disableDataEdit = true
        this.message.showSucessMessage('Processo atualizado com sucesso!');
      },
      error: (error) => {
        console.log("error", error)
        this.message.showErrorMessage('Erro ao criar processo: ' + error.error.message);
      }
    });
  }

  buildPayload(): any {
    let licensesConnections = this.listOfData.filter(item => item.child).map(item => {
      return {
        linkedLicenseId: item.linkedLicenseId,
        connectionType: item.type
      }
    });

    return {
      projectId: this.projectId,
      competentOrganId: this.listOfData[0].competentOrgan?.id,
      licenseTypeId: this.listOfData[0].license?.id,
      title: this.buildTitle(),
      userId: this.user.id,
      userLegalRepId: this.listOfData[0].userLegalRep?.id,
      protocolDate: this.listOfData[0].protocolDate,
      deadline: this.listOfData[0].deadline,
      processNumber: this.listOfData[0].processNumber,
      licensesConnections: licensesConnections
    }
  }

  buildTitle(): string {
    let title = '';
    title = this.processName;
    if (this.processBond !== '') {
      title = title + this.processBond;
    }

    if (this.processNameManual !== '') {
      title = title + ' - ' + this.processNameManual;
    }

    return title
  }

  getProject() {
    this.projectService.getProject(this.projectId).subscribe({
      next: (project) => {
        this.project = project;
        this.getUsers();
        this.dispatchProjectOnStore(this.project)
      }
    });
  }

  private getUsers() {
    if (this.project.region) {
      this.userService.getUsersByRoleAndRegion("ROLE_LICENCIAMENTO", this.project.region?.id).subscribe({
        next: (users) => {
          this.users = users;
        }
      })
    }
  }

  getCompetentOrgans() {
    this.competentOrganService.getCompetentOrgans().subscribe({
      next: (organs) => {
        this.competentOrgans = organs;
      }
    });
  }

  onReload(event: boolean): void {
    if (event) {
      this.getActivitysList();
    }
  }

  getActivitysList() {
    this.licensingService.getActivitysList(this.processId).pipe(take(1)).subscribe({
      next: (data) => {
        if (data) {
          this.listOfActivity = data;
        }
      }, error: (err) => {
        if (err) {
          this.message.showErrorMessage("Erro ao carregar lista de atividade: " + err.message)
        }
      }
    })
  }

  getLoggedUser(): void {
    const loggedUser = sessionStorage.getItem("loggedUser");
    this.user = loggedUser ? JSON.parse(loggedUser) : null;
    if (this.user.roles === null) return;
    if (this.user.roles.some(r => r.role === 'ROLE_GESTOR')) {
      this.gestor = true;
    }
  }


  public disabledDate = (current: Date) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return current < currentDate;
  };

  applyLinkedLicense(data: ProcessData) {

    if (data.linkedLicenseId === '' || data.type === '' || data.linkedLicenseId === '') {
      this.message.showErrorMessage('Favor prencher os campos obrigatórios');
      return;
    }

    this.licensingService.getLicencing(+data.linkedLicenseId).subscribe({
      next: (license) => {
        this.addLinkedLicenceData(data, license);
        this.cancelEdit(data.id);
        this.addingChild = false;

        this.updateLinkedProcesses();

        this.updateLicenceForLink('');

      }
    });


  }

  private addLinkedLicenceData(data: ProcessData, license: LicencingModel) {
    const index = this.listOfData.findIndex(item => item.id === data.id);
    this.listOfData[index].competentOrgan = license.competentOrgan;
    this.listOfData[index].license = license.license;
    this.listOfData[index].userLegalRep = license.userLegalRep;
    this.listOfData[index].deadline = license.deadline !== null ? (license.deadline + 'T00:00:00') : '';
    this.listOfData[index].protocolDate = license.protocolDate !== null ? (license.protocolDate + 'T00:00:00') : '';
    this.listOfData[index].status = license.status;
    this.listOfData[index].processNumber = license.processNumber;
    this.listOfData[index].documentNumber = license.documentNumber;
    this.listOfData[index].licenseEmissionDate = license.licenseEmissionDate;
    this.listOfData[index].effectiveDate = license.effectiveDate;
    this.listOfData[index].renewalDeadlineDate = license.renewalDeadlineDate;
    this.listOfData[index].expiredStatus = license.expiredStatus;

    this.updateCachedProcessData();
  }

  private updateCachedProcessData() {
    let temp: ProcessData[] = [];
    this.listOfData.forEach(val => temp.push(Object.assign({}, val)));
    this.listOfDataComplete = temp;
  }

  changeTitleBond(type: string) {
    if (type === 'REN') {
      this.processBond = ' (Renovação) ';
    } else {
      this.processBond = '';
    }
  }

  isEditingSavedLinkedProcess(): boolean {
    return this.isEditingSavedProcess() && !this.addingChild;
  }

  isEditingSavedProcess(): boolean {
    return this.processId !== 0;
  }

  private copyObject(obj: any): any {
    return JSON.parse(JSON.stringify(this.listOfData));
  }

  getDeadline(deadLine: string | null | undefined): string | null {
    if (deadLine !== undefined && deadLine !== null && deadLine !== '') {
      return this.datePipe.transform(deadLine, 'dd/MM/yyyy');
    } else {
      if (this.isEditingSavedProcess()) {
        return '<span class="pendingProcessText">Pendente</span>'
      } else {
        return 'Informe...';
      }
    }
  }

  getProtocolDate(protocolDate: string | null | undefined): string | null {
    if (protocolDate !== undefined && protocolDate !== null && protocolDate !== '') {
      return this.datePipe.transform(protocolDate, 'dd/MM/yyyy');
    } else {
      if (this.isEditingSavedProcess()) {
        return 'Não protocolado';
      } else {
        return 'Informe...';
      }
    }
  }

  getUserLegal(data: any) {
    return data.userLegalRep !== undefined && data.userLegalRep !== null ? data.userLegalRep?.name : 'Informe...';
  }

  getProcessNumber(data: any) {
    return data.processNumber !== undefined ? data.processNumber : 'Informe...';
  }

  getLicense(data: any) {
    return data.license !== undefined ? data.license.description : 'Informe...';
  }

  getCompetentOrgan(data: any) {
    return data.competentOrgan !== undefined ? data.competentOrgan.code : 'Informe...';
  }

  getTextColor(text: string | null | undefined): string {
    return text === 'Informe...' ? 'greyText' : '';
  }

  getTextColorForLicense(text: string | null | undefined): string {
    return text === 'Não emitida' ? 'greyText' : '';
  }

  showConfirm(): void {
    this.modal.confirm({
      nzTitle: 'Deseja mesmo cancelar?',
      nzContent: 'Essa ação não poderá ser desfeita.',
      nzOkText: 'Cancelar',
      nzCancelText: 'Voltar',
      nzOnOk: () => this.goBack()
    });
  }

  getLicenseDocumentNumber(data: any): string {
    return data.documentNumber !== null && data.documentNumber !== undefined ? data.documentNumber : 'Não emitida';
  }

  getLicenseEmissionDate(data: any): string {
    return data.licenseEmissionDate !== null && data.licenseEmissionDate !== undefined ?
      this.datePipe.transform(data.licenseEmissionDate, 'dd/MM/yyyy') + '' : 'Não emitida';
  }

  getLicenseEffectiveDate(data: any): string {
    return data.effectiveDate !== null && data.effectiveDate !== undefined ?
      this.datePipe.transform(data.effectiveDate, 'dd/MM/yyyy') + '' : 'Não emitida';
  }

  getLicenseRenewDeadline(data: any): string {
    return data.renewalDeadlineDate !== null && data.renewalDeadlineDate !== undefined ?
      this.datePipe.transform(data.renewalDeadlineDate, 'dd/MM/yyyy') + '' : 'Não emitida';
  }

}
