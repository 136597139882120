<div nz-col class="margin " *ngIf="finalAnalysisResponse && checkIfIsSketch()">
  <div class="rounded-row final-opinion-container">
    <h2>{{ 'projects.analysis.final-opinion.title' | translate }}</h2>
    <div class="in-progress" *ngIf="subProject.status!=='CON'">
      <p>{{ 'projects.analysis.final-opinion.in-progress' | translate }}</p>
      <a *ngIf="riplaFinalAnalisys" [routerLink]="['/project-v2-analysis-consolidation-final/'+subProject.projectId+'/'+subProject.id]">
        {{ 'projects.analysis.final-opinion.continue-final-opinion' | translate }}
      </a>
    </div>


      <h3 class="mt-16">Avaliação da equipe de licenciamento</h3>
      <div class="warning-errors" *ngIf="finalAnalysisResponse.asmtRestrictions === true">
        <div class="warning-message">
          <img src="assets/icons/icon_warning_alt.svg" alt="" class="mr-20">
          <div>
            <p>O projeto possui as seguintes restrições ambientais:</p>
            <ul>
              <li class="restrictions"
                  *ngFor="let restriction of finalAnalysisResponse.restrictions">{{ restriction.description }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    <div class="finished" *ngIf="subProject.status==='CON'">
      <div>
        <div class="licence-team-assessment">
          <div class="mr-40" *ngIf="finalAnalysisResponse.asmtRestrictions === false">
            <p>Restrições ambientais?</p>
            <span class="asmtRestrictions">{{ 'Não' }}</span>
          </div>
          <div class="mr-40">
            <p>Prioritário por questões legais?</p>
            <span class="asmtPriority">{{ finalAnalysisResponse.asmtPriority === true ? 'Sim' : 'Não' }}</span>
          </div>
          <div class="mr-40">
            <p>Demanda por licenciamento?</p>
            <span class="asmtLicenseRequired">{{
                finalAnalysisResponse.asmtLicenseRequired === true ? 'Sim' : 'Não'
              }}</span>
          </div>
        </div>
      </div>

      <ng-container *ngIf="finalAnalysisResponse.initialLicenseStrategyResponses.length > 0">
        <h3 class="mt-30">Licenciamentos necessários</h3>
        <nz-table nzTemplateMode nzSize="small" nzTableLayout="fixed">
          <thead>
          <tr>
            <th class="tableTh">Órgão</th>
            <th class="tableTh">Processo de licenciamento</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let data of finalAnalysisResponse.initialLicenseStrategyResponses">
            <td class="tableTd licenceOrgan">{{ data.competentOrgan.code }}</td>
            <td class="tableTd licenceType">{{ data.license.description }}</td>
          </tr>
          </tbody>
        </nz-table>
      </ng-container>

      <ng-container *ngIf="finalAnalysisResponse.environmentalStudyResponse.length > 0">
        <h3 class="mt-30">Estudos necessários</h3>
        <nz-table nzTemplateMode nzSize="small" nzTableLayout="fixed">
          <thead>
          <tr>
            <th class="tableTh">Descrição</th>
            <th class="tableTh">Processo de licenciamento</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let study of finalAnalysisResponse.environmentalStudyResponse">
            <td class="tableTd studyDescription">{{ study.description }}</td>
            <td class="tableTd studyLicenseType">{{ study.license.description }}</td>
          </tr>
          </tbody>
        </nz-table>
      </ng-container>

      <h3 class="mt-30">Estimativa de custos</h3>
      <nz-table nzTemplateMode nzSize="small" nzTableLayout="fixed">
        <thead>
        <tr>
          <th class="tableTh">Descrição</th>
          <th class="tableTh">Custo estimado</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="tableTd">Estudos</td>
          <td class="tableTd">
            <div class="cost-value">
              <div class="studies">{{ finalAnalysisResponse.costs.studies.cost | currency : 'BRL' }}</div>
              <img src="assets/icons/icon_caution_sign_circle.svg"
                   width="12"
                   height="12"
                   alt=""
                   *ngIf="finalAnalysisResponse.environmentalStudyResponse.length > 0"
                   [nzTooltipColor]="'#2E2E2E'"
                   [nzTooltipOverlayStyle]=customStyle
                   [nzTooltipTitle]="studiesToolTipTemplate"
                   nzTooltipPlacement="bottomRight"
                   nz-button
                   nz-tooltip
                   class="cursor-pointer">
              <ng-template #studiesToolTipTemplate>
                <div>
                  <div class="tooltipTitle">Estudos</div>
                  <div class="tooltipContent" *ngFor="let item of finalAnalysisResponse.environmentalStudyResponse">
                    <div>
                      {{ item.description }}
                    </div>
                    <div>
                      {{ item.cost | currency : 'BRL' }}
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </td>
        </tr>
        <tr>
          <td class="tableTd">Compensações</td>
          <td class="tableTd">
            <div class="cost-value">
              <div class="compensations">{{ finalAnalysisResponse.costs.compensations.cost | currency : 'BRL' }}</div>
              <img src="assets/icons/icon_caution_sign_circle.svg"
                   width="12"
                   height="12"
                   alt=""
                   *ngIf="finalAnalysisResponse.costs.compensations.itens.length > 0"
                   [nzTooltipColor]="'#2E2E2E'"
                   [nzTooltipOverlayStyle]=customStyle
                   [nzTooltipTitle]="compensationToolTipTemplate"
                   nzTooltipPlacement="bottomRight"
                   nz-button
                   nz-tooltip
                   class="cursor-pointer">
              <ng-template #compensationToolTipTemplate>
                <div>
                  <div class="tooltipTitle">Compensações</div>
                  <div class="tooltipContent" *ngFor="let item of finalAnalysisResponse.costs.compensations.itens">
                    <div>
                      {{ item.title }}
                    </div>
                    <div>
                      {{ item.value | currency : 'BRL' }}
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </td>
        </tr>
        <tr>
          <td class="tableTd">Outros</td>
          <td class="tableTd">
            <div class="cost-value">
              <div class="other">{{ finalAnalysisResponse.costs.otherCosts.cost | currency : 'BRL' }}</div>
              <img src="assets/icons/icon_caution_sign_circle.svg"
                   width="12"
                   height="12"
                   alt=""
                   *ngIf="finalAnalysisResponse.costs.otherCosts.itens.length > 0"
                   [nzTooltipColor]="'#2E2E2E'"
                   [nzTooltipOverlayStyle]=customStyle
                   [nzTooltipTitle]="otherToolTipTemplate"
                   nzTooltipPlacement="bottomRight"
                   nz-button
                   nz-tooltip
                   class="cursor-pointer">
              <ng-template #otherToolTipTemplate>
                <div>
                  <div class="tooltipTitle">Outros</div>
                  <div class="tooltipContent" *ngFor="let item of finalAnalysisResponse.costs.otherCosts.itens">
                    <div>
                      {{ item.title }}
                    </div>
                    <div>
                      {{ item.value | currency : 'BRL' }}
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
          </td>
        </tr>
        <tr>
          <td class="tableTd total">Total</td>
          <td class="tableTd total totalCost">{{ finalAnalysisResponse.costs.total.cost | currency : 'BRL' }}</td>
        </tr>
        </tbody>
      </nz-table>

      <h3 class="mt-30" *ngIf="attachments && attachments.length > 0">Documentos do parecer</h3>
      <div class="fileList">
        <a class="fileLink"
           (click)="downloadFile(attachment.id,attachment.name)"
           *ngFor="let attachment of attachments"
           nz-popover
           nzPopoverContent="{{ getHintText(attachment.name) }}">
          <img src="assets/icons/uploaded_document-icon.svg" alt="" class="mr-10">
          <span class="fileName">{{ attachment.name }}</span>
        </a>
      </div>
    </div>
  </div>
</div>
