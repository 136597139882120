<div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="app-generic-resume-sidebar">
  <div class="scope__items">
    <div class="scope__items__item" id="resumoEscopo" >
      <h3 class="scope__items__item__title" *ngIf="blockTitle">
        {{ blockTitle | translate }}
      </h3>

      <div class="scope__items__item__row" *ngFor="let row of contentRows">
        <div>
          <p class="scope__items__item__row__title">
            {{ row.title | translate }}
          </p>
        </div>
        <div *ngIf="row.contentType === 'bulletList'">
          <span *ngIf="!row.content">-</span>
          <ul class="bulletList">
            <li *ngFor="let item of row.content">
              {{ item }}
            </li>
          </ul>
        </div>

        <div *ngIf="row.contentType === 'plainText'">
          <p *ngIf="!row.content">-</p>
          <p>
            {{ row.content }}
          </p>
        </div>

        <div *ngIf="row.contentType === 'dashList'">
          <span *ngIf="!row.content">-</span>
          <ul  class="dashList">
            <li *ngFor="let item of row.content">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="scope__items__item" *ngIf="attachmentsBlockTitle">
      <h3 class="scope__items__item__title h3-title" *ngIf="attachmentsBlockTitle">
        {{ attachmentsBlockTitle | translate }}
      </h3>
      <div class="fileList"  *ngIf="attachments && attachments.length > 0">
        <div class="document mt-05" *ngFor="let attachment of attachments">
          <img class="demand-file-icon" *ngIf="attachment.status === 'done'" src=" {{ getIconFromFileName(attachment.name) }}" alt="some file" />
          <app-svg-icon *ngIf="attachment.status === 'error'" icon="atention-yellow-icon" [width]="16" [height]="16" class="iconAlignCenter"></app-svg-icon>
          <div
            class="fileName"
            [class.fileName-error]="attachment.status === 'error'"
            (click)="downloadFile(attachment)">
            {{attachment.name.length > 40 ?
              (getAttachmentTruncatedName(attachment.name)) :
              (attachment.name)
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
