import { createReducer, on } from "@ngrx/store";

import { BREADCRUMB_ACTIONS } from "./breadcrumb.actions";
import { BreadcrumbState } from "./breadcrumb.state.model";


const initialState: BreadcrumbState = {
    project: null
}

const reducer = createReducer(initialState,
    on(BREADCRUMB_ACTIONS.getRiplaProject, (state, action) => ({
        ...state, project: action.project
    }))
);

export function breadcrumbReducer(state, action): any {
    return reducer(state, action);
}