<div class="project_v2_req_revision_cont" style="padding: 20px !important;" *ngIf="!loadingSubProjects">

  <div nz-row class="mb-20">
    <div nz-col nzSpan="15" class="header">
      <h1 id="riplaTitle" *ngIf="subProject">
        {{ 'projects.requisition-resume.title' | translate }}
      </h1>
    </div>
    <div nz-col nzSpan="9" class="action-box">
      <button class="btn-cancel" (click)="goBackAnalysis()">
        {{ 'projects.requisition-resume.btn-cancel' | translate }}
      </button>

      <button *ngIf="this.riplaAnswerRevision" class="btn-final"  (click)="updateSubProjectReview()">
        <img src="assets/icons/icon_analysis.svg" alt="" class="mr-5">
        {{ 'projects.requisition-resume.btn-reenviar-analise' | translate }}
      </button>
    </div>
  </div>

  <div nz-row>

    <!-- CARD DADOS DA REVISAO  DA RIPLA -->
    <div nz-col nzSpan="15" class="pr-20">

      <!-- PEDIDO DE REVISÃO DA RIPLA PARA A AREA SOLICITANTE -->
      <div class="rounded-row">
        <app-revision-ripla-requesting-area [riplaRevision]="dataRevisionRiplaRequestingArea"></app-revision-ripla-requesting-area>
      </div>


       <!-- SOBRE O PROJETO -->
       <div class="rounded-row-scroll mt-20" (scroll)="onScroll()">
        <h2 class="mb-10">{{ 'projects.requisition-resume.title-about-project' | translate }}</h2>

        <!-- ALERTA -->
        <nz-alert nzType="warning" id="alertWarning"
          nzDescription="{{ 'projects.requisition-revision.alert-text' | translate }}"
          [nzIcon]="customIconTemplate"
          nzShowIcon>
        </nz-alert>

        <!-- ICONE CUSTOMIZADO DO ALERT -->
        <ng-template #customIconTemplate>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M12.0003 8.99996V13V8.99996ZM10.2503 4.18996L2.6303 18C2.45398 18.3053 2.36153 18.6519 2.36231 19.0045C2.36309 19.3571 2.45708 19.7032 2.63475 20.0078C2.81242 20.3124 3.06745 20.5645 3.374 20.7388C3.68054 20.913 4.02771 21.0031 4.3803 21H19.6203C19.9729 21.0031 20.3201 20.913 20.6266 20.7388C20.9332 20.5645 21.1882 20.3124 21.3659 20.0078C21.5435 19.7032 21.6375 19.3571 21.6383 19.0045C21.6391 18.6519 21.5466 18.3053 21.3703 18L13.7503 4.18996C13.5773 3.87735 13.3238 3.61679 13.0161 3.43534C12.7083 3.2539 12.3576 3.1582 12.0003 3.1582C11.643 3.1582 11.2923 3.2539 10.9845 3.43534C10.6768 3.61679 10.4233 3.87735 10.2503 4.18996Z"
              stroke="#E9AB13" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.0502 17H11.9502" stroke="#E9AB13" stroke-width="2.5" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </ng-template>

        <div class="cardProject" [formGroup]="form">

          <!-- COLUNA ESQUERDA -->
          <div nz-row class="columns">
            <div nz-col class="firstColumn mt-10">
              <!-- NOME DO PROJETO -->
              <nz-form-item class="sl-form-item" style="flex-direction: column;">
                <nz-form-label nzFor="projectName" nzRequired>
                  <span class="sl-form-label">Nome do projeto</span>
                </nz-form-label>
                <input nz-input class="inputField" formControlName="projectName"/>
              </nz-form-item>


              <!-- AREA SOLICITANTE -->
              <nz-form-item class="sl-form-item" style="flex-direction: column;">
                <nz-form-label
                  nzFor="areaSolicitante"
                  nzRequired>
                  <span class="sl-form-label">Área Solicitante</span>
                </nz-form-label>
                <nz-form-control nzErrorTip="">
                  <nz-select  id="selEditable"
                              class="sl__form__inputFieldSelect inputFieldSelect"
                              formControlName="areaSolicitante"
                              [compareWith]="compareFn"
                              [nzOpen]="isOpenSelect"
                              #selectAreaSolicitante
                              nzShowSearch
                              [nzAllowClear]="this.form.controls['areaSolicitante'].value !== '' ? 'true' : 'false'"
                              (ngModelChange)="replaceResumeData()"
                              [nzSize]="'large'"
                              nzPlaceHolder="Selecione">
                    <nz-option *ngFor="let item of resquestingAreasFiltered" [nzLabel]="item.description" [nzValue]="item.id"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item class="sl-form-item mt-20">
                <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="projectClassification" nzRequired>
                  <span class="sl-form-label">Classificação do projeto</span>
                </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-radio-group formControlName="projectClassification" [disabled]="true">

                    <label nz-radio [nzValue]="'Greenfield'" id="projectClassificationGreen" nzDisabled="true">
                      <span style="display: flex; align-items: center;">
                        <span class="card--radio__span">Greenfield</span>
                        <span nz-tooltip
                        nzTooltipTitle="Projeto realizado em áreas que não possuem instalações e facilidades pré-existentes. Novos empreendimentos."
                        nzTooltipPlacement="bottomLeft" [nzTooltipOverlayStyle]="{ 'font-size': '12px'}">
                        <app-svg-icon icon="icon_information"></app-svg-icon>
                      </span>
                    </span>
                    </label>

                    <label nz-radio [nzValue]="'Brownfield'" id="projectClassificationBrown" nzDisabled="true">
                      <span style="display: flex; align-items: center;">
                        <span class="card--radio__span">Brownfield</span>
                        <span nz-tooltip
                          nzTooltipTitle="Projeto realizado em áreas que possuem instalações e facilidades às quais o produto do projeto será incorporado. Expansão de empreendimentos."
                          nzTooltipPlacement="bottomLeft" [nzTooltipOverlayStyle]="{ 'font-size': '12px'}">
                          <app-svg-icon icon="icon_information"></app-svg-icon>
                        </span>
                      </span>
                    </label>
                  </nz-radio-group>
                </nz-form-control>
              </nz-form-item>
            </div>

            <!-- COLUNA DIREITA -->
            <div nz-col class="secondColumn mt-10">

              <!-- CODIGO DO PROJETO -->
              <nz-form-item class="sl-form-item">
                <nz-form-label nzFor="projectCode">
                  <span class="sl-form-label">Código do projeto na área solicitante (opcional)</span>
                </nz-form-label>
                <nz-form-control>
                  <input nz-input class="inputField" formControlName="projectCode" [disabled]="true"/>
                </nz-form-control>
              </nz-form-item>

              <nz-form-item class="sl-form-item">
                <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="areaSolicitante" nzRequired>
                  <span class="sl-form-label">Responsável pelo projeto na área solicitante</span>
                </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="" class="inputField">
                  <nz-select 
                    id="selEditable" 
                    class="sl__form__inputFieldSelect inputFieldSelect" 
                    [nzSize]="'large'" 
                    [nzAllowClear]="this.form.controls['responsavelAreaSolicitante'].value !== '' ? 'true' : 'false'"
                    nzPlaceHolder="Selecione" 
                    [compareWith]="compareFn" 
                    formControlName="responsavelAreaSolicitante"
                    (ngModelChange)="replaceResumeData()" 
                    [nzLoading]="isProjectTypesLoading">
                    <nz-option *ngFor="let type of users" nzLabel="{{ type.name | titlecase }}"
                      nzValue="{{ type.id }}"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <!-- LINHA OBSERVACOES -->
          <div nz-row class="fullLineColumns">
            <nz-form-item class="fullLineColumns">
              <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="comments" nzRequired="true">
                <span class="sl-form-label">Breve descrição/Objetivo do Projeto</span>
              </nz-form-label>
              <nz-form-control [nzSm]="24" [nzXs]="24"
                nzErrorTip="Por favor insira as observações com no máximo 2000 caracteres">
                <nz-textarea-count [nzMaxCharacterCount]="2000">
                <textarea id="txtObservacao" rows="5" placeholder="Informe uma breve descrição"  formControlName="comments" nz-input
                  (ngModelChange)="replaceResumeData()"></textarea>
              </nz-textarea-count>
              </nz-form-control>
            </nz-form-item>
          </div>

          <!-- LOCALIZACAO -->
          <h2 class="card--title__h2">Localização</h2>
          <div nz-col class="threeColumns mt-10">
            <nz-form-item class="threeColumn" style="flex-direction: row !important;">
              <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="corredor" nzRequired>
                <span class="sl-form-label">Corredor</span>
              </nz-form-label>
              <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Por favor selecione o corredor" class="inputField">
                <nz-select id="selComplexo"
                  class="sl__form__inputFieldSelect inputFieldSelect"
                  [nzSize]="'large'"
                  [compareWith]="compareFn"
                  formControlName="corredor"
                  [nzLoading]="isRegionsLoading">
                  <nz-option *ngFor="let region of regions" nzLabel="{{ region.description | titlecase }}"
                    nzValue="{{ region.id }}" ></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item class="threeColumn" style="flex-direction: row !important;">
              <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="complexo" nzRequired>
                <span class="sl-form-label">Complexo</span>
              </nz-form-label>
              <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Por favor selecione o complexo" class="inputField">
                <nz-select id="selComplexo"
                  class="sl__form__inputFieldSelect inputFieldSelect"
                  [nzSize]="'large'"
                  [compareWith]="compareFn"
                  formControlName="complexo"
                  [nzLoading]="isComplexesLoading">
                  <nz-option *ngFor="let complex of complexes" nzLabel="{{ complex.description | titlecase }}"
                    nzValue="{{ complex.id }}"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item class="threeColumn" style="flex-direction: row !important;">
              <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="unidadeOperacional" nzRequired>
                <span class="sl-form-label">Unidade Operacional</span>
              </nz-form-label>
              <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Por favor selecione a unidade operacional" class="inputField">
                <nz-select id="selComplexo"
                  class="sl__form__inputFieldSelect inputFieldSelect"
                  [nzSize]="'large'"
                  [compareWith]="compareFn"
                  formControlName="unidadeOperacional"
                  [nzLoading]="isOperacionalUnitLoading">
                  <nz-option *ngFor="let unit of operacionalUnits" nzLabel="{{ unit.description | titlecase }}"
                    nzValue="{{ unit.id }}"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>

          </div>

         <!-- ARQUIVOS NECESSARIOS -->
         <h2 class="card--title__h2" *ngIf="documents.length > 0">Arquivos necessários</h2>

          <nz-alert *ngIf="documents.length > 0" nzType="warning"  id="alertWarning"
            nzDescription="Os arquivos listados abaixo são obrigatórios para análise do time de licenciamento."
            [nzIcon]="customIconTemplate" nzShowIcon>
          </nz-alert>
          <!-- ICONE CUSTOMIZADO -->
          <ng-template #customIconTemplate>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M12.0003 8.99996V13V8.99996ZM10.2503 4.18996L2.6303 18C2.45398 18.3053 2.36153 18.6519 2.36231 19.0045C2.36309 19.3571 2.45708 19.7032 2.63475 20.0078C2.81242 20.3124 3.06745 20.5645 3.374 20.7388C3.68054 20.913 4.02771 21.0031 4.3803 21H19.6203C19.9729 21.0031 20.3201 20.913 20.6266 20.7388C20.9332 20.5645 21.1882 20.3124 21.3659 20.0078C21.5435 19.7032 21.6375 19.3571 21.6383 19.0045C21.6391 18.6519 21.5466 18.3053 21.3703 18L13.7503 4.18996C13.5773 3.87735 13.3238 3.61679 13.0161 3.43534C12.7083 3.2539 12.3576 3.1582 12.0003 3.1582C11.643 3.1582 11.2923 3.2539 10.9845 3.43534C10.6768 3.61679 10.4233 3.87735 10.2503 4.18996Z"
                stroke="#E9AB13" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12.0502 17H11.9502" stroke="#E9AB13" stroke-width="2.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </ng-template>
          <div class="document mt-10" *ngFor="let document of documents">
            <div class="documentStatusIcon" *ngIf="document.statusUpload === 'uploded' ||document.statusUpload === 'ok'">
              <app-svg-icon
                *ngIf="document.name"
                icon="confirmed_green-icon"
                class="iconAlignCenter"
                [size]="16"
              ></app-svg-icon>
            </div>
            <div class="documentStatusIcon" *ngIf="document.statusUpload === 'error'">
              <img  src="assets/icons/cross-red.svg" class="iconAlignCenter">
            </div>
            <div class="documentName {{ document.statusUpload === '' ? 'upload' : document.statusUpload }}">{{ document.name }}</div>
            <div class="documentActions">
              <div class="icon documentActionButon" *ngIf="document.statusUpload !=='uploded' && document.statusUpload !=='ok' " >
                <nz-upload [(nzFileList)]="fileList"  (nzChange)="onChangeFile($event, document)" [nzShowUploadList]="false">
                  <app-svg-icon [size]="16" icon="upload_document-icon" class="iconAlignCenter"></app-svg-icon>
                </nz-upload>
              </div>
              <div class="icon"
                   *ngIf="document.statusUpload==='uploded' && editMode"
                   (click)="downloadFileByIdAndName(document.fieluid, document.fileName)">
                <app-svg-icon [size]="16" icon="download_document-icon" class="iconAlignCenter" ></app-svg-icon>
              </div>
              <div class="icon"
                   *ngIf="document.statusUpload==='ok' || document.statusUpload==='uploded' || document.statusUpload==='error'" nz-popconfirm style="margin: 2px" nzPopconfirmTitle="{{ 'cancelationMessage' | translate }}"
                   (nzOnConfirm)="deleteMandatoryDoc(document)">
                <app-svg-icon [size]="16" icon="trash_document-icon" class="iconAlignCenter" ></app-svg-icon>
              </div>
            </div>
          </div>

          <!-- ARQUIVOS ADICIONAIS -->
          <h2 class="card-optional-title__h2">Arquivos adicionais</h2>

            <!-- LISTA DE ARQUIVOS ADICIONAIS E NZ-UPLOAD -->
            <div nz-row style="width: 100%;">
              <nz-upload id="btnUpArquivoV2Additional"
                          nzType="drag"
                          [nzMultiple]="true"
                          [nzBeforeUpload]="beforeUploadAdditional"
                          [(nzFileList)]="fileListAdditional"
                          [nzRemove]="removeFilesAdditional"
                          nzListType="picture"
                          [nzDownload]="downloadFileAdditional"
                          class="uploadProjectFilesAdditional">
                <p class="ant-upload-drag-icon" #uploadInput>
                  <img src="assets/icons/upload-v2-icon.svg" alt="upload" />
                </p>
                <p class="ant-upload-hint">
                  <span class="text-upload">{{ 'projects.assessment.drag-drop' | translate }}</span>
                </p>
              </nz-upload>
            </div>

        </div>

      </div>

    </div>



    <!-- CARD RESUMO REVISAO RIPLA -->
    <div nz-col nzSpan="9">
      <div class="rounded-row-resume">
        <app-requisition-resume [resumeData]="resumeData"></app-requisition-resume>
      </div>

    </div>

  </div>

</div>

<div class="spinner" *ngIf="loadingSubProjects">
  <nz-spin nzSimple id="spinner"></nz-spin>
</div>
