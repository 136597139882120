import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ReviewResponse } from 'src/app/shared/models/project-v2-ripla-review-request.model';

import { SUB_PROJECT_REVIEWS, SUB_PROJECT_REVIEWS_CANCEL } from 'src/app/modules/projects/constants/apiEndpointsConstants';

@Injectable({
  providedIn: 'root'
})
export class ProjectV2RiplaReviewRequestService {
  baseUrl: string = environment.apiUrl;

  constructor(
    private readonly http: HttpClient
  ) { }

  getSubProjectReviews(id: number, idSubProject: number): Observable<any> {
    return this.http.get<ReviewResponse>(`${SUB_PROJECT_REVIEWS}/${id}/${idSubProject}`)
  }

  patchCancelSubProjectReview(id: number): Observable<any> {
    return this.http.patch<ReviewResponse>(`${SUB_PROJECT_REVIEWS_CANCEL}/${id}`, id)
  }
}
