export const DOCUMENTS_MOCK = [
  {
    folderId: 1,
    name: 'Cerca Sirenes Barragens na APA do Gelado',
    complexName: 'Norte',
    user: {
      id: 2,
      name: 'ANDRÉ BATISTA',
      email: 'andre.batista@vale.com',
    },
    creationDate: '2023-02-10T00:00:00',
    updateDate: '2023-02-10T00:00:00',
    isBookmarked: true,
  },
  {
    folderId: 2,
    name: 'Compensação Ambiental da Mina N4/N5',
    complexName: 'Sul',
    user: {
      id: 3,
      name: 'FERNANDA SANTOS',
      email: 'fernanda.santos@vale.com',
    },
    creationDate: '2023-04-24T00:00:00',
    updateDate: '2023-04-24T00:00:00',
    isBookmarked: false,
  },
  {
    folderId: 3,
    name: 'Diagnóstico Ambiental Preliminar S16',
    complexName: 'Norte',
    user: {
      id: 1,
      name: 'MAURO MENDES',
      email: 'mauro.mendes@vale.com',
    },
    creationDate: '2022-05-10T00:00:00',
    updateDate: '2022-05-10T00:00:00',
    isBookmarked: true,
  },
];


export const DOCUMENT_MOCK_NIVEL_1 = [
  
  {
      id: 1,
      name: "Cerca Sirenes Barragens na APA do Gelado",
      complexName: null,
      user: {
          id: 2,
          name: "ANDRÉ BATISTA",
          email: "andre.batista@vale.com"
      },
      creationDate: "2023-02-10T00:00:00",
      updateDate: "2023-08-14T00:00:00",
      isBookmarked: false
  },
  {
      id: 2,
      name: "Compensação Ambiental da Mina N4/N5",
      complexName: null,
      user: {
          id: 3,
          name: "FERNANDA SANTOS",
          email: "fernanda.santos@vale.com"
      },
      creationDate: "2023-04-24T00:00:00",
      updateDate: "2023-08-14T00:00:00",
      isBookmarked: false
  },
  {
      id: 3,
      name: "Diagnóstico Ambiental Preliminar S16",
      complexName: null,
      user: {
          id: 1,
          name: "MAURO MENDES",
          email: "mauro.mendes@vale.com"
      },
      creationDate: "2022-05-10T00:00:00",
      updateDate: "2023-08-14T00:00:00",
      isBookmarked: false
  }
]


export const DOCUMENTS_MOCK_NIVEL_2_B = {
  id: 1,
  name: "DIRETÓRIO DO PROJETO",
  subfolders: [
      {
          id: 2,
          name: "DIRETÓRIO DA DEMANDA",
          children: null,
          attachments: null,
          user: {
              id: 2,
              name: "ANDRÉ BATISTA",
              email: "andre.batista@vale.com"
          },
          isBookmarked: false,
          updateDate: "2023-08-14T00:00:00",
          creationDate: "2023-02-10T00:00:00"
      }
  ],
  attachments: [
      {
          id: 2,
          entityId: 1,
          entityName: "PROJECT",
          name: "Anexo_A",
          description: "Descricao Anexo A",
          isBookmarked: false,
          date: "2023-05-04T00:00:00",
          user: {
              id: 1,
              name: "MAURO MENDES",
              email: "mauro.mendes@vale.com"
          }
      },
      {
          id: 3,
          entityId: 1,
          entityName: "PROJECT",
          name: "Anexo_B",
          description: "Descricao Anexo B",
          isBookmarked: false,
          date: "2023-05-03T00:00:00",
          user: {
              id: 1,
              name: "MAURO MENDES",
              email: "mauro.mendes@vale.com"
          }
      },

  ],
  user: {
    id: 2,
    name: "ANDRÉ BATISTA",
    email: "andre.batista@vale.com"
},
  isBookmarked: null,
  updateDate: "2023-08-14T00:00:00",
  creationDate: "2023-02-10T00:00:00"
}

export const DOCUMENTS_MOCK_nivel_2 =
{
  files:[
    {
      id: 1,
      project: 'GATITOS 1',
      complex: 'Serra Norte',
      updateDate: '2023-03-06',
      deploymentDate: '2023-02-28',
      responsible: 'don gato',
      favorite: true,
    },
    {
      id: 2,
      project: 'GATITOS 2',
      complex: 'Serra Norte',
      updateDate: '2023-03-06',
      deploymentDate: '2023-02-28',
      responsible: 'don gato',
      favorite: true,
    },
    {
      id: 3,
      project:'GATITOS 3',
      complex: 'Serra Norte',
      updateDate: '2023-03-06',
      deploymentDate: '2023-02-28',
      responsible: 'don gato',
      favorite: false,
      isfile:false,
  
    },
    {
      id: 4,
      project:'GATITOS 4',
      complex: 'Serra Norte',
      updateDate: '2023-03-06',
      deploymentDate: '2023-02-28',
      responsible: 'don gato',
      favorite: true,
      isfile:false,
  
    },
    {
      id: 5,
      project:'GATITOS 5',
      complex: 'Serra Norte',
      updateDate: '2023-03-06',
      deploymentDate: '2023-02-28',
      responsible: 'ANDRÉ BATISTA',
      favorite: false,
      isfile:false,
  
    },
    {
      id: 6,
      project:'GATITOS 6',
      complex: 'Serra Norte',
      updateDate: '2023-03-06',
      deploymentDate: '2023-02-28',
      responsible: 'ANDRÉ BATISTA',
      favorite: false,
      isfile:false,
  
    },
    {
      id: 7,
      project: 'Projeto Reintroduções de espécies endêmicas da Flora de Carajás',
      complex: 'Serra Norte',
      updateDate: '2023-03-06',
      deploymentDate: '2023-02-28',
      responsible: 'ANDRÉ BATISTA',
      favorite: false,
      isfile:false,
    },
  ],
  attachements:[
    {
      id: 14,
      isfile: true,
      name: "test.pdf",
      link:"D",
      description: "test.pdf",
      complex: 'Serra Norte',
      updateDate: '2023-03-06',
      deploymentDate: '2023-02-28',
      responsible: 'ANDRÉ BATISTA',
      favorite: false,
  }
  ]
}
