import {Component, inject} from '@angular/core';
import {NzModalRef} from "ng-zorro-antd/modal";

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent{
  errorMessage: string;
  readonly #modal = inject(NzModalRef);
  subTitle: string;

  constructor() { }


  handleOk() {
    this.#modal.destroy();
  }
}
