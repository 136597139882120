import {Component, inject, Input, OnInit} from '@angular/core'
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms'
import {NzModalRef, NzModalService} from "ng-zorro-antd/modal";
import {DisciplineAssessmentDto} from 'src/app/shared/dto/discipline-assessment.dto'
import {MessageService} from 'src/app/shared/common/services/message.service'
import {NlpModalEvaluateSectionService} from './service/nlp-modal-evaluate-section.service'
import {SectionEvaluation} from '../../interface/nlp-interface'
import {SectionEvaluationDto} from 'src/app/shared/dto/section-evaluation.dto';
import {UserService} from "../../../../shared/services/user.service";
import {UserModel} from "../../../../shared/models/user.model";


@Component({
  selector: 'app-nlp-modal-evaluate-section',
  templateUrl: './nlp-modal-evaluate-section.component.html',
  styleUrls: ['./nlp-modal-evaluate-section.component.scss']
})
export class NlpModalEvaluateSectionComponent implements OnInit {

  @Input() evaluation?: boolean;
  @Input() sectionId?: number;
  @Input() selectedAssessment?: DisciplineAssessmentDto

  readonly #modal = inject(NzModalRef);
  section: SectionEvaluation | undefined;
  form!: UntypedFormGroup;
  user: UserModel;

  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly modal: NzModalService,
    private readonly message: MessageService,
    private nlpModalEvaluateSectionService: NlpModalEvaluateSectionService,
    private userService: UserService
  ) {
  }

  ngOnInit(): void {
    this.initForm();
    this.userService.getLoogedUser().subscribe({
      next: (user: UserModel) => {
        this.user = user;
      }
    })
  }

  initForm(): void {
    this.form = this.fb.group({
      comments: [null, [Validators.required]]
    });
  }

  submitForm(): void {
    this.evaluateSection(this.evaluation!);
  }

  private buildPayload(): SectionEvaluationDto {
    return {
      idNlpSections: this.sectionId!,
      userId: this.user.id !== undefined ? this.user.id : 1,
      isApproved: this.evaluation!,
      comments: this.form.value.comments
    };
  }

  evaluateSection(evaluation: boolean): void {
    const sectionEvaluation: SectionEvaluationDto = this.buildPayload();

    this.nlpModalEvaluateSectionService.postSectionEvaluation(sectionEvaluation)
      .subscribe(result => this.onSuccess()
        , error => this.onError(error));
  }

  private onSuccess() {
    this.message.showSucessMessage(this.evaluation ? "Aprovação da seção do estudo enviada!" : "Reprovação da seção do estudo enviada!");
    this.close();
  }

  private onError(error: any) {
    this.message.showErrorMessage(`Erro ao avaliar seção: ${error.message}`);
  }

  showCancelConfirm(): void {
    if (this.form.dirty) {
      this.modal.confirm({
        ...(this.message.showMessageModalConfirmCancel() as any),
        nzOnOk: () => this.close(),
      });
    } else {
      this.close();
    }
  }

  private close() {
    this.#modal.close(true);
  }

  handleCancel(): void {
    this.#modal.triggerCancel();
  }
}
