import { Component, type OnInit } from '@angular/core';
import { ActivePageService } from 'src/app/shared/common/services/active-page/active-page.service';
import { TopBreadcrumbService } from 'src/app/shared/common/services/top-breadcrumb/top-breadcrumb.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
  constructor(private service: UserService, private activePage:ActivePageService, private topBreadcrumb: TopBreadcrumbService,) {}

  flagTopMenu = false;

  ngOnInit(): void {
    this.activePage.setPage('documents');

    this.service.flagTopMenu$.subscribe((flag) => {
      this.flagTopMenu = flag;
    });

    this.topBreadcrumb.reorgLevels({
      level: 1,
      title: 'Documentos',
      route: ''
    });
  }
}
