<div nz-row class="fullPage">
  <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">

    <h2 class="card--title_h2">{{ 'projects.requisition.resume.title' | translate}}</h2>
    <h3 class="card--title_h3">{{ 'projects.requisition.resume.basics.informations' | translate}}</h3>

    <div *ngIf="!resumeData.showResume" class="boxHeight"
      style="display: flex; align-items: center; justify-content: center;">
      <nz-empty nzNotFoundImage="simple" [nzNotFoundContent]="contentTpl"></nz-empty>
      <ng-template #contentTpl>
        <span [innerHTML]="'projects.requisition.resume.no.informations' | translate">
        </span>
      </ng-template>
    </div>

    <div *ngIf="resumeData.showResume" class="boxHeight">
      <div class="resume-item">
        <p class="resume-item__title">Nome do Projeto</p>
        <p class="resume-item__description">
          {{ resumeData.projectName ? resumeData.projectName : '-' }}
        </p>
      </div>

      <div class="resume-item">
        <p class="resume-item__title">Código do Projeto</p>
        <p class="resume-item__description">
          {{ resumeData.projectCode ? resumeData.projectCode : '-' }}
        </p>
      </div>

      <div class="resume-item">
        <p class="resume-item__title">Localização</p>
        <p class="resume-item__description">
          {{ resumeData.corredor ? (resumeData.corredor +
          (resumeData.complexo !== undefined ? resumeData.complexo : '-') +
          (resumeData.unidadeOperacional !== undefined ? resumeData.unidadeOperacional : '-')) : '-' }}
        </p>
      </div>

      <div class="resume-item">
        <p class="resume-item__title">Área solicitante</p>
        <p class="resume-item__description">
          {{ resumeData.requestingArea ? resumeData.requestingArea : '-' }}
        </p>
      </div>

      <div class="resume-item">
        <p class="resume-item__title">Responsável</p>
        <p class="resume-item__description">
          {{ (resumeData.responsavelAreaSolicitante ? (resumeData.responsavelAreaSolicitante | titlecase) : '-') }}
        </p>
      </div>

      <div class="resume-item">
        <p class="resume-item__title">Classificação do projeto</p>
        <p class="resume-item__description">
          {{ resumeData.projectClassification ? resumeData.projectClassification : '-' }}
        </p>
      </div>

      <div class="resume-item">
        <p class="resume-item__title">Breve descrição / Objetivo do Projeto</p>
        <p class="resume-item__description">
          {{ resumeData.comments ? resumeData.comments : '-'}}
        </p>
      </div>
    </div>

    <p class="subTituloProjeto">Documentos para análise</p>

    <div class="document mt-05" *ngFor="let document of resumeData.documents">
      <div class="documentOk">
        <img
          *ngIf="(document.statusUpload === 'ok' || document.statusUpload === 'uploded')"
          class="iconAlignCenter" [width]="16" [height]="16" src=" {{ getIconFromFileName(document.fileName) }}" alt="some file"
        />
        <app-svg-icon *ngIf="(document.statusUpload !== 'ok' && document.statusUpload !== 'uploded') "
          icon="atention-yellow-icon" [width]="16" [height]="16" class="iconAlignCenter"></app-svg-icon>
      </div>
      <div class="documentName" *ngIf="!document.fileName"
        [ngClass]="document.statusUpload !== 'ok' ? 'gray' : 'green'">
        {{ document.statusUpload !== 'ok' ? "Adicione o " : '-' }}
        {{ document.id === 2 ? "apêndice " : '-' }}
        {{ document.name}}
      </div>
      <div class="documentName" nz-tooltip nz-element #filename="nzElement" nzTooltipTitle="{{document.fileName}}"
        *ngIf="document.fileName"
        [ngClass]="document.statusUpload === 'ok' || document.statusUpload === 'uploded' ? 'green' : 'gray'">
        {{document.fileName.length > 40 ? (document.fileName | slice:0:30) + '...' + (document.fileName | slice: document.fileName.length - 10 : document.fileName.length)
        : document.fileName}}
      </div>
    </div>
  </div>
</div>
