import {Component, Input, OnInit} from '@angular/core';
import {SubProjectByIdResponse} from "../../../../shared/dto/subproject-by-id-response.dto";
import {FinalAnalysisService} from "../../../../shared/services/final-analysis.service";
import {FinalAnalysis} from "../../../../shared/dto/final-analysis-reponse.dto";
import {AttachmentsService} from "../../../../shared/services/attachments.service";
import {AttachmentsModel} from "../../../../shared/models/attachments.model";
import {FINAL_ANALYSIS_ENTITY_NAME} from "../../../projects/constants/projectsNewConstants";
import {UtilService} from "../../../../shared/util.service";

@Component({
  selector: 'app-final-opinion',
  templateUrl: './final-opinion.component.html',
  styleUrls: ['./final-opinion.component.scss']
})
export class FinalOpinionComponent implements OnInit {
  @Input() subProject!: SubProjectByIdResponse;
  @Input() finalAnalysisResponse: FinalAnalysis;

  riplaFinalAnalisys: boolean = false;
  attachments: AttachmentsModel[];
  customStyle = {
    'font-size': '12px',
    'width': '300px',
    'max-width': '300px',
    'max-heigth': 'auto'
  };

  constructor(private finalAnalysisService: FinalAnalysisService,
              private readonly utilService: UtilService,
              private attachmentsService: AttachmentsService) {
  }

  ngOnInit(): void {
    this.riplaFinalAnalisys = this.utilService.getAuthorization('edit', 'RIPLAFINALPROTOCOL')
    if (!this.finalAnalysisResponse) {
      this.getFinalAnalysis();
    }
  }

  getFinalAnalysis(): void {
    this.finalAnalysisService.getFinalAnalysis(this.subProject.id).subscribe({
      next: (response) => {
        if(response){
          this.finalAnalysisResponse = response
          if(!this.checkIfIsSketch() || this.subProject.status === 'CON'){
            this.getAttachments();
          }
        }
      }
    });
  }

  checkIfIsSketch() {
    return this.finalAnalysisResponse.asmtLicenseRequired !== null ||
      this.finalAnalysisResponse.asmtRestrictions !== null ||
      this.finalAnalysisResponse.asmtPriority !== null;
  }

  downloadFile(id: number | undefined, name: string): void {
    if (id === undefined) return;

    this.attachmentsService.getDownloadFile(id).subscribe({
      next: (resp) => this.attachmentsService.downloadFile(resp, name),
    });
  }

  getHintText(fileName: string): string {
    if (fileName.length > 60) {
      return fileName
    }
    return "";
  }

  private getAttachments(): void {
    this.attachmentsService.getAttachmentsByEntityIdEntityNameAndContextInfo(this.subProject.id, FINAL_ANALYSIS_ENTITY_NAME, 'Parecer Final').subscribe({
      next: (attachments) => {
        this.attachments = attachments;
      },
      error: (err) => {
        if(err.status === 400){
          console.warn("Arquivos não encontrados")
        }
      }
    });
  }

  getFileIcon(file: string | undefined): string {
    if(file !== undefined){
      return this.utilService.getIconFromFileName(file);
    }
    return '';
  }
}
