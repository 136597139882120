import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Params} from "@angular/router";
import {NzModalService} from 'ng-zorro-antd/modal';
import {TopBreadcrumbService} from 'src/app/shared/common/services/top-breadcrumb/top-breadcrumb.service';
import {TasksService} from './service/tasks.service';
import {ActivePageService} from 'src/app/shared/common/services/active-page/active-page.service';
import {NewTaskModalComponent} from './new-task-modal/new-task-modal.component';
import {UtilService} from 'src/app/shared/util.service';
import {MessageService} from 'src/app/shared/common/services/message.service';
import {UserService} from 'src/app/shared/services/user.service';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {BreadcrumbState} from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';
import {BREADCRUMB_ACTIONS} from 'src/app/shared/ngrx/breadcrumb/breadcrumb.actions';
import {ProjectState} from 'src/app/shared/ngrx/breadcrumb/model';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<any> = new Subject();
  @ViewChild('contentTemplate') divClick!: ElementRef;
  demandId;
  userId;

  searchValue;
  filterByLicensing: string = 'todos';
  priorityList: any[] = [];
  filterByProject: string = 'todos';
  filterByProjects: any[] = []
  filterByLicensings: any[] = []
  filterByPriority: string = 'todas';

  dateFormat = 'dd/MM/yyyy';
  taskList;
  taskListOriginal: any[] = [];

  taskListAFA: any[] = [];
  taskListEXE: any[] = [];
  taskListCON: any[] = [];

  totalTasksAFA: number = 0;
  totalTasksEXE: number = 0;
  totalTasksCON: number = 0;

  dateToShowColor: number = 0;

  visible: boolean = false;
  bodyFormat: any;
  widthView: number = 0;

  customStyle = {
    background: 'white',
    'font-size': '12px',
    color: ' #FFF'
  };

  constructor(
    private readonly modal: NzModalService,
    private readonly message: MessageService,
    private route: ActivatedRoute,
    private viewContainerRef: ViewContainerRef,
    private topBreadcrumb: TopBreadcrumbService,
    private tasksService: TasksService,
    private userService: UserService,
    private activePage: ActivePageService,
    private utilService: UtilService,
    private store: Store<BreadcrumbState>,
  ) { }

  ngOnInit(): void {
    this.activePage.setPage('tasks');
    this.updateBradcrumb();
    this.getLoggedUser();
    this.widthView = window.innerWidth;
    this.dispatchProjectOnStore()
  }


  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private dispatchProjectOnStore(): void {
    let project: ProjectState = {
      name: 'Tarefas',
      url: '/tasks',
      project: null,
      sublevels: false,
      subProject: null,
      parecerFinal: null,
      pedidoRevisao: null,
      comunicacoes: null,
      processos: null,
      atividades: null,
      subatividades: null,
      mudancaEscopo: null
    }
    this.store.dispatch(BREADCRUMB_ACTIONS.getRiplaProject({ project }));
  }

  replaceItalicTag(value: string): string {
    let removeItalicTag = /<\/*i>/g;
    return value.replace(removeItalicTag, "");
  }

  checkLengthString(value: string): string {
    if (value.length > 108) {
      return value + '...';
    }
    return value;
  }

  checkWindowResolution(value: string): boolean {
    if (this.widthView >= 1920) {
      return value.length > 108;
    } else {
      return value.length > 75;
    }
  }

  getLoggedUser() {
    const sessionUser = sessionStorage.getItem("user")
    this.userService.getLoogedUser().subscribe(data => {
      if (data) {
        sessionStorage.setItem("loggedUser", JSON.stringify(data));
        this.userId = data.id ? data.id : "0";
        this.getTasks();
      }
    })
  }

  getFilters() {
    // BUSCA DADOS FILTRO PRIORIDADE
    this.priorityList = this.tasksService.priorityList;
    this.getFilterProjects()
    this.getFilterLicensings()
  }

  updateBradcrumb() {
    this.route.params.subscribe((params: Params) => {
      this.demandId = +params['id'];
    });
    this.topBreadcrumb.reorgLevels({
      level: 0,
      title: 'Página Inicial',
      route: `tasks/`,
    });
    this.topBreadcrumb.reorgLevels({
      level: 1,
      title: 'Minhas Tarefas',
      route: `tasks`,
    });
  }

  private getTasks(): void {
    this.taskList = []
    this.taskListAFA = []
    this.taskListCON = []
    this.taskListEXE = []
    this.tasksService.getTasksList(this.userId).subscribe((tasks) => {
      this.taskList = tasks['content'];
      this.taskListOriginal = tasks['content'];
      this.getFilters();
      this.taskList.forEach(taskItem => {
        if (taskItem.status === 'EXE' || taskItem.status === 'AND') {

          const newDateDeadline = this.getNewDateDeadline(taskItem.deadline)
          taskItem.deadlinetToShow = this.formatDateNewDeadline(taskItem.deadline)
          taskItem.dateToShowColor = newDateDeadline
          taskItem.deadline = taskItem.deadline + 'T00:00:00';

          this.taskListEXE.push(taskItem)
          this.totalTasksEXE += 1

        } else if (taskItem.status === 'AFA' || taskItem.status === 'INI') {
          const newDateDeadline = this.getNewDateDeadline(taskItem.deadline)
          taskItem.deadlinetToShow = this.formatDateNewDeadline(taskItem.deadline)
          taskItem.dateToShowColor = newDateDeadline
          taskItem.deadline = taskItem.deadline + 'T00:00:00';

          this.taskListAFA.push(taskItem)

          this.totalTasksAFA += 1

        } else {
          const newDateDeadline = this.getNewDateDeadline(taskItem.deadline)
          taskItem.deadlinetToShow = this.formatDateNewDeadline(taskItem.deadline)
          taskItem.dateToShowColor = newDateDeadline
          taskItem.deadline = taskItem.deadline + 'T00:00:00';

          this.taskListCON.push(taskItem)

          this.totalTasksCON += 1
        }
      });
    });
  }

  getNewDateDeadline(deadline: string) {
    let date1 = new Date();
    let date2 = new Date(deadline);
    date1.setHours(0, 0, 0, 0)
    let timeDiff = Math.round(date2.getTime() - date1.getTime());
    this.dateToShowColor = Math.ceil(timeDiff / (1000 * 3600 * 24))
    return this.dateToShowColor
  }

  formatDateNewDeadline(deadline) {
    let newDate = deadline.split('-')
    newDate = (newDate[0] + '/' + newDate[1] + '/' + newDate[2])
    return new Date(newDate)
  }

  showAddTaskModal(): void {
    const modal = this.modal.create<NewTaskModalComponent>({
      nzTitle: 'Nova tarefa',
      nzWidth: 780,
      nzContent: NewTaskModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
      nzOnCancel: (instance: NewTaskModalComponent) => new Promise((resolve, reject) => {
        if (instance.form.dirty) {
          instance.showCancelConfirm();
          reject(new Error('Network timeout'));
        }

        resolve();
      }),
    });
    modal.getContentComponent();
    modal.afterClose.subscribe(() => {
      this.getTasks();
    });
  }

  searchFilter(): void {
    this.taskList = this.taskListOriginal.filter((element) => {
      const description = element.description || '';
      return (
        description ? description.toLowerCase().includes(this.searchValue.toLowerCase()) : null
      );
    });

    this.updateTaskListFilter(this.taskList)
  }

  filterByProjectElements() {
    if (this.filterByProject === "todos") {
      this.taskList = this.taskListOriginal;
      this.updateTaskListFilter(this.taskList)
      return;
    }
    this.taskList = this.taskListOriginal.filter(
      (result) => result.projectName === this.filterByProject
    );
    this.updateTaskListFilter(this.taskList)
  }

  filterByLicensingElements() {
    if (this.filterByLicensing === "todos") {
      this.taskList = this.taskListOriginal;
      this.updateTaskListFilter(this.taskList)
      return;
    }
    this.taskList = this.taskListOriginal.filter(
      (result) => result.licenceName === this.filterByLicensing
    );
    this.updateTaskListFilter(this.taskList)
  }

  private getFilterProjects() {
    const jsonData = this.taskListOriginal;

    this.filterByProjects = [...new Set(jsonData.map(item => item.projectName))].filter(item => item);
  }

  private getFilterLicensings() {
    const jsonData = this.taskListOriginal;

    this.filterByLicensings = [... new Set(jsonData.map(item => item.licenceName))].filter(item => item);
  }

  filterPriorityElements() {
    if (this.filterByPriority === "todas") {
      this.taskList = this.taskListOriginal;
      this.updateTaskListFilter(this.taskList)
      return;
    }
    const varTeste: boolean = this.filterByPriority === "SIM"
    this.taskList = this.taskListOriginal.filter(
      (result) => result.priority === varTeste
    );
    this.updateTaskListFilter(this.taskList)
  }

  filterDateElements(comp) {
    const datas = comp.inputValue;

    if (datas && datas.length >= 2) {
      //tratamento de conversão por estar usando a data do inputvalue do zorro e não a data do event
      const [day1, month1, year1] = datas[0].split('/').map(Number);
      const date1 = new Date(year1, month1 - 1, day1);
      const [day2, month2, year2] = datas[0].split('/').map(Number);
      const date2 = new Date(year2, month2 - 1, day2);
      const dateInitial = this.utilService.DateFormatter(date1.toString())
      const dateFinal = this.utilService.DateFormatter(date2.toString())
      this.taskList = this.taskListOriginal.filter((result) => {
        const currentDate = this.utilService.DateFormatter(result.deadline);
        return currentDate >= dateInitial && currentDate <= dateFinal;
      });
      this.taskList.forEach((element, i) => {
        this.taskList[i].deadline = element.deadline.replaceAll('undefined/', '')
      });
      this.updateTaskListFilter(this.taskList);
    }
  }

  updateTaskListFilter(taskList) {
    this.totalTasksAFA = 0
    this.totalTasksCON = 0
    this.totalTasksEXE = 0
    this.taskListAFA = []
    this.taskListCON = []
    this.taskListEXE = []
    taskList.forEach(element => {
      if (element.status === 'EXE' || element.status === 'AND') {

        const newDateDeadline = this.getNewDateDeadline(element.deadline)
        element.deadlinetToShow = this.formatDateNewDeadline(element.deadline)
        element.dateToShowColor = newDateDeadline

        this.taskListEXE.push(element)
        this.totalTasksEXE += 1

      } else if (element.status === 'AFA' || element.status === 'INI') {
        const newDateDeadline = this.getNewDateDeadline(element.deadline)
        element.deadlinetToShow = this.formatDateNewDeadline(element.deadline)
        element.dateToShowColor = newDateDeadline

        this.taskListAFA.push(element)

        this.totalTasksAFA += 1

      } else {
        const newDateDeadline = this.getNewDateDeadline(element.deadline)
        element.deadlinetToShow = this.formatDateNewDeadline(element.deadline)
        element.dateToShowColor = newDateDeadline

        this.taskListCON.push(element)

        this.totalTasksCON += 1
      }
    });
  }

  getActiveTask(section): boolean {
    return true;
  }

  alteraStatusTask(task, taskStatus): void {
    let texts = document.getElementsByClassName("ant-popover-inner-content") as HTMLCollectionOf<HTMLElement>;

    if (texts.length > 0) {
      texts[0].style.display = 'none';
    }

    const bodyReq = {
      "id": task.id,
      "status": taskStatus,
    }
    this.tasksService.updateTaskStatus(bodyReq).subscribe({
      next: () => {
        this.getTasks()
        this.message.showSucessMessage('Status alterada com sucesso!');
        this.updateTaskListFilter(this.taskList)
      },
      error: () => {
        this.tasksService.showErrorMessage('Erro ao alterar status');
      }
    })

  }

  priorityChange(idTask) {
    const newPriority = !idTask.priority
    const bodyReq = {
      "id": idTask.id,
      "priority": newPriority,
    }
    this.tasksService.updateTaskPriority(bodyReq).subscribe({
      next: () => {
        this.getTasks()
        this.message.showSucessMessage('Prioridade alterada com sucesso!');
        this.updateTaskListFilter(this.taskList)
      },
      error: () => {
        this.tasksService.showErrorMessage('Erro ao alterar prioridade');
      }
    })

  }

  onChangeDeadline(e: any, task: any) {
    let dateChange = this.utilService.DateFormatter(e).split("T")
    if (dateChange[0] !== task.deadline) {
      const bodyReq = {
        "id": task.id,
        "deadline": dateChange[0],
      }

      this.tasksService.updateTaskDeadline(bodyReq).subscribe({
        next: () => {
          this.getTasks()
          this.message.showSucessMessage('Data prazo alterada com sucesso!');
          this.updateTaskListFilter(this.taskList)
        },
        error: () => {
          this.tasksService.showErrorMessage('Erro ao alterar data prazo');
        }
      })
    }
  }

  sanitizeInputAllowOnlyItalicTag(text: string): string{
    return this.utilService.sanitizeInputAllowOnlyItalicTag(text);
  }
}
