import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-send',
  templateUrl: './button-send.component.html',
  styleUrls: ['./button-send.component.scss']
})
export class ButtonSendComponent implements OnInit {

  @Input() id?: string = '';
  @Input() name?: string = 'Enviar';
  @Input() width?: string = '150';

  constructor() { }

  ngOnInit(): void { return;}

}
