import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActivePageService {

  constructor() { }


  private activePage = new BehaviorSubject<string>('Initial Value');

  setPage(newPage) {
    this.activePage.next(newPage);
  }

  getPage() {
    return this.activePage.asObservable();
  }
}
