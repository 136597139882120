import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-button-add-linked-process',
  templateUrl: './button-add-linked-process.component.html',
  styleUrls: ['./button-add-linked-process.component.scss']
})
export class ButtonAddLinkedProcessComponent {
  @Output() public newHandler: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  newLink() {
    this.newHandler.emit(true);
  }

}
