<div nz-row nz-col nzSpan="24">

  <div nz-col nzSpan="24" class="modalBodyText">
    <h1 class="titulo">Deseja mesmo excluir este processo?</h1>
    <small class="textoFixo" >As informações contidas no processo serão perdidas e não poderão ser recuperadas.</small>
  </div>

  <div nz-col nzSpan="24" class="modalActions">
    <button
      id="btnCancel"
      type="button"
      class="btnGoBack"
      (click)="handleCancel()">
      Voltar
    </button>
    <button
      id="btnOk"
      type="button"
      class="btnDelete"
      (click)="handleOk()">
      Excluir processo
    </button>
  </div>
</div>
