<form >
  <div class="new-link sl-form" [formGroup]="form">
    <div>
      <nz-form-item class="sl-form-item" style="flex-direction: column;" >
        <nz-form-label nzFor="process">
          <span class="sl-form-label">{{ 'projects.processes.process-title' | translate }}</span>
        </nz-form-label>
        <nz-form-control>
          <input placeholder='Informe o nome do projeto' nz-input id="projecprocesstName" class="inputTxt sl__form__inputTxt"
            formControlName="process" />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div>
      <nz-form-item class="sl-form-item">
        <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="linkType" nzRequired>
          <span class="sl-form-label">{{ 'projects.processes.link-type' | translate }}</span>
        </nz-form-label>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Preenchimento obrigatório" class="inputDrop">
          <nz-select id="selLinkType" class=" sl__form__inputFieldSelect inputFieldSelect" [nzSize]="'large'" nzAllowClear
            nzPlaceHolder="Selecione" formControlName="linkType">
            <nz-option *ngFor="let item of linkTypes" nzLabel="{{ item.name | titlecase }}"
              nzValue="{{ item.id }}"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div>
      <nz-form-item class="sl-form-item">
        <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="linkedProcess" nzRequired>
          <span class="sl-form-label">{{ 'licensing-process.save' | translate }}</span>
        </nz-form-label>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="Preenchimento obrigatório" class="inputDrop">
          <nz-select id="selLinkedProcess" class="sl__form__inputFieldSelect inputFieldSelect" [nzSize]="'large'" nzAllowClear
            nzPlaceHolder="Selecione" formControlName="linkedProcess">
            <nz-option *ngFor="let item of processesList" nzLabel="{{ item.title | titlecase }}"
              nzValue="{{ item.id }}"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div class="actions">
      <div class="btn-trash">
        <app-svg-icon icon="trash_document-icon" class="action-icon mt-10" (click)="deleteItem()" ></app-svg-icon>
      </div>
      <div class="ali-right form-cta">
        <button class="btn-text-green" (click)="handleCancel()" nz-popconfirm  nzPopconfirmTitle="{{ 'commons.confirm-cancel' | translate }}?" (nzOnConfirm)="handleCancel()"> {{ 'commons.cancel' | translate }}</button>
        <button class="btn-sl btn-white" (click)="handleSave()"> {{ 'projects.processes.apply-link' | translate }}</button>      
      </div>
    </div>  
  </div>
</form>
