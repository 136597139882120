import { Component, Input, inject } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { DocumentListService } from '../../documents-list/services/documents-list.service';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-delete-file',
  templateUrl: './delete-file-modal.component.html',
  styleUrls: ['./delete-file-modal.component.scss'],
})
export class DeleteFileModalComponent {
  readonly #modal = inject(NzModalRef);
  @Input() fileId;
  @Input() fileName;

  constructor(
    private documentsService: DocumentListService,
    private message: NzMessageService
  ) {}

  public handleDelete() {
    this.documentsService.deleteFile(this.fileId).subscribe((result) => {
      if(result?.errors) {
        this.message.error('Erro ao excluir arquivo!', {
          nzDuration: 1500,
        });
      } else {
        this.message?.success('Documento excluído!', {
          nzDuration: 2500,
        });
      }
    }, error => {
      console.log("error", error.message)
    });
    this.close();
  }

  public close() {
    this.#modal.close(true);
  }

  public handleCancel(): void {
    this.close();
  }
}
