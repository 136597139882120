import {
  Component,
  Input,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from 'src/app/shared/util.service';

@Component({
  selector: 'app-requisition-resume',
  templateUrl: './requisition-resume.component.html',
  styleUrls: ['./requisition-resume.component.scss'],
})
export class RequisitionResumeComponent {
  @Input() resumeData!: any;

  constructor(
    private translate: TranslateService,
    private readonly util: UtilService,
  ) {}

  getIconFromFileName(filename: string) {
    return this.util.getIconFromFileName(filename);
  }
}
