import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LinkedProcessesDTO, ProcessesListResponseDto } from 'src/app/shared/dto/process-list-response.dto';
import { ProjectsV2Service } from 'src/app/shared/services/projects-v2.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-linked-processes-list',
  templateUrl: './linked-processes-list.component.html',
  styleUrls: ['./linked-processes-list.component.scss']
})
export class LinkedProcessesListComponent implements OnInit {
  @Input() processTitle:string;
  @Input() links: ProcessesListResponseDto[];
  @Input() editLinks:LinkedProcessesDTO[];
  @Output() listUpdate: EventEmitter<any> = new EventEmitter<any>();
  listOfData: any[] = [];
  editMode = false;
  newItem = false;
  outerItem;
  editCache: { [key: string]: { edit: boolean; data: any } } = {};

  constructor(private projectService: ProjectsV2Service, private datePipe: DatePipe) { }

  ngOnInit() {
    this.linksList();
  }

  addNewItem() {
    this.newItem = true;
    const id = this.listOfData ? this.listOfData?.length + 1 : 1;
    let newItem = {
      id,
      organ: '',
      organId: '',
      type: '',
      code: '',
      user:'',
      userId: '',
      date:'',
      status: '',
      docCode:'',
      sendDate:'',
      linkId: '',
      linkType: ''
    }
    this.listOfData = [
      ...this.listOfData,
      newItem
    ]

    this.updateList()
    this.startEdit(id)
  }

  generateList(){
    const id = this.listOfData ? this.listOfData?.length + 1 : 1;
    let newItem = {
      id,
      organ: '',
      organId: '',
      type: '',
      code: '',
      user:'',
      userId: '',
      date:'',
      status: '',
      docCode:'',
      sendDate:'',
      linkId: '',
      linkType: ''
    }

    this.listOfData = [
      ...this.listOfData,
      newItem
    ];
  }

  linksList(){
    if(this.editLinks.length > 0){
      this.editMode = true;

      this.editLinks.forEach((item) => {
        //adiciona na lista
        this.generateList();

        this.editCache[this.listOfData?.length] = { edit: false, data: {} };
        //completa os dados do que já tem na lista
        this.convertList(item, true);
      });
    }
  }

  startEdit(id){
    this.editCache[id].edit = true;
    this.newItem = false;
  }

  updateList() {
    this.listOfData.forEach(item => {
      this.editCache[item.id] = {
        edit: false,
        data: {...item}
      }
    });

    this.listUpdate.emit(this.listOfData);
  }

  convertList(body, edit?){
    if(!edit){
      this.editCache[body.id].edit = false;
    }
    const index = this.listOfData.findIndex(item => item.id === body.id);
    const processIndex = this.links.findIndex(process => process.id === +body.linkedProcess);
    const process = this.links[processIndex];

    if (index !== -1) {
      this.listOfData[index] = {
        id: process.id,
        organ: process.competentOrgan.code,
        type: process.licenses,
        code: process.processNumber ? process.processNumber : '',
        user: process.responsible?.name,
        date: process.protocolDate ? this.datePipe.transform(new Date(process.protocolDate), 'MMM/yyyy', 'pt-BR') : 'Não protocolado',
        status: process.status,
        deadline: process.deadline,
        docCode: process.documentNumber,
        licenseEmissionDate: process.licenseEmissionDate ? this.datePipe.transform(new Date(process.licenseEmissionDate), 'dd/MM/yyyy', 'pt-BR') : 'Não Protocolado',
        linkType: '' //get from form
      }
    } else {
      console.error('Error on match process Id');
    }

    //check best moment to past
    //console.warn('test convert', body);
    this.updateList();
  }

  cancelEdit(id: number) {
    const index = this.listOfData.findIndex(item => item.id === id);
    this.editCache[id] = {
      data: {...this.listOfData[index]},
      edit: false
    };

    this.newItem = false;

  }

  deleteFromList(id){
    const index = this.listOfData.findIndex(item => item.id === id);
      this.listOfData.splice(index, 1);
      delete this.editCache[id];
      this.newItem = false;
  }

  saveEdit(id) {
    const index = this.listOfData.findIndex(item => item.id === id);
    Object.assign(this.listOfData[index], this.editCache[id].data);
    this.editCache[id].edit = false;
    this.newItem = false;

  }
}
