import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';

export interface ISelectSimple {
  id?: number;
  label: string;
  value: any;
}

@Component({
  selector: 'app-select-simple',
  templateUrl: './select-simple.component.html',
  styleUrls: ['./select-simple.component.scss']
})
export class SelectSimpleComponent implements OnInit, OnDestroy  {
  private readonly destroy$: Subject<any> = new Subject();
  
  @Input() controls: any;
  @Input() id: string = '';
  @Input() name: string = '';
  @Input() placeHolder: string = '';
  @Input() errorMessage: string = '';
  @Input() maxTagCount: number = 3;
  @Input() status: any = ' ' || 'error' || 'warning';
  @Input() listOfOption: Array<ISelectSimple> = [];

  @Output() changeValue: EventEmitter<any> = new EventEmitter<any>();
  
  constructor() { }

  ngOnInit(): void {
    this.onChangeValue();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  onChangeValue(): void {
    this.controls.valueChanges
    .pipe(takeUntil(this.destroy$), distinctUntilChanged())
    .subscribe((value) => {
      if (this.controls.value = value) {
        this.changeValue.emit(value);
      }
    });
  }

}
