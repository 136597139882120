<section>
  <header class="header">
    <div class="header__info">
      <div class="header__info__left">
        <a id="back-button" (click)="goBack()">
          <img src="assets/icons/icon_arrow-back.svg" alt="Voltar">
        </a>
        <div class="header__info__left__text">
          <h1 class="header__info__left__text__title">{{ pageTitle }}</h1>
          <p class="header__info__left__text__subtitle" *ngIf="pageId !== ''">ID {{ pageId }}</p>
        </div>
      </div>
      <div class="header__actions">
        <a class="btn-action btn-cancel-communication" (click)="showCancelConfirm()">Cancelar</a>
        <a class="btn-action btn-save-communication" (click)="save()">Salvar</a>
      </div>
    </div>
  </header>
  <div nz-row [nzGutter]="[16, 16]" class="content" (scroll)="onRemoveFocusInput()">
    <div nz-col [nzSpan]="15" [nzXs]="24" [nzLg]="15">
      <app-communication-new [isSaving]="isSaving" (communication)="changeData($event)" 
        (communicationAttachments)="attachmentsUpdated($event)"
      ></app-communication-new>
    </div>
    <div nz-col [nzSpan]="9">
      <app-communication-summary [communication]="communication" [attachments]="communicationAttachments"></app-communication-summary>
    </div>
  </div>
</section>
