<section>
  <div id="hidden-data" *ngIf="showLinks">
    <app-button-add-linked-process (newHandler)="linkedProcessesList.addNewItem()" *ngIf="linkedProcessesList.newItem === false"></app-button-add-linked-process>
    <app-linked-processes-list #linkedProcessesList [processTitle]="processName" [links]="availbleLinks" (listUpdate)="listUpdate($event)" [editLinks]="linksToEdit"></app-linked-processes-list>
  </div>

  <button class="expand-linked-processes" (click)="toggleLinks()" [ngClass]="{ 'active': showLinks }">
    <span class="expand-linked-processes-text" >
      {{ linksToEdit.length > 0 ? linksToEdit.length : '' }} {{ 'licensing-process.linked-licenses' | translate }} &nbsp;
      <svg width="8" height="12" viewBox="0 0 8 12" xmlns="http://www.w3.org/2000/svg" class="linked-process-expand-icon">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M0.292893 11.7071C-0.0976311 11.3166 -0.0976312 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976316 1.31658 -0.0976316 0.683417 0.292893 0.292893C0.683417 -0.0976315 1.31658 -0.0976315 1.70711 0.292893L7.41421 6L1.70711 11.7071C1.31658 12.0976 0.683418 12.0976 0.292893 11.7071Z"
          />
      </svg>
    </span>
  </button>
</section>