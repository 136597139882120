import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BookmarkedFile, BookmarkedFolder, DocumentListItemOrAttachments, DocumentsNivel, HistoricDocuments } from 'src/app/shared/models/documents-list-item.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentListService {
  baseUrl: string = environment.apiUrl;
  constructor(private readonly http: HttpClient) {}

  getDocumentsList(userLogged: number): Observable<DocumentListItemOrAttachments[]> {
    return this.http.get<DocumentListItemOrAttachments[]>(
      `${this.baseUrl}/folder/root/${userLogged}`
    );
  }

  getDocumentsListNivel(id: number, userLogged: number): Observable<DocumentsNivel> {
    return this.http.get<DocumentsNivel>(`${this.baseUrl}/folder/${id}/${userLogged}`);
  }

  downloads(ids: number[] | number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/octet-stream',
    });
    const options = {
      headers: headers,
      responseType: 'blob' as 'json',
    };
    const data = Array.isArray(ids) ? { filesIds: ids } : { filesIds: [ids] };
    return this.http.post<any>(
      `${this.baseUrl}/folder/download-files`,
      data,
      options
    );
  }

  downloadFolders(ids: number[] | number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/octet-stream',
    });
    const options = {
      headers: headers,
      responseType: 'blob' as 'json',
    };
    const data = Array.isArray(ids) ? { foldersIds: ids } : { foldersIds: [ids] };
    return this.http.post<any>(
      `${this.baseUrl}/folder/download-folder`,
      data,
      options
    );
  }

  getHistoric(
    entityId: number,
    entityName: string,
    name: string
  ): Observable<HistoricDocuments[]> {
    return this.http.get<HistoricDocuments[]>(
      `${this.baseUrl}/attachments/file-versions/${entityId}/${entityName}/${name}`
    );
  }
  restoreVersionFile(id: number) {
    return this.http.get<any>(`${this.baseUrl}/folder/restore/${id}`);
  }

  deleteFile(attachmentId: number) {
    return this.http.delete<any>(`${this.baseUrl}/attachments/${attachmentId}`);
  }

  getUrlFlux(attachmentId: number): Observable<string> {
    let link = this.http.get(
      `${this.baseUrl}/attachments/url-flux/${attachmentId}`,
      { responseType: 'text' }
    );
    return link;
  }

  setBookmarkedFile(
    userId: number,
    attachmentId: number
  ): Observable<BookmarkedFile> {
    const params = {
      userId: userId,
      attachmentId: attachmentId,
    };
    return this.http.post<BookmarkedFile>(
      `${this.baseUrl}/bookmarks/attachment`,
      params
    );
  }

  setBookmarkedFolder(userId: number, folderId: number, favorite: boolean): Observable<BookmarkedFolder> {
    const params = {
      userId: userId,
      folderId: folderId,
      favorite: favorite
    };
    return this.http.post<BookmarkedFolder>(
      `${this.baseUrl}/bookmarks/folder`,
      params
    );
  }

  unmakedFileBookmarked(userId: number, attachmentId: number): Observable<BookmarkedFile> {
    const params = {
      userId: userId,
      attachmentId: attachmentId
    };
    return this.http.post<BookmarkedFile>(
      `${this.baseUrl}/bookmarks/attachment`,
      params
    );
  }

  unmakedFolderBookmarked(userId: number, folderId: number): Observable<BookmarkedFolder> {
    const params = {
      userId: userId,
      folderId: folderId,
    };
    return this.http.post<BookmarkedFolder>(
      `${this.baseUrl}/bookmarks/folder`,
      params
    );
  }

}
