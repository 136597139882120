<nz-layout>
  <nz-content>
    <form nz-form [formGroup]="form" class="newRiplaForm">
    <div class="infoRipla">
      <div class="infoRipla--header">
        <h1 class="infoRipla--header_h1">{{ riplaType}}</h1>
      </div>
      <div *ngIf="riplarequisition" class="infoRipla--header_btn">
        <button (click)="showCancelConfirm()" id="btnNavigateBackAction" type="button" class="btn btn--tertiary">
          Cancelar
        </button>
        <button (click)="salvarRascunho()" id="btnSecondaryAction" type="button" class="btn btn--secondary">
          <i class="">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="save">
                <path id="primary"
                  d="M13.3337 4.66667V13.3333C13.3337 13.5101 13.2634 13.6797 13.1384 13.8047C13.0134 13.9298 12.8438 14 12.667 14H3.33366C3.15685 14 2.98728 13.9298 2.86225 13.8047C2.73723 13.6797 2.66699 13.5101 2.66699 13.3333V2.66667C2.66699 2.48986 2.73723 2.32029 2.86225 2.19526C2.98728 2.07024 3.15685 2 3.33366 2H10.667L13.3337 4.66667ZM5.33366 4.66667H6.66699H5.33366ZM10.667 10H5.33366V14H10.667V10Z"
                  stroke="#007E7A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </g>
            </svg>
          </i>
          Salvar Rascunho
        </button>

        <button (click)="realizarAnalise()" id="btnPrimaryAction" type="button" class="btn btn--primary">
          <i class="">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="icon_analysis">
                <path id="primary (Stroke)" fill-rule="evenodd" clip-rule="evenodd"
                  d="M4.0002 0.8C4.0002 0.358172 4.35837 0 4.8002 0H14.4002C14.8245 0 15.2315 0.16857 15.5316 0.468629C15.8316 0.768688 16.0002 1.17566 16.0002 1.6V14.4C16.0002 14.8243 15.8316 15.2313 15.5316 15.5314C15.2315 15.8314 14.8245 16 14.4002 16H4.8002C4.37585 16 3.96888 15.8314 3.66882 15.5314C3.36877 15.2313 3.2002 14.8243 3.2002 14.4V12C3.2002 11.5582 3.55837 11.2 4.0002 11.2C4.44202 11.2 4.8002 11.5582 4.8002 12V14.4H14.4002V1.6H4.8002C4.35837 1.6 4.0002 1.24183 4.0002 0.8Z"
                  fill="white" />
                <path id="primary (Stroke)_2" fill-rule="evenodd" clip-rule="evenodd"
                  d="M3.02218 3.73852C3.54841 3.3869 4.1671 3.19922 4.8 3.19922C5.64869 3.19922 6.46263 3.53636 7.06274 4.13648C7.66286 4.73659 8 5.55053 8 6.39922C8 7.03212 7.81232 7.65081 7.4607 8.17704C7.10908 8.70328 6.60931 9.11343 6.02459 9.35563C5.43986 9.59783 4.79645 9.6612 4.17571 9.53773C3.82109 9.46719 3.48297 9.33749 3.17479 9.1558L1.36569 10.9649C1.05327 11.2773 0.546734 11.2773 0.234315 10.9649C-0.0781049 10.6525 -0.0781049 10.146 0.234315 9.83353L2.04342 8.02442C1.86173 7.71625 1.73203 7.37813 1.66149 7.02351C1.53801 6.40277 1.60139 5.75936 1.84359 5.17463C2.08579 4.58991 2.49594 4.09014 3.02218 3.73852ZM4.8 4.79922C4.48355 4.79922 4.17421 4.89306 3.91109 5.06887C3.64797 5.24468 3.44289 5.49456 3.32179 5.78693C3.20069 6.07929 3.16901 6.40099 3.23074 6.71136C3.29248 7.02173 3.44487 7.30683 3.66863 7.53059C3.89239 7.75435 4.17749 7.90674 4.48786 7.96848C4.79822 8.03021 5.11993 7.99853 5.41229 7.87743C5.70466 7.75633 5.95454 7.55125 6.13035 7.28813C6.30616 7.02501 6.4 6.71567 6.4 6.39922C6.4 5.97487 6.23143 5.56791 5.93137 5.26785C5.63131 4.96779 5.22435 4.79922 4.8 4.79922ZM12 7.19922C12.4418 7.19922 12.8 7.55739 12.8 7.99922V11.9992C12.8 12.441 12.4418 12.7992 12 12.7992C11.5582 12.7992 11.2 12.441 11.2 11.9992V7.99922C11.2 7.55739 11.5582 7.19922 12 7.19922ZM8 11.9992V10.3992C8 9.95739 8.35817 9.59922 8.8 9.59922C9.24183 9.59922 9.6 9.95739 9.6 10.3992V11.9992C9.6 12.441 9.24183 12.7992 8.8 12.7992C8.35817 12.7992 8 12.441 8 11.9992Z"
                  fill="white" />
              </g>
            </svg>
          </i>
          Enviar para análise
        </button>
      </div>
    </div>


    <div class="cardsPage">
      <!-- CARD SOBRE O PROJETO -->
      <div class="projectsCardProject" (scroll)="onRemoveFocusInput()">
        <span id="hideListDropdown"></span>
        <div [formGroup]="form" >
          <h2 class="card--title__h2">Sobre o projeto</h2>

          <div nz-row class="flex-wrap">
            <div class="sl-equal-col">
              <nz-form-item class="sl-form-item"  
                [ngClass]="(formTouched && form.value?.projectName === '') || (formTouched && !form.value?.projectName) ? 'invalidSelector' : ''">
                <nz-form-label nzFor="projectName" nzRequired>
                  <span class="sl-form-label">Nome do projeto</span>
                </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24" class="inputField">
                  <nz-select  id="projectName"
                              class="sl__form__inputFieldSelect inputFieldSelect"
                              formControlName="projectName"
                              [compareWith]="compareFn"
                              [nzOpen]="isOpenSelectProject"
                              #selectNomeProjeto
                              nzShowSearch
                              nzAllowClear
                              (ngModelChange)="getSubprojectDetails($event)"
                              [nzSize]="'large'"
                              [nzDropdownRender]="projectNameTemplate"
                              nzPlaceHolder="Selecione">
                    <nz-option *ngFor="let item of filteredOptions" [nzLabel]="item.projectName ? (item.projectName &&
                                                                               item.projectName.length > 40 ?
                                                                               (item.projectName | slice:0:40) + '...' : item.projectName) : '-'"
                               [nzValue]="item.projectName + '_' + item.subProjectId"></nz-option>
                  </nz-select>
                  <ng-template #projectNameTemplate>
                    <nz-divider style="padding: 0px;"></nz-divider>
                    <div class="container">
                      <a class="add-item" style="margin-left: 10px; color: black" (click)="addNewProject(selectNomeProjeto)">
                        Criar Novo
                      </a>
                    </div>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div class="sl-equal-col">
              <nz-form-item class="sl__form__item">
                <nz-form-label nzFor="projectCode">
                  <span class="sl-form-label project-code-label">Código do projeto na área solicitante (opcional)</span>
                </nz-form-label>
                <nz-form-control>
                  <input nz-input class="inputField" id="txtCodeProject" (blur)="replaceResumeData()"
                    formControlName="projectCode" placeholder="X0000-00" [mask]="'A0000-00'" [dropSpecialCharacters]="true"
                    [style]="{ 'text-transform': 'uppercase' }">
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row class="flex-wrap">
            <div class="sl-equal-col">
              <nz-form-item class="sl-form-item" 
                [ngClass]="(formTouched && form.value?.areaSolicitante === '') || (formTouched && !form.value?.areaSolicitante) ? 'invalidSelector' : ''">
                <nz-form-label
                  nzFor="areaSolicitante"
                  nzRequired>
                  <span class="sl-form-label">Área Solicitante</span>
                </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24" class="inputField">
                  <nz-select  id="areaSolicitante"
                              class="sl__form__inputFieldSelect inputFieldSelect"
                              formControlName="areaSolicitante"
                              [compareWith]="compareFn"
                              [nzOpen]="isOpenSelect"
                              #selectAreaSolicitante
                              nzShowSearch
                              nzAllowClear
                              (ngModelChange)="replaceResumeData()"
                              [nzSize]="'large'"
                              [nzDropdownRender]="renderTemplate"
                              nzPlaceHolder="Selecione">
                    <nz-option *ngFor="let item of resquestingAreasFiltered" [nzLabel]="item.description ? (item.description &&
                                                                               item.description.length > 40 ?
                                                                               (item.description | slice:0:40) + '...' : item.description) : '-'"
                               [nzValue]="item.id"></nz-option>
                  </nz-select>
                  <ng-template #renderTemplate>
                    <nz-divider style="padding: 0px"></nz-divider>
                    <div class="container">
                      <a class="add-item" style="margin-left: 10px; color: black" (click)="addAreaSolicitante(selectAreaSolicitante)">
                      Criar Novo
                      </a>
                    </div>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div class="sl-equal-col">
              <nz-form-item class="sl-form-item" 
                [ngClass]="(formTouched && form.value?.responsavelAreaSolicitante === '') || (formTouched && !form.value?.responsavelAreaSolicitante) ? 'invalidSelector' : ''">
                <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="areaSolicitante" nzRequired>
                  <span class="sl-form-label">Responsável pelo projeto na área solicitante</span>
                </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24" class="inputField">
                  <nz-select  id="selResponsavelAreaSolicitante" class="sl__form__inputFieldSelect inputFieldSelect" [nzSize]="'large'" nzAllowClear
                    nzPlaceHolder="Selecione" [compareWith]="compareFn" formControlName="responsavelAreaSolicitante"
                    (ngModelChange)="replaceResumeData()" [nzLoading]="isProjectTypesLoading">
                    <nz-option *ngFor="let type of users" nzLabel="{{ type.name | titlecase }}"
                      nzValue="{{ type.id }}"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row class="flex-wrap">
            <div class="sl-equal-col">
              <nz-form-item class="sl-form-item">
                <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="projectClassification" nzRequired>
                  <span class="sl-form-label">Classificação do projeto</span>
                </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-radio-group formControlName="projectClassification"
                    (ngModelChange)="getMandatoryDocuments()">

                    <label nz-radio [nzValue]="'Greenfield'" id="projectClassificationGreen">
                      <span style="display: flex;">
                        <span class="card--radio__span">Greenfield</span>
                        <img src="assets/icons/information-icon.svg"
                          alt="info"
                          nz-tooltip
                          nzTooltipPlacement="bottomLeft"
                          nzTooltipTitle="Projeto realizado em áreas que não possuem instalações e facilidades pré-existentes. Novos empreendimentos."
                          [nzTooltipArrowPointAtCenter]="false"
                          [nzTooltipOverlayStyle]="{ 'font-size': '12px'}">
                      </span>
                    </label>

                    <label nz-radio [nzValue]="'Brownfield'" id="projectClassificationBrown">
                      <span style="display: flex;">
                        <span class="card--radio__span">Brownfield</span>
                        <img src="assets/icons/information-icon.svg"
                          alt="info"
                          nz-tooltip
                          nzTooltipPlacement="bottomLeft"
                          nzTooltipTitle="Projeto realizado em áreas que possuem instalações e facilidades às quais o produto do projeto será incorporado. Expansão de empreendimentos."
                          [nzTooltipArrowPointAtCenter]="false"
                          [nzTooltipOverlayStyle]="{ 'font-size': '12px'}">
                      </span>
                    </label>
                  </nz-radio-group>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>

          <div nz-row>
              <nz-form-item class="block-line" [ngClass]="formTouched && form.value?.comments === '' ? 'invalidInput' : ''">
                <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="comments" nzRequired="true">
                  <span class="sl-form-label">Breve descrição/Objetivo do Projeto</span>
                </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                 <nz-textarea-count [nzMaxCharacterCount]="2000">
                  <textarea id="txtObservacao" rows="5" placeholder="Informe uma breve descrição"  formControlName="comments" nz-input
                    (ngModelChange)="replaceResumeData()"></textarea>
                </nz-textarea-count>
                </nz-form-control>
              </nz-form-item>
          </div>

          <!-- LOCALIZACAO -->
          <h2 class="card--title__h2">Localização</h2>
          <div nz-col class="threeColumns mt-20">
            <nz-form-item class="threeColumn" style="flex-direction: row !important;"  
              [ngClass]="(formTouched && form.value?.corredor === '') || (formTouched && !form.value?.corredor) ? 'invalidSelector' : ''">
              <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="corredor" nzRequired>
                <span class="sl-form-label">Corredor</span>
              </nz-form-label>
              <nz-form-control [nzSm]="24" [nzXs]="24" class="inputField">
                <nz-select id="selCorredor" class=" sl__form__inputFieldSelect inputFieldSelect" [nzSize]="'large'" nzAllowClear
                  nzPlaceHolder="Selecione" [compareWith]="compareFn" formControlName="corredor" (ngModelChange)="onChangeRegions($event)"
                  [nzLoading]="isRegionsLoading">
                  <nz-option *ngFor="let region of regions" nzLabel="{{ region.description }}"
                    nzValue="{{ region.id }}"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item class="threeColumn" style="flex-direction: row !important;" 
              [ngClass]="(formTouched && form.value?.complexo === '') || (formTouched && !form.value?.complexo) ? 'invalidSelector' : ''">
              <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="complexo" nzRequired>
                <span class="sl-form-label">Complexo</span>
              </nz-form-label>
              <nz-form-control [nzSm]="24" [nzXs]="24" class="inputField">
                <nz-select id="selComplexo" class="sl__form__inputFieldSelect inputFieldSelect" [nzSize]="'large'" nzAllowClear
                  nzPlaceHolder="Selecione" [compareWith]="compareFn" formControlName="complexo" (ngModelChange)="onChangeComplex($event)"
                  [nzLoading]="isComplexesLoading">
                  <nz-option *ngFor="let complex of complexes" nzLabel="{{ complex.description }}"
                    nzValue="{{ complex.id }}"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item class="threeColumn" style="flex-direction: row !important;" 
              [ngClass]="(formTouched && form.value?.unidadeOperacional === '') || formTouched && !form.value?.unidadeOperacional ? 'invalidSelector' : ''">
              <nz-form-label [nzSm]="24" [nzXs]="24" nzFor="unidadeOperacional" nzRequired>
                <span class="sl-form-label">Unidade Operacional</span>
              </nz-form-label>
              <nz-form-control [nzSm]="24" [nzXs]="24"
                class="inputField">
                <nz-select id="selUnidadeOperacional" class="sl__form__inputFieldSelect inputFieldSelect" [nzSize]="'large'" nzAllowClear
                  nzPlaceHolder="Selecione" [compareWith]="compareFn" formControlName="unidadeOperacional" (ngModelChange)="replaceResumeData()"
                  [nzLoading]="isOperacionalUnitLoading" [nzDisabled]="form.controls['projectClassification'].value === 'Greenfield'">
                  <nz-option *ngFor="let unit of operacionalUnits" 
                    nzLabel="{{ unit.description }}"
                    nzValue="{{ unit.id }}"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>

          </div>

          <h2 class="card--title__h2" *ngIf="documents.length > 0">Documentos necessários</h2>

          <!-- ALERTA -->
          <nz-alert *ngIf="documents.length > 0" nzType="warning"
            nzDescription="Os arquivos listados abaixo são obrigatórios para análise do time de licenciamento."
            [nzIcon]="customIconTemplate" nzShowIcon>
          </nz-alert>
          <!-- ICONE CUSTOMIZADO -->
          <ng-template #customIconTemplate>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M12.0003 8.99996V13V8.99996ZM10.2503 4.18996L2.6303 18C2.45398 18.3053 2.36153 18.6519 2.36231 19.0045C2.36309 19.3571 2.45708 19.7032 2.63475 20.0078C2.81242 20.3124 3.06745 20.5645 3.374 20.7388C3.68054 20.913 4.02771 21.0031 4.3803 21H19.6203C19.9729 21.0031 20.3201 20.913 20.6266 20.7388C20.9332 20.5645 21.1882 20.3124 21.3659 20.0078C21.5435 19.7032 21.6375 19.3571 21.6383 19.0045C21.6391 18.6519 21.5466 18.3053 21.3703 18L13.7503 4.18996C13.5773 3.87735 13.3238 3.61679 13.0161 3.43534C12.7083 3.2539 12.3576 3.1582 12.0003 3.1582C11.643 3.1582 11.2923 3.2539 10.9845 3.43534C10.6768 3.61679 10.4233 3.87735 10.2503 4.18996Z"
                stroke="#E9AB13" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M12.0502 17H11.9502" stroke="#E9AB13" stroke-width="2.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </ng-template>
          <!-- FIM ICONE CUSTOMIZADO -->


          <!-- TABELA DE ARQUIVOS NECESSÁRIOS -->
          <div class="document mt-10" *ngFor="let document of documents">
            <div class="documentStatusIcon" *ngIf="document.statusUpload === 'uploded' ||document.statusUpload === 'ok'">
              <app-svg-icon
                *ngIf="document.name"
                icon="confirmed_green-icon"
                class="iconAlignCenter"
                [width]="24"
                [height]="18"
              ></app-svg-icon>
            </div>
            <div class="documentStatusIcon" *ngIf="document.statusUpload === 'error'">
              <img  src="assets/icons/cross-red.svg" class="iconAlignCenter">
            </div>
            <div class="documentName {{ document.statusUpload === '' ? 'upload' : document.statusUpload }}">{{ document.name }}</div>
            <div class="documentActions">
              <div class="icon documentActionButon" *ngIf="document.statusUpload !=='uploded' && document.statusUpload !=='ok' " >
                <nz-upload [(nzFileList)]="fileList" [nzAction]="'https://www.mocky.io/v2/5cc8019d300000980a055e76'" (nzChange)="onChangeFile($event, document)" [nzShowUploadList]="false">
                  <app-svg-icon icon="upload_document-icon" class="iconAlignCenter"></app-svg-icon>
                </nz-upload>
              </div>
              <div class="icon" *ngIf="document.statusUpload==='uploded' && editMode" (click)="downloadFile(document.fieluid, document.fileName)">
                <app-svg-icon icon="download_document-icon" class="iconAlignCenter" ></app-svg-icon>
              </div>
              <div class="icon" *ngIf="document.statusUpload==='ok' || document.statusUpload==='uploded' || document.statusUpload==='error'" nz-popconfirm style="margin: 2px" nzPopconfirmTitle="{{ 'cancelationMessage' | translate }}" (nzOnConfirm)="deleteMandatoryDoc(document)">
                <app-svg-icon icon="trash_document-icon" class="iconAlignCenter" ></app-svg-icon>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!-- CARD RESUMO DO PROJETO -->
      <div class="projectsCardResume">
        <app-requisition-resume [resumeData]="resumeData"></app-requisition-resume>
      </div>

    </div>
  </form>
  </nz-content>
</nz-layout>
