<section class="communications__section">
  <header class="header">
    <div class="header__info">
      <div class="header__info__left">
        <div class="header__info__left__text">
          <h1 class="header__info__left__text__title">
            <!-- Comunicações com órgãos competentes -->
            {{ "projects.communication.list-title" | translate }}
          </h1>
        </div>
      </div>
      <div class="header__actions">
        <button *ngIf="cadCommunicationsInsert" nz-popover nzPopoverPlacement="bottom" nzPopoverTrigger="click" [nzPopoverContent]="listCommunication"
          [nzPopoverOverlayStyle]="popoverOverlayStyle" [(nzPopoverVisible)]="popOverNotification"
          class="btn__primary btn__primary__green">
          <span class="btn__primary__span">
            {{ "projects.communication.button-new-communication" | translate }}
          </span>
          <div class="btn__primary__line"></div>
          <span nz-icon>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M5.03436 6.63422C5.34678 6.3218 5.85331 6.3218 6.16573 6.63422L8.00005 8.46853L9.83436 6.63422C10.1468 6.3218 10.6533 6.3218 10.9657 6.63422C11.2782 6.94664 11.2782 7.45317 10.9657 7.76559L8.00005 10.7313L5.03436 7.76559C4.72194 7.45317 4.72194 6.94664 5.03436 6.63422Z"
                stroke="white" stroke-width="0.7" fill="white" />
            </svg>
          </span>
        </button>
        <ng-template #listCommunication>
          <div class="btnNewCommunication" (click)="newCommunication('ENV')">
            {{ 'projects.communication.button-communication-sent' | translate}}
          </div>
          <div class="btnNewCommunication" (click)="newCommunication('REC')">
            {{ 'projects.communication.button-communication-received' | translate}}
          </div>
        </ng-template>
      </div>
    </div>
  </header>

  <div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24" class="communications__card">
    <div class="communications__card__header">
      <h2 class="communications__card__header__title">
        {{ 'projects.communication.general-information' | translate }}
      </h2>
      <button type="button" class="communications__card__header__btn">
        <img src="assets/icons/icon_configuracoes.svg" alt="Configurações">
      </button>
    </div>

    <nz-table #filterTable #sortTable #borderedTable nzTableLayout="fixed" [nzFrontPagination]="false"
      class="communications__table" [nzData]="communicationsListFiltered">
      <thead>
        <tr>
          <th id="cummunicationsType" nzWidth="4%">
            <img src="assets/icons/icon_large_mail_sended_and_received.svg" alt="Comunicações enviadas/recebidas">
          </th>
          <th nzCustomFilter [(nzSortOrder)]="listOfColumns[1].sortOrder" class="communications__table__th"
            [nzSortFn]="listOfColumns[1].sortFn" [nzShowFilter]="false" [nzWidth]="listOfColumns[1].width"
            id="communication1">
            <span class="communications__table__th__span">
              {{ listOfColumns[1].name | translate}}
              <img src="assets/icons/icon_sort_down.svg" alt="Ordenar por {{ listOfColumns[1].name | translate}}">
            </span>
            <nz-filter-trigger [(nzVisible)]="listOfColumns[1].visible" [nzDropdownMenu]="titleDropdown">
              <img src="assets/icons/icon_filter.svg" alt="Filtrar por {{ listOfColumns[1].name | translate}}">
            </nz-filter-trigger>
          </th>
          <th nzCustomFilter [(nzSortOrder)]="listOfColumns[2].sortOrder" class="communications__table__th"
            [nzSortFn]="listOfColumns[2].sortFn" [nzShowFilter]="false" [nzWidth]="listOfColumns[2].width"
            id="communication2">
            <span class="communications__table__th__span">
              {{ listOfColumns[2].name | translate}}
              <img src="assets/icons/icon_sort_down.svg" alt="Ordenar por {{ listOfColumns[2].name | translate}}">
            </span>
            <nz-filter-trigger [(nzVisible)]="listOfColumns[2].visible" [nzDropdownMenu]="projectNameDropdown">
              <img src="assets/icons/icon_filter.svg" alt="Filtrar por {{ listOfColumns[2].name | translate}}">
            </nz-filter-trigger>
          </th>
          <th nzCustomFilter [(nzSortOrder)]="listOfColumns[3].sortOrder" class="communications__table__th"
            [nzSortFn]="listOfColumns[3].sortFn" [nzShowFilter]="false" [nzWidth]="listOfColumns[3].width"
            id="communication3">
            <span class="communications__table__th__span communications__table__th__span__space">
              {{ listOfColumns[3].name | translate}}
              <img src="assets/icons/icon_sort_down.svg" alt="Ordenar por {{ listOfColumns[3].name | translate}}">
            </span>
            <nz-filter-trigger [(nzVisible)]="listOfColumns[3].visible" [nzDropdownMenu]="processNameDropdown">
              <img src="assets/icons/icon_filter.svg" alt="Filtrar por {{ listOfColumns[3].name | translate}}">
            </nz-filter-trigger>
          </th>
          <th nzCustomFilter [(nzSortOrder)]="listOfColumns[4].sortOrder" class="communications__table__th"
            [nzSortFn]="listOfColumns[4].sortFn" [nzShowFilter]="false" [nzWidth]="listOfColumns[4].width"
            id="communication4">
            <span class="communications__table__th__span">
              {{ listOfColumns[4].name | translate}}
              <img src="assets/icons/icon_sort_down.svg" alt="Ordenar por {{ listOfColumns[4].name | translate}}">
            </span>
            <nz-filter-trigger [(nzVisible)]="listOfColumns[4].visible" [nzDropdownMenu]="organDropdown">
              <img src="assets/icons/icon_filter.svg" alt="Filtrar por {{ listOfColumns[4].name | translate}}">
            </nz-filter-trigger>
          </th>
          <th nzCustomFilter [(nzSortOrder)]="listOfColumns[5].sortOrder" class="communications__table__th"
            [nzSortFn]="listOfColumns[5].sortFn" [nzShowFilter]="false" [nzWidth]="listOfColumns[5].width"
            id="communication5">
            <span class="communications__table__th__span">
              {{ listOfColumns[5].name | translate}}
              <img src="assets/icons/icon_sort_down.svg" alt="Ordenar por {{ listOfColumns[5].name | translate}}">
            </span>
            <nz-filter-trigger [(nzVisible)]="listOfColumns[5].visible" [nzDropdownMenu]="processNumberDropdown">
              <img src="assets/icons/icon_filter.svg" alt="Filtrar por {{ listOfColumns[5].name | translate}}">
            </nz-filter-trigger>
          </th>
          <th nzCustomFilter [(nzSortOrder)]="listOfColumns[6].sortOrder" class="communications__table__th"
            [nzSortFn]="listOfColumns[6].sortFn" [nzShowFilter]="false" [nzWidth]="listOfColumns[6].width"
            id="communicationDate">
            <span class="communications__table__th__span">
              {{ listOfColumns[6].name | translate}}
              <img src="assets/icons/icon_sort_down.svg" alt="Ordenar por {{ listOfColumns[6].name | translate}}">
            </span>
            <nz-filter-trigger [(nzVisible)]="listOfColumns[6].visible" [nzDropdownMenu]="dateDropdown">
              <img src="assets/icons/icon_filter.svg" alt="Filtrar por {{ listOfColumns[6].name | translate}}">
            </nz-filter-trigger>
          </th>
          <th nzCustomFilter [(nzSortOrder)]="listOfColumns[7].sortOrder" class="communications__table__th"
            [nzSortFn]="listOfColumns[7].sortFn" [nzShowFilter]="false" [nzWidth]="listOfColumns[7].width"
            id="communicationLimitDate">
            <span class="communications__table__th__span">
              {{ listOfColumns[7].name | translate}}
              <img src="assets/icons/icon_sort_down.svg" alt="Ordenar por {{ listOfColumns[7].name | translate}}">
            </span>
            <nz-filter-trigger [(nzVisible)]="listOfColumns[7].visible" [nzDropdownMenu]="answerLimitDateDropdown">
              <img src="assets/icons/icon_filter.svg" alt="Filtrar por {{ listOfColumns[7].name | translate}}">
            </nz-filter-trigger>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of filterTable.data" (click)="goToCommunication(item.id)">
          <td>
            <img *ngIf="item.communicationType === 'REC'" src="assets/icons/icon_large_mail_received.svg"
              alt="Comunicação recebida">
            <img *ngIf="item.communicationType === 'ENV'" src="assets/icons/icon_large_mail_sended.svg"
              alt="Comunicação enviada">
          </td>
          <td class="communications__table__body__title" nz-tooltip [nzTooltipTitle]="item.title">
            {{ item.title }}
          </td>
          <td>{{ item.projectName}}</td>
          <td>{{ item.processName}}</td>
          <td>{{ item.organ}}</td>
          <td>{{ item.processNumber}}</td>
          <td>{{ item.date | date: "dd/MM/YYYY" }}</td>
          <td>{{ item.answerLimitDate | date: "dd/MM/YYYY" }}</td>
        </tr>
      </tbody>
    </nz-table>
  </div>

  <!-- Filters -->
  <nz-dropdown-menu #titleDropdown="nzDropdownMenu">
    <div class="ant-table-filter-dropdown popoverCorredor">
      <div class="search-box">
        <nz-checkbox-group [(ngModel)]="filterByTitle" (ngModelChange)="filterBy($event, 'title')">
          <label nz-checkbox *ngFor="let option of filterByTitle" [for]="'checkbox-' + option.label">
            {{ option.label }}
          </label>
          <br>
        </nz-checkbox-group>
        <nz-divider class="divider16"></nz-divider>
        <button class="btn__text" (click)="resetSearch('title')">
          <span class="btn__text__span">Limpar filtro</span>
        </button>
      </div>
    </div>
  </nz-dropdown-menu>

  <nz-dropdown-menu #projectNameDropdown="nzDropdownMenu">
    <ul nz-menu>
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <li nz-menu-item>
            <nz-checkbox-group [(ngModel)]="filterByProject" (ngModelChange)="filterBy($event, 'projectName')">
              <label nz-checkbox *ngFor="let option of filterByProject" [title]="option.label" [for]="'checkbox-' + option.label">
                {{ option.label }}
              </label>
            </nz-checkbox-group>
          </li>
          <nz-divider class="divider"></nz-divider>
          <button class="btn__text" (click)="resetSearch('projectName')">
            <span class="btn__text__span">Limpar filtro</span>
          </button>
        </div>
      </div>
    </ul>
  </nz-dropdown-menu>

  <nz-dropdown-menu #processNameDropdown="nzDropdownMenu">
    <ul nz-menu>
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <li nz-menu-item>
            <nz-checkbox-group [(ngModel)]="filterByProcess" (ngModelChange)="filterBy($event, 'processName')">
              <label nz-checkbox *ngFor="let option of filterByProcess" [title]="option.label" [for]="'checkbox-' + option.label">
                {{ option.label }}
              </label>
            </nz-checkbox-group>
          </li>
          <nz-divider class="divider"></nz-divider>
          <button class="btn__text" (click)="resetSearch('processName')">
            <span class="btn__text__span">Limpar filtro</span>
          </button>
        </div>
      </div>
    </ul>
  </nz-dropdown-menu>

  <nz-dropdown-menu #organDropdown="nzDropdownMenu">
    <ul nz-menu>
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <li nz-menu-item>
            <nz-checkbox-group [(ngModel)]="filterByOrgan" (ngModelChange)="filterBy($event, 'organ')">
              <label nz-checkbox *ngFor="let option of filterByOrgan" [title]="option.label" [for]="'checkbox-' + option.label">
                {{ option.label }}
              </label>
            </nz-checkbox-group>
          </li>
          <nz-divider class="divider"></nz-divider>
          <button class="btn__text" (click)="resetSearch('organ')">
            <span class="btn__text__span">Limpar filtro</span>
          </button>
        </div>
      </div>
    </ul>
  </nz-dropdown-menu>

  <nz-dropdown-menu #processNumberDropdown="nzDropdownMenu">
    <ul nz-menu>
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <li nz-menu-item class="flexColumn">
            <nz-checkbox-group [(ngModel)]="filterByProcessNumber" (ngModelChange)="filterBy($event, 'processNumber')">
              <label nz-checkbox *ngFor="let option of filterByProcessNumber" [title]="option.label" [for]="'checkbox-' + option.label">
                {{ option.label }}
              </label>
            </nz-checkbox-group>
          </li>
          <nz-divider class="divider"></nz-divider>
          <button class="btn__text" (click)="resetSearch('processNumber')">
            <span class="btn__text__span">Limpar filtro</span>
          </button>
        </div>
      </div>
    </ul>
  </nz-dropdown-menu>

  <nz-dropdown-menu #dateDropdown="nzDropdownMenu">
    <ul nz-menu>
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <li nz-menu-item>
            <nz-checkbox-group [(ngModel)]="filterByDate" (ngModelChange)="filterBy($event, 'date')">
              <label nz-checkbox *ngFor="let option of filterByDate" [title]="option.label" [for]="'checkbox-' + option.label">
                  {{ option.label }}
              </label>
            </nz-checkbox-group>
          </li>
          <nz-divider class="divider"></nz-divider>
          <button class="btn__text" (click)="resetSearch('date')">
            <span class="btn__text__span">Limpar filtro</span>
          </button>
        </div>
      </div>
    </ul>
  </nz-dropdown-menu>

  <nz-dropdown-menu #answerLimitDateDropdown="nzDropdownMenu">
    <ul nz-menu>
      <div class="ant-table-filter-dropdown">
        <div class="search-box">
          <li nz-menu-item>
            <nz-checkbox-group [(ngModel)]="filterByDateLimit"
              (ngModelChange)="filterBy($event, 'answerLimitDate')">
              <label nz-checkbox *ngFor="let option of filterByDateLimit" [title]="option.label" [for]="'checkbox-' + option.label">
                {{ option.label }}
              </label>
            </nz-checkbox-group>
          </li>
          <nz-divider class="divider"></nz-divider>
          <button class="btn__text" (click)="resetSearch('answerLimitDate')">
            <span class="btn__text__span">Limpar filtro</span>
          </button>
        </div>
      </div>
    </ul>
  </nz-dropdown-menu>
</section>
