import { Component, Input, OnInit } from '@angular/core';

import { SummaryAboutProject, SummaryScopeChange } from '../../../interface/summary-scope-change';
import { AttachmentsModel } from 'src/app/shared/models/attachments.model';
import { UtilService } from 'src/app/shared/util.service';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { AttachmentsService } from 'src/app/shared/services/attachments.service';
import { ProjectSuspension } from 'src/app/shared/dto/project-suspension.dto';

@Component({
  selector: 'app-resume-scope-change-request-container',
  templateUrl: './resume-scope-change-request-container.component.html',
  styleUrls: ['./resume-scope-change-request-container.component.scss']
})
export class ResumeScopeChangeRequestContainerComponent implements OnInit{
  @Input() shouldShowTitle?: boolean = true;
  @Input() scopeSummary?: SummaryScopeChange = undefined
  @Input() suspension?: ProjectSuspension = undefined;
  @Input() suspensionLabel?: string = "";
  @Input() projectSummary!: SummaryAboutProject;
  @Input() attachments: NzUploadFile[];

  constructor(
    private readonly util: UtilService,
    private readonly attachmentsService: AttachmentsService
  ) { }

  ngOnInit(): void {
    this.sortScopeChangeSummary();
  }


  getIconFromFileName(filename: string) {
    return this.util.getIconFromFileName(filename);
  }

  getAttachmentTruncatedName(filename: string) {
    const name = filename.split('.')
    const filenameWithoutExtension = name.slice(0, name.length - 1).join('')
    const fileExtension = name[name.length - 1];

    if (filenameWithoutExtension.length > 30) {
      return filenameWithoutExtension.slice(0, 28) + '...' + fileExtension
    } else {
      return filename
    }
  }

  downloadFile = (file: NzUploadFile): void => {
    if (file.uid === undefined) return;
    this.attachmentsService.getDownloadFile(+file.uid).subscribe({
      next: (resp) => this.attachmentsService.downloadFile(resp, file.name),
    });
  };

  sortScopeChangeSummary() {
    if(this.scopeSummary && this.scopeSummary?.change?.length > 0){
      return this.scopeSummary.change.sort((a,b) => a.description.localeCompare(b.description))
    }
    return [];
  }
}
