
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { BreadcrumbState } from './breadcrumb.state.model';

import { REDUCER } from './constants';


export const getBreadcrumbState = 
createFeatureSelector<BreadcrumbState>(REDUCER.RIPLA_BREADCRUMB);

export const selectRiplaProject = createSelector(
    getBreadcrumbState,
    (state: BreadcrumbState) => state
);
