<div class="disciplineLink {{ collaborativeLink ? '' : 'height66' }}">
  <div class="tituloLink">Arquivo colaborativo para consolidação das análises</div>


  <div >
    <div class="subtituloLink">As análises de cada disciplina devem ser inseridas no arquivo disponível para a construção do Anexo IV.</div>
    <div class="linkGrid" *ngIf="collaborativeLink">
      <div class="icon">
        <app-svg-icon icon="document-icon"></app-svg-icon>
      </div>
      <div class="linkName" ><a href="{{collaborativeLink}}" target="_blank">{{collaborativeLink}}</a></div>

    </div>
  </div>
</div>
