import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-button-save-draft',
  templateUrl: './button-save-draft.component.html',
  styleUrls: ['./button-save-draft.component.scss']
})
export class ButtonSaveDraftComponent implements OnInit {

  @Input() id?: string = '';
  @Input() name?: string = 'Salvar Rascunho';
  @Input() width?: string = '150';
  
  constructor() { }

  ngOnInit(): void { return;}

}
