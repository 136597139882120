import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';

import { Subject, take, takeUntil, tap } from 'rxjs';

import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

import { MessageService } from 'src/app/shared/common/services/message.service';

import { ProjectV2RiplaReviewRequestService } from 'src/app/shared/services/project-v2-ripla-review-request.service';
import { ProjectsRequestReviewService } from 'src/app/modules/projects/projects-ripla/projects-request-revision/projects-request-review.service';

@Component({
  selector: 'app-request-review-analisys-modal',
  templateUrl: './request-review-analisys-modal.component.html',
  styleUrls: ['./request-review-analisys-modal.component.scss']
})
export class RequestReviewAnalisysModalComponent implements OnInit, OnDestroy {

  @Input() reviewId: number;

  private readonly destroy$: Subject<any> = new Subject();
  readonly #modal = inject(NzModalRef);

  // Form Variables
  formRequestReview: FormGroup;
  checkOptions: Array<{ description: string, id: number }>;
  subProjectId: number;

  editReview: boolean = false;
  reasonsIdsChecked: Array<number> = [];

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly modalService: NzModalService,
    private readonly messageService: MessageService,
    private readonly projectsService: ProjectsRequestReviewService,
    private readonly riplaReviewRequest: ProjectV2RiplaReviewRequestService
  ) { }

  ngOnInit(): void {
    this.subProjectId = Number(this.route.snapshot.paramMap.get('subProjectId'))
    this.inicializarForm();
    this.getReasons();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  inicializarForm(): void {
    this.formRequestReview = this.formBuilder.group({
      subProjectId: [this.subProjectId, Validators.required],
      comments: [null, [Validators.required]],
      reasonsIds: [null, Validators.required],
    });
  }

  onChangeCheckbox(event: any): void {
    this.formRequestReview.controls['reasonsIds'].setValue(event);
  }

  onCancel(): void {
    this.modalService.confirm({
      ...(this.messageService.showMessageModalConfirmCancel() as any),
      nzOnOk: () => {
        this.#modal.close();
      }
    });
  }

  createSubProjectReviews() {
    this.projectsService.postSubprojectReviews(this.formRequestReview.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: any) => {
          this.#modal.close(data);
          this.messageService.showSucessMessage('Solicitação de Revisão Enviada com Sucesso.')
        },
        error: (err) => {
          this.messageService.showErrorMessage(err.error.errors.details)
        }
      })
  }

  updateSubProjectReviews() {
    this.projectsService.putSubProjectReviews(this.reviewId, this.formRequestReview.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data: any) => {
          this.#modal.close(data);
          this.messageService.showSucessMessage('Solicitação de Revisão Enviada com Sucesso.')
        },
        error: (err) => {
          this.messageService.showErrorMessage(err.error.errors.details)
        }
      })
  }

  onSubmit(): void {
    if (this.formRequestReview.valid) {
      if (this.editReview) {
        this.updateSubProjectReviews()
      }
      else {
        this.createSubProjectReviews();
      }
    } else {
      this.messageService.showErrorMessage("Os campos de Motivo e Detalhamento são obrigatórios");
    }
  }

  private getReasons(): void {
    this.projectsService.getProjectsRequestReviewReasons()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.checkOptions = response.reviewItems;

          if (this.reviewId) {
            this.editReview = true;
            this.getDataFromReasonsModal();
          }
        }
      })
  }

  getDataFromReasonsModal(): void {
    this.riplaReviewRequest
      .getSubProjectReviews(this.reviewId, this.subProjectId)
      .pipe(
        take(1),
        tap((data) => {
          const reviewListId = data.subProjectReviewReasons.map((getReviewList) => getReviewList.reason.id);

          this.reasonsIdsChecked = reviewListId;
          this.formRequestReview.controls['reasonsIds'].setValue(reviewListId);
          this.formRequestReview.controls['comments'].setValue(data.comments);
          this.formRequestReview.controls['subProjectId'].setValue(data.subProjectId);

        })
      )
      .subscribe();
  }
}
