import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, filter, takeUntil } from 'rxjs/operators'
import { selectRiplaProject } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.selector';
import { BreadcrumbState } from 'src/app/shared/ngrx/breadcrumb/breadcrumb.state.model';

interface BreadCrumb {
  label: string;
  url: string;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})

export class BreadcrumbComponent implements OnInit, OnDestroy {
  private readonly destroy$: Subject<any> = new Subject();

  public breadcrumbs: BreadCrumb[];
  public breadcrumb: any[];



  constructor(
    private store: Store<BreadcrumbState>,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.breadcrumbs = [];
   }

  projectName: string | undefined
  subProjectName: string = ''
  testeProjectName: string | undefined;

  bread: any

  ngOnInit() {
    this.router.events.pipe( filter((event) => event instanceof NavigationEnd), map((e) => e as NavigationEnd) )
    .subscribe(event => {
      if(event.url === '/dashboards' || event.url === '/nlp' ){
        this.breadcrumbs=[];
      } else if(event.url === '/documents' ){
        this.breadcrumbs=[ {
          label:'Documentos',
          url: '/documents'
        }];
      } else if(event.url === '/help-center'){
        this.breadcrumbs=[ {
          label:'Central de Ajuda',
          url: '/help-center'
        }];
      }
      else {
        this.loadProjectFromStore();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private loadProjectFromStore(): void {
    this.store
      .pipe(
        takeUntil(this.destroy$),
        select(selectRiplaProject)
      ).subscribe((result) => {
        this.breadcrumb = []
        this.breadcrumb.push(result)
        this.createAndUpdateBreadcrumb();
      });
  }

  createAndUpdateBreadcrumb() {

    this.breadcrumbs=[]

    // PRIMEIRO LEVEL
    if(this.breadcrumb[0].project && this.breadcrumb[0].project?.name) {
      let breadcrumb: BreadCrumb = {
        label: this.breadcrumb[0].project?.name,
        url: this.breadcrumb[0].project?.url,
      };
      this.breadcrumbs.push(breadcrumb);
    }

    // SEGUNDO LEVEL
    if(this.breadcrumb[0].project && this.breadcrumb[0].project.project !== null && this.breadcrumb[0].project.project.name) {
      let breadcrumb: BreadCrumb = {
        label: this.breadcrumb[0].project?.project.name,
        url: this.breadcrumb[0].project?.project.url,
      };
      this.breadcrumbs.push(breadcrumb);
    }

    // TERCEIRO LEVEL
    if(this.breadcrumb[0].project && this.breadcrumb[0].project.subProject !== null) {
      let breadcrumb: BreadCrumb = {
        label: this.breadcrumb[0].project.subProject.name,
      url: this.breadcrumb[0].project.subProject.url,
      };
      this.breadcrumbs.push(breadcrumb);

    }

    // QUARTO LEVEL
    if(this.breadcrumb[0].project && this.breadcrumb[0].project.subProject !== null && this.breadcrumb[0].project.subProject.discipline !== undefined && this.breadcrumb[0].project.subProject.discipline !== null) {
      let breadcrumb: BreadCrumb = {
        label: this.breadcrumb[0].project.subProject.discipline.name,
        url: this.breadcrumb[0].project.subProject.discipline.url,
      };
      this.breadcrumbs.push(breadcrumb);
    }

    // QUINTO LEVEL
    if(this.breadcrumb[0].project && this.breadcrumb[0].project.parecerFinal !== null) {
      let breadcrumb: BreadCrumb = {
        label: this.breadcrumb[0].project.parecerFinal.name,
        url: this.breadcrumb[0].project.parecerFinal.url,
      };
      this.breadcrumbs.push(breadcrumb);
    }

    // SEXTO LEVEL
    if(this.breadcrumb[0].project && this.breadcrumb[0].project.pedidoRevisao !== null) {
      let breadcrumb: BreadCrumb = {
        label: this.breadcrumb[0].project.pedidoRevisao.name,
        url: this.breadcrumb[0].project.pedidoRevisao.url,
      };
      this.breadcrumbs.push(breadcrumb);
    }

   

    // OITAVO LEVEL // PROCESSOS
    if(this.breadcrumb[0].project && this.breadcrumb[0].project.processos !== null) {
      let breadcrumb: BreadCrumb = {
        label: '',
        url: '',
      };
      if(this.breadcrumb[0].project && this.breadcrumb[0].project.subatividades !== null && this.breadcrumb[0].project.atividades.name !== 'Nova atividade' && this.breadcrumb[0].project.atividades.name !== 'Nova subatividade') {
        breadcrumb = {
          label: '...',
          url: '',
        };
      } else {
        breadcrumb = {
          label: this.breadcrumb[0].project.processos.name,
          url: this.breadcrumb[0].project.processos.url,
        };

      }
      this.breadcrumbs.push(breadcrumb);
    }

    // NONO LEVEL
    if(this.breadcrumb[0].project && this.breadcrumb[0].project.atividades !== null) {
      let breadcrumb: BreadCrumb = {
        label: this.breadcrumb[0].project.atividades.name,
        url: this.breadcrumb[0].project.atividades.url,
      };
      this.breadcrumbs.push(breadcrumb);
    }
    
    // DECIMO LEVEL
    if(this.breadcrumb[0].project && this.breadcrumb[0].project.subatividades !== null && this.breadcrumb[0].project.atividades.name !== 'Nova atividade' && this.breadcrumb[0].project.atividades.name !== 'Nova subatividade') {
      let breadcrumb: BreadCrumb = {
        label: this.breadcrumb[0].project.subatividades.name,
        url: this.breadcrumb[0].project.subatividades.url,
      };
      this.breadcrumbs.push(breadcrumb);
    }

    // MUDANCA DE ESCOPO
    if(this.breadcrumb[0].project && this.breadcrumb[0].project.mudancaEscopo !== null) {
      let breadcrumb: BreadCrumb = {
        label: this.breadcrumb[0].project.mudancaEscopo.name,
        url: this.breadcrumb[0].project.mudancaEscopo.url,
      };
      this.breadcrumbs.push(breadcrumb);
    }

    //comunicacoes
    if(this.breadcrumb[0].project && this.breadcrumb[0].project.comunicacoes !== null) {
      let breadcrumb: BreadCrumb = {
        label: this.breadcrumb[0].project.comunicacoes.name,
        url: this.breadcrumb[0].project.comunicacoes.url,
      };
      this.breadcrumbs.push(breadcrumb);
    }

    // console.log("BREADCRUMBS ==> ", this.breadcrumbs)

  }

}
