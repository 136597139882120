import { Component, Input, OnChanges } from '@angular/core';
import { FilterLastDaysHelper } from 'src/app/shared/helpers/filter-last-days/filter-last-days.helper';

@Component({
  selector: 'app-assigned-notifications',
  templateUrl: './assigned-notifications.component.html',
  styleUrls: ['./assigned-notifications.component.scss']
})
export class AssignedNotificationsComponent implements OnChanges {
  @Input() listItems;
  top7Days;
  offsetDays;
  
    constructor(
      private readonly filter: FilterLastDaysHelper
    ) { }
    
    ngOnChanges(){
      this.filterDays();
    }
  
    filterDays(){
      const groupDays = this.filter.filterTopDays(this.listItems, 7);
      this.top7Days = groupDays[0];
      this.offsetDays =  groupDays[1];
    }
  

}
