import { Subject, takeUntil } from 'rxjs';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewContainerRef} from '@angular/core';

import { NzModalService } from 'ng-zorro-antd/modal';

import { ReviewResponse } from 'src/app/shared/models/project-v2-ripla-review-request.model';

import { MessageService } from 'src/app/shared/common/services/message.service';
import { ProjectV2RiplaReviewRequestService } from 'src/app/shared/services/project-v2-ripla-review-request.service';
import { SubProjectByIdResponse } from 'src/app/shared/dto/subproject-by-id-response.dto';
import moment from "moment";

@Component({
  selector: 'app-project-v2-ripla-review-request',
  templateUrl: './project-v2-ripla-review-request.component.html',
  styleUrls: ['./project-v2-ripla-review-request.component.scss'],
})
export class ProjectV2RiplaReviewRequestComponent implements OnDestroy, OnInit {

  private readonly destroy$: Subject<void> = new Subject<void>();

  @Input() projectId: number;
  @Input() reviewId!: number;
  @Input() subProjectId!: number;
  @Input() subProject: SubProjectByIdResponse;
  @Input() enableEditAndCancelReview: boolean = false;
  @Input() enableResponseReview: boolean = false;
  @Input() reviewData!: ReviewResponse;
  @Input() viewContainerRef: ViewContainerRef;

  @Output() updateReview: EventEmitter<boolean> = new EventEmitter;
  @Output() cancelReview: EventEmitter<boolean> = new EventEmitter;

  constructor(
    private readonly riplaReviewRequest: ProjectV2RiplaReviewRequestService,
    private readonly modalService: NzModalService,
    private readonly messageService: MessageService,
  ) { }


  ngOnInit() {
    this.reviewData.deadline = moment(this.reviewData?.registrationDate).add(120, 'days').toISOString()
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  showOpenRequestReviewAnalisysModal(): void {
    this.updateReview.emit(true);
  }

  onCancel(): void {
    this.modalService.confirm({
      ...(this.messageService.showMessageModalConfirmCancelGeneric('Cancelar pedido') as any),
      nzOnOk: () => {
        this.riplaReviewRequest.patchCancelSubProjectReview(this.reviewId)
          .pipe(takeUntil(this.destroy$))
          .subscribe({
            next: (response) => {
              this.messageService.showSucessMessage('Pedido Cancelado com sucesso.');
              this.cancelReview.emit();
            },
            error: (err) => {
              this.messageService.showErrorMessage(err.error.errors.details)
            }
          });
      }
    });
  }

}
