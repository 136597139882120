import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {
  DISCIPLINE_ASSESSMENT_LIST,
  PROCESS_BY_PROJECT_ID,
  PROJECT_API,
  PROJECTS_V2,
  RESTRICTIONS
} from "../../modules/projects/constants/apiEndpointsConstants";
import {SubProjectModelReq} from "../models/projects-v2.model";
import {ProjectV2ViewResponseDto} from "../dto/project-v2-view-response.dto";
import {ProjectByIdResponseDto} from "../dto/project-by-id-response.dto";
import { ProcessesListResponseDto } from '../dto/process-list-response.dto';
import {SubProjectByIdResponse} from "../dto/subproject-by-id-response.dto";
import { DisciplineBySubProjectListResponse } from '../dto/discipline-by-subproject-list-response.dto';

@Injectable({
  providedIn: 'root'
})
export class RestrictionsService {

  constructor(private readonly http: HttpClient) {
  }

  private project: any;

  getRestrictions(): Observable<any> {
    const url = `${RESTRICTIONS}`;
    return this.http.get<any>(url);
  }
  getProjectById(projectId: number): Observable<ProjectV2ViewResponseDto> {
    return this.http.get<ProjectV2ViewResponseDto>(`${PROJECTS_V2}/${projectId}`);
  }
  editSubProjectRascunho(body: any, id: number){
    return this.http.put<SubProjectModelReq>(`${PROJECTS_V2}/${id}`, body)
  }
  getProjectByProjectId(projectId: number): Observable<ProjectByIdResponseDto> {
    return this.http.get<ProjectByIdResponseDto>(`${PROJECT_API}/${projectId}`);
  }

  getSubProjectBySubProjectId(subProjectId: number): Observable<SubProjectByIdResponse> {
    return this.http.get<SubProjectByIdResponse>(`${PROJECTS_V2}/${subProjectId}`);
  }

  updateSubProjectUser(subProjectId: number, body: any): Observable<SubProjectByIdResponse> {
    return this.http.patch<SubProjectByIdResponse>(`${PROJECTS_V2}/user-responsible/${subProjectId}`,body);
  }

  getProcessessList(projectId: number): Observable<ProcessesListResponseDto[]>{
    return this.http.get<any>(`${PROCESS_BY_PROJECT_ID}/${projectId}`);
  }

  getDisciplineAssessmentList(projectId: number, subprojectId: number): Observable<DisciplineBySubProjectListResponse[]> {
    return this.http.get<DisciplineBySubProjectListResponse[]>(`${DISCIPLINE_ASSESSMENT_LIST}/${subprojectId}`)
  }

  addNewDisciplineSubProject(request: any, subprojectId: number):Observable<any>{
    return this.http.post(`${PROJECTS_V2}/${subprojectId}/disciplines`, request);
  }
}
