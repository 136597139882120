<div class="rounded-row projectInfoBox" *ngIf="!loading && project!== null">
  <h2 class="mb-20">{{ 'projects.info.title' | translate }}</h2>
  <h3 class="mb-10">{{ 'projects.info.subtitle' | translate }}</h3>
  <div class="projectInfo">
    <span class="leftText">{{ 'projects.info.name' | translate }}</span>
    <span class="rightText">
      <app-short-text-hint id="projectName" [originalText]="project.name" [maxChars]="50"></app-short-text-hint></span>
  </div>
  <div class="projectInfo">
    <span class="leftText">{{ 'projects.info.number' | translate }}</span>
    <span class="rightText" id="projectNumber">{{ project.number }}</span>
  </div>
  <div class="projectInfo">
    <span class="leftText">{{ 'projects.info.code' | translate }}</span>
    <span class="rightText" id="projectCode">{{ project.code ? project.code : "-" }}</span>
  </div>
  <div class="projectInfo">
    <span class="leftText">{{ 'projects.info.location' | translate }}</span>
    <span class="rightText" id="projectLocation">{{ project.region.description }} / {{ project.complex.description }}
      {{ project.operationalUnit ? '/ ' + project.operationalUnit.description : '' }}</span>
  </div>
  <div class="projectInfo">
    <span class="leftText">{{ 'projects.info.area' | translate }}</span>
    <span class="rightText" id="projectAreadescription">{{ project.area.description }}</span>
  </div>
  <div class="projectInfo">
    <span class="leftText">{{ 'projects.info.responsible' | translate }}</span>
    <span class="rightText" id="projectResponsible">{{ project.userLegalRepresentative.name | titlecase}}</span>
  </div>
  <div class="projectInfo">
    <span class="leftText">{{ 'projects.info.classification' | translate }}</span>
    <span class="rightText" id="projectClassification">{{ project.classificationType | titlecase }}</span>
  </div>
  <div class="projectInfo">
    <span class="leftText">{{ 'projects.info.description' | translate }}</span>
    <span class="rightText" id="projectDescription">{{ project.comments ? project.comments : "-"}}</span>
  </div>
  <h3 class="mb-10 doc-title">{{ 'projects.info.documents' | translate }}</h3>
  <div class="fileList">
    <a class="fileLink"
       (click)="downloadFile(attachment.id,attachment.name)"
       *ngFor="let attachment of attachments"
       nz-tooltip nzTooltipTitle="{{attachment.name}}"
       [nzTooltipPlacement]="['topLeft', 'leftTop']" >
      <app-svg-icon
        *ngIf="(attachment.name?.includes('.shp'))"
        icon="map_icon" class="iconAlignCenter mr-10">
      </app-svg-icon>
      <app-svg-icon [width]="16" [height]="16"
        *ngIf="(!attachment.name?.includes('.shp'))"
        icon="uploaded_document-icon" class="iconAlignCenter mr-10">
      </app-svg-icon>
      <span class="fileName">{{attachment.name.length > 40 ? (attachment.name | slice:0:30) + '...' + (attachment.name | slice: attachment.name.length - 10 : attachment.name.length) : attachment.name}}</span>
    </a>
  </div>
</div>

