<div class="rounded-row">
  <h2 class="mb-10">{{ 'projects.assessment.about-assessment' | translate }}</h2>
  <nz-table nzTemplateMode
            nzSize="small">
    <thead>
    <tr>
      <th class="tableTh">{{ 'projects.assessment.analysis-responsible' | translate }}</th>
      <th class="tableTh">{{ 'projects.assessment.open-date' | translate }}</th>
      <th class="tableTh">{{ 'projects.assessment.deadline-date' | translate }}</th>
      <th class="tableTh">{{ 'projects.assessment.status' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr class="editable-row" id="assessmentInfo">
      <td class="tableTd" id="assessmentUserName">
        {{ disciplineAssessment.data.userAssessment.name }}
      </td>
      <td class="tableTd" id="assessmentRegistrationDate">
        {{ disciplineAssessment.data.registrationDate | date: 'dd/MM/yyyy' }}
      </td>
      <td class="tableTd" id="assessmentDeadlineDate">
        {{ disciplineAssessment.data.analysisDeadline | date: 'dd/MM/yyyy' }}
      </td>
      <td class="tableTd statusTd">
        <span 
        id="assessmentStatus"
        [ngClass]="disciplineAssessment.data.status === 'EAN' ? 'statusOff' : 'statusOn'"
        [class.statusDisciplineAfterDate]="isDeadLineDate(disciplineAssessment.data) && disciplineAssessment.data.status === 'EAN'">
         {{ 'projects.assessment.statuses.' + disciplineAssessment.data.status.toLowerCase() | translate }}
        </span>
      </td>
    </tr>
    </tbody>
  </nz-table>
</div>
