import { ReviewResponse } from "../models/project-v2-ripla-review-request.model"

export interface SubProjectByIdResponse {
  id: number
  projectId: number
  projectName: string
  projectCode: string
  area: Area
  userLegalRepresentative: UserLegalRepresentative
  projectUserLegal: UserLegalRepresentative
  number: string
  type: string
  projectClassification: string
  projectComplex: ProjectComplex
  projectOperationalUnit: ProjectOperationalUnit
  comments: string
  folder: any
  title: string
  version: number
  status: string
  submissionDate: string
  analysisDocumentUrl: string
  analysisDeadLine: string
  subProjectReviews: ReviewResponse[]
  asmtRegistrationDate: string
  overdue: boolean
}

export interface Area {
  id: number
  description: string
}

export interface UserLegalRepresentative {
  id: number
  name: string
  email?: string
  complexes?: Complex[]
}

export interface Complex {
  id: number
  code: string
  description: string
  region: Region
}

export interface Region {
  id: number
  description: string
  code: string
}

export interface ProjectComplex {
  id: number
  code: string
  description: string
  region: Region
}

export interface ProjectOperationalUnit {
  id: number
  description: string
  code: any
}

export interface SubProjectReview {
  id: number
  subProjectId: number
  user: User
  comments: string
  registrationDate: string
  dateResponse: string
  userResponse: User
  deadline: string
  status: string
  subProjectReviewReasons: SubProjectReviewReason[]
}

export interface User {
  id: number
  name: string
  email: string
  roles: Role[]
}

export interface Role {
  id: number
  role: string
  description: string
  active: string
}

export interface SubProjectReviewReason {
  id: number
  reason: Reason
}

export interface Reason {
  id: number
  description: string
}
