<div class="app-select-multiple-tag sl__form">
    <div class="sl__form__label">
        <span class="sl__form__required" *ngIf="required">*</span>
        <span class="sl__form__title">{{ name }}</span>
    </div>

    <nz-select
        [nzBorderless]="false"
        [nzStatus]="status"
        [nzId]="id"
        [nzMaxTagCount]="maxTagCount"
        [nzMaxTagPlaceholder]="tagPlaceHolder"
        nzMode="tags"
        [compareWith]="compareFn"
        [nzPlaceHolder]="placeHolder"
        [formControl]="controls"
        (ngModelChange)="onChangeValue($event)">
        <nz-option 
            *ngFor="let item of listOfOption" 
            [nzLabel]="item.description"
            [nzValue]="item">
        </nz-option>
    </nz-select>
    
    <ng-template #tagPlaceHolder let-selectedList>+ {{ selectedList.length }} selecionados</ng-template>
   
    <div class="error" *ngIf="(errorMessage !== '')">
        {{ errorMessage }}
    </div>
</div>