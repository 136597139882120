import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationsService } from '../notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  wholeList:any[] = [];
  listContent:any = [];
  listAssigned;
  allNotifications: any[];

  antDrawerBodyElementRef: any;

  constructor(
    private notificationService: NotificationsService,
  ) { }

  ngOnInit() {
   this.getAllNotifications();
   this.getAssignedNotifications();
   this.getMentionsNotifications();
   this.onScroll();
  }

  ngOnDestroy(): void {
    this.antDrawerBodyElementRef = null;
  }

  getAllNotifications(){
    this.notificationService.getAllNotifications().subscribe({
      next: (data:any) => {
        this.wholeList = data.content;
        this.listContent = data.content;
        this.allNotifications = data.content;
      },
      error: (data) => {}
    });
  }

  listFilter(event){

    if (event.length === 0) {
      this.listContent = this.allNotifications;
    }else{
      this.listContent = this.allNotifications;
      this.listContent = this.listContent.filter( element => element.projectName == event[0].projectName);
    }
  }

  getAssignedNotifications(){
    this.listAssigned = this.listContent.filter(item => item.notificationRecipients[0].main === true);
  }

  getMentionsNotifications(){

  }

  onScroll() {
    this.antDrawerBodyElementRef = document.querySelector('.ant-drawer-body');
    this.antDrawerBodyElementRef.addEventListener('scroll', () => {
       this.antDrawerBodyElementRef.click();
    });
  }
}
