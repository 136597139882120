<div class="container" *ngIf="!loadingAssessment && !loadingProject">
  <ng-template #iconTemplate>
    <img src="assets/icons/icon_arrow_breadcrumb.svg" alt="">
  </ng-template>
  <div nz-row
       class="mb-20">
    <div nz-col
         nzSpan="16"
         class="header">
      <a [routerLink]="['/project-v2-analysis/' + projectId + '/' + subProjectId]"
         class="mr-10"
         id="goBackToRiplaAnalysis">
        <img src="/assets/icons/icon_arrow-back.svg" alt="">
      </a>
      <h1 id="projectAssessmentName">
        {{ 'projects.assessment.analysis-of' | translate }}
        {{ disciplineAssessment.data.discipline.description }}
      </h1>
    </div>
    <div *ngIf="disciplineAnalisys" nz-col
         nzSpan="8"
         class="action-box">
      <button *ngIf="disciplineAssessment.data.status !== 'CON'" class="btn-save-assessment"
              (click)="submitForm('EAN')">
        <img src="assets/icons/icon_save.svg"
             alt="">
        {{ 'projects.assessment.save-sketch' | translate }}
      </button>

      <button class="btn-end-assessment"
              (click)="submitForm('CON')">
        <img src="assets/icons/icon_check_small.svg"
             alt="">
        {{ 'projects.assessment.end-assessment' | translate }}
      </button>
    </div>
  </div>
  <div nz-row>
    <div nz-col
         nzSpan="9"
         class="pr-20">
      <app-project-info [projectId]="projectId"
                        [project]="project"
                        [subProjectId]="subProjectId"
                        *ngIf="project && subProjectId"></app-project-info>
    </div>
    <div nz-col
         nzSpan="15">

      <app-assessment-info
        [assessmentId]="assessmentId"
        [disciplineAssessment]="disciplineAssessment">
      </app-assessment-info>

      <div class="rounded-row mt-20 form-assessment" (scroll)="formAssessmentOnScroll()">

        <app-colaborative-link [collaborativeLink]="collaborativeArchive"
                               [subProjectId]="subProjectId"
                               *ngIf="!loadingSubProjects"></app-colaborative-link>

        <h2 class="mb-10">{{ 'projects.assessment.general-evaluation' | translate }}</h2>
        <form nz-form [formGroup]="form">
          <div nz-row>
            <div nz-col
                 nzSpan="12">
              <nz-form-item class="sl-form-item">
                <nz-form-label [nzSm]="24"
                               [nzXs]="24"
                               nzFor="requiresFieldVisit">
                  <span class="sl-form-label">
                    {{ 'projects.assessment.requiresFieldVisit' | translate }}
                    <span class="colorRed">*</span>
                  </span>
                </nz-form-label>
                <nz-form-control [nzSm]="24"
                                 [nzXs]="24">
                  <nz-radio-group formControlName="requiresFieldVisit"
                                  (ngModelChange)="updateValidators()">
                    <label nz-radio
                           nzValue="true"
                           id="requiresFieldVisitYes"
                           class="radioText">
                           {{ 'commons.yes' | translate }}
                    </label>
                    <label nz-radio
                           nzValue="false"
                           id="requiresFieldVisitNo"
                           class="radioText">
                           {{ 'commons.no' | translate }}
                    </label>
                  </nz-radio-group>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item class="sl-form-item"
                            *ngIf="form?.value?.requiresFieldVisit === 'true'">
                <nz-form-label [nzSm]="24"
                               [nzXs]="24"
                               nzFor="analysisDeadlineAfter">
                  <span class="sl-form-label">
                    {{ 'projects.assessment.analysisDeadlineAfter' | translate }}
                    <span class="colorRed">*</span>
                  </span>
                </nz-form-label>
                <nz-form-control [nzSm]="24" [nzXs]="24">
                  <nz-date-picker
                    #analysisDataPicker
                    nzFormat="dd/MM/YYYY"
                    formControlName="analysisDeadlineAfter"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="12">
              <!-- Flag Questions -->
              <nz-form-item class="sl-form-item"
                            *ngFor="let question of disciplineAssessment.data.answeredQuestions; index as i">
                <nz-form-label [nzSm]="24"
                               [nzXs]="24">
                  <span class="sl-form-label">
                    {{ question.question }}
                    <span class="colorRed">*</span>
                  </span>
                </nz-form-label>
                <nz-form-control [nzSm]="24"
                                 [nzXs]="24"
                                 class="question">
                  <nz-radio-group formControlName="question-{{i}}">
                    <label nz-radio
                           nzValue="true"
                           class="radioText " id="question-label-sim-{{i}}">{{ 'commons.yes' | translate }}</label>
                    <label nz-radio
                           nzValue="false"
                           class="radioText question-label-nao-{{i}}">{{ 'commons.no' | translate }}</label>
                  </nz-radio-group>
                </nz-form-control>
              </nz-form-item>

            </div>
          </div>
          <div nz-row>
            <div nz-col
                 nzXs="24"
                 nzSm="24"
                 nzMd="24"
                 nzLg="24"
                 nzXl="24"
                 class="form-col">
              <nz-form-item class="sl-form-item">
                <nz-form-label [nzSm]="24"
                               [nzXs]="24"
                               nzFor="analysisSummary">
                  <span class="sl-form-label">{{ 'projects.assessment.analysisSummary' | translate }}:</span>
                </nz-form-label>
                <nz-form-control [nzSm]="24"
                                 [nzXs]="24"
                                 nzErrorTip="Por favor insira o resumo com no máximo 2000 caracteres">
                  <nz-textarea-count [nzMaxCharacterCount]="2000">
                    <textarea id="analysisSummary"
                              rows="5"
                              formControlName="analysisSummary"
                              nz-input
                              placeholder="{{ 'projects.assessment.provideSummary' | translate }}">
                    </textarea>
                  </nz-textarea-count>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div nz-row class="mb-20">
            <app-outer-costs (sendCostStudy)="setCostStudyList($event)" (deleteCostStudy)="removeCostStudy($event)" [disciplineId]="disciplineAssessment.data.id" [outerCosts]="costStudyList" [outerCost]="false" [edit]="false"></app-outer-costs>
          </div>
          <div nz-row class="mb-10">
            <app-outer-costs (sendCostStudy)="setCostStudyList($event)" (deleteCostStudy)="removeCostStudy($event)" [disciplineId]="disciplineAssessment.data.id" [outerCost]="true" [outerCosts]="costStudyList" [edit]="false" [hint]="hintOtherCost"></app-outer-costs>
          </div>
          <div nz-row>
            <h2 class="mb-10 mt-10">{{ 'projects.assessment.additional-documents' | translate }}</h2>
            <div nz-col
                 nzXs="24"
                 nzSm="24"
                 nzMd="24"
                 nzLg="24"
                 nzXl="24"
                 class="form-col">
              <nz-upload id="btnUpArquivoV2"
                         nzType="drag"
                         [nzMultiple]="true"
                         [nzBeforeUpload]="beforeUpload"
                         [(nzFileList)]="fileList"
                         [nzRemove]="removeFiles"
                         nzListType="picture"
                         [nzDownload]="downloadFile"
                         class="uploadProjectFiles">
                <p class="ant-upload-drag-icon" #uploadInput>
                  <img src="assets/icons/upload-v2-icon.svg" alt="upload" />
                </p>
                <p class="ant-upload-hint">
                  <span class="text-upload">{{ 'projects.assessment.drag-drop' | translate }}</span>
                </p>
              </nz-upload>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
