<div class="container request-review-modal-container">
  <div style="display: flex; justify-content: space-between;">
    <div nz-col  class="ali-center-flex">
      <h1 class="titulo" *ngIf="type === 'suspension'">{{ 'projects.suspension-cancellation.modal.title.suspension' | translate }}</h1>
      <h1 class="titulo" *ngIf="type === 'cancellation'">{{ 'projects.suspension-cancellation.modal.title.cancellation' | translate }}</h1>
    </div>
    <div class="buttonClose" (click)="onCancel()">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M12 12L4 4M4 12L12 4" stroke="#007E7A" stroke-width="1.77778" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  </div>

  <form [formGroup]="formRequestReview" class="mt-4">

    <div class="line__form">
      <app-select-multiple-tag
          [id]="'select-multiple-tag-detail'"
          [name]="this.reasonNameText"
          errorMessage=""
          [maxTagCount]="3"
          [status]="checkInputForm('suspensionCancellationReason')"
          [listOfOption]="listOfOption"
          [controls]="formRequestReview.get('suspensionCancellationReason')"
          (changeValue)="onChangeReason($event)">
      </app-select-multiple-tag>
    </div>

    <div class="textarea-detail">
      <span>{{ 'projects.suspension-cancellation.modal.reason' | translate }}</span><span>
        *</span>
      <textarea formControlName="comments" rows="6" nz-input 
        placeholder="{{ 'projects.suspension-cancellation.modal.reason-placeholder' | translate }}">
      </textarea>
    </div>

    <div class="columns" *ngIf="type === 'suspension'">
      <div class="titleEstimative">
        <span>{{ 'projects.suspension-cancellation.modal.estimative' | translate }}</span><span>*</span>
        <div nz-row class="flex-wrap">
          <div class="sl-equal-col">
            <nz-form-item class="sl-form-item">
              <nz-form-control [nzSm]="24" [nzXs]="24">
                <nz-radio-group formControlName="returnProject" (ngModelChange)="changeReturnProject()">

                  <label nz-radio [nzValue]="'Sim'" id="retunProjectSim" class="options">
                    <span style="display: flex;">
                      <span class="card--radio__span options">Sim</span>
                  </span>
                  </label>

                  <label nz-radio [nzValue]="'Nao'" id="retunProjectNao" class="options">
                    <span style="display: flex;">
                      <span class="card--radio__span options">Não</span>
                    </span>
                  </label>
                </nz-radio-group>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>

      <div class="titleEstimative" *ngIf="hasEstimative">
        <span>{{ 'projects.suspension-cancellation.modal.prediction-of-resumption' | translate }}</span><span>*</span>
          <!-- INSERIR DATEPICKER -->
          <div class="dateBorder">
            <nz-date-picker nzFormat="dd/MM/YYYY" [(ngModel)]="returnDateModel" [nzSuffixIcon]="''" nzSize="small"
              formControlName="returnDate" [nzDisabledDate]="disabledDate">
            </nz-date-picker>
          </div>
      </div>

    </div>

    <div class="mt-5">
      <app-information-banner
        [bannerText]="getBannerText()"
        [iconName]="'icon_warning_orange.svg'"
        [bannerColor]="'yellow'"
        [colorText]="'orange'"
    ></app-information-banner>

    </div>

  </form>

  <div class="buttons">
    <button class="btn-cancel" (click)="onCancel()">
      {{ 'commons.cancel' | translate }}
    </button>

    <button *ngIf="type==='suspension'" class="btn-request" (click)="onSubmit()">
      {{ 'commons.send-request-suspension' | translate }}
    </button>

    <button *ngIf="type==='cancellation'" class="btn-request" (click)="onSubmit()">
      {{ 'commons.send-request-cancellation' | translate }}
    </button>
  </div>


</div>
