import {ChangeDetectorRef, Component, Input, OnInit, ViewContainerRef} from '@angular/core';
import {GeneralInformation} from '../../../interface/nlp-interface';
import {UtilService} from 'src/app/shared/util.service';
import {Observable} from 'rxjs';
import {NlpAddProjInformationsComponent} from '../../../nlp-add-proj-informations/nlp-add-proj-informations.component';
import {NzModalService} from 'ng-zorro-antd/modal';
import {AttachmentsService} from "../../../../../shared/services/attachments.service";
import {
  ProjectsInformationsService
} from "../../../../projects/projects-flow/components/menu-ripla/project-sidebar/projects-informations/projects-informations.service";
import {SidebarService} from "../../service/sidebar.service";

@Component({
  selector: 'app-card-sidebar',
  templateUrl: './card-sidebar.component.html',
  styleUrls: ['./card-sidebar.component.scss']
})
export class CardSidebarComponent implements OnInit {

  @Input() generalInformation !: GeneralInformation;
  @Input() anexo!: Observable<any[]>;
  @Input() reportsFiles!: Observable<any[]>;
  brazilTimeZone = 'America/Sao_Paulo';
  modalClosedSuccessfully = false;

  constructor(
    private readonly util: UtilService,
    private modalService: NzModalService,
    private readonly viewContainerRef: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private attachmentsService: AttachmentsService,
    private projectsInformationsService: ProjectsInformationsService,
    private sidebarService: SidebarService
  ) {
  }


  getIconFromFileName(filename: string) {
    return this.util.getIconFromFileName(filename);
  }

  editProductNlp(): void {

    const modal = this.modalService.create<NlpAddProjInformationsComponent>({
      nzTitle: "Editar informações complementares",
      nzWidth: 780,
      nzContent: NlpAddProjInformationsComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
      nzOkLoading: true,
      nzCancelText: 'No',
      nzOnCancel: () => {
        this.modalClosedSuccessfully = true
      },
      nzComponentParams: {
        editMode: true,
      }
    })

    modal.getContentComponent();
    modal.afterClose.subscribe(() => {
      if (this.modalClosedSuccessfully) {
        this.cdr.detectChanges();
      } else {
        this.cdr.detectChanges();
      }
      this.modalClosedSuccessfully = false;
    });
  }

  downloadFile(id: number, name: string) {
    this.attachmentsService.getDownloadFile(id).subscribe({
      next: (resp) => this.attachmentsService.downloadFile(resp, name),
      error: () => this.handleDownloadError(),
    });
  }

  private handleDownloadError() {

  }

  ngOnInit(): void {
    if (this.sidebarService.selectedProjectId !== 0) {
      this.projectsInformationsService.getProjectsInformations(this.sidebarService.selectedProjectId).subscribe(
        projectsInformations => {
          this.generalInformation.name = projectsInformations.projectName;
          this.generalInformation.complex = projectsInformations.complex;
          this.generalInformation.corridor = projectsInformations.installation;
          this.generalInformation.responsible = projectsInformations.userDescription;
        }
      );
    }
  }
}
