<div nz-col nzXs="24" nzSm="24" nzMd="24" nzLg="24" nzXl="24">
  <div class="review">
    <h1 class="review__header">
      <!-- Pedido de revisão da RIPLA para área solicitante -->
      {{'projects.analysis.ripla-review-request' | translate}}
    </h1>
    <div class="review__data">
      <div class="review__data__item">
        <p class="review__data__item__title">Data do pedido</p>
        <p class="review__data__item__description">{{reviewData.registrationDate | date : "dd/MM/yyyy" }}</p>
      </div>

      <div class="review__data__item">
        <p class="review__data__item__title">Prazo para retorno</p>
        <p class="review__data__item__description">{{reviewData.deadline | date : "dd/MM/yyyy"}}

          <span>
            <img src="assets/icons/icon_information.svg" alt="Informação" nz-tooltip nzTooltipPlacement="bottom"
              nzTooltipTitle="RIPLAs sem resposta em até 120 dias serão canceladas e arquivadas." />
          </span>
        </p>
      </div>

      <div class="review__data__item">
        <p class="review__data__item__title">Solicitado por</p>
        <p class="review__data__item__description">{{reviewData.user.name}}</p>
      </div>

      <div class="review__data__item">
        <p class="review__data__item__title">Motivos para revisão</p>
        <ul>
          <li class="review__data__item__description" *ngFor="let reason of reviewData.subProjectReviewReasons">
            {{reason.reason.description}}
          </li>
        </ul>
      </div>

      <div class="review__data__item">
        <p class="review__data__item__title">Descrição</p>
        <p class="review__data__item__description"> {{reviewData.comments}}</p>
      </div>
    </div>

    <div class="review__footer">
      <button id="editReviewButton" type="button" class="button button__edit" [disabled]="!enableEditAndCancelReview"
        *ngIf="!enableResponseReview" (click)="showOpenRequestReviewAnalisysModal()">
        <i class="icon__edit">
          <svg class="svg-fill" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M14.1395 3.69638L16.3034 5.86034C16.5693 5.48321 16.7068 5.15032 16.7508 4.87173C16.8114 4.48799 16.713 4.10607 16.3034 3.69643C15.8937 3.28679 15.5118 3.18845 15.1281 3.24903C14.8495 3.29302 14.5166 3.43054 14.1395 3.69638ZM15.0712 7.19258L12.8072 4.92857L3.96181 13.774L3.9618 16.038L6.22581 16.038L15.0712 7.19258ZM14.8453 1.4579C15.8653 1.29685 16.8132 1.64179 17.5856 2.41422C18.358 3.18665 18.703 4.13456 18.5419 5.15454C18.3895 6.11976 17.8039 7.02436 16.9946 7.83368L7.24247 17.5858C7.07243 17.7558 6.84182 17.8513 6.60136 17.8513L3.05514 17.8513C2.81468 17.8513 2.58407 17.7558 2.41404 17.5858C2.244 17.4158 2.14848 17.1851 2.14848 16.9447L2.14848 13.3985C2.14848 13.158 2.244 12.9274 2.41403 12.7574L12.1661 3.00525C12.9755 2.19593 13.8801 1.6103 14.8453 1.4579Z" />
          </svg>
        </i>
        <span class="button__text">Editar</span>
      </button>

      <button id="cancelReviewButton" type="button" class="button button__cancel"
        [disabled]="!enableEditAndCancelReview" (click)="onCancel()">
        <i class="icon__cancel">
          <svg class="svg-stroke" width="24" height="24" viewBox="0 0 24 24" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9 15L15 9M21 12C21 13.78 20.4722 15.5201 19.4832 17.0001C18.4943 18.4802 17.0887 19.6337 15.4442 20.3149C13.7996 20.9961 11.99 21.1743 10.2442 20.8271C8.49836 20.4798 6.89472 19.6226 5.63604 18.364C4.37737 17.1053 3.5202 15.5016 3.17294 13.7558C2.82567 12.01 3.0039 10.2004 3.68509 8.55585C4.36628 6.91131 5.51983 5.50571 6.99987 4.51677C8.47991 3.52784 10.22 3 12 3C14.387 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12V12ZM15 15L9 9L15 15Z"
              stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </i>
        <span class="button__text">Cancelar pedido de revisão</span>
      </button>


      <button id="responseReviewButton" type="button" class="button button__cancel" *ngIf="enableResponseReview"
        [routerLink]="['/project-v2-requisition-revision/' + projectId +  '/' + subProjectId + '/' + reviewId]">

        <i class="icon__response">
          <svg class="svg-stroke" width="16" height="16" viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M4.66663 10.6667V13.3333C4.66663 13.5101 4.73686 13.6797 4.86189 13.8047C4.98691 13.9298 5.15648 14 5.33329 14H13.3333C13.5101 14 13.6797 13.9298 13.8047 13.8047C13.9297 13.6797 14 13.5101 14 13.3333V2.66667C14 2.48986 13.9297 2.32029 13.8047 2.19526C13.6797 2.07024 13.5101 2 13.3333 2H5.33329C5.15648 2 4.98691 2.07024 4.86189 2.19526C4.73686 2.32029 4.66663 2.48986 4.66663 2.66667V6.66667"
              stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
            <path
              d="M11.3334 4C11.3334 4.17681 11.2631 4.34638 11.1381 4.4714C11.0131 4.59643 10.8435 4.66667 10.6667 4.66667H8.00005C7.82323 4.66667 7.65367 4.59643 7.52864 4.4714C7.40362 4.34638 7.33338 4.17681 7.33338 4V2H11.3334V4ZM2.19338 7.12667L3.12671 6.19333C3.25133 6.07118 3.41888 6.00276 3.59338 6.00276C3.76788 6.00276 3.93543 6.07118 4.06005 6.19333L7.33338 9.46667V11.3333H5.46671L2.19338 8.06C2.07123 7.93538 2.00281 7.76784 2.00281 7.59333C2.00281 7.41883 2.07123 7.25129 2.19338 7.12667V7.12667Z"
              stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

        </i>
        <span class="button__text">Responder pedido de revisão</span>
      </button>
    </div>
  </div>
</div>
