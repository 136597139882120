import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CompetentOrgan} from "../models/competent-organ.model";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CompetentOrganService {
  baseUrl: string = environment.apiUrl

  constructor(private readonly http: HttpClient) { }

  getCompetentOrgans(): Observable<CompetentOrgan[]>{
    return this.http.get<any>(`${this.baseUrl}/competent-organs`)
  }
}
