import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-mentions-notifications',
  templateUrl: './mentions-notifications.component.html',
  styleUrls: ['./mentions-notifications.component.scss']
})
export class MentionsNotificationsComponent {
  @Input() listItems;
  constructor() { }

}
