import {Component, Input, OnInit} from '@angular/core';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { AttachmentsModel } from '../../models/attachments.model';
import { AttachmentsService } from '../../services/attachments.service';
import { UtilService } from '../../util.service';

@Component({
  selector: 'app-generic-resume-sidebar',
  templateUrl: './generic-resume-sidebar.component.html',
  styleUrls: ['./generic-resume-sidebar.component.scss']
})
export class GenericResumeSidebarComponent implements OnInit {
  blockTitle?:string;
  attachments: NzUploadFile[];
  @Input() entityId?: number;
  @Input() entityName?: string = "";
  @Input() contentRows!:any;
  @Input() attachmentsId?: number = undefined;
  @Input() attachmentsBlockTitle?:string = "commons.files";
  
  attachmentsEntityName?:string;
  
  constructor(
    private attachmentsService: AttachmentsService,
    private readonly utilService: UtilService,
  ) { }

  ngOnInit() {
    if (this.entityId && this.entityName) {
      this.getAttachments(this.entityId, this.entityName);
    }
  }

  downloadFile = (file: NzUploadFile): void => {
    if (file.uid === undefined) return;
    this.attachmentsService.getDownloadFile(+file.uid).subscribe({
      next: (resp) => this.attachmentsService.downloadFile(resp, file.name),
    });
  };

  getAttachments(id: number, name: string) {
    this.attachmentsService.getAttachmentsByEntityIdEntityNameAndContextInfo(
      id, name, ""
    ).subscribe({
      next: (attach) => {
        this.attachments = this.buildNzUploadFileArrayFromResponse(attach);
      } 
    })
  }

  getIconFromFileName(filename: string) {
    return this.utilService.getIconFromFileName(filename);
  }

  getAttachmentTruncatedName(filename: string) {
    const name = filename.split('.')
    const filenameWithoutExtension = name.slice(0, name.length - 1).join('')
    const fileExtension = name[name.length - 1];

    if (filenameWithoutExtension.length > 30) {
      return filenameWithoutExtension.slice(0, 28) + '...' + fileExtension
    } else {
      return filename
    }
  }

  buildNzUploadFileArrayFromResponse(data: AttachmentsModel[]) {
    const files: NzUploadFile[] = [];
    for (const image of data) {
      const file: NzUploadFile = {
        description: image.description,
        name: image.name,
        filename: image.name,
        uid: image.id?.toString() || '',
        thumbUrl: this.utilService.getIconFromFileName(image.name),
        status: 'done',
      };
      files.push(file);
    }
    return files;
  }

}
