import { Component, Input } from '@angular/core';
import { ProcessesListResponseDto } from 'src/app/shared/dto/process-list-response.dto';

@Component({
  selector: 'app-list-process-edit',
  templateUrl: './list-process-edit.component.html',
  styleUrls: ['./list-process-edit.component.scss']
})
export class ListProcessEditComponent {
  @Input() process:ProcessesListResponseDto;

  constructor(
    ) { }
    
  licenseTypeFormat(title){
    return title.split(' - ');
  }
}
