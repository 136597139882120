import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { formDocumentSection } from '../../../interface/nlp-interface';
import { NLP_MODAL_NEW_SECTION_DOC_REFERENCE, NLP_MODAL_NEW_SECTION_DOC_SECTION } from 'src/app/modules/projects/constants/apiEndpointsConstants';

@Injectable({
  providedIn: 'root'
})
export class NlpModalSectionTermReferenceService {

  flagDocumentSection = BehaviorSubject
  documentFlagSubject: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);
  message$ = this.documentFlagSubject.asObservable();


  baseUrl: string = environment.apiUrl
  constructor(private readonly http: HttpClient) {
  }

  postDocumentReference(idProjectTemp?:number | null, nameDocument?:string): Observable<any> | undefined{
    if(idProjectTemp !== undefined){
      return  this.http.post<any>(`${NLP_MODAL_NEW_SECTION_DOC_REFERENCE}/${idProjectTemp}`,{description:nameDocument})
    }
    return  ;
  }


  postDocumentSection(result:any, form:formDocumentSection): Observable<any> {
    return  this.http.post<any>(`${NLP_MODAL_NEW_SECTION_DOC_SECTION}`,{
      idDocReference:result.id,
      description:form.content,
      title:form.title
    })
  }

  postDocumentPositionSection(result:any, form:formDocumentSection): Observable<any> {
    return  this.http.post<any>(`${NLP_MODAL_NEW_SECTION_DOC_SECTION}/${result.position}`,{
      idDocReference:result.id,
      description:form.content,
      title:form.title
    })
  }

  editDocumentSection(result:any, form:formDocumentSection): Observable<any> {
    return  this.http.patch<any>(`${NLP_MODAL_NEW_SECTION_DOC_SECTION}/${result.id}`,{
      description:form.content,
      title:form.title
    })
  }


  deleteDocumentSection(result:any): Observable<any> {
    return  this.http.delete<any>(`${NLP_MODAL_NEW_SECTION_DOC_SECTION}/${result.id}`)
  }
}
