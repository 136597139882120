<form
  nz-form
  [formGroup]="form">


  <div nz-row>
    <div
      nz-col
      nzXs="24"
      nzSm="24"
      nzMd="24"
      nzLg="24"
      nzXl="24"
      class="form-col">
      <nz-form-item class="sl-form-item">
        <nz-form-label
          [nzSm]="24"
          [nzXs]="24"
          nzFor="termReference"
          nzRequired
        >
          <span class="sl-form-label">Selecionar documento:</span>
        </nz-form-label>
        <nz-form-control
          [nzSm]="24"
          [nzXs]="24"
          nzErrorTip="Selecione documento pré-cadastrado"
        >
          <nz-select
            id="selTermReference"
            nzBorderless
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Selecionar documento pré-cadastrado"
            formControlName="termReference"
            [nzSize]="'small'"
          >
            <nz-option
              *ngFor="let term of terms"
              nzLabel="{{ term.description }}"
              nzValue="{{ term.id }}"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>

<div class="ant-modal-footer">
  <div nz-row>
    <div nz-col
         nzXs="18"
         nzSm="18"
         nzMd="18"
         nzLg="18"
         nzXl="18"
         [nzSpan]="18" [nzPush]="6">
      <button class="btn-sl btn-sl-grey btn-cancel"
              (click)="handleCancel()">Cancelar
      </button>

      <button button
              nz-button
              nzType="primary"
              class="btn-sl btn-sl-green btn-save"
              [disabled]="!form.valid"
              (click)="submitForm()">Salvar
      </button>
    </div>
    <div nz-col [nzSpan]="6" [nzPull]="18"></div>
  </div>
</div>

