<div *ngIf="isEIA && !disabledBlock" class="round-border mb-10">
  <app-activity-study-items
    [activitySubId]="activitySubId"
    [projectId]="projectId"
    [activities]="eiaStudys"
    [processId]="id"
    (reload)="onReload($event)"
  >
  </app-activity-study-items>
</div>

<section
  class="activitiesContainer"
  [ngClass]="{ 'empty-placeholder': disabledBlock }"
>
  <div class="activitiesContainer__card">
    <header
      class="activitiesContainer__header"
      [ngClass]="{ 'empty-placeholder': disabledBlock }"
    >
      <h3 class="activitiesContainer__header__title">
        {{ componentTitle | translate }}
      </h3>
      <a
        *ngIf="!disabledBlock && !addingProcess"
        (click)="toNewActivity()"
        class="project-doc-link new-content"
      >
        <img
          src="/assets/icons/icon_plus_circle.svg"
          alt="{{ 'projects.view.add-ripla' }}"
        />
      </a>
    </header>
    <div [ngStyle]="{ 'overflow-y': disabledBlock ? 'auto' : 'hidden' }">
      <app-activities-list
        *ngIf="!disabledBlock"
        [hasActivityParent]="type === 'SUB'"
        [activitySubId]="activitySubId"
        [projectId]="projectId"
        [activities]="data"
        [processId]="id"
        [titleResponsibleFor]="dinamicTitle"
        (reload)="onReload($event)"
      >
      </app-activities-list>
      <div class="empty_section" *ngIf="disabledBlock || data.length === 0">
        <img src="/assets/icons/empty_box-icon.svg" alt="Sem dados" />
        <span class="empty_section_description">{{
          emptyPlaceholder | translate
        }}</span>
      </div>
    </div>
  </div>
</section>
