import { Subject, take } from "rxjs";
import { DatePipe, Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import {
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewContainerRef
} from '@angular/core';


import { NzUploadFile } from "ng-zorro-antd/upload";
import { NzModalService } from "ng-zorro-antd/modal";

import { UserModel } from "src/app/shared/models/user.model";
import { AttachmentsModel } from "src/app/shared/models/attachments.model";

import { UtilService } from "src/app/shared/util.service";
import { UserService } from "src/app/shared/services/user.service";
import { AttachmentSaveDto } from "src/app/shared/dto/attachment-save.dto";
import { ActivitiesService } from "src/app/shared/services/activities.service";
import { ProjectsV2Service } from "src/app/shared/services/projects-v2.service";
import { MessageService } from "src/app/shared/common/services/message.service";
import { AttachmentsService } from "src/app/shared/services/attachments.service";
import { ProjectByIdResponseDto } from "src/app/shared/dto/project-by-id-response.dto";
import { CommunicationRequestDto } from "src/app/shared/dto/communication-request.dto";
import { ProcessesListResponseDto } from "src/app/shared/dto/process-list-response.dto";
import { CommunicationsServices } from "src/app/shared/services/communications.services";
import { CommunicationTypeService } from "src/app/shared/services/communication-type.service";
import { LicensingService } from "src/app/modules/projects/projects-licensing/service/licensing.service";

import { DisciplineAssessmentResponse } from "src/app/shared/dto/assessment-by-id-response.dto";
import { CommunicationByIdResponse } from "src/app/shared/dto/communication-by-id-response.dto";
import { CommunicationTypesResponseDto } from "src/app/shared/dto/communication-types-response.dto";
import { CommunicationsListResponseDto } from "src/app/shared/dto/communications-list-response.dto";
import { ActivitesByProcessidResponseDto } from "src/app/shared/dto/activites-by-processid-response.dto";

import { COMMUNICATION_ENTITY_NAME } from "src/app/modules/projects/constants/projectsNewConstants";

import { ProgressModalComponent } from "src/app/modules/projects-v2/project-v2-assessment/progress-modal/progress-modal.component";
import { SuccessConfirmationComponent } from 'src/app/modules/projects-v2/project-v2-assessment/success-confirmation/success-confirmation.component';
import { ProjectState } from 'src/app/shared/ngrx/breadcrumb/model';
import { BreadcrumbState } from "src/app/shared/ngrx/breadcrumb/breadcrumb.state.model";
import { Store } from "@ngrx/store";
import { BREADCRUMB_ACTIONS } from "src/app/shared/ngrx/breadcrumb/breadcrumb.actions";
import {FileValidationService} from "../../../../shared/services/file-validation.service";


@Component({
  selector: 'app-communication-new',
  templateUrl: './communication-new.component.html',
  styleUrls: ['./communication-new.component.scss']
})
export class CommunicationNewComponent implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {
  listOfOption: Array<{ label: string; value: string }> = [];
  returnMeans;
  projects;
  communicationId: string | null = '';
  projectId: string | null;
  processId: string | null;
  activityId: string | null;
  type: string | null;
  subProjectId: number;
  disciplineAssessment: DisciplineAssessmentResponse;
  fileList: NzUploadFile[] = [];
  fileLisToSave: NzUploadFile[] = [];
  filesToDelete: string[] = [];
  fileListDuplicated: NzUploadFile[] = [];
  project: ProjectByIdResponseDto;
  form!: UntypedFormGroup;
  projectDisabled: boolean = true;
  processesList: ProcessesListResponseDto[] = [];
  users: UserModel[] = [];
  compareFnString = (o1: string, o2: string): boolean => (o1 && o2 ? o1 === o2 : o1 === o2);
  communicationTypes: CommunicationTypesResponseDto[];
  deadLineResponseDate: string | null = '';
  enableAboutLicense: boolean;
  activities: any[] = [];
  @Output() newTitleEvent = new EventEmitter<string>();
  @Output() communication = new EventEmitter<CommunicationByIdResponse>();
  @Output() communicationAttachments = new EventEmitter<NzUploadFile[]>;
  @Input() isSaving: Subject<boolean>;
  projectLicenseReadonly: boolean = false;
  communicationsList: CommunicationsListResponseDto[];
  progress: number = 0;
  fileListAlreadyUploaded: NzUploadFile[] = [];
  communicationSummary: CommunicationByIdResponse = ({} as any) as CommunicationByIdResponse;
  complementaryResponseSent: boolean = false;

  statusProcess;
  sendedDate: string = '';
  deadline: string = '';
  processName: string = ''

  private readonly destroy$: Subject<any> = new Subject();
  license: any;
  activity: any;
  communicationData: any;

  isInvalidFile: boolean = false
  constructor(
    private licensingService: LicensingService,
    private projectService: ProjectsV2Service,
    private attachmentsService: AttachmentsService,
    private message: MessageService,
    private route: ActivatedRoute,
    private projectsV2Service: ProjectsV2Service,
    private fb: UntypedFormBuilder,
    private communicationTypeService: CommunicationTypeService,
    private communicationsService: CommunicationsServices,
    private userService: UserService,
    private utilService: UtilService,
    private datePipe: DatePipe,
    private activityService: ActivitiesService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private location: Location,
    private _store: Store<BreadcrumbState>,
    private fileValidationService: FileValidationService
  ) { }

  ngAfterContentInit(): void {
    this.updateSummaryTitle();
  }

  ngOnInit(): void {
    this.projectId = this.route.snapshot.queryParamMap.get('projectId');

    this.processId = this.route.snapshot.queryParamMap.get('processId');

    this.activityId = this.route.snapshot.queryParamMap.get('activityId');

    this.type = this.route.snapshot.queryParamMap.get('type');


    this.getReturnMeans();

    this.initForm();

    this.getProjectFromParameter();

    this.getCommunicationTypesByCategory(this.type);

    this.save();

    this.loadCommunicationData();

    this.getProcessStatusById(this.processId);

  }

  ngAfterViewInit() {
    this.getProcessStatusById(this.processId)
  }

  getProcessStatusById(processId){
    if (processId) {
      this.licensingService.getLicencing(processId).subscribe({
        next: (license) => {
          this.statusProcess = license.status;
        }, complete: () => {
          this.getCommunicationTypesByCategory(this.type)
        }
      })
    }
  }


  // INICIO BREADCRUMB
  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  getDataForBreadcrumb() {

    this.route.queryParams.subscribe(params => {
      if(params) { this.processName = params['processName']}
    })

    this.projectsV2Service.getProjectByProjectId(Number(this.projectId)).subscribe({
      next: (project: ProjectByIdResponseDto) => {
        this.project = project;
      },
      complete: () => {
        this.projectService.getProcessessList(Number(this.projectId)).subscribe({
          next: (data) => {
            this.processesList = data.content;
            this.license = this.processesList.filter(process => process.title === this.processName)
            if(this.license.length===0) { this.license = this.processesList.filter(process => process.id === Number(this.processId)) }
          },
          complete: () => {
            if(this.communicationId) {
              this.communicationsService.getCommunicationById(Number(this.communicationId)).subscribe({
                next: (response) => {
                  this.communicationData = response
                  this.activityId = response.activities && response.activities?.length > 0 ? response.activities[0].id .toString() : null
                },
                complete: () => {
                  this.getActivityIfIdExists();
                }
              })
            } else {
              this.getActivityIfIdExists();
            }
          }
        })
      }
    });
  }

  private getActivityIfIdExists() {
    if(this.activityId && this.activityId != 'NaN'){
      this.licensingService.getActivityById(Number(this.activityId)).subscribe({
        next: (response) => {
          this.activity = response
        },
        complete: () => {
          this.dispatchProjectOnStore()
        }
      })
    } else {
      this.dispatchProjectOnStore()
    }
  }

  private dispatchProjectOnStore(): void {
    let project: ProjectState = {
      name: 'Projetos',
      url: '/projects-v2',
      last: false,
      sublevels: true,
      project: {
        name: this.project?.name,
        url: 'project-v2-view/' + this.project?.id,
        last: false,
      },
      subProject: null,
      parecerFinal: null,
      pedidoRevisao: null,
      atividades:  this.activity ? {
        name: this.activity ?  this.activity.title.description : '...',
        url: this.activity ? 'projects/' + this.project?.id + '/process/' + this.processId + '/edit-activity/' + this.activityId : '',
        last: true
      } : null,
      subatividades: null,
      comunicacoes: {
        name: this.communicationData ? this.communicationData.title : 'Nova comunicação',
        url: this.communicationData ? 'projects/' + this.project?.id + '/process/' + this.processId + '/edit-activity/' + this.activityId : 'communications/',
        last: true
      },
      processos: {
        name: this.license.length > 0 ? this.license[0].title : (this.communicationData ? this.communicationData.projectLicensing.name : ''),
        url: 'project/' + this.project?.id + '/edit-processv2/' + (this.license[0].id ? this.license[0].id : this.processesList[0].id),
        last: false,
      },
      mudancaEscopo: null
    }
    this._store.dispatch(BREADCRUMB_ACTIONS.getRiplaProject({ project }));
  }
  // FINAL BREADCRUMB

  pegarNumerosDaURL(url) {
    const primeiroIndice = url.indexOf('=');
    if (primeiroIndice === -1) {
        return '';
    }
    const segundoIndice = url.indexOf('=', primeiroIndice + 1);
    if (segundoIndice === -1) {
        return '';
    }
    const substring = url.substring(segundoIndice + 1);
    const numeros = substring.match(/\d+/);
    if (numeros) {
        return numeros[0];
    } else {
        return '';
    }
}


  private loadCommunicationData() {
    this.communicationId = this.route.snapshot.queryParamMap.get('communicationId');

    if (this.communicationId !== undefined && this.communicationId !== null) {
      this.communicationsService.getCommunicationById(+this.communicationId).subscribe((response) => {

        this.form.patchValue(response);
        this.form.controls['projectId'].setValue(response.project?.id + '');
        this.form.controls['projectLicensingId'].setValue(response.projectLicensing?.id + '');
        this.form.controls['activitiesIds'].setValue(response.activities?.map((a) => {
          return a.id
        }));
        this.form.controls['connectionsIds'].setValue(response.connections?.map((c) => {
          return c.id
        }));
        this.form.controls['communicationTypeId'].setValue(response.communicationType?.id + '');
        this.form.controls['responsibleUserId'].setValue(response.responsibleUser?.id + '');
        this.form.controls['returnMeanId'].setValue(response.returnMean?.id + '');
        this.form.controls['date'].setValue(response.date+'T00:00:00');
        this.sendedDate = this.form.controls['date'].value;
        this.form.controls['licenseEmissionDate'].setValue(response.licenseEmissionDate !== null ? response.licenseEmissionDate + 'T00:00:00' : '');
        this.form.controls['licenseExpirationDate'].setValue(response.licenseExpirationDate != null ? response.licenseExpirationDate + 'T00:00:00' : '');
        this.deadLineResponseDate = this.datePipe.transform(response.deadLineResponseDate, 'dd/MM/yyyy');

        this.projectId = response.project?.id + '';
        this.processId = response.projectLicensing?.id + '';
        this.type = response.type + '';

        this.getProjectFromParameter();
        this.getCommunicationTypesByCategory(this.type);
        this.getAttachment();

        this.communicationSummary = response;
        this.communication.emit(this.communicationSummary);

        this.projectDisabled = true;

        this.getDataForBreadcrumb()

      });
    } else {
      if(this.route.snapshot.queryParamMap.get('activityId')){
        this.activityId = this.route.snapshot.queryParamMap.get('activityId');
        if (this.activityId && this.activityId != 'NaN') {
          this.licensingService.getActivityById(Number(this.activityId)).subscribe((response) => {
            this.form.controls['activitiesIds'].setValue(response.id);
          })
        }
      }
      this.getDataForBreadcrumb()
      this.updateSummaryTitle();
    }
  }

  getAttachment() {

    this.attachmentsService
      .getAttachmentsByEntityAndEntityId(
        this.communicationId !== null ? +this.communicationId : 0,
        COMMUNICATION_ENTITY_NAME
      )
      .subscribe((data) => {
        const nzUploadFiles = this.buildNzUploadFileArrayFromResponse(data);
        this.fileList = nzUploadFiles;
        this.fileListAlreadyUploaded = nzUploadFiles;
      });
  }

  private buildNzUploadFileArrayFromResponse(data: AttachmentsModel[]) {
    const files: NzUploadFile[] = [];
    for (const image of data) {
      const file: NzUploadFile = {
        description: image.description,
        name: image.name,
        filename: image.name,
        uid: image.id?.toString() || '',
        thumbUrl: this.utilService.getIconFromFileName(image.name),
        status: 'done',
      };
      files.push(file);
    }
    return files;
  }

  private getCommunications(projectId: number): void {
    this.communicationsService.getCommunicationList(projectId).subscribe({
      next: (communicationsList) => this.communicationsList = communicationsList
    });
  }

  public getCommunicationsByType(communicationType: string, processId: number): void {
    this.communicationsService.getCommunicationByType(communicationType).subscribe({
      next: (communicationsList) => this.communicationsList = communicationsList
    })
  }

  private updateProjectSummary(): void {
    this.communicationSummary.project = { ...this.project };
    this.updateSummaryWithoutForm();
  }

  updateSummaryActivities(): void {
    this.communicationSummary.activities = [];

    this.form.controls['activitiesIds'].value.forEach(id => {
      let index = this.activities.findIndex(a => a.id === +id);
      this.communicationSummary.activities?.push({ id: this.activities[index].id, title: this.activities[index].title });
    });
    this.communication.emit(this.communicationSummary);
  }

  updateSummaryTitleFocusLost(): void {
    this.communicationSummary.title = this.form.controls['title'].value;
    this.communication.emit(this.communicationSummary);
  }

  updateSummaryIdentificationNumber(): void {
    this.communicationSummary.identificationNumber = this.form.controls['identificationNumber'].value;
    this.communication.emit(this.communicationSummary);
  }

  updateSummaryProtocolNumber(): void {
    this.communicationSummary.protocolNumber = this.form.controls['protocolNumber'].value;
    this.communication.emit(this.communicationSummary);
  }

  updateSummaryRegisterNumber(): void {
    this.communicationSummary.registerNumber = this.form.controls['registerNumber'].value;
    this.communication.emit(this.communicationSummary);
  }

  updateSummaryOtherIdentificationNumber(): void {
    this.communicationSummary.otherIdentificationNumber = this.form.controls['otherIdentificationNumber'].value;
    this.communication.emit(this.communicationSummary);
  }

  updateSummaryDate(): void {
    this.communicationSummary.date = this.form.controls['date'].value;
    this.communication.emit(this.communicationSummary);
  }

  updateSummaryOrganResponse(): void {
    this.communicationSummary.organResponse = this.form.controls['organResponse'].value;
    this.communication.emit(this.communicationSummary);
  }

  updateSummaryDescription(): void {
    this.communicationSummary.description = this.form.controls['description'].value;
    this.communication.emit(this.communicationSummary);
  }

  updateSummaryLicenseNumber(): void {
    this.communicationSummary.licenseNumber = this.form.controls['licenseNumber'].value;
    this.communication.emit(this.communicationSummary);
  }

  updateSummaryExistsObligation(): void {
    this.communicationSummary.existsObligation = this.form.controls['existsObligation'].value;
    this.communication.emit(this.communicationSummary);
  }

  updateSummaryLicenseEmissionDate(): void {
    this.communicationSummary.licenseEmissionDate = this.form.controls['licenseEmissionDate'].value;
    this.communication.emit(this.communicationSummary);
  }

  updateSummaryLicenseExpirationDate(): void {
    this.communicationSummary.licenseExpirationDate = this.form.controls['licenseExpirationDate'].value;
    this.communication.emit(this.communicationSummary);
  }

  updateSummaryRenewalDeadline(): void {
    this.communicationSummary.renewalDeadline = this.form.controls['renewalDeadline'].value;
    this.communication.emit(this.communicationSummary);
  }

  updateSummaryCommunicationType(): void {
    let index = this.communicationTypes.findIndex(a => a.id === +this.form.controls['communicationTypeId'].value);
    this.communicationSummary.communicationType = this.communicationTypes[index];
    this.communication.emit(this.communicationSummary);
  }

  updateSummaryResponsibleUser(): void {
    let index = this.users.findIndex(a => a.id === +this.form.controls['responsibleUserId'].value);
    this.communicationSummary.responsibleUser = { id: this.users[index].id, name: this.users[index].name };
    this.communication.emit(this.communicationSummary);
  }

  updateSummaryReturnMean(): void {
    let index = this.returnMeans.findIndex(a => a.value === +this.form.controls['returnMeanId'].value);
    this.communicationSummary.returnMean = { id: this.returnMeans[index].value, name: this.returnMeans[index].label };
    this.communication.emit(this.communicationSummary);
  }

  updateSummaryConnections(): void {
    this.communicationSummary.connections = [];

    this.form.controls['connectionsIds'].value.forEach(id => {
      let index = this.communicationsList.findIndex(a => a.id === +id);
      this.communicationSummary.connections?.push({
        id: this.communicationsList[index].id,
        name: this.communicationsList[index].title
      });
    });

    this.communication.emit(this.communicationSummary);
  }

  updateSummaryWithoutForm(): void {
    this.communication.emit(this.communicationSummary);
  }

  private updateSummaryTitle() {
    this.communicationSummary.title = (this.type === 'REC') ? 'Nova comunicação recebida' : 'Nova comunicação enviada';
  }

  private getProjectFromParameter() {
    if (this.projectId !== null) {
      this.getProject(+this.projectId);
      this.getProcessByProject(+this.projectId);
      this.getCommunications(+this.projectId);
    } else {
      this.projectDisabled = false;
      this.getProjectsByUserComplex();
    }
  }

  initForm(): void {

    this.form = this.fb.group({
      type: [this.type, [Validators.required]],
      projectId: [this.projectId, [Validators.required]],
      projectLicensingId: [this.processId, [Validators.required]],
      processNumber: [''],
      communicationTypeId: ['', [Validators.required]],
      title: ['', [Validators.required]],
      responsibleUserId: ['', [Validators.required]],
      identificationNumber: ['', [Validators.required]],
      protocolNumber: [''],
      registerNumber: [''],
      otherIdentificationNumber: [''],
      returnMeanId: ['', [Validators.required]],
      date: ['', [Validators.required]],
      organResponse: ['', [Validators.required]],
      deadlineDays: [''],
      description: ['', [Validators.required]],
      activitiesIds: [[]],
      connectionsIds: [[]],
      licenseNumber: [''],
      existsObligation: [''],
      licenseEmissionDate: [''],
      licenseExpirationDate: [''],
      renewalDeadline: [''],
    });
  }

  private getProject(projectId: number): void {
    this.projectsV2Service.getProjectByProjectId(projectId).subscribe({
      next: (project: ProjectByIdResponseDto) => {
        this.project = project;
        if(this.projectId !== null){
          this.projects = [{ id: project.id, name: project.name }];
        }
        this.updateProjectSummary();
        this.getUsers(this.project.complex.id);
      }
    });
  }

  private getReturnMeans(): void {
    this.licensingService.getMeiosRecebimentoNotificacao().subscribe({
      next: (returnMeans) => {
        this.returnMeans = returnMeans
      }
    })
  }

  private getProjectsByUserComplex(): void {
    this.projectService.getProjectsByUserComplex().subscribe({
      next: (projects) => {
        this.projects = projects;
      }
    })
  }

  private getUsers(complexId): void {
    this.userService.getUsersByComplex(complexId).subscribe({
      next: (users) => {
        this.users = users;
      }
    })
  }

  private getActivities(): void {
    if (this.form.value.projectLicensingId === null) return;
    this.activityService.getActivitiesByProjectLicenceId(this.form.value.projectLicensingId).subscribe({
      next: (activities) => {
        this.activities = this.mapActivitiesWithKeyAndLeaf(activities);

        if (this.activityId) {
          this.form.controls['activitiesIds'].disable();
          this.form.controls['activitiesIds'].setValue([+this.activityId]);
        }
      }
    })
  }

  private mapActivitiesWithKeyAndLeaf(activities: ActivitesByProcessidResponseDto[]) {
    return activities.map(activity => (
      {
        ...activity,
        key: activity.id,
        isLeaf: (activity.children === null),
        children: activity.children !== null && activity.children !== undefined ? this.mapActivitiesWithKeyAndLeaf(activity.children) : []
      })
    ) as ActivitesByProcessidResponseDto[];
  }

  updateDeadlineDaysValidators() {
    if (this.form.value.organResponse) {
      this.form.controls["deadlineDays"].addValidators([Validators.required]);
    } else {
      this.form.controls["deadlineDays"].clearValidators();
      this.form.controls["deadlineDays"].updateValueAndValidity();
    }
    this.updateSummaryOrganResponse();
  }

  getProcessByProjectAndCommunications() {
    if (this.form?.value?.projectId === null) return;
    this.form.controls['projectLicensingId'].setValue('');
    this.getProject(this.form?.value?.projectId);
    this.getProcessByProject(this.form?.value?.projectId);
    this.getCommunications(this.form?.value?.projectId);
  }

  getProcessByProject(projectId: number) {
    this.projectService.getProcessessList(projectId).subscribe({
      next: (data) => {
        this.processesList = data.content;
        if (this.processId !== null) {
          this.showProcessData();
          this.projectLicenseReadonly = true;

        }
      }
    })
  }

  showProcessData(): void {
    let index = this.processesList.findIndex(p => p.id === +this.form?.value?.projectLicensingId);
    if (index !== -1) {

      if (this.processesList[index].processNumber === undefined ||
        this.processesList[index].processNumber === null ||
        this.processesList[index].processNumber === '') {
        this.form.controls['processNumber'].enable();
        this.form.controls['processNumber'].setValue('');

      } else {
        this.form.controls['processNumber'].disable();
        this.form.controls['processNumber'].setValue(this.processesList[index].processNumber);

      }
      this.updateProcessSummary(index);
    }
    this.getActivities();
  }

  private updateProcessSummary(index: number) {
    this.communicationSummary.projectLicensing = {
      id: this.processesList[index].id,
      name: this.processesList[index].title
    };
    this.communicationSummary.organ = this.processesList[index].competentOrgan.description;
    this.communicationSummary.processNumber = this.processesList[index].processNumber;
    this.updateSummaryWithoutForm();
    this.getProcessStatusById(this.communicationSummary.projectLicensing.id);
  }

  async verifyIfIsValidFile(file) {
    await this.fileValidationService.isExeFile(file).then(result => {
      if (result === true) {
        this.isInvalidFile = true
        this.message.showErrorMessage(`Tipo de arquivo ${file.name} não é suportado!`);
      }
    })
    await this.fileValidationService.isZipFile(file).then(result => {
      if (result === true) {
        this.isInvalidFile = true
        this.message.showErrorMessage(`Conteúdo ou extensão do arquivo ${file.name} não é suportada!`);
      }
    })
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    this.verifyIfIsValidFile(file).then(result => {
      if( this.isInvalidFile === false){
        if(this.fileValidationService.isExtensionForbidden(file.name)){
          this.message.showErrorMessage(`Extenção do arquivo ${file.name} não é suportada!`);
        } else {
          if (this.checkFileNameLength(file) && this.checkFileSize(file)) {
            file.thumbUrl = this.utilService.getIconFromFileName(file.name);
            this.fileList = this.fileList.concat(file);
          }
        }
      } else {
        this.isInvalidFile = false
      }
    })
    this.fileLisToSave = this.fileList;
    return false;
  };

  removeFiles = (file: NzUploadFile): boolean => {
    this.filesToDelete.push(file.uid);
    this.fileList = this.fileList.filter((item) => item !== file);
    this.fileListDuplicated = this.fileListDuplicated.filter(
      (item) => item !== file
    );
    return false;
  };

  downloadFile = (file: NzUploadFile): void => {
    if (file.uid === undefined) return;

    this.attachmentsService.getDownloadFile(+file.uid).subscribe({
      next: (resp) => this.attachmentsService.downloadFile(resp, file.name),
    });
  };

  private checkFileNameLength(file: NzUploadFile): boolean {
    if (file.name.length > 200) {
      this.message.showErrorMessage('Nome do arquivo muito grande. Máximo é 200 caracteres.');
      return false;
    }
    return true;
  }

  private checkFileSize(file: NzUploadFile): boolean {
    if (file && file.size && file.size > 350 * 1024 * 1024) {
      this.message.showErrorMessage('O arquivo é muito grande. Por favor, selecione um arquivo com menos de 350 MB.');
      return false;
    }
    return true;
  }

  getCommunicationTypesByCategory(type: string | null) {
    if (type !== null) {
      this.communicationTypeService.getCommunicationByCategory(type).subscribe({
        next: (comTypes) => {
          this.communicationTypes = comTypes;
          this.enableAboutLicenseForm();
        }
      });
    }
  }

  calcDealineDate() {
    if(this.form.controls['deadlineDays'].value < 0) {
      this.message.showErrorMessage("Este campo só aceita números positivos!")
      this.form.controls['deadlineDays'].setValue(Math.abs(this.form.value.deadlineDays))
      return
    }
    if (this.form.value.date !== '') {

      let date = new Date(this.utilService.DateFormatter(this.form.value.date));
      date.setDate(date.getDate() + this.form.value.deadlineDays);
      this.deadLineResponseDate = this.datePipe.transform(date + '', 'dd/MM/yyyy');

      this.communicationSummary.deadLineResponseDate = date;
      this.communicationSummary.deadlineDays = this.form.value.deadlineDays;
      this.communication.emit(this.communicationSummary);
    }
  }

  enableAboutLicenseForm() {

    if (this.communicationTypes === undefined) return;

    let index = this.communicationTypes.findIndex(c => c.id === +this.form.value.communicationTypeId);

    if (index !== -1) {
      if (this.communicationTypes[index].code === 'CLA') {
        this.enableAboutLicense = true;
        this.form.controls["licenseNumber"].addValidators([Validators.required]);
        this.form.controls["existsObligation"].addValidators([Validators.required]);
        this.form.controls["licenseEmissionDate"].addValidators([Validators.required]);
        this.form.controls["renewalDeadline"].addValidators([Validators.required]);
        this.form.controls["licenseExpirationDate"].addValidators([Validators.required]);
        this.complementaryResponseSent = false;
      } else if (this.communicationTypes[index].code === 'AIC') {
        this.complementaryResponseSent = true;
        if (this.processId) {
          this.getCommunicationsByType("PIC", +this.processId);
          this.form.controls["connectionsIds"].addValidators([Validators.required]);
        }
      } else {
        this.complementaryResponseSent = false;
        this.enableAboutLicense = false;
        this.form.controls["licenseNumber"].clearValidators();
        this.form.controls["licenseNumber"].updateValueAndValidity();

        this.form.controls["existsObligation"].clearValidators();
        this.form.controls["existsObligation"].updateValueAndValidity();

        this.form.controls["licenseEmissionDate"].clearValidators();
        this.form.controls["licenseEmissionDate"].updateValueAndValidity();

        this.form.controls["licenseExpirationDate"].clearValidators();
        this.form.controls["licenseExpirationDate"].updateValueAndValidity();

        this.form.controls["renewalDeadline"].clearValidators();
        this.form.controls["renewalDeadline"].updateValueAndValidity();
      }
    }
    this.updateSummaryCommunicationType()
  }

  private save() {

    this.isSaving.subscribe(() => {

      if(!this.form.controls['deadlineDays'].valid) {
        this.message.showErrorMessage("Prazo para resposta deverá ser um valor válido!")
        return
      }
      if (this.form.valid) {
        this.removeDuplicatedFiles();

        if (this.communicationId === null) {
          this.createCommunication();
        } else {

          this.deleteAttachment(this.filesToDelete);

          this.updateCommunication(+this.communicationId);

        }

      } else {
        Object.values(this.form.controls).forEach(control => {
          if (control.invalid) {
            control.markAsDirty()
            control.updateValueAndValidity({ onlySelf: true })
          }
        });

        this.message.showErrorMessage("Preencha todos os campos obrigatórios antes de salvar!");
      }

    });
  }

  private handleProgress() {
    let modalProgressComponent = this.modalProgress();

    if (this.fileLisToSave.length > 0) {
      let progressPerFile = 100 / this.fileLisToSave.length;
      this.loopThroughFiles(modalProgressComponent, progressPerFile);
    } else {
      modalProgressComponent.progress = 100;
      this.checkProgressAndCloseModal(modalProgressComponent);
    }
  }

  private loopThroughFiles(modalProgressComponent: ProgressModalComponent,
    progressPerFile: number) {
    for (let file of this.fileLisToSave) {

      if (!this.fileListAlreadyUploaded.some((item) => item.uid === file.uid)) {

        const attachment = this.buildFilePayload(file);

        this.uploadAttachmentFile(attachment, modalProgressComponent, progressPerFile);

      } else {
        modalProgressComponent.progress += progressPerFile;
        this.checkProgressAndCloseModal(modalProgressComponent);
      }
    }
  }

  private uploadAttachmentFile(attachment: AttachmentSaveDto,
    modalProgressComponent: ProgressModalComponent,
    progressPerFile: number) {
    attachment.contextInfo = {
      phase: 'Processos'
    };
    this.attachmentsService
      .uploadAttachment(attachment)
      .pipe(take(1))
      .subscribe({
        next: () => {
          modalProgressComponent.progress += progressPerFile;
          this.checkProgressAndCloseModal(modalProgressComponent);
        }
      });
  }

  private checkProgressAndCloseModal(modalProgressComponent: ProgressModalComponent) {
    if (modalProgressComponent.progress > 99) {
      this.modal.closeAll();
      this.modalSuccess();
    }
  }

  modalSuccess() {
    const modal = this.modal.create<SuccessConfirmationComponent>({
      nzTitle: '',
      nzClosable: true,
      nzWidth: 555,
      nzCentered: true,
      nzContent: SuccessConfirmationComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });

    const instance = modal.getContentComponent();

    instance.title = 'Comunicação salva com sucesso!';
    instance.subTitle = '';

    modal.afterClose.subscribe(() => {
      this.location.back();
    });
  }

  private buildFilePayload(file: NzUploadFile) {
    const attachment: AttachmentSaveDto = {
      name: file.name,
      description: file.name,
      entityId: this.communicationId !== null ? +this.communicationId : 0,
      entityName: COMMUNICATION_ENTITY_NAME,
      contextInfo: {
        phase: 'Cadastro'
      },
      file,
    };
    return attachment;
  }

  private createCommunication() {
    this.communicationsService.createCommunication(this.buildPayload()).subscribe({
      next: (response) => {
        this.communicationId = response;
        this.handleProgress();
      },
      error: () => this.message.showErrorMessage("Erro ao salvar comunicação!")
    });
  }

  private updateCommunication(communicationId: number) {
    this.communicationsService.updateCommunication(this.buildPayload(), communicationId).subscribe({
      next: () => {
        this.handleProgress();
      },
      error: (error) => {
        if (error.message && error.message.includes("Descrição")) {
          this.message.showErrorMessage(error.message)
        } else {
          this.message.showErrorMessage("Erro ao salvar comunicação!")
        }
      }
    });

  }

  modalProgress(): ProgressModalComponent {

    const modal = this.modal.create<ProgressModalComponent>({
      nzTitle: '',
      nzClosable: false,
      nzMaskClosable: false,
      nzKeyboard: false,
      nzWidth: 555,
      nzCentered: true,
      nzContent: ProgressModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzFooter: null,
    });

    const instance = modal.getContentComponent();

    instance.subtitulo = 'Isso pode levar alguns minutos'

    instance.titulo = 'Salvando comunicação e fazendo upload dos arquivos...';

    this.progress = 0;
    instance.progress = this.progress;

    return instance;
  }

  removeDuplicatedFiles() {

    this.fileLisToSave = this.fileListDuplicated
      .filter(
        (item1) =>
          !this.fileList.some(
            (item2) => item1.uid === item2.uid && item1.name === item2.name
          )
      )
      .concat(
        this.fileList.filter(
          (item2) =>
            !this.fileListDuplicated.some(
              (item1) => item2.uid === item1.uid && item2.name === item1.name
            )
        )
      );
  }

  deleteAttachment(fileListToDelete: string[]): void {
    if (this.filesToDelete.length > 0) {
      for (const file of fileListToDelete) {
        this.attachmentsService
          .deleteAttachment(file)
          .pipe(take(1))
          .subscribe(() => {
          });
      }
    }
  }

  buildPayload(): CommunicationRequestDto {
    const activitiesIds = this.form.controls['activitiesIds']?.value?.filter(id => (id != null && !Number.isNaN(id)));

    return {
      ...this.form.value,
      activitiesIds: activitiesIds,
      date: this.utilService.DateFormatter(this.form.controls['date'].value),
      licenseEmissionDate: this.form.controls['licenseEmissionDate'].value !== '' ? this.utilService.DateFormatter(this.form.controls['licenseEmissionDate'].value) : '',
      licenseExpirationDate: this.form.controls['licenseExpirationDate'].value !== '' ? this.utilService.DateFormatter(this.form.controls['licenseExpirationDate'].value) : ''
    } as CommunicationRequestDto;
  }
}
