import { Component, Input, type OnInit, inject } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Observable, take } from 'rxjs';
import { UtilService } from '../../util.service';

@Component({
  selector: 'app-loading-modal',
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss']
})
export class LoadingComponent implements OnInit {
  readonly #modal = inject(NzModalRef);
  @Input() modalTitle: string;
  @Input() loadingCalls: any[];
  @Input() savingMessage: string;
  @Input() savedEntityIdPath: string;
  @Input() initialCallsAmount?: number;
  @Input() requiredValuePath?: string;
  currentLoadingLabel: string;
  entityId: any;
  callsPromisified: any[] = [];
  lastCall: any;
  requiredValue: string;

  constructor(
    private readonly utilService: UtilService
  ){}

  ngOnInit(): void {
    this.initialCallsAmount = this.initialCallsAmount ? this.initialCallsAmount : 1;
    this.makeApiCalls();
  }

  async makeApiCalls() {
    let loadingCalls = this.loadingCalls.slice(0, this.initialCallsAmount);
    
    for (let loadingCall of loadingCalls) {
      if (loadingCall && loadingCall.call) {
        loadingCall.state = 'in_progress';
        await new Promise<void>((resolve, reject) => (loadingCall.call() as Observable<any>).pipe(take(1))
          .subscribe({
            next: savedEntity => {
              try {
                if (this.savedEntityIdPath) {
                  this.entityId = this.utilService.getNestedObjectByString(savedEntity, this.savedEntityIdPath);
                  if (this.requiredValuePath) {
                    this.requiredValue = this.utilService.getNestedObjectByString(savedEntity, this.requiredValuePath);
                  }
                }
              } catch (e) {
                reject(`ID not found with ${this.savedEntityIdPath} path`)
              }
  
              resolve();
            }
          }
        ))
  
        loadingCall.state = 'finished';
      }
    }

    await this.callAttachmentsUpload(this.loadingCalls.slice(this.initialCallsAmount));
  }

  async callAttachmentsUpload(uploadsCalls: any[]) {
    await Promise.all(uploadsCalls.map(call => {
      call.state = 'in_progress';
      this.currentLoadingLabel = call.label;

      if (call.lastCall) {
        this.lastCall = call;
        return true;
      }

      const callPromisified = this.promisifyCall(call);

      this.callsPromisified.push(callPromisified);
      return callPromisified;
    })).then(async () => {
      if (this.lastCall) {
        this.currentLoadingLabel = this.lastCall.label;
        await this.lastCall.call(this.entityId).pipe(take(1)).subscribe({
          next: (savedEntity) => {
            if (this.requiredValuePath && !this.requiredValue) {
              this.requiredValue = this.utilService.getNestedObjectByString(savedEntity, this.requiredValuePath);
            }
            this.closeModal();
          }
        });
      } else {
        this.closeModal();
      }
    }).catch(() => {
      this.closeModal(false);
    })
  }

  private promisifyCall(call: any): Promise<void> {
    return new Promise<void>((resolve, reject) => (call.call(this.entityId) as Observable<any>).pipe(take(1))
      .subscribe({
        next: () => {
          this.currentLoadingLabel = call.label;
          call.state = 'finished';
          resolve()
        },
        error: () => {
          reject();
        }
      })
    )
  }

  getCurrentUrl() {
    return document.URL
  }

  closeModal(status: boolean = true) {
    this.#modal.destroy({
      entityId: this.entityId,
      status,
      requiredValue: this.requiredValue
    });
  }

}
