<div class="activities-header-actions">
  <div class="h3-size">{{ "activities.study-items-title" | translate }}</div>
  <a (click)="toNewEIAStudy()" class="project-doc-link new-content" *ngIf="cadActivityInsert">
    <img src="/assets/icons/icon_plus_circle.svg" alt="{{'projects.view.add-ripla'}}">
  </a>
</div>

<table class="sl-table" *ngIf="isExistActivity()">
  <thead>
    <tr>
      <th>{{ 'commons.title' | translate }}</th>
      <th>{{ 'commons.responsible' | translate }}</th>
      <th class="act-completition">{{ 'commons.completion-date' | translate  }}</th>
      <th class="act-status">{{ 'commons.status' | translate }}</th>
      <th class="act-actions"></th>
    </tr>
  </thead>
</table>

<div *ngIf="isExistActivity()" class="activity_study_items_container">
  <div class="activity-row" *ngFor="let activity of activities; let i = index">
    <div class="item">
      <div class="activity-header" [class.pl-not-icon]="!activity.children?.length">
        <div class="expand-row" (click)="toggle(activity)" [ngClass]="{'rotate-invert90': !activity.expanded}" *ngIf="activity.children?.length">
          <img src="/assets/icons/icon_arrow_down_small.svg" alt="expandir">
        </div>
        <span class="btn_link" (click)="goToActivity(activity.id)">
          <app-short-text-hint [originalText]="activity.title" [maxChars]="25">
            {{ activity.title }}
          </app-short-text-hint>
        </span>
      </div>
      <div class="act-responsible">{{ activity.responsibleUser }}</div>
      <div class="act-completition">{{ activity.dueDate }}</div>
      <div class="act-status"><app-badge [badgeText]="activity.status"></app-badge></div>
      <div *ngIf="cadActivtyExclude" class="act-actions"
        nz-popover nzPopoverTrigger="click"
        [nzPopoverContent]="popDelete"
        nzPopoverPlacement="leftBottom">
        <app-svg-icon icon="icon_more_vertical" [current]="true"></app-svg-icon>
        <ng-template #popDelete class="whiteDropdown">
          <span style="display: flex; align-items: center;">
            <app-svg-icon icon="trash_document-icon" [current]="true" style="height: 24px;padding-top: 3px;"> </app-svg-icon>
            <button class="btn-text-green" style="padding-left: 0;" (click)="deleteActivity(activity.id)">
              {{ 'commons.delete' | translate}}
            </button>
          </span>
        </ng-template>
      </div>
    </div>

    <div *ngIf="activity.children?.length && activity.expanded">
      <div class="item-child" *ngFor="let children of activity.children">
        <div class="child activity-header" (click)="goToActivity(children.id)">
          <app-short-text-hint
            [originalText]="children.title"
            [maxChars]="25">
          </app-short-text-hint><span></span>

          <span *ngIf="children.children?.length">
            <div class="hidden-childs txt-dark4">
              +{{ activity.children!.length - 1 }}
            </div>
          </span>
        </div>
        <div class="child act-responsible">{{ children.responsibleUser }}</div>
        <div class="child act-completition">{{ children.dueDate }}</div>
        <div class="child act-status"><app-badge [badgeText]="children.status"></app-badge></div>
        <div *ngIf="cadActivtyExclude" class="child act-actions"
          nz-popover
          nzPopoverTrigger="click"
          [nzPopoverContent]="popDelete"
          nzPopoverPlacement="leftBottom">
          <app-svg-icon icon="icon_more_vertical" [current]="true"></app-svg-icon>
          <ng-template #popDelete>
            <span style="display: flex; align-items: center;">
              <app-svg-icon icon="trash_document-icon" [current]="true" style="height: 24px;padding-top: 3px;"></app-svg-icon>
              <button class="btn-text-green"style="padding-left: 0;" (click)="deleteActivity(children.id)">
                {{ 'commons.delete' | translate}}
              </button>
            </span>
          </ng-template>
        </div>
      </div>
    </div>

  </div>
</div>
