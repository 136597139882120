<div nz-row style="margin-top: 10px">
    <div nz-col [nzSpan]="24" class="prazos">
      <div>
        <svg class="icon" width="6" height="16" viewBox="0 0 6 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="5" height="15" rx="2.5" fill="#969696" stroke="white"/>
          </svg>
          
        <span class="text">A iniciar</span>

        <svg class="icon" width="6" height="16" viewBox="0 0 6 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="5" height="15" rx="2.5" fill="#61B3E0" stroke="white"/>
          </svg>
          
        <span class="text">Em andamento</span>
        <svg class="icon" width="6" height="16" viewBox="0 0 6 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="5" height="15" rx="2.5" fill="#FFC943" stroke="white"/>
        </svg>
          
        <span class="text">Cancelado/Suspenso</span>

        <svg class="icon" width="6" height="16" viewBox="0 0 6 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="5" height="15" rx="2.5" fill="#00A599" stroke="white"/>
          </svg>
          
        <span class="text">Concluído</span>
        <svg class="icon" width="6" height="16" viewBox="0 0 6 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="5" height="15" rx="2.5" fill="#DC2F37" stroke="white"/>
          </svg>
          
        <span class="text">Em atraso</span>
      </div>
    </div>
  </div>